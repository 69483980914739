import { Checkbox, FormControlLabel, FormGroup, TextField, Tooltip } from '@mui/material';
import React from 'react';
import { bethelChoirType, girlsChoirType, kidsChoirType, LightBlue, LightDarkGray, mensChoirType, otherChoirType, seniorChoirType, unitedChoirType, youthChoirType } from '../../../../../../constants';
import InfoIcon from '@mui/icons-material/Info';

const ChoirTypeSelector = (props) => {
    const { serviceData, handleSongSubEntityTypeChange } = props;

    return (
        <>
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center', marginTop: '5px'}}>
                <TextField
                    select
                    label="Choir Type"
                    SelectProps={{
                        native: true
                    }}
                    sx={{marginTop: '10px'}}
                    fullWidth
                    size="small"
                    value={serviceData.song_sub_entity}
                    onChange={handleSongSubEntityTypeChange}
                    >
                    <option value=''></option>
                    <option value={seniorChoirType}>Senior Choir</option>
                    <option value={youthChoirType}>Youth Choir</option>
                    <option value={mensChoirType}>Mens Choir</option>
                    <option value={girlsChoirType}>Girls Choir</option>
                    <option value={kidsChoirType}>Kids Choir</option>
                    <option value={unitedChoirType}>United Choir</option>
                    <option value={bethelChoirType}>Bethel Choir</option>
                    <option value={otherChoirType}>Other Choir</option>
                </TextField>

                <Tooltip title={
                    <div>
                        <div>Senior Choir: Only Senior Choir</div>
                        <div>Youth Choir: Only Youth Choir</div>
                        <div>Mens Choir: Only Mens Choir</div>
                        <div>Girls Choir: Only Girls Choir</div>
                        <div>Kids Choir: Only Kids Choir</div>
                        <div>United Choir: Senior Choir, Youth Choir, and Kids Choir combined</div>
                        <div>Bethel Choir: Senior Choir and Youth Choir combined</div>
                        <div>Other Choir: Usually reversed for a guest choir</div>
                    </div>
                            }
                    sx={{
                        color: LightDarkGray,
                        marginLeft: '10px',
                        '&:hover': {
                            color: LightBlue
                        },
                    }}>
                    <InfoIcon />
                </Tooltip>
            </div>
            <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked={false} size="small"/>} label="With Praise Band" />
            </FormGroup>
        </>
    );
};

export default ChoirTypeSelector;
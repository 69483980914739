import React from 'react';
import GeneralDescriptionSelector from '../generalDescriptionSelector';
import GeneralTitleSelector from '../generalTitleSelector';
import LanguageSelector from '../languageSelector';
import PersonnelSelector from '../personnelSelector';
import SongTitleSelector from '../songTitleSelector';

const Other = (props) => {
    const { serviceData, handleLanguageChange, handleSongTitleEnChange, handleSongTitleRoChange, handleAddPersonnel, addPersonDialogOpen, addPeopleOpen, handlePersonChange, people, handlePositionChange, saveNewPerson, newPerson, newPosition, handleAddPerson, handleAddPeopleClose, removePerson, handleGeneralTitleEnChange, handleGeneralTitleRoChange, handleGeneralDescriptionEnChange, handleGeneralDescriptionRoChange, addPersonToPeople, personPanelIsOpen, handleAddPersonClose } = props;

    return ( 
        <>
            <LanguageSelector serviceData={serviceData} handleLanguageChange={handleLanguageChange}/>
            {serviceData.language && <SongTitleSelector serviceData={serviceData} handleSongTitleEnChange={handleSongTitleEnChange} handleSongTitleRoChange={handleSongTitleRoChange}/>}
            {(serviceData.song_title_en || serviceData.song_title_ro) && <GeneralTitleSelector serviceData={serviceData} handleGeneralTitleRoChange={handleGeneralTitleRoChange} handleGeneralTitleEnChange={handleGeneralTitleEnChange}/>}
            {(serviceData.song_title_en || serviceData.song_title_ro) && <GeneralDescriptionSelector serviceData={serviceData} handleGeneralDescriptionEnChange={handleGeneralDescriptionEnChange} handleGeneralDescriptionRoChange={handleGeneralDescriptionRoChange}/>}
            {((serviceData.general_title_en || serviceData.general_title_ro) && (serviceData.general_description_en || serviceData.general_description_ro)) && <PersonnelSelector serviceData={serviceData} handleAddPersonnel={handleAddPersonnel} addPersonToPeople={addPersonToPeople} personPanelIsOpen={personPanelIsOpen} handleAddPersonClose={handleAddPersonClose} addPersonDialogOpen={addPersonDialogOpen} addPeopleOpen={addPeopleOpen} handlePersonChange={handlePersonChange} people={people} handlePositionChange={handlePositionChange} saveNewPerson={saveNewPerson} parentProps={props} newPerson={newPerson} newPosition={newPosition} handleAddPerson={handleAddPerson} handleAddPeopleClose={handleAddPeopleClose} removePerson={removePerson}/>}
        </>
    );
};

export default Other;
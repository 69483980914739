import { useContext, useEffect, useRef, useState } from "react";
import {
  BY_DATE,
  BY_RECENT,
  DarkGray,
  DEFAULT,
  defaultVimeoId,
  EN,
  unavailableVideoString,
} from "../../constants";
import MessagePanel from "../../multiuseComponents/messagePanel/messagePanel";
import ServiceContext from "../../contexts/serviceContext";
import * as dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import unavailable_en from "../../assets/video/Unavailable_EN.mp4";
import unavailable_ro from "../../assets/video/Unavailable_RO.mp4";
import ServiceLoading from "../../components/ServiceLoading";
import UrgentMessageDisplay from "../../components/UrgentMessageDisplay";
import { services_endpoint } from "../../helpers/api/apiConstants";
import { getUrl } from "../../helpers/api/api";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../app/slices/languageSlice";

const mainStyle = {
  backgroundColor: "white",
  fontFamily: "Montserrat",
  display: "flex",
  justifyContent: "center",
  position: "relative",
  top: "56px",
  width: "100%",
  flex: "1",
};

const mainStyleMobile = {
  backgroundColor: "white",
  fontFamily: "Montserrat",
  display: "flex",
  flexDirection: "column",
  top: "56px",
  width: "100%",
  position: "relative",
};

const messagePanelStyle = {
  color: DarkGray,
  flexDirection: "column",
  alignItems: "center",
  width: "400px",
  position: "fixed",
  top: "56px",
  right: "0",
};

const messagePanelStyleMobile = {
  color: DarkGray,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  overflow: "auto",
  marginTop: "10px",
  height: "100%",
};

const videoPanelStyle = {
  flex: "1",
  overflow: "visible",
  position: "fixed",
  top: "0",
  left: "0",
  bottom: "0",
  right: "400px",
  width: "calc(100vw - 400px)",
  zIndex: 1,
};

const videoStyle = {
  position: "absolute",
  top: "56px",
  left: 0,
  width: "100%",
  height: "100%",
  maxHeight: "calc(100vh - 100px)",
  objectFit: "fill",
  zIndex: 1,
  overflowY: "visible",
};

const videoStyleMobile = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  maxHeight: "calc(100vh - 100px)",
  objectFit: "fill",
  zIndex: 1,
  overflowY: "visible",
};

const videoPanelStyleMobile = {
  width: "100%",
  top: "56px",
  position: "sticky",
  zIndex: "1",
};

const defaultVideoStyle = {
  width: "100%",
  height: "auto",
  overflowX: "hidden",
};

const reelSource =
  "https://firebasestorage.googleapis.com/v0/b/bethel-bible-church.appspot.com/o/ServicesOverlay720.mov?alt=media&token=6640fe9b-fafd-4ac1-8729-3d65cfd8ee3d";
const photoSource =
  "https://firebasestorage.googleapis.com/v0/b/bethel-bible-church.appspot.com/o/OverlayGif.gif?alt=media&token=dfd8b630-97d9-484d-ae3c-c90d82c741c0";

const Services = () => {
  const { serviceId, setServiceId } = useContext(ServiceContext);
  const language = useAppSelector(selectLanguage);
  const location = useLocation();
  const videoRef = useRef(null);
  const [vimeoId, setVimeoId] = useState("");
  const [services, setServices] = useState();
  const [isDefault, setIsDefault] = useState(true);
  const [videoError, setVideoError] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState(BY_RECENT);
  const [sortDate, setSortDate] = useState(dayjs());
  const [isMobile, setIsMobile] = useState(false);
  const [startSecond, setStartSecond] = useState("0");
  const [serviceStartTimestamp, setServiceStartTimestamp] = useState();
  const [servicePointTimestamp, setServicePointTimestamp] = useState();
  const [autoplayError, setAutoPlayError] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(true);

  // SERVICES FETCHING -----------------------------------------------
  // Gets the initial service set
  const getServices = async () => {
    fetch(
      getUrl(
        `${services_endpoint}?page=${page}${
          sortBy === BY_DATE ? `&date=${sortDate}` : ""
        }`
      )
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (!data || data.length < 40) {
          setHasMore(false);
        }
        setServices(data);
        setLoadingInitial(false);
      })
      .catch((error) => {
        console.error("Error getting service data. Error: ", error);
      });
  };

  // Gets more data after a scroll
  const getMoreServices = async () => {
    fetch(
      getUrl(
        `${services_endpoint}?page=${page}${
          sortBy === BY_DATE ? `&date=${sortDate}` : ""
        }`
      )
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (!data || data.length < 40) {
          setHasMore(false);
        }
        const tmpServices = [...services];
        setServices([].concat(tmpServices, data));
      })
      .catch((error) => {
        console.error("Error getting service data. Error: ", error);
      });
  };

  /**
   * This use effect is used for loading up initial service data when clicking on the services page.
   */
  useEffect(() => {
    getServices();
    setIsMounted(true);
    handleResize();
    window.addEventListener("resize", handleResize);

    // Handles a Vimeo error
    const handleMessage = (event) => {
      if (event.origin !== "https://player.vimeo.com") {
        return;
      }
      if (
        event.data.event === "error" &&
        event.data.data.message === "This video does not exist."
      ) {
        console.error("Vimeo video not found");
        setVideoError(true);
      }
    };

    window.addEventListener("message", handleMessage, false); // This captures a Vimeo error

    // This captures an autoplay error
    const video = videoRef.current;
    video.muted = true;
    video.play().catch(() => {
      setAutoPlayError(true);
    });

    return () => {
      setServices(null);
      setIsMounted(false);
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  /**
   * This use effect is used for service videos and loading them up.
   */
  useEffect(() => {
    if (serviceId) {
      setShowControls(true);
      setVideoError(false);
      if (services) {
        const service = services.find(
          (service) => service.SERVICE_KEY === serviceId
        );
        if (service) {
          setServiceStartTimestamp(service?.START_TIMESTAMP_UNIX);
          const vimeo_id = service?.VIMEO_ID;
          setVimeoId(
            vimeo_id !== DEFAULT ? vimeo_id : defaultVimeoId(language)
          );
        }

        //setVimeoId(services.filter((service) => service.SERVICE_KEY === serviceId)[0].VIMEO_ID);
      }
      setIsDefault(false);
    } else {
      setIsDefault(true);
    }
  }, [serviceId, isDefault, showControls, services]);

  /**
   * This use effect is used to query for more data whenever there is a page change
   */
  useEffect(() => {
    if (isMounted) {
      if (page === 1) {
        setHasMore(true);
        getServices();
      } else {
        getMoreServices();
      }
    }
  }, [page]);

  /**
   * This use effect is to set the starting time based on clicking a service point
   */
  useEffect(() => {
    if (serviceStartTimestamp && servicePointTimestamp) {
      const time = (servicePointTimestamp - serviceStartTimestamp).toString();
      setStartSecond(time);
    } else {
      setStartSecond("0");
    }
  }, [serviceStartTimestamp, servicePointTimestamp]);

  /**
   * This use effect is used to reset a page of result when either the sorting by is changed or if the date is changed
   */
  useEffect(() => {
    setHasMore(true);
    if (page === 1) {
      getServices();
    } else {
      setPage(1);
    }
  }, [sortBy, sortDate]);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 990);
  };

  // ------------------------------------------------------------------
  const getMore = () => {
    setPage(page + 1);
  };

  const handleSortByChange = (value) => {
    setSortBy(value);
  };

  const handleDateChange = (date) => {
    setSortDate(date);
  };

  const handlePointTimestamp = (value) => {
    setServicePointTimestamp(value);
  };

  const handleAddResultToResults = (result) => {
    // If the service isn't already in the list, we'll add it into the list so we can play the video
    if (
      !services.find((service) => service.SERVICE_KEY === result.SERVICE_KEY)
    ) {
      const newResult = {
        DATE: result.DATE,
        DAY_NIGHT: result.DAY_NIGHT,
        DISABLE_SERVICE: false,
        SERVICE_KEY: result.SERVICE_KEY,
        START_TIMESTAMP: result.service_start_timestamp,
        START_TIMESTAMP_UNIX: result.service_start_timestamp_unix,
        VIMEO_ID: result.VIMEO_ID,
        WEEKDAY: result.WEEKDAY,
        YEAR: result.YEAR,
      };

      const tmpServices = [...services];
      setServices([].concat(tmpServices, [newResult]));
    }
  };

  // This use effect is used for changing the serviceId back to blank after leaving this page so that we can go back to default when returning to it
  useEffect(() => {
    // function to be fired off when URL changes
    const handleRouteChange = () => {
      setServiceId("");
    };
    handleRouteChange();
  }, [location.pathname]);

  return (
    <div style={isMobile ? mainStyleMobile : mainStyle}>
      <UrgentMessageDisplay page="Services" />

      <div style={isMobile ? videoPanelStyleMobile : videoPanelStyle}>
        {isDefault && (
          <div>
            {!autoplayError && (
              <video
                autoPlay
                muted
                playsInline
                loop
                style={defaultVideoStyle}
                ref={videoRef}
              >
                <source src={reelSource} type="video/mp4" />
              </video>
            )}
            {autoplayError && (
              <div>
                <img
                  src={photoSource}
                  alt="background-gif"
                  style={defaultVideoStyle}
                />
              </div>
            )}
          </div>
        )}

        {!isDefault && (
          <div
            style={{
              paddingBottom: "56.25%",
              position: "relative",
              display: "block",
              maxHeight: "calc(100vh - 56px - 44px)",
              maxWidth: "100%",
            }}
          >
            {!videoError && isMounted ? (
              <iframe
                key={vimeoId + startSecond}
                src={`https://player.vimeo.com/video/${vimeoId}?autoplay=1#t=${startSecond}s`}
                width="100%"
                height="100%"
                play="true"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                autoPlay={true}
                controls={true}
                showcontrols="true"
                title="Video Serivce"
                style={isMobile ? videoStyleMobile : videoStyle}
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                playsInline
                allowFullScreen
              />
            ) : (
              <video
                loop="true"
                autoPlay="true"
                style={{ width: "100%" }}
                src={language === EN ? unavailable_en : unavailable_ro}
                alt={unavailableVideoString(language)}
              />
            )}
          </div>
        )}
      </div>

      <div style={isMobile ? messagePanelStyleMobile : messagePanelStyle}>
        {services && (
          <MessagePanel
            from={"services"}
            data={services}
            hasMore={hasMore}
            getMore={getMore}
            sortBy={sortBy}
            handleSortByChange={handleSortByChange}
            sortDate={sortDate}
            handleDateChange={handleDateChange}
            isMobile={isMobile}
            handlePointTimestamp={handlePointTimestamp}
            handleAddResultToResults={handleAddResultToResults}
          />
        )}
        {loadingInitial && <ServiceLoading />}
      </div>
    </div>
  );
};

export default Services;

import React from 'react';
import { BsFillGeoAltFill } from 'react-icons/bs'; // Import for the address pin
import { street, city, state, zip } from '../../constants';
import { isMobile } from 'react-device-detect';

// Styling object for the address
const addressStyle = {
    fontSize: '1rem',
    paddingTop: '5px'
};

const addressMobileStyle = {
    fontSize: '0.7rem',
    paddingTop: '1px'
}

/**
 * This component is for a footer address
 * @returns Footer address component
 */
const AddressComponent = () => {
    return (
        <div style={isMobile ? addressMobileStyle : addressStyle}> 
            <a href='https://goo.gl/maps/uz7WX7xbMQ9sioxb7' target='_blank'  rel="noreferrer" style={isMobile ? addressMobileStyle : addressStyle}>
            <span className='mapPoint'><BsFillGeoAltFill /></span>{street}, {city}, {state} {zip}</a>
        </div>
    );
};

export default AddressComponent;
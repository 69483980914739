import { Grid, useTheme } from '@mui/material';
import { Button } from '@mui/material';
import React from 'react';
import RequireAuth from '../requireAuth';
import { Link, useNavigate } from "react-router-dom";
import { DarkGray, dashboardPath, goLivePath, LightGray, urgentMessagesDashboardPath } from '../../../constants';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Userfront from "@userfront/react";
import BackButton from '../backButton';
import Card from '../../../components/dashboard/card';
import { faArrowRightFromBracket, faRotateLeft, faRss, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const menuStyle = {
    alignItems: 'center',
    backgroundColor: LightGray,
    width: '100%',
    paddingTop: '10px',
    paddingBottom: '10px',
    color: DarkGray,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '56px'
};

const cardContainerStyle = {
    flex: '0 0 calc(33.33% - 10.66px)',  // We subtract 1/3 of the total gap to ensure 3 fit in a row.
};

const Properties = () => {
    const theme = useTheme();

    const mainStyle = {
        fontFamily: "Montserrat",
        display: 'flex',
        flexDirection: 'column',
        margin: '0',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'space-between', // Make the items spread out
        backgroundColor: theme.palette.secondary.main,
    };

    const cardsContainerStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '16px',
        maxWidth: 'calc(282px + 282px + 282px + 200px)'
    };

    const contentWrapperStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,  // This will make it take the available vertical space
        width: '100%',
    };

    const navigate = useNavigate();
    const handleBackButton = () => {
        navigate(dashboardPath);
    };

    return (
        <div style={mainStyle}>
            <RequireAuth />
            <BackButton backToPath={dashboardPath} />

            <div style={contentWrapperStyle}>
                <div style={cardsContainerStyle}>

                    <div style={cardContainerStyle} onClick={() => window.location.href = goLivePath}>
                        <Card
                            icon={faRss}
                            title="Live Feed Settings"
                            description="Update live feed status, link, and more" />
                    </div>

                    <div style={cardContainerStyle} onClick={() => window.location.href = urgentMessagesDashboardPath}>
                        <Card
                            icon={faTriangleExclamation}
                            title="Urgent Messages"
                            description="Post, edit, and delete urgent messages for BBC guests" />
                    </div>

                    <div style={cardContainerStyle} onClick={() => window.location.href = dashboardPath}>
                        <Card
                            icon={faRotateLeft}
                            title="Main Menu"
                            description="Go back to the main admin dashboard" />
                    </div>

                    <div style={cardContainerStyle} onClick={Userfront.logout}>
                        <Card
                            icon={faArrowRightFromBracket}
                            title="Sign Out"
                            description="Sign out from Bethel Bible Church "
                        />
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Properties;
import { NavLink } from "react-router-dom";
import {
  homePath,
  servicePath,
  donationsPath,
  contactPath,
  homeString,
  servicesString,
  donationsString,
  contactString,
} from "../../constants";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../app/slices/languageSlice";

/*
 * STYLES
 */
const menuItemsStyle = {
  display: "flex",
  flexDisplay: "row",
  alignItems: "center",
  padding: "0",
  margin: "0",
};

const navStyle = {
  fontSize: "1.2rem",
  alignItems: "center",
};

/**
 * Menu Items displayed on the footer when in full screen
 * @param props - handleClick gets passed here to handle the navigation from the footer
 * @returns
 */
const MenuItems = (props) => {
  const language = useAppSelector(selectLanguage);

  return (
    <div stlye={menuItemsStyle}>
      <NavLink
        activeclassname="active"
        onClick={props.handleClick}
        to={homePath}
        style={navStyle}
      >
        {" "}
        {homeString(language)}{" "}
      </NavLink>
      <NavLink
        activeclassname="active"
        onClick={props.handleClick}
        to={servicePath}
        style={navStyle}
      >
        {" "}
        {servicesString(language)}{" "}
      </NavLink>
      <NavLink
        activeclassname="active"
        onClick={props.handleClick}
        to={donationsPath}
        style={navStyle}
      >
        {" "}
        {donationsString(language)}{" "}
      </NavLink>
      <NavLink
        activeclassname="active"
        onClick={props.handleClick}
        to={contactPath}
        style={navStyle}
      >
        {" "}
        {contactString(language)}{" "}
      </NavLink>
    </div>
  );
};

export default MenuItems;

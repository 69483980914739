import { Autocomplete, Checkbox, createFilterOptions, FormControlLabel, FormGroup, TextField } from '@mui/material';
import React from 'react';
import AddPersonComponent from '../../../../addPersonComponent';

const filter = createFilterOptions();

const SpeakerSelector = (props) => {
    const { newPerson, people, handlePersonChange, addPersonToPeople, personPanelIsOpen, handleAddPersonClose, handleGuestSpeakerChange } = props;

    return (
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>  
            {<AddPersonComponent isOpen={personPanelIsOpen} onClose={handleAddPersonClose} addPersonToPeople={addPersonToPeople} changePerson={handlePersonChange}/>}
            {<Autocomplete
                    value={newPerson}
                    size="small"
                    onChange={(event, newValue) => {
                        handlePersonChange(newValue);
                    }
                }
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== '') {
                            filtered.push({
                            inputValue: params.inputValue,
                            label: `Add "${params.inputValue}"`,
                            });
                        }
                        return filtered;
                    }}
                    id="Speakers"
                    options={people}
                    getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.label;
                    }}
                    
                    renderOption={(props, option) => <li {...props}>{option.label}</li>}
                    
                    freeSolo
                    fullWidth
                    renderInput={(params) => <TextField {...params} label="Speaker" />}
                    sx={{marginTop: '10px'}}
                />}
                <FormGroup sx={{marginLeft: '5px'}}>
                    <FormControlLabel control={<Checkbox defaultChecked={false} onClick={handleGuestSpeakerChange}/>} label="Guest" labelPlacement="left"/>
                </FormGroup>
        </div>
    );
};

export default SpeakerSelector;
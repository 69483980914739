import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { LightDarkGray, PastelRed } from '../../../../../../constants';
import AddPersonDialog from '../../../addPersonDialog';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

const mainStyle = {
    marginTop: '5px',
    color: LightDarkGray,
    fontFamily: 'Helvetica'
};

const PersonnelSelector = (props) => {
    const { serviceData, handleAddPersonnel, handleAddPeopleClose, addPeopleOpen, personPanelIsOpen, handleAddPersonClose, addPersonToPeople, handlePersonChange, handlePositionChange, handleAddPerson, people, addPersonDialogOpen, saveNewPerson, parentProps, newPerson, newPosition, removePerson} = props;

    return (
        <Accordion style={mainStyle}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <span style={{fontWeight: '500', color: LightDarkGray}}>People ({serviceData.personnel.length})</span>
            </AccordionSummary>
            <AccordionDetails>
  
                {serviceData.personnel.map((person, index) => 
                    <div key={index} style={{padding: '5px', fontWeight: '500', fontSize: '17px', alignItems: 'center', display: 'flex', justifyContent: 'space-between'}}>
                        <span>{person.position} - {person.person.label}  </span> 
                        <PersonRemoveIcon sx={{
                            marginLeft: '5px',
                            '&:hover': {
                                color: PastelRed,
                                cursor: 'pointer'
                            }
                            }}
                            onClick={() => {removePerson(index)}}
                            />
                    </div>)}
                <Button variant="contained" endIcon={<AddIcon size="small"/>} size="small" onClick={handleAddPersonnel} sx={{marginTop: '5px'}}>Add Item</Button>
            </AccordionDetails>

            <AddPersonDialog handleAddPeopleClose={handleAddPeopleClose} addPeopleOpen={addPeopleOpen} personPanelIsOpen={personPanelIsOpen} handleAddPersonClose={handleAddPersonClose} addPersonToPeople={addPersonToPeople} handlePersonChange={handlePersonChange} handleAddPerson={handleAddPerson} people={people} addPersonDialogOpen={addPersonDialogOpen} handlePositionChange={handlePositionChange} saveNewPerson={saveNewPerson} parentProps={parentProps} newPerson={newPerson} newPosition={newPosition}/>
        </Accordion>
    );
};

export default PersonnelSelector;
import React from 'react';
import './refundPolicy.css';
import { BsFillGeoAltFill } from 'react-icons/bs';
import {LightBlue, textWhite} from '../../constants';

const blueDiv = {
  backgroundColor: LightBlue,
  color: textWhite
};

const headerStyle = {
  color: LightBlue
};
  
const RefundPolicy = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'Center',
        alignItems: 'Center',
        paddingTop: '56px',
        paddingBottom: '5px',
        backgroundColor: 'white',
        paddingLeft: '30px',
        paddingRight: '30px'
      }}
    >
      <div className='refundPolicy'>
        <h1 style={headerStyle}>Refund Policy</h1>
        <p>This Refund Policy governs the manner in which Bethel Bible Church handles refunds from online contributions/donations to www.bethelbibleoc.com website (“Site”). This policy applies to the Site and all products and services offered by Bethel Bible Church.</p>

        <div style={blueDiv}>
          <h3>Online Contributions/Donations</h3>
          <p>Refund Policy Online Contributions/Donations to the ministry Bethel Church are considered to be non-refundable.</p>
        </div>

        <h3 style={headerStyle}>Changes to this policy</h3>
        <p>Bethel Bible Church has the discretion to update this refund policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.</p>

        <div style={blueDiv}>
          <h3>Your acceptance of these terms</h3>
          <p>By using this Site, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.</p>
        </div>

        <h3 style={headerStyle}>Contacting us</h3>
        <p>If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us at:</p>
        <p><strong>Bethel Bible Church</strong></p>
        <p><a href='https://bethelbibleoc.com' >www.bethelbibleoc.com</a></p>
        <p><div className='footerAddress'> 
            <a href='https://goo.gl/maps/uz7WX7xbMQ9sioxb7' target='_blank' rel="noreferrer">
            <span className='mapPoint'><BsFillGeoAltFill /></span>10801 Dale Ave, Stanton, CA 90680</a>
        </div></p>

        <p style={{ fontSize: '12px'}}>This document was last updated on May 2, 2017</p>

      </div>
    </div>
  );
};
  
export default RefundPolicy;
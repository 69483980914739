import { useState } from "react";
import { LightBlue, LightDarkGray, testimonyString } from "../../../constants";
import { useAppSelector } from "../../../app/hooks";
import { selectLanguage } from "../../../app/slices/languageSlice";

const testimonyEntityStyle = {
  fontWeight: "500",
};

const hoverStyle = {
  color: LightBlue,
  cursor: "pointer",
  paddingBottom: "10px",
  fontSize: "14px",
};

const mainStyle = {
  color: LightDarkGray,
  fontSize: "14px",
  paddingBottom: "10px",
};

const TestimonyPoint = (props) => {
  const language = useAppSelector(selectLanguage);
  const { servicePoint, handlePointTimestamp } = props;
  const [testimonyStyle, setTestimonyStyle] = useState(mainStyle);

  const handleMouseEnter = () => {
    setTestimonyStyle(hoverStyle);
  };

  const handleClick = () => {
    handlePointTimestamp(servicePoint.START_TIMESTAMP_UNIX);
  };

  const handleMouseLeave = () => {
    setTestimonyStyle(mainStyle);
  };

  return (
    <div
      style={testimonyStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <span style={testimonyEntityStyle}>{testimonyString(language)}</span>
      {/** TODO: In the future, we should add to these points to include testimony details */}
    </div>
  );
};

export default TestimonyPoint;

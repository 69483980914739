import { createTheme, GlobalStyles } from '@mui/material/styles';
import { ALMOST_BLACK, LIGHT_BLUE, LIGHT_DARK_GRAY, PAGE_WHITE, PASTEL_RED, SOFT_TAN } from './colors';

const theme = createTheme({
  palette: {
    primary: {
      main: LIGHT_BLUE,
      text: ALMOST_BLACK,
      softText: LIGHT_DARK_GRAY
    },
    secondary: {
      main: SOFT_TAN,
    },
    background: {
      paper: PAGE_WHITE
    },
    warning: {
      main: PASTEL_RED
    }
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif', // Added AmazonEmber as primary font
    secondaryFontFamily: 'Playfair Display, serif',
    paragraphFontFamily: 'Roboto, sans-serif',
    cardFontFamily: 'Amazon Ember, sans-serif'
  },
  components: {
    MuiCssBaseline: { // This component handles global styles
      styleOverrides: `
        @font-face {
          font-family: 'Amazon Ember';
          src: url('../assets/fonts/AmazonEmber_Rg.ttf') format('truetype');
          font-weight: 400;
          font-style: normal;
        }
      `,
    },
  }
});

export default theme;

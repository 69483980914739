/**
 * This file contains helpers for posting announcements
 */

import { Alert, Box, Button, Dialog, DialogActions, DialogTitle, Snackbar, TextField } from "@mui/material";
import dayjs from "dayjs";
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { LightBlue } from "../../../constants";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Currently, the default day is 7 days from today
export const defaultExpireDate = dayjs().add(7, 'day');

export const MAX_TITLE_LENGTH = 160;
export const MAX_ANNOUNCEMENT_LENGTH = 1000;

/**
 * COMPONENTS
 */
export const NewAnnouncementConfirmation = (props) => {
    const { submitDialog, handleCloseDialog, submitting, addNewAnnouncement } = props;
    return (
        <Dialog open={submitDialog} onClose={handleCloseDialog}>
            <DialogTitle>Are you sure you want to submit a new announcement?</DialogTitle>
            <DialogActions>
                <Button onClick={handleCloseDialog}>Cancel</Button>
                {!submitting ? <Button onClick={addNewAnnouncement} autoFocus>
                    Confirm
                </Button> :
                <Box sx={{ display: 'flex', color: LightBlue}}>
                    <CircularProgress color="inherit"/>
                </Box>}
            </DialogActions>
        </Dialog>
    );
};

export const DeleteAnnouncementConfirmation = (props) => {
    const { deleteDialog, handleCloseDeleteDialog, deleting, deleteAnnouncement } = props;
    return (
        <Dialog open={deleteDialog} onClose={handleCloseDeleteDialog}>
            <DialogTitle>Are you sure you want to delete this announcement?</DialogTitle>
            <DialogActions>
                <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
                {!deleting ? <Button onClick={  deleteAnnouncement } autoFocus>
                    Confirm
                </Button> :
                <Box sx={{ display: 'flex', color: LightBlue}}>
                    <CircularProgress color="inherit"/>
                </Box>}
            </DialogActions>
        </Dialog>
    );
};

export const AlertDateError = (props) => {
    const { invalidDate, handleSnackbarClose } = props;
    return (
        <Snackbar open={invalidDate} autoHideDuration={7000} onClose={handleSnackbarClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
            <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                That date is invalid. The selected date cannot be before today's date.
            </Alert>
        </Snackbar>
    );
};

export const AlertInvalidTitle = (props) => {
    const { invalidTitle, handleSnackbarClose } = props;
    return (
        <Snackbar open={invalidTitle} autoHideDuration={7000} onClose={handleSnackbarClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
            <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                {`The length of an announcement title cannot exceed ${MAX_TITLE_LENGTH} characters.`}
            </Alert>
        </Snackbar>
    );
};

export const AlertInvalidAnnouncement = (props) => {
    const { invalidAnnouncement, handleSnackbarClose } = props;
    return (
        <Snackbar open={invalidAnnouncement} autoHideDuration={7000} onClose={handleSnackbarClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
            <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                {`The length of an announcement cannot exceed ${MAX_ANNOUNCEMENT_LENGTH} characters.`}
            </Alert>
        </Snackbar>
    );
};

export const AlertInvalidForm = (props) => {
    const { invalidForm, handleSnackbarClose } = props;
    return (
        <Snackbar open={invalidForm} autoHideDuration={7000} onClose={handleSnackbarClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
            <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                You must enter an English and Romanian Title, and an English and Romanian Announcement.
            </Alert>
        </Snackbar>
    );
};

export const AlertAnnouncementFailure = (props) => {
    const { announcementFailed, handleSnackbarClose } = props;
    return (
        <Snackbar open={announcementFailed} autoHideDuration={7000} onClose={handleSnackbarClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
            <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                The announcement has failed to post. Please try again later.
            </Alert>
        </Snackbar>
    );
};

export const AlertSuccess = (props) => {
    const { successMessage, handleSnackbarClose } = props;
    return (
        <Snackbar open={successMessage} autoHideDuration={10000} onClose={handleSnackbarClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
            <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                The announcement has successfully been posted
            </Alert>
        </Snackbar>
    );
};

export const ExpireOnDatePicker = (props) => {
    const { expire_on, handleDateChange } = props;
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
                label="Announcement Expiration Date"
                inputFormat="MM/DD/YYYY"
                value={expire_on}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
    );
};
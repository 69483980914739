import React, { useState } from 'react';
import * as dayjs from 'dayjs';
import RequireAuth from '../requireAuth';
import { announcementsDashboardPath } from '../../../constants';
import  AnnouncementForm  from './announcementForm';
import { saveAnnouncement } from '../../../helpers/backend';
import { useEffect } from 'react';
import AnnouncementSuccess from './announcementSuccess';
import BackButton from '../backButton';
import { AlertAnnouncementFailure, AlertDateError, AlertInvalidAnnouncement, AlertInvalidForm, AlertInvalidTitle, AlertSuccess, defaultExpireDate, MAX_ANNOUNCEMENT_LENGTH, MAX_TITLE_LENGTH, NewAnnouncementConfirmation } from './announcementPostHelper';

const mainStyle = {
    backgroundColor: 'white',
    fontFamily: "Montserrat",
    display: 'flex',
    flexDirection: 'column',
    margin: '0',
    width: '100%',
    height: '100%',
    alignItems: 'center',
  };

const NewAnnouncement = () => {
    const [date, setDate] = useState(defaultExpireDate);
    const [titleEn, setTitleEn] = useState('');
    const [titleRo, setTitleRo] = useState('');
    const [announcementEn, setAnnouncementEn] = useState('');
    const [announcementRo, setAnnouncementRo] = useState('');
    const [submitDialog, setSubmitDialog] = useState(false);
    const [invalidDate, setInvalidDate] = useState(false);
    const [invalidTitle, setInvalidTitle] = useState(false);
    const [invalidAnnouncement, setInvalidAnnouncement] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [invalidForm, setInvalidForm] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [announcementFailed, setAnnouncementFailed] = useState(false);

    // Handles the change of the expiration date. The date selected cannot be before today's date.
    const handleDateChange = (newDate) => {
        const today = dayjs();
        if (today <= newDate) {
            setDate(newDate);
            setInvalidDate(false);
        }
        else {
            setInvalidDate(true);
        }
    };

    // Handles the change of the English title. This title cannot be more than a certain amount characters.
    const handleTitleEnChange = (event) => {
        if (event.target.value.length < MAX_TITLE_LENGTH) {
            setTitleEn(event.target.value);
            setInvalidTitle(false);
        }
        else {
            setInvalidTitle(true);
        }
    };

    // Handles the change of the Romanian title. This title cannot be more than a certain amount characters.
    const handleTitleRoChange = (event) => {
        if (event.target.value.length < MAX_TITLE_LENGTH) {
            setTitleRo(event.target.value);
            setInvalidTitle(false);
        }
        else {
            setInvalidTitle(true);
        }
    };

    // Handles the change of the English Announcement. This announcement cannot be more than a certain amount characters.
    const handleAnnouncementEnChange = (event) => {
        if (event.target.value.length < MAX_ANNOUNCEMENT_LENGTH) {
            setAnnouncementEn(event.target.value);
            setInvalidAnnouncement(false);
        }
        else {
            setInvalidAnnouncement(true);
        }
    };

    // Handles the change of the Romanian Announcement. This announcement cannot be more than a certain amount characters.
    const handleAnnouncementRoChange = (event) => {
        if (event.target.value.length < MAX_ANNOUNCEMENT_LENGTH) {
            setAnnouncementRo(event.target.value);
            setInvalidAnnouncement(false);
        }
        else {
            setInvalidAnnouncement(true);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (announcementEn.length < 1 || announcementRo.length < 1 || titleEn.length < 1 || titleRo.length < 1) {
            setInvalidForm(true);
        }
        else {
            setInvalidForm(false);
            setSubmitDialog(true);
        }
    };

    const handleCloseDialog = () => {
        setSubmitDialog(false);
        setSubmitting(false);
    };

    const addNewAnnouncement = async () => {
        setSubmitting(true);
        try {
            const returnValue = await saveAnnouncement(titleEn, titleRo, announcementEn, announcementRo, date);
            if (returnValue) {
                setSubmitted(true);
            }
        }
        catch(error) {
            setAnnouncementFailed(true);
        }
        setSubmitting(false);
    };

    const handleSnackbarClose = () => {
        setInvalidAnnouncement(false);
        setInvalidDate(false);
        setInvalidTitle(false);
        setInvalidForm(false);
        setAnnouncementFailed(false);
        setSuccessMessage(false);
    };

    // This use effect is used to have a success pop-up
    useEffect(() => {
        if (submitted) {
            setSuccessMessage(true);
            setSubmitDialog(false);
        }
    }, [submitted]);

    return (
        <div style={mainStyle}>
            <RequireAuth />

            <BackButton backToPath={announcementsDashboardPath} />

            <div style={{flex: '1', alignContent: 'center', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                {!submitted && <AnnouncementForm announcement={{
                    announcementEn: announcementEn,
                    announcementRo: announcementRo,
                    titleEn: titleEn,
                    titleRo: titleRo,
                    expiration_date: date
                }}
                handleSubmit={handleSubmit}
                handleAnnouncementEnChange={handleAnnouncementEnChange}
                handleAnnouncementRoChange={handleAnnouncementRoChange}
                handleTitleEnChange={handleTitleEnChange}
                handleTitleRoChange={handleTitleRoChange}
                handleDateChange={handleDateChange}
                />}
                {submitted && <AnnouncementSuccess />}
            </div>

            <NewAnnouncementConfirmation submitDialog={submitDialog} handleCloseDialog={handleCloseDialog} submitting={submitting} addNewAnnouncement={addNewAnnouncement} />
            <AlertDateError invalidDate={invalidDate} handleSnackbarClose={handleSnackbarClose} />
            <AlertInvalidTitle handleSnackbarClose={handleSnackbarClose} invalidTitle={invalidTitle} />
            <AlertInvalidAnnouncement handleSnackbarClose={handleSnackbarClose} invalidAnnouncement={invalidAnnouncement} />
            <AlertInvalidForm handleSnackbarClose={handleSnackbarClose} invalidForm={invalidForm} />
            <AlertAnnouncementFailure handleSnackbarClose={handleSnackbarClose} announcementFailed={announcementFailed}/>
            <AlertSuccess handleSnackbarClose={handleSnackbarClose} successMessage={successMessage} />
        </div>
    );
};

export default NewAnnouncement;
import { followUsString, LightBlue } from "../../constants";
import { Social as SocialLinks } from "../../menu/social/social";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../app/slices/languageSlice";

const mainStyle = {
  backgroundColor: "white",
  fontFamily: "Montserrat",
  display: "flex",
  flexDirection: "column",
  margin: "0",
  width: "100%",
  justifyContent: "center",
  height: "calc(100%)",
  alignItems: "center",
};

const Social = () => {
  const language = useAppSelector(selectLanguage);

  return (
    <div style={mainStyle}>
      <h1 style={{ color: LightBlue }}>{followUsString(language)}</h1>

      <SocialLinks />
    </div>
  );
};

export default Social;

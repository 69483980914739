import { useEffect, useState } from "react";
import {
  EN,
  LightDarkGray,
  LightGray,
  SEARCH,
  SERVICES,
} from "../../constants";
import { getDateToServiceMap } from "../../helpers/dataBuilders";
import PanelFooterItem from "./panelFooterItem";
import PanelMessageDate from "./panelMessageDate";
import SearchPanel from "./searchPanel";
import SermonsPanel from "./sermonsPanel";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../app/slices/languageSlice";

const mainStyle = {
  width: "100%",
  backgroundColor: "white",
  height: "100%",
};

const mainStyleMobile = {
  width: "100%",
  backgroundColor: "white",
  height: "auto",
};

const footerStyle = {
  display: "flex",
  position: "fixed",
  bottom: "43px",
  width: "100%",
  height: "32px",
  backgroundColor: LightGray,
  alignItems: "center",
  flexDirection: "row",
  fontWeight: "600",
  color: LightDarkGray,
  fontSize: "15px",
};

const footerStyleMobile = {
  display: "flex",
  position: "fixed",
  bottom: "0px",
  width: "100%",
  height: "32px",
  backgroundColor: LightGray,
  alignItems: "center",
  flexDirection: "row",
  fontWeight: "600",
  color: LightDarkGray,
  fontSize: "15px",
};

const MessagePanel = (props) => {
  const {
    from,
    hasMore,
    getMore,
    data,
    handleSortByChange,
    sortBy,
    sortDate,
    handleDateChange,
    isMobile,
    handlePointTimestamp,
    handleAddResultToResults,
  } = props;
  const [selectedItem, setSelectedItem] = useState("SERVICES");
  const [formattedData, setFormattedData] = useState();
  const [isFooterGone, setIsFooterGone] = useState(false);

  const language = useAppSelector(selectLanguage);

  const itemSwitch = (item) => {
    setSelectedItem(item);
  };

  const handleResize = () => {
    setIsFooterGone(window.innerWidth <= 660);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (data) {
      setFormattedData(getDateToServiceMap(data));
    }
  }, [data, language]);

  return (
    <div style={isMobile ? mainStyleMobile : mainStyle}>
      {(selectedItem === "SERVICES" || from === "announcements") &&
        formattedData && (
          <PanelMessageDate
            data={formattedData}
            from={from}
            hasMore={hasMore}
            getMore={getMore}
            handleSortByChange={handleSortByChange}
            sortBy={sortBy}
            sortDate={sortDate}
            handleDateChange={handleDateChange}
            isMobile={isMobile}
            handlePointTimestamp={handlePointTimestamp}
          />
        )}

      {selectedItem === "SERMONS" && <SermonsPanel />}

      {selectedItem === "SEARCH" && (
        <SearchPanel
          handlePointTimestamp={handlePointTimestamp}
          handleAddResultToResults={handleAddResultToResults}
        />
      )}

      {from === "services" && (
        <div style={isFooterGone ? footerStyleMobile : footerStyle}>
          <PanelFooterItem
            title={SERVICES(language)}
            tab={SERVICES(EN)}
            selected={selectedItem === SERVICES(EN)}
            handleSwitch={itemSwitch}
          />
          {/* {<PanelFooterItem title={SERMONS(language)} tab={SERMONS(EN)} selected={selectedItem === SERMONS(language)} handleSwitch={itemSwitch}/>} */}
          <PanelFooterItem
            title={SEARCH(language)}
            tab={SEARCH(EN)}
            selected={selectedItem === SEARCH(EN)}
            handleSwitch={itemSwitch}
          />
        </div>
      )}
    </div>
  );
};

export default MessagePanel;

import { Box, Button } from "@mui/material";
import RomanianFlagIcon from "./romania.svg";
import AmericanFlagIcon from "./united-states-of-america.svg";
import { EN, RO } from "../../constants";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLanguage, updateLanguage } from "../../app/slices/languageSlice";

/*
 * STYLES
 */

// General style for any flag image
const flagStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "1.2rem",
  minHeight: "1rem",
};

// For the selected language, opacity should be 100%
const selectedStyle = {
  opacity: "100%",
  alignItems: "center",
  margin: "0",
  height: "1.5vh",
};

// For the non-selected language, opacity should be 50%, but on hover should be 100%
const nonSelectedStyle = {
  opacity: "50%",
  alignItems: "center",
  margin: "0",
  "&:hover": {
    opacity: "100%",
  },
  display: "flex",
  justifyContent: "center",
};

// Boolean language getters
const isRo = (lang) => {
  return lang === RO;
};
const isEn = (lang) => {
  return lang === EN;
};

const Flags = () => {
  const language = useAppSelector(selectLanguage);
  const dispatch = useAppDispatch();

  // Changes the React Context to Romanian
  const changeToRo = () => {
    dispatch(updateLanguage(RO));
  };

  // Changes the React Context to English
  const changeToEn = () => {
    dispatch(updateLanguage(EN));
  };

  return (
    <Box display="flex" alignItems="center">
      <Button
        disableTouchRipple={true}
        sx={isRo(language) ? selectedStyle : nonSelectedStyle}
        size="small"
        onClick={changeToRo}
      >
        <img src={RomanianFlagIcon} style={flagStyle} alt="Romanian" />
      </Button>
      <Button
        disableTouchRipple
        sx={isEn(language) ? selectedStyle : nonSelectedStyle}
        size="small"
        onClick={changeToEn}
      >
        <img src={AmericanFlagIcon} style={flagStyle} alt="English" />
      </Button>
    </Box>
  );
};

export default Flags;

import React from 'react';

const SermonsPanel = () => {

    return (
        <div>
            Testing Sermons Panel
        </div>
    );
};

export default SermonsPanel;
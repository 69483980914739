import { Box, Paper } from '@mui/material';
import React, { useContext, useState } from 'react';
import CircleDate from '../../../components/CircleDate';
import { LighterBlue, LightGray } from '../../../constants';
import ServiceContext from '../../../contexts/serviceContext';
import SearchDetails from './searchDetails';

const mainStype = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center', 
    '& > :not(style)': {
        m: 1,
        width: '90%',
        height: 'auto'
    }
};

const paperStyle = {
    '&:hover': {
        backgroundColor: LighterBlue,
        color: 'white'
    },
    cursor: 'pointer',
    backgroundColor: LightGray,
    display: 'flex',
    alignItems: 'center'
};



const SearchResult = (props) => {
    const { result, searchFor, handlePointTimestamp, handleAddResultToResults} = props;
    const { setServiceId } = useContext(ServiceContext);
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleClick = () => {
        handleAddResultToResults(result.results);
        setServiceId(result.results.SERVICE_KEY);
        handlePointTimestamp(result.results.START_TIMESTAMP_UNIX);
    };
    
    return (
        <Box sx={mainStype} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick}>
            <Paper elevation={1} sx={paperStyle}>
                <CircleDate date={result.date} />
                <SearchDetails result={result} searchFor={searchFor} isHovered={isHovered}/> 
            </Paper>
        </Box>
    );
};

export default SearchResult;
import { useState } from "react";
import {
  LightBlue,
  LightDarkGray,
  presentationString,
} from "../../../constants";
import { useAppSelector } from "../../../app/hooks";
import { selectLanguage } from "../../../app/slices/languageSlice";

const presentationEntityStyle = {
  fontWeight: "500",
};

const hoverStyle = {
  color: LightBlue,
  cursor: "pointer",
  paddingBottom: "10px",
  fontSize: "14px",
};

const mainStyle = {
  color: LightDarkGray,
  fontSize: "14px",
  paddingBottom: "10px",
};

const PresentationPoint = (props) => {
  const language = useAppSelector(selectLanguage);
  const { servicePoint, handlePointTimestamp } = props;
  const [presentationStyle, setPresentationStyle] = useState(mainStyle);

  const handleMouseEnter = () => {
    setPresentationStyle(hoverStyle);
  };

  const handleClick = () => {
    handlePointTimestamp(servicePoint.START_TIMESTAMP_UNIX);
  };

  const handleMouseLeave = () => {
    setPresentationStyle(mainStyle);
  };

  return (
    <div
      style={presentationStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <span style={presentationEntityStyle}>
        {presentationString(language)}
      </span>
      {/** TODO: In the future, we should add to these points to include presentation details */}
    </div>
  );
};

export default PresentationPoint;

import { useState } from "react";
import { LightBlue, LightDarkGray, readingString } from "../../../constants";
import { getDisplayVerse } from "../../../helpers/bible";
import { useAppSelector } from "../../../app/hooks";
import { selectLanguage } from "../../../app/slices/languageSlice";

const readingEntityStyle = {
  fontWeight: "500",
};

const readingTitleStyle = {
  fontWeight: "200",
};

const hoverStyle = {
  color: LightBlue,
  cursor: "pointer",
  paddingBottom: "10px",
  fontSize: "14px",
};

const mainStyle = {
  color: LightDarkGray,
  fontSize: "14px",
  paddingBottom: "10px",
};

const ReadingPoint = ({ servicePoint, handlePointTimestamp }) => {
  const { PASSAGE } = servicePoint;
  const language = useAppSelector(selectLanguage);
  const [readingPointStyle, setReadingPointStyle] = useState(mainStyle);

  const handleMouseEnter = () => {
    setReadingPointStyle(hoverStyle);
  };

  const handleMouseLeave = () => {
    setReadingPointStyle(mainStyle);
  };

  const handleClick = () => {
    handlePointTimestamp(servicePoint.START_TIMESTAMP_UNIX);
  };

  return (
    <div
      style={readingPointStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <span style={readingEntityStyle}>{readingString(language)}</span>
      {PASSAGE && (
        <span style={readingTitleStyle}>
          : {getDisplayVerse(language, PASSAGE)}
        </span>
      )}
    </div>
  );
};

export default ReadingPoint;

import React, { useContext, useState } from 'react';
import * as dayjs from 'dayjs';
import { formatMomentDate, newMomentDay } from '../helpers/dateHelper';

/**
 * Creates a context for the web-app to be able to pull the Service Editor Context from anywhere.
 * 
 * https://reactjs.org/docs/context.html
 */
const ServiceEditorContext = React.createContext();

export const useServiceEditorContext = () => {
    return useContext(ServiceEditorContext);
}

export const ServiceEditorProvider = ({ children }) => {
    const [serviceData, setServiceData] = useState();
    const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [serviceStartTime, setServiceStartTime] = useState(newMomentDay().unix());
    const [morningOrNight, setMorningOrNight] = useState();
    const [vimeoId, setVimeoId] = useState();
    const [addPeopleOpen, setAddPeopleOpen] = useState(false);
    const [personPanelIsOpen, setPersonPanelIsOpen] = useState(false);
    const [newPersonPosition, setNewPersonPosition] = useState();
    const [newPerson, setNewPerson] = useState();
    const [people, setPeople] = useState([]);
    const [saveServiceDialog, setSaveServiceDialog] = useState(false);
    const [disableService, setDisableService] = useState(false);
    const [serviceItems, setServiceItems] = useState([]);
    const [successSnackbar, setSuccessSnackbar] = useState(false);
    const [errorSnackbar, setErrorSnackbar] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    /**
     * This function is used to update the state based on the received serviceData. 
     * It checks for the existence of various properties in the serviceData object 
     * and updates the corresponding state variable accordingly.
     * 
     * @param {Object} serviceData - The service data received from the server.
     *   It's expected to have the following properties: date, day_night, 
     *   start_timestamp, vimeo_id
     */
    const handleServiceData = (serviceData) => {
        setDate(formatMomentDate(serviceData.date || ''));
        setMorningOrNight(serviceData.day_night || '');
        setServiceStartTime(serviceData.start_timestamp_unix || '');
        setVimeoId(serviceData.vimeo_id || '');
    }

    const value = {
        serviceData, setServiceData,
        date, setDate,
        serviceStartTime, setServiceStartTime,
        morningOrNight, setMorningOrNight,
        vimeoId, setVimeoId,
        addPeopleOpen, setAddPeopleOpen,
        personPanelIsOpen, setPersonPanelIsOpen,
        newPersonPosition, setNewPersonPosition,
        newPerson, setNewPerson,
        people, setPeople,
        saveServiceDialog, setSaveServiceDialog,
        disableService, setDisableService,
        serviceItems, setServiceItems,
        successSnackbar, setSuccessSnackbar,
        errorSnackbar, setErrorSnackbar,
        loadingSave, setLoadingSave,
        handleServiceData
    };

    return (
        <ServiceEditorContext.Provider value={value}>
            {children}
        </ServiceEditorContext.Provider>
    );
};
import { DialogTitle } from '@material-ui/core';
import { Autocomplete, Button, createFilterOptions, Dialog, Grid, TextField } from '@mui/material';
import React from 'react';
import AddPersonComponent from '../addPersonComponent';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

const filter = createFilterOptions();

const AddPersonDialog = (props) => {
    const { handleAddPeopleClose, addPeopleOpen, personPanelIsOpen, handleAddPersonClose, addPersonToPeople, handlePersonChange, handlePositionChange, handleAddPerson, people, newPerson, newPosition } = props;


    return (
        <Dialog onClose={handleAddPeopleClose} open={addPeopleOpen} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <DialogTitle>Add Member</DialogTitle>
            <Grid container spacing={2} sx={{padding: '10px'}}>
                <AddPersonComponent isOpen={personPanelIsOpen} onClose={handleAddPersonClose} addPersonToPeople={addPersonToPeople} changePerson={handlePersonChange}/>
                <Grid item xs={12}> 
                    <TextField
                        id="Point"
                        value={newPosition}
                        label="Position"
                        onChange={handlePositionChange}
                        select
                        fullWidth
                        SelectProps={{
                            native: true
                        }}
                        placeholder={''}
                        >
                        <option value=''></option>
                        <option value={'Worship Leader'}>Worship Leader</option>
                        <option value={'Singer'}>Singer</option>
                        <option value={'Piano'}>Piano</option>
                        <option value={'Acoustic Guitar'}>Acoustic Guitar</option>
                        <option value={'Bass'}>Bass</option>
                        <option value={'Electric Guitar'}>Electric Guitar</option>
                        <option value={'Drums'}>Drums</option>
                        <option value={'Keys'}>Keys</option>
                        <option value={'Strings'}>Strings</option>
                        <option value={'Conductor'}>Conductor</option>
                        <option value={'Instrumentalist'}>Other Instrument</option>
                        <option value={'Announcer'}>Announcer</option>
                        <option value={'Speaker'}>Speaker</option>
                        <option value={'Other'}>Other</option>
                    </TextField>
                </Grid>
                <Grid item xs={12} sx={{padding: '0', margin: '0'}}>
                    <Autocomplete
                        value={newPerson}
                        onChange={(event, newValue) => {
                        handlePersonChange(newValue);
                        }
                    }
                        filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== '') {
                            filtered.push({
                            inputValue: params.inputValue,
                            label: `Add "${params.inputValue}"`,
                            });
                        }
                        console.log(filtered);
                        return filtered;
                        }}
                        id="free-solo-dialog-demo"
                        options={people}
                        getOptionLabel={(option) => {
                        // e.g value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return option.label;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        
                        freeSolo
                        renderInput={(params) => <TextField {...params} label="Person" />}
                    />
                </Grid>

                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Button onClick={handleAddPeopleClose}>Close</Button>
                    <Button variant="outlined" startIcon={<PersonAddAlt1Icon />} onClick={handleAddPerson} disabled={!newPerson || !newPosition}>
                        Add
                    </Button>
                </Grid>
            </Grid> 
            
        </Dialog>
    );
};

export default AddPersonDialog;
import React from 'react';

/**
 * Creates a context for the web-app to be able to pull the Service Context from anywhere.
 * 
 * https://reactjs.org/docs/context.html
 */
const ServiceContext = React.createContext({
    serviceId: '',
    serviceTimestamp: '',
    vimeoId: '',
    setServiceId: () => {},
    setServiceTimestamp: () => {},
    setVimeoId: () => {}

});
export const ServiceProvider = ServiceContext.Provider; 
export default ServiceContext;
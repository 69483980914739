import { Divider, Skeleton, Typography } from '@mui/material';
import React from 'react';

const AnnouncementLoading = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
            <Typography variant="h1" style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
                {<Skeleton width="40%" />}
            </Typography>
            <Divider style={{ width: '90%', marginBottom: '10px' }} />
            <Typography variant="h2" style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '50px', marginBottom: '10px' }}>
                {<Skeleton width="25%" />}
            </Typography>
            <Typography variant="h3" style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '50px', marginBottom: '10px' }}>
                {<Skeleton width="20%" />}
            </Typography>
            <Typography variant="h3" style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '50px', marginBottom: '0px', padding: '0' }}>
                {<Skeleton width="90%" />}
            </Typography>
            <Typography variant="h3" style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '50px', marginBottom: '0px', marginTop: '0px', padding: '0' }}>
                {<Skeleton width="90%" />}
            </Typography>
            <Divider style={{ width: '90%', marginTop: '10px' }} />
            <Typography variant="h2" style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '50px', marginBottom: '10px' }}>
                {<Skeleton width="25%" />}
            </Typography>
            <Typography variant="h3" style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '50px', marginBottom: '10px' }}>
                {<Skeleton width="20%" />}
            </Typography>
            <Typography variant="h3" style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '50px', marginBottom: '0px', padding: '0' }}>
                {<Skeleton width="90%" />}
            </Typography>
            <Typography variant="h3" style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '50px', marginBottom: '0px', marginTop: '0px', padding: '0' }}>
                {<Skeleton width="90%" />}
            </Typography>
            <Divider style={{ width: '90%', marginTop: '10px' }} />
        </div>
    );
};

export default AnnouncementLoading;
import { InputLabel, TextField } from '@mui/material';
import React from 'react';

const SermonTitleSelector = (props) => {
    const { serviceData, handleSermonTitleEnChange, handleSermonTitleRoChange } = props;

    return (
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', marginTop: '5px'}}>
            <InputLabel>Sermon Title</InputLabel>
            <div>
                <TextField label="Sermon Title (English)" size="small" value={serviceData.sermon_title_en} onChange={handleSermonTitleEnChange} />
                <TextField label="Sermon Title (Romanian)" size="small" value={serviceData.sermon_title_ro} onChange={handleSermonTitleRoChange} />
            </div>
        </div>
    );
};

export default SermonTitleSelector;
import { useState } from "react";
import { LightBlue, LightDarkGray } from "../../../constants";
import { getSongEntityTitle, getSongTitle } from "../../../helpers/points";
import { useAppSelector } from "../../../app/hooks";
import { selectLanguage } from "../../../app/slices/languageSlice";

const poemEntityStyle = {
  fontWeight: "500",
};

const poemTitleStyle = {
  fontWeight: "200",
};

const hoverStyle = {
  color: LightBlue,
  cursor: "pointer",
  paddingBottom: "10px",
  fontSize: "14px",
};

const mainStyle = {
  color: LightDarkGray,
  fontSize: "14px",
  paddingBottom: "10px",
};

const PoemPoint = ({ servicePoint, handlePointTimestamp }) => {
  const {
    SONG_ENTITY,
    GROUP_TITLE_EN,
    GROUP_TITLE_RO,
    SONG_TITLE_EN,
    SONG_TITLE_RO,
  } = servicePoint;
  const language = useAppSelector(selectLanguage);
  const [poemStyle, setPoemStyle] = useState(mainStyle);

  const handleMouseEnter = () => {
    setPoemStyle(hoverStyle);
  };

  const handleMouseLeave = () => {
    setPoemStyle(mainStyle);
  };

  const handleClick = () => {
    handlePointTimestamp(servicePoint.START_TIMESTAMP_UNIX);
  };

  // TODO: Test poems, and all points to make sure that they look as they're supposed to

  return (
    <div
      style={poemStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <span style={poemEntityStyle}>
        {getSongEntityTitle(
          language,
          SONG_ENTITY,
          null,
          GROUP_TITLE_EN,
          GROUP_TITLE_RO
        )}
      </span>
      {(SONG_TITLE_EN || SONG_TITLE_RO) && (
        <span style={poemTitleStyle}>
          : {getSongTitle(language, language, SONG_TITLE_EN, SONG_TITLE_RO)}
        </span>
      )}
    </div>
  );
};

export default PoemPoint;

import { Alert, InputLabel, Snackbar, TextField } from '@mui/material';
import React, { useState } from 'react';

const EncouragementForSelector = (props) => {
    const { handleEncouragementForChange } = props;
    const [encouragementOptionSelected, setEncouragementOptionSelected] = useState();
    const [otherEncouragement, setOtherEncouragement] = useState();
    const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    // if (reason === 'clickaway') {
    //   return;
    // }

    setOpen(false);
  };

    const handleLocalChange = (event) => {
        if (event.target.value !== "Other") {
            setOtherEncouragement('');
            handleEncouragementForChange(event.target.value);
        }
        else {
            handleEncouragementForChange('');
        }
        setEncouragementOptionSelected(event.target.value);
    };

    const handleOtherTypeChange = (event) => {
        if (event.target.value.length <= 30) {
            handleEncouragementForChange(event.target.value);
            setOtherEncouragement(event.target.value);
        }
        else {
            setOpen(true);
        }
    };

    return (
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', marginTop: '5px'}}>
            <InputLabel>Encouragement For</InputLabel>
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center'}}>
                <TextField label="Encouragement For" size="small" value={encouragementOptionSelected} onChange={handleLocalChange} select fullWidth SelectProps={{native: true}} sx={{marginTop: '5px'}}>
                    <option value=""></option>
                    <option value="Causes/Petitions">Causes/Petitions</option>
                    <option value="Families">Families</option>
                    <option value="Repentence">Repentence</option>
                    <option value="Worship/Praise">Worship/Praise</option>
                    <option value="Other">Other</option>
                </TextField>
                {encouragementOptionSelected === 'Other' && <TextField value={otherEncouragement} onChange={handleOtherTypeChange} label="Other Encouragement Type" fullWdith sx={{marginTop: '10px'}}/>}
            </div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
                    Other encouragement should be less than 30 characters
                </Alert>
            </Snackbar>
        </div>
    );
};

export default EncouragementForSelector;
import React, { useState } from "react";
import UrgentMessageDisplay from "../../components/UrgentMessageDisplay";
import {
  DarkGray,
  hereString,
  ifNotRedirectedString,
  LightBlue,
  redirectingString,
  toBeRedirectedString,
} from "../../constants";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../app/slices/languageSlice";

const mainStyle = {
  display: "flex",
  position: "relative",
  top: "56px",
  height: "calc(100vh - 56px",
  width: "100%",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Montserrat",
  backgroundColor: "white",
  color: DarkGray,
};

const Give = () => {
  const language = useAppSelector(selectLanguage);
  const [autoRedirect, setAutoRedirect] = useState(false);

  React.useEffect(() => {
    if (autoRedirect) {
      document.getElementById("giveLink").click(); // Clicks the hidden button to re-route to planning center give instantly
    }
  }, [autoRedirect]);

  /**
   * We only want to redirect when there are no errors in the system that we want to display. This will turn true whenever it's time to click the giving link
   * @param {*} shouldRedirect
   */
  const handleAutoRedirect = (shouldRedirect) => {
    setAutoRedirect(shouldRedirect);
  };

  return (
    <div style={mainStyle}>
      <UrgentMessageDisplay
        page="Donations"
        handleNoData={handleAutoRedirect}
      />
      <h2 style={{ color: LightBlue }}>{redirectingString(language)}</h2>
      <div style={{ fontSize: "1.5rem" }}>
        <span>{ifNotRedirectedString(language)}</span>
        <span>
          <a
            href="https://bethelrpc.churchcenter.com/giving"
            id="giveLink"
            style={{ fontSize: "inherit", color: LightBlue }}
          >
            {hereString(language)}
          </a>
        </span>
        <span>{toBeRedirectedString(language)}</span>
      </div>
    </div>
  );
};

export default Give;

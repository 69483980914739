import { Button, Grid, TextField } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { LightDarkGray } from '../../../constants';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import CancelIcon from '@mui/icons-material/Cancel';
import { AlertAnnouncementFailure, AlertDateError, AlertInvalidAnnouncement, AlertInvalidForm, AlertInvalidTitle, AlertSuccess, DeleteAnnouncementConfirmation, ExpireOnDatePicker, MAX_ANNOUNCEMENT_LENGTH, MAX_TITLE_LENGTH } from './announcementPostHelper';


const formatDate = (date) => {
    return dayjs(date).format('dddd, MMMM D, YYYY');
};

const Announcement = (props) => {
    const { announcement, index, deleteAnnouncement, updateAnnouncement } = props; 
    const [editMode, setEditMode] = useState(false);
    const [displayAnnouncement, setDisplayAnnouncement] = useState(announcement);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [invalidDate, setInvalidDate] = useState(false);
    const [invalidTitle, setInvalidTitle] = useState(false);
    const [invalidAnnouncement, setInvalidAnnouncement] = useState(false);
    const [invalidForm, setInvalidForm] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [announcementFailed, setAnnouncementFailed] = useState(false);

    const handleSave = () => {
        //Set editing mode back to false and send back to "pastAnnouncements" to update the database and the announcements prop.
        setEditMode(false);
        updateAnnouncement(displayAnnouncement);
    };

    const handleCancel = () => {
        // Revert the displayed announcement back to it's original queried state.
        setDisplayAnnouncement(announcement);
        
        // Close editing mode
        setEditMode(false);
    };

    const handleDelete = () => {
        // Brings up a dialog to confirm they want to delete the announcement
        setDeleteDialog(true);
    };

    const handleConfirmDelete = () => {
        // Once the dialog is confirmed to want to be deleted, we actually do the delete here.
        setDeleting(true);
        setDeleteDialog(false);
        deleteAnnouncement(index);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialog(false);
    };

    // Handles the change of the expiration date. The date selected cannot be before today's date.
    const handleDateChange = (newDate) => {
        const today = dayjs();
        if (today <= newDate) {
            const newAnnouncement = {...displayAnnouncement, EXPIRE_ON: newDate};
            setDisplayAnnouncement(newAnnouncement);
            setInvalidDate(false);
        }
        else {
            setInvalidDate(true);
        }
    };

    // Handles the change of the English title. This title cannot be more than a certain amount characters.
    const handleTitleEnChange = (event) => {
        if (event.target.value.length < MAX_TITLE_LENGTH) {
            const newAnnouncement = {...displayAnnouncement, SUBJECT_EN: event.target.value};
            setDisplayAnnouncement(newAnnouncement);
            setInvalidTitle(false);
        }
        else {
            setInvalidTitle(true);
        }
    };

    // Handles the change of the Romanian title. This title cannot be more than a certain amount characters.
    const handleTitleRoChange = (event) => {
        if (event.target.value.length < MAX_TITLE_LENGTH) {
            const newAnnouncement = {...displayAnnouncement, SUBJECT_RO: event.target.value};
            setDisplayAnnouncement(newAnnouncement);
            setInvalidTitle(false);
        }
        else {
            setInvalidTitle(true);
        }
    };

    // Handles the change of the English Announcement. This announcement cannot be more than a certain amount characters.
    const handleAnnouncementEnChange = (event) => {
        if (event.target.value.length < MAX_ANNOUNCEMENT_LENGTH) {
            const newAnnouncement = {...displayAnnouncement, DESCRIPTION_EN: event.target.value};
            setDisplayAnnouncement(newAnnouncement);
            setInvalidAnnouncement(false);
        }
        else {
            setInvalidAnnouncement(true);
        }
    };

    // Handles the change of the Romanian Announcement. This announcement cannot be more than a certain amount characters.
    const handleAnnouncementRoChange = (event) => {
        if (event.target.value.length < MAX_ANNOUNCEMENT_LENGTH) {
            const newAnnouncement = {...displayAnnouncement, DESCRIPTION_RO: event.target.value};
            setDisplayAnnouncement(newAnnouncement);
            setInvalidAnnouncement(false);
        }
        else {
            setInvalidAnnouncement(true);
        }
    };

    const handleSnackbarClose = () => {
        setInvalidAnnouncement(false);
        setInvalidDate(false);
        setInvalidTitle(false);
        setInvalidForm(false);
        setAnnouncementFailed(false);
        setSuccessMessage(false);
    };

    return (
        <div>
            <h3 style={{ color: LightDarkGray }}>{formatDate(announcement.DATE)}</h3>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        variant={editMode ? 'outlined' : 'filled'}
                        label="Title (English)"
                        value={displayAnnouncement.SUBJECT_EN} 
                        onChange={handleTitleEnChange}
                        InputProps={{
                            readOnly: !editMode,
                          }}/>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                            fullWidth
                            variant={editMode ? 'outlined' : 'filled'}
                            label="Title (Romanian)"
                            value={displayAnnouncement.SUBJECT_RO} 
                            onChange={handleTitleRoChange}
                            InputProps={{
                                readOnly: !editMode,
                            }}/>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                            fullWidth
                            variant={editMode ? 'outlined' : 'filled'}
                            multiline 
                            rows={6}
                            label="Announcement (English)"
                            value={displayAnnouncement.DESCRIPTION_EN} 
                            onChange={handleAnnouncementEnChange}
                            InputProps={{
                                readOnly: !editMode,
                            }}/>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                            fullWidth 
                            variant={editMode ? 'outlined' : 'filled'}
                            multiline 
                            rows={6}
                            label="Announcement (Romanian)"
                            value={displayAnnouncement.DESCRIPTION_RO} 
                            onChange={handleAnnouncementRoChange}
                            InputProps={{
                                readOnly: !editMode,
                            }}/>
                </Grid>

                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                    {editMode ? <ExpireOnDatePicker expire_on={displayAnnouncement.EXPIRE_ON} handleDateChange={handleDateChange}/> : 
                                <span style={{fontWeight: '600', color: LightDarkGray}}>{`Announcement Expiration Date: ${formatDate(displayAnnouncement.EXPIRE_ON)}`}</span>}
                </Grid>

                <Grid item xs={6} style={{display: 'flex', justifyContent: 'right', alignItems: 'center'}}>
                    {editMode ? <Button startIcon={<SaveAsIcon />} variant='outlined' onClick={ handleSave } >Save</Button> : 
                                <Button startIcon={<EditIcon />} variant='outlined' onClick={ () => setEditMode(true) } >Edit</Button>}
                </Grid>

                <Grid item xs={6} style={{display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
                    {editMode ? <Button startIcon={<CancelIcon />} variant='outlined' onClick={ handleCancel } >Cancel</Button> :
                                <Button startIcon={<DeleteForeverIcon />} variant='outlined' onClick={ handleDelete } >Delete</Button>}
                </Grid>
            </Grid>

            <DeleteAnnouncementConfirmation deleteDialog={deleteDialog} handleCloseDeleteDialog={handleCloseDeleteDialog} deleting={deleting} deleteAnnouncement={handleConfirmDelete} index={index} />
            <AlertDateError invalidDate={invalidDate} handleSnackbarClose={handleSnackbarClose} />
            <AlertInvalidTitle handleSnackbarClose={handleSnackbarClose} invalidTitle={invalidTitle} />
            <AlertInvalidAnnouncement handleSnackbarClose={handleSnackbarClose} invalidAnnouncement={invalidAnnouncement} />
            <AlertInvalidForm handleSnackbarClose={handleSnackbarClose} invalidForm={invalidForm} />
            <AlertAnnouncementFailure handleSnackbarClose={handleSnackbarClose} announcementFailed={announcementFailed}/>
            <AlertSuccess handleSnackbarClose={handleSnackbarClose} successMessage={successMessage} />
        </div>
    );
};

export default Announcement;
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import DatePicker from '../../../../components/DatePicker';
import dayjs from 'dayjs';
import { CircularProgress, MenuItem, Typography } from '@material-ui/core';

const AddMessage = (props) => {
    const { handleClose, isOpen, addMessage, loadingSave } = props;
    const [messageEn, setMessageEn] = useState('');
    const [messageRo, setMessageRo] = useState('');
    const [expirationDate, setExpirationDate] = useState('');
    const [severity, setSeverity] = useState('Low');
    const [pages, setPages] = useState('');

    const handleDateChange = (date) => {
        setExpirationDate(date);
    };

    const handleMessageEnChange = (event) => {
        let input = event.target.value;
        input = input.replace(/[^a-zA-Z0-9(),.#$!-_/ ]/g, ''); // remove all special characters except those allowed
        input = input.slice(0, 200); // limit to 200 characters
        setMessageEn(input);
    };

    const handleMessageRoChange = (event) => {
        let input = event.target.value;
        input = input.replace(/[^a-zA-Z0-9(),.#$!-_/ ]/g, ''); // remove all special characters except those allowed
        input = input.slice(0, 200); // limit to 200 characters
        setMessageRo(input);
    };

    const handlePagesChange = (selectedPages) => {
        console.log('selectedPages', selectedPages);
        setPages(selectedPages.filter(page => page !== '').join(', '));
    };

    const handleSeverityChange = (event) => {
        setSeverity(event.target.value);
    };

    const handleSubmit = () => {
        if (messageEn.trim().length === 0 || messageRo.trim().length === 0) {
            alert('Please enter a meaningful message in both fields');
            return;
        }
        if (dayjs(expirationDate).isBefore(dayjs())) {
            alert('Please select a valid expiration date.');
            return;
        }

        if (pages.split(', ').length === 0 || (pages.split(', ').length === 1 && !pages[0])) {
            alert('Please select at least one page to apply this message to.');
            return;
        }
        addMessage(messageEn, messageRo, expirationDate, severity, pages);
        setMessageEn(null);
        setMessageRo(null);
        setExpirationDate(null);
        setSeverity('Low');
        setPages('');
    };

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Add a Message?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    This message will display on the home screen for users to see important information. If it's not urgent, please
                    post an announcement instead.
                </DialogContentText>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <TextField label="Message (English)" value={messageEn} onChange={handleMessageEnChange} sx={{ width: '100%' }} />
                    <TextField label="Message (Romanian)" value={messageRo} onChange={handleMessageRoChange} sx={{ width: '100%' }} />
                    <TextField
                        select
                        label="Severity"
                        value={severity}
                        onChange={handleSeverityChange}
                        size="small"
                    >
                        <MenuItem value="Low">Low</MenuItem>
                        <MenuItem value="Medium">Medium</MenuItem>
                        <MenuItem value="High">High</MenuItem>
                    </TextField>
                    <DatePicker label="Expiration Date" date={expirationDate} handleDateChange={handleDateChange} minDate={dayjs().add(1, 'day')} />
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant="h6" component="div">
                            Affected Pages
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                        {[
                            'Home',
                            'Services',
                            'About',
                            'Donations',
                            'Contact',
                            'Upcoming Events',
                            'Announcements',
                        ].map((optionValue) => {
                            const isChecked = pages.split(', ').includes(optionValue);
                            return (
                                <div key={optionValue}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                value={optionValue}
                                                checked={isChecked}
                                                onChange={(event) => {
                                                    const selectedOptions = isChecked
                                                        ? pages.split(', ').filter((value) => value !== optionValue)
                                                        : pages.split(', ').concat(optionValue);
                                                    handlePagesChange(selectedOptions);
                                                }}
                                            />
                                        }
                                        label={optionValue}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <div>
                    <Button onClick={handleSubmit} autoFocus variant="contained" disabled={loadingSave}>
                        {loadingSave ? (
                            <CircularProgress size={24} />
                        ) : (
                            'Post'
                        )}
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default AddMessage;
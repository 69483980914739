import { Suspense, lazy, useState, useEffect } from 'react';
import { animateScroll as scroll } from "react-scroll";
import { AppBar, Tabs, Tab, useTheme, IconButton } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import './about.css';
import { aboutSectionString, historySectionString, missionSectionString } from '../../constants';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/slices/languageSlice';

const AboutSection = lazy(() => import('./AboutSection'));
const MissionSection = lazy(() => import('./MissionSection'));
const HistorySection = lazy(() => import('./HistorySection'));

const About = () => {
  const theme = useTheme();
  const language = useAppSelector(selectLanguage);
  const [showScroll, setShowScroll] = useState(false);

  const tabStyle = {
    '&:hover': {
      color: theme.palette.primary.main
    }
  };

  const mainStyle = {
    fontFamily: theme.typography.fontFamily,
    backgroundColor: 'white', 
    paddingTop: '53px', 
    paddingBottom: '42px',
  };

  useEffect(() => {
    const checkScrollTop = () => {    
      if (!showScroll && window.pageYOffset > 0){
        setShowScroll(true)    
      } else if (showScroll && window.pageYOffset <= 0){
        setShowScroll(false)    
      }  
    };

    window.addEventListener('scroll', checkScrollTop)

    return () => window.removeEventListener('scroll', checkScrollTop)
  }, [showScroll]);

  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  const scrollToSection = (id) => {
    const headerHeight = 55;
    let element = document.getElementById(id);
    if (element) {
      let position = element.offsetTop - headerHeight;
      scroll.scrollTo(position);
    }
  }

  return (
    <div style={ mainStyle }>
      <AppBar 
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          top: '56px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: '1'
        }}
        >
        <Tabs variant="fullWidth">
          <Tab 
            label={aboutSectionString(language)}
            onClick={() => scrollToSection('about')}
            sx={tabStyle}
          />
          <Tab 
            label={missionSectionString(language)}
            onClick={() => scrollToSection('mission')}
            sx={tabStyle}
          />
          <Tab 
            label={historySectionString(language)}
            onClick={() => scrollToSection('history')}
            sx={tabStyle}
          />
        </Tabs>

        <IconButton 
          color="primary"
          aria-label="scroll back to top"
          onClick={scrollTop}
          style={{display: showScroll ? 'flex' : 'none', marginRight: '10px'}}
        >
          <ArrowUpwardIcon />
        </IconButton>

      </AppBar>

      <Suspense fallback={<div>Loading...</div>}>
        <div id='about'><AboutSection /></div>
        <div id='mission'><MissionSection /></div>
        <div id='history'><HistorySection /></div>
      </Suspense>
    </div>
  );
};

export default About;

import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { bibleBookChapters, bibleBooks, bibleBookChapterVerses } from '../../helpers/bible';
import RemoveIcon from '@mui/icons-material/Remove';
import { PASTEL_RED } from '../../theme/colors';

const mainStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
};

const labelStyle = {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: 1.75,
    letterSpacing: "0.02857em",
    color: "rgba(0, 0, 0, 0.87)",
    display: "flex",
    alignItems: "center",
    marginRight: "16px",
    marginBottom: "12px",
    cursor: "pointer",
  };

  const removeIconStyle = {
    cursor: 'pointer',
    '&:hover': {
        color: PASTEL_RED
    }
  };

class BiblePassage {
    constructor(passage) {
        if (passage) {
            const regex = /^([\w\s]+)\s+(\d+)(?::(\d+))?(?:-(\d+))?$/;
            const match = passage.match(regex);

            if (match) {
                this.book = match[1];
                this.chapter = parseInt(match[2], 10);

                if (match[3]) {
                    this.verse = parseInt(match[3], 10);
                }

                if (match[4]) {
                    this.endVerse = parseInt(match[4], 10);
                }
            } else {
                throw new Error('Invalid passage format');
            }
        }
    }
}
  
const PassageInput = (props) => {
    const { handleRemovePassage, index, handleLocalPassageChange, passage } = props;
    const biblePassage = new BiblePassage(passage);
    const [book, setBook] = useState(biblePassage.book ? biblePassage.book : '');
    const [bookInput, setBookInput] = useState(biblePassage.book ? biblePassage.book : '');
    const [chapter, setChapter] = useState(biblePassage.chapter ? biblePassage.chapter : '');
    const [chapterInput, setChapterInput] = useState(biblePassage.chapter ? biblePassage.chapter : '');
    const [verse, setVerse] = useState(biblePassage.verse ? biblePassage.verse : '');
    const [verseInput, setVerseInput] = useState(biblePassage.verse ? biblePassage.verse : '');
    const [endVerse, setEndVerse] = useState(biblePassage.endVerse  ? biblePassage.endVerse : '');
    const [endVerseInput, setEndVerseInput] = useState(biblePassage.endVerse  ? biblePassage.endVerse : '');
    const [chapters, setChapters] = useState([]);
    const [verses, setVerses] = useState([]);
    const [hasEndVerse, setHasEndVerse] = useState(true);


    const handleBookChange = (newBook) => {
        setBook(newBook);
        setChapter('');
        setVerse('');
        setVerses([]);
        setEndVerse();
        
        if (newBook !== '') {
            setChapters(Array.from({ length: bibleBookChapters[newBook] }, (_, index) => index + 1));
        }
        else {
            setChapters([]);
        }
    };

    const handleChapterChange = (newChapter) => { 
        setChapter(newChapter);

        if (newChapter !== '') {
            setVerses(Array.from({ length: bibleBookChapterVerses[book][newChapter] }, (_, index) => index + 1));
        }
        else {
            setVerse();
            setVerses([])
            setEndVerse();
        }
    };

    const handleVerseChange = (newVerse) => { 
        setVerse(newVerse);
        setEndVerse();
    };

    const handleHasEndVerseChange = (event) => {
        if (!event.target.checked) {
            setEndVerse();
        }
        setHasEndVerse(event.target.checked);
    };

    const handleEndVerseChange = (newEndVerse) => { setEndVerse(newEndVerse); };

    useEffect(() => {
        if(book && chapter){
            const passageString = `${book} ${chapter}${verse ? `:${verse}` : ''}${hasEndVerse && endVerse ? `-${endVerse}` : ''}`;
            handleLocalPassageChange(index, passageString);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [book, chapter, verse, endVerse, hasEndVerse, index]);

    return (
        <div style={mainStyle}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                <Autocomplete
                    options={bibleBooks}
                    getOptionLabel={(option) => String(option)}
                    key={book}
                    size="small"
                    sx={{width: '14vw'}}
                    inputValue={bookInput}
                    value={book}
                    onInputChange={(event, newInputValue) => {
                        setBookInput(newInputValue);
                    }}
                    onChange={(event, newBookValue) => {
                        handleBookChange(newBookValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Book"
                        />
                    )}
                    />

                {book && (
                    <Autocomplete
                    options={chapters}
                    getOptionLabel={(option) => String(option)}
                    size="small"
                    sx={{ width: '6vw' }}
                    inputValue={chapterInput}
                    onInputChange={(event, newInputValue) => {
                        setChapterInput(newInputValue);
                    }}
                    value={chapter}
                    onChange={(event, newChapter) => {
                        handleChapterChange(newChapter);
                    }}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        label="Chapter"
                        />
                    )}
                    />
                )}

                {chapter && ':'}

                {chapter && (
                    <Autocomplete
                    options={verses}
                    getOptionLabel={(option) => String(option)}
                    size="small"
                    sx={{ width: '6vw' }}
                    inputValue={verseInput}
                    onInputChange={(event, newInputValue) => {
                        setVerseInput(newInputValue);
                    }}
                    value={verse}
                    onChange={(event, newVerse) => {
                        handleVerseChange(newVerse);
                    }}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        label="Verse"
                        />
                    )}
                    />
                )}

                {verse && hasEndVerse && ' - '}

                {verse && hasEndVerse && (
                    <Autocomplete
                    options={verses.filter((verseNum) => verseNum > verse)}
                    getOptionLabel={(option) => String(option)}
                    size="small"
                    sx={{ width: '6vw' }}
                    inputValue={endVerseInput}
                    onInputChange={(event, newInputValue) => {
                        setEndVerseInput(newInputValue);
                    }}
                    value={endVerse}
                    onChange={(event, newEndVerse) => {
                        handleEndVerseChange(newEndVerse);
                    }}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        label="End Verse"
                        />
                    )}
                    />
                )}

                <RemoveIcon sx={removeIconStyle} onClick={() => handleRemovePassage(index)}/>
            </div>
            {verse && <label style={labelStyle}>
                    <input type="checkbox" checked={hasEndVerse} onChange={handleHasEndVerseChange} />
                    Multiple Verses
                </label>}
        </div>
    );
};

export default PassageInput;
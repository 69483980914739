import { Button } from '@mui/material';
import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router';
import { DARK_GRAY, LIGHT_GRAY } from '../../theme/colors';

const menuStyle = {
    alignItems: 'center',
    backgroundColor: LIGHT_GRAY,
    width: '100%',
    paddingTop: '10px',
    paddingBottom: '10px',
    color: DARK_GRAY,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '56px'
  };

const BackButton = (props) => {
    const {backToPath} = props;

    const navigate = useNavigate(); 
    const handleBackButton = () => {
        navigate(backToPath);
    };

    return (
        <div style={menuStyle}>
            <Button variant="outlined" sx={{marginLeft: '10px'}} startIcon={<ArrowBackIosIcon />} onClick={handleBackButton}>
                Back
            </Button>
        </div>
    );
};

export default BackButton;
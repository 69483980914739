import { useState, useEffect } from "react";
import "./mobileMenu.css";
import { NavLink } from "react-router-dom";
import Social from "../social/social";
//import MobileMore from './mobileMore/MobileMore';
import {
  homePath,
  servicePath,
  aboutPath,
  announcementsPath,
  contactPath,
  twoColumnWidth as policyWidth,
  refundPolicyPath,
  privacyPolicyPath,
  homeString,
  servicesString,
  aboutString,
  donationsString,
  announcementsString,
  contactString,
  privacyPolicyString,
  refundPolicyString,
  givePath,
  LightDarkGray,
  mediaPath,
  mediaString,
  bibleReadingPlanPath,
  bibleReadingPlanString,
} from "../../constants";
import Flags from "../../footer/flags/Flags";
import MobileServiceTimes from "./mobileMore/MobileServiceTimes";
import { street, city, state, zip } from "../../constants";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../app/slices/languageSlice";

export function MobileMenu(props) {
  const policyMatch = window.matchMedia(`(max-width: ${policyWidth})`);
  const [, setIsPolicy] = useState(policyMatch.matches);
  const language = useAppSelector(selectLanguage);

  useEffect(() => {
    // Resize handler
    const handleResize = () => {
      const policyMatch = window.matchMedia(`(max-width: ${policyWidth})`);
      setIsPolicy(policyMatch.matches);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (props.isActive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [props.isActive]);

  if (props.isActive) {
    return (
      <div className="mobileMenu">
        <div className="menuContainer">
          {" "}
          {/* Add this line */}
          <NavLink
            activeclassname="active"
            onClick={props.handleClick}
            to={homePath}
          >
            {" "}
            {homeString(language)}{" "}
          </NavLink>
          <NavLink
            activeclassname="active"
            onClick={props.handleClick}
            to={servicePath}
          >
            {" "}
            {servicesString(language)}{" "}
          </NavLink>
          <NavLink
            activeclassname="active"
            onClick={props.handleClick}
            to={aboutPath}
          >
            {" "}
            {aboutString(language)}{" "}
          </NavLink>
          <NavLink
            activeclassname="active"
            onClick={props.handleClick}
            to={givePath}
            target="_blank"
          >
            {" "}
            {donationsString(language)}{" "}
          </NavLink>
          <NavLink
            activeclassname="active"
            onClick={props.handleClick}
            to={announcementsPath}
          >
            {" "}
            {announcementsString(language)}{" "}
          </NavLink>
          <NavLink
            activeclassname="active"
            onClick={props.handleClick}
            to={mediaPath}
          >
            {" "}
            {mediaString(language)}{" "}
          </NavLink>
          <NavLink
            activeclassname="active"
            onClick={props.handleClick}
            to={contactPath}
          >
            {" "}
            {contactString(language)}{" "}
          </NavLink>
          <NavLink
            activeclassname="active"
            onClick={props.handleClick}
            to={bibleReadingPlanPath}
          >
            {" "}
            {bibleReadingPlanString(language)}{" "}
          </NavLink>
          <NavLink
            activeclassname="active"
            onClick={props.handleClick}
            to={"/ezra"}
          >
            Ezra Conference
          </NavLink>
          <MobileServiceTimes />
          {/* We do not currently have "more" items. If we ever get more items, then we can uncomment the code below and add it to the more menu */}
          {/* <MobileMore handleClick={props.handleClick}/> */}
          <Social />
          <p
            style={{
              fontSize: "10px",
              color: LightDarkGray,
              fontWeight: "200",
              marginBottom: "0",
              marginLeft: "10px",
            }}
          >
            Bethel Bible Church
          </p>
          <a
            href="https://goo.gl/maps/uz7WX7xbMQ9sioxb7"
            target="_blank"
            rel="noreferrer"
            style={{
              fontSize: "10px",
              color: LightDarkGray,
              fontWeight: "200",
              marginTop: "0",
              paddingTop: "0",
            }}
          >
            {street}, {city}, {state} {zip}
          </a>
          <div className="policy">
            <NavLink
              activeclassname="active"
              onClick={props.handleClick}
              to={privacyPolicyPath}
            >
              {" "}
              {privacyPolicyString(language)}{" "}
            </NavLink>
            <NavLink
              activeclassname="active"
              onClick={props.handleClick}
              to={refundPolicyPath}
            >
              {" "}
              {refundPolicyString(language)}{" "}
            </NavLink>
          </div>
          <ul style={{ "list-style-type": "none", margin: "0", padding: "0" }}>
            <Flags />
          </ul>
          {/* The rest of your components goes here */}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

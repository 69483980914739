import React from 'react';
import { otherGroupType } from '../../../../../../../constants';
import GroupTitleSelector from '../groupTitleSelector';
import GroupTypeSelector from '../groupTypeSelector';
import LanguageSelector from '../languageSelector';
import PersonnelSelector from '../personnelSelector';
import SongTitleSelector from '../songTitleSelector';

const Group = (props) => {
    const { serviceData, handleSongSubEntityTypeChange, handleLanguageChange, handleGroupTitleEnChange, handleGroupTitleRoChange, handleSongTitleEnChange, handleSongTitleRoChange, handleAddPersonnel, addPersonDialogOpen, addPeopleOpen, handlePersonChange, people, handlePositionChange, saveNewPerson, newPerson, newPosition, handleAddPerson, handleAddPeopleClose, removePerson, addPersonToPeople, personPanelIsOpen, handleAddPersonClose } = props;

    return (
        <>
            <GroupTypeSelector serviceData={serviceData} handleSongSubEntityTypeChange={handleSongSubEntityTypeChange} />
            {serviceData.song_sub_entity === otherGroupType && <GroupTitleSelector serviceData={serviceData} handleGroupTitleEnChange={handleGroupTitleEnChange} handleGroupTitleRoChange={handleGroupTitleRoChange}/>}
            {serviceData.song_sub_entity && <LanguageSelector serviceData={serviceData} handleLanguageChange={handleLanguageChange}/>}
            {serviceData.language && <SongTitleSelector serviceData={serviceData} handleSongTitleEnChange={handleSongTitleEnChange} handleSongTitleRoChange={handleSongTitleRoChange}/>}
            {(serviceData.song_title_en || serviceData.song_title_ro)&& <PersonnelSelector serviceData={serviceData} handleAddPersonnel={handleAddPersonnel} addPersonDialogOpen={addPersonDialogOpen} addPeopleOpen={addPeopleOpen} handlePersonChange={handlePersonChange} people={people} handlePositionChange={handlePositionChange} saveNewPerson={saveNewPerson} parentProps={props} newPerson={newPerson} newPosition={newPosition} handleAddPerson={handleAddPerson} handleAddPeopleClose={handleAddPeopleClose} removePerson={removePerson} addPersonToPeople={addPersonToPeople} personPanelIsOpen={personPanelIsOpen} handleAddPersonClose={handleAddPersonClose}/>}
        </>
    );
};

export default Group;
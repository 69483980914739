import React from 'react';
import Userfront from "@userfront/react";
import config from '../../configuration.json';
import RequireAuth from './requireAuth';
import BackButton from './backButton';
import { dashboardPath } from '../../constants';
import { Grid } from '@mui/material';

Userfront.init(config.USERFRONT.id);

const SignupForm = Userfront.build({
    toolId: `${config.USERFRONT.signUpId}`
  });

function SignupBox() {
return (
    <div>
        <SignupForm />
    </div>
);
}

const mainStyle = {
    fontFamily: "Montserrat",
    display: 'flex',
    flexDirection: 'column',
    margin: '0',
    width: '100%',
    height: '100%',
    alignItems: 'center'
  };


const NewUser = (props) => {
    return (
        <div style={mainStyle}>
            {/* <RequireAuth /> */}
            <BackButton backToPath={dashboardPath}/>

            <Grid container spacing={2} alignContent={'center'} justifyContent={'center'} sx={{flex: '1'}}>
                <Grid item xs={12} sx={{flex: '1'}}>
                    <SignupBox />
                </Grid>
            </Grid>
        </div>
    );
};

export default NewUser;
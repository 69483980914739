import React, { useEffect, useState } from 'react';
import { encouragement_for_attribute, language_attribute, location_from_attribute, passage_attribute, speaker_attribute, speaker_key_attribute } from '../../../../../constants';
import EncouragementForSelector from './sermonPointComponents/encouragementForSelector';
import LocationFromSelector from './sermonPointComponents/locationFromSelector';
import PassageSelector from './sermonPointComponents/passageSelector';
import SpeakerSelector from './sermonPointComponents/speakerSelector';
import LanguageSelector from './songPointComponents/languageSelector';

const EncouragementPoint = (props) => {
    const { serviceData, handleItemAttributeChange, index, people, addPersonToPeople } = props; 

    const [newPerson, setNewPerson] = useState();
    const [personPanelIsOpen, setPersonPanelIsOpen] = useState(false);
    const [guestSpeaker, setGuestSpeaker] = useState(false);

    const handleAddPersonClose = () => {
        setPersonPanelIsOpen(false);
    };

    const handlePersonChange = (person) => {
        if (person.label.startsWith("Add ")) {
            console.log(`Adding ${person.label}`);
            setPersonPanelIsOpen(true);
            return;
        }
        setNewPerson(person);
        handleItemAttributeChange(index, speaker_attribute, person.label);
        handleItemAttributeChange(index, speaker_key_attribute, person.id);
    };

    const handleLanguageChange = (event) => {
        handleItemAttributeChange(index, language_attribute, event.target.value);
    };

    const handlePassageChange = (passages) => {
        handleItemAttributeChange(index, passage_attribute, passages);
    };

    const handleGuestSpeakerChange = (event) => {
        setGuestSpeaker(event.target.checked);
    };

    const handleLocationFromChange = (location) => {
        handleItemAttributeChange(index, location_from_attribute, location);
    };

    const handleEncouragementForChange = (encouragementFor) => {
        handleItemAttributeChange(index, encouragement_for_attribute, encouragementFor);
    };

    useEffect(() => {
        if (!guestSpeaker) {
            handleLocationFromChange('');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [guestSpeaker]);
 
    return (
        <div>
            <SpeakerSelector newPerson={newPerson} people={people} handlePersonChange={handlePersonChange} addPersonToPeople={addPersonToPeople} personPanelIsOpen={personPanelIsOpen} handleAddPersonClose={handleAddPersonClose} handleGuestSpeakerChange={handleGuestSpeakerChange}/>
            {/* IN THE FUTURE, WE SHOULD ADD THE PERSON'S TITLE HERE*/}
            {guestSpeaker && <LocationFromSelector serviceData={serviceData} handleLocationFromChange={handleLocationFromChange}/>}
            {serviceData.speaker && <LanguageSelector serviceData={serviceData} handleLanguageChange={handleLanguageChange}/>}
            {serviceData.language && <PassageSelector serviceData={serviceData} handlePassageChange={handlePassageChange} /> }
            {serviceData.language && <EncouragementForSelector serviceData={serviceData} handleEncouragementForChange={handleEncouragementForChange} />}
        </div>
    );
};

export default EncouragementPoint;
import React from 'react';
import LanguageSelector from '../languageSelector';
import SongTitleSelector from '../songTitleSelector';

const Worship = (props) => {
    const { serviceData, handleLanguageChange, handleSongTitleEnChange, handleSongTitleRoChange } = props;

    return (
        <>
            <LanguageSelector serviceData={serviceData} handleLanguageChange={handleLanguageChange} />
            {serviceData.language && <SongTitleSelector 
                                        serviceData={serviceData} 
                                        handleSongTitleEnChange={handleSongTitleEnChange} 
                                        handleSongTitleRoChange={handleSongTitleRoChange} 
                                        />}
        </>
    );
};

export default Worship;
import { Button, useTheme } from "@mui/material";
import myImage from "./EzraImage.jpg";
const Ezra = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        alignItems: "center",
        paddingTop: "56px",
      }}
    >
      <img
        src={myImage}
        style={{
          width: "50%",
          maxHeight: "70%",
          objectFit: "contain",
        }}
        alt="The Ezra Conference"
      />
      <h1
        style={{
          fontFamily: "Montserrat",
          color: "#666667",
          fontWeight: "400",
          fontSize: "2.5vw",
        }}
      >
        Friay, October 4, 2024 & Saturday, October 5, 2024
      </h1>
      <a
        href="https://bethelrpc.churchcenter.com/registrations/events/2435120"
        target="_blank"
      >
        <Button variant="outlined" size="large">
          Sign Up For the Ezra Conference
        </Button>
      </a>
    </div>
  );
};

export default Ezra;

// TODO: This is a temporary page. Anything still here after October 2024 should be removed

import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import { serviceDateString } from "../constants";
import { useAppSelector } from "../app/hooks";
import { selectLanguage } from "../app/slices/languageSlice";

const DateDisplay = (props) => {
  const language = useAppSelector(selectLanguage);
  const { date, handleDateChange } = props;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "10px",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          label={serviceDateString(language)}
          inputFormat="MM/DD/YYYY"
          value={date}
          onChange={handleDateChange}
          renderInput={(params) => <TextField size="small" {...params} />}
          minDate={new Date("2010-01-01")}
        />
      </LocalizationProvider>
    </div>
  );
};

export default DateDisplay;

import { useState } from "react";
import {
  announcementsString,
  LightBlue,
  LightDarkGray,
} from "../../../constants";
import { useAppSelector } from "../../../app/hooks";
import { selectLanguage } from "../../../app/slices/languageSlice";

const announcementPointEntityStyle = {
  fontWeight: "500",
};

const hoverStyle = {
  color: LightBlue,
  cursor: "pointer",
  paddingBottom: "10px",
  fontSize: "14px",
};

const mainStyle = {
  color: LightDarkGray,
  fontSize: "14px",
  paddingBottom: "10px",
};

const AnnouncementPoint = ({ servicePoint, handlePointTimestamp }) => {
  const language = useAppSelector(selectLanguage);
  const [announcementStyle, setAnnouncementStyle] = useState(mainStyle);

  const handleMouseEnter = () => {
    setAnnouncementStyle(hoverStyle);
  };

  const handleMouseLeave = () => {
    setAnnouncementStyle(mainStyle);
  };

  const handleClick = () => {
    handlePointTimestamp(servicePoint.START_TIMESTAMP_UNIX);
  };

  return (
    <div
      style={announcementStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <span style={announcementPointEntityStyle}>
        {announcementsString(language)}
      </span>
      {/* <span style={announcemerStyle}>{getAnnouncerTitle(language, GENERAL_TITLE_EN, GENERAL_TITLE_RO)}</span> */}
      {/* TODO: We'll need to finish this logic soon */}
    </div>
  );
};

export default AnnouncementPoint;

import React from 'react';
import VisuallyHiddenText from '../../components/VisuallyHiddenText';
import { facebookPath, instagramPath, youtubePath, twitterPath } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faFacebook,
    faInstagram,
    faXTwitter,
    faYoutube
} from '@fortawesome/free-brands-svg-icons';

/*
 * STYLES
*/
const socialLinksStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '1.3rem'
};

/**
 * Component returning social media links
 * @returns 
 */
const SocialLinks = () => {
    
    return (
        <div style={socialLinksStyle}>
            <a href={facebookPath} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faFacebook} aria-hidden="true" size="xs" />
                <VisuallyHiddenText>Facebook</VisuallyHiddenText>
            </a>
            <a href={instagramPath} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faInstagram} aria-hidden="true" size="xs" />
                <VisuallyHiddenText>Instagram</VisuallyHiddenText>
            </a>
            <a href={twitterPath} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faXTwitter} aria-hidden="true" size="xs" />
                <VisuallyHiddenText>Twitter</VisuallyHiddenText>
            </a>
            <a href={youtubePath} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faYoutube} aria-hidden="true" size="xs"/>
                <VisuallyHiddenText>YouTube</VisuallyHiddenText>
            </a>
        </div>
    );
};

export default SocialLinks;

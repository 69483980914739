import React from 'react';
import Userfront from "@userfront/react";
import config from '../../configuration.json';

Userfront.init(config.USERFRONT.id);

const LoginForm = Userfront.build({
    toolId: `${config.USERFRONT.loginId}`,
  });

function LoginBox() {
return (
    <div>
        <LoginForm />
    </div>
);
}

const mainStyle = {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Montserrat',
    height: '100%',
    width: '100%'
};


const Login = (props) => {
    if (!props.token) {
    return (
        <div style={mainStyle}>
            <LoginBox />
        </div>
    );}
    else {
        return (
            <div style={mainStyle}>
                
            </div>
        ); 
    }
};

export default Login;
import React, { useState, useEffect } from 'react';
import { LighterBlue } from '../../constants';

const footerItemsStyle = {
    padding: '0px 7px 0px 7px',
    cursor: 'pointer',
    height: '100%',
    alignItems: 'center',
    display: 'flex',
};

const footerItemsSelectedStyle = {
    padding: '0px 7px 0px 7px',
    cursor: 'pointer',
    backgroundColor: 'white',
    height: '100%',
    display: 'flex',
    alignItems: 'center'
};

const hoverStyle = {
    padding: '0px 7px 0px 7px',
    cursor: 'pointer',
    backgroundColor: LighterBlue,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'white'
};

const PanelFooterItem = (props) => {
    const { selected, title, tab, handleSwitch } = props;
    const [currentStyle, setCurrentStyle] = useState(selected ? footerItemsSelectedStyle : footerItemsStyle);

    const handleMouseEnter = () => { setCurrentStyle(hoverStyle) };
    const handleMouseLeave = () => { setCurrentStyle(selected ? footerItemsSelectedStyle : footerItemsStyle) };

    useEffect(() => {
        setCurrentStyle(selected ? footerItemsSelectedStyle : footerItemsStyle);
    }, [selected]);

    return (
        <div style={currentStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => {handleSwitch(tab)}}>
            <div style={{alignContent: 'center'}}>{title}</div>
        </div>
    );
};

export default PanelFooterItem;
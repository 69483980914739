export const GRAY = '#32373c';
export const LIGHT_BLUE = '#007cbb';
export const SOFT_TEAL = '#669999';
export const LIGHTER_BLUE = '#38a2c9'
export const ALMOST_BLACK = '#FFFFFF,'
export const LIGHT_GRAY = '#f6f6f6';
export const DARK_GRAY = '#404140';
export const LIGHT_DARK_GRAY = '#666667';
export const PASTEL_RED = '#ff5148';
export const WARNING_ORANGE = '#f7d1ad';
export const MONEY_GREEN = '#27a86c';
export const DATE_GRAY = '#343434';
export const BORDER_GRAY = '#e3d7d1';
export const TEXT_BLACK = '#32373c';
export const TEXT_WHITE = '#F7F7F7';
export const PAGE_WHITE = '#F6F6F6';
export const SOFT_TAN = '#e8e3d6';
export const LIGHT_BLUE_RGB = `${parseInt(LIGHT_BLUE.slice(1, 3), 16)}, ${parseInt(LIGHT_BLUE.slice(3, 5), 16)}, ${parseInt(LIGHT_BLUE.slice(5), 16)}`;
import { announcementTitleString } from "../../../constants";
import Announcement from "./announcement";
import Divider from "@mui/material/Divider";
import { Typography, Box, useTheme } from "@mui/material";
import { useAppSelector } from "../../../app/hooks";
import { selectLanguage } from "../../../app/slices/languageSlice";

const mainStyle = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dividerStyle = {
  width: "100%",
};

const AnnouncementPanel = (props) => {
  const language = useAppSelector(selectLanguage);
  const theme = useTheme();

  const announcements = props.data.map((announcement, index) => (
    <Box
      key={announcement.ANNOUNCEMENT_KEY}
      sx={{
        bgcolor: index % 2 === 0 ? theme.palette.secondary.main : "white",
        px: 1.5,
      }}
    >
      <Announcement data={announcement} />
      <Divider sx={dividerStyle} />
    </Box>
  ));

  return (
    <Box
      style={mainStyle}
      sx={{
        bgcolor: "white",
        pt: 0,
        pb: 0,
        fontFamily: theme.typography.fontFamily,
      }}
    >
      <Typography
        align="left"
        variant="h4"
        gutterBottom
        sx={{
          fontFamily: theme.typography.secondaryFontFamily,
          mt: 1,
          mb: 1,
          ml: 1,
          color: theme.palette.primary.text,
        }}
      >
        {announcementTitleString(language)}
      </Typography>
      {announcements}
    </Box>
  );
};

export default AnnouncementPanel;

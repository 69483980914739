import {useState, useEffect} from 'react';
import { BY_DATE, finishedLoadingMessage, LightBlue, LightDarkGray, recentServicesString } from '../../constants';
import { getDisplayDateWithYear } from '../../helpers/dataBuilders';
import PanelMessageEntry from './panelMessageEntry';
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material';
import DateDisplay from '../dateDisplay';
import DateSortButtonGroup from './dateSortButtonGroup';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/slices/languageSlice';

const mainStyle = {
    display: 'flex',    
    flexDirection: 'column',
    color: LightDarkGray,
    fontSize: '13.3px',
    fontWeight: '400',
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '20px',
    marginRight: '25px',
};


const listStyle = {
    width: '100%',
    height: '100vh',
    overflow: 'scroll'
  };

const PanelMessageDate = (props) => {
    const language = useAppSelector(selectLanguage);
    const theme = useTheme();
    const { getMore, hasMore, data, from, closeService, sortBy, handleSortByChange, sortDate, handleDateChange, handlePointTimestamp } = props;
    const [content, setContent] = useState();

    const serviceHeaderStyle = {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        fontWeight: '400',
        color: LightBlue,
        padding: '0',
        fontSize: 'min(4vw, 35px)',
        fontFamily: theme.typography.secondaryFontFamily
    };

    const dateStyle = {
        paddingLeft: '6px',
    };

    const handleScroll = (event) => {
        const element = event.target;
        if (Math.ceil(element.scrollHeight - element.scrollTop) <= element.clientHeight + 2 && hasMore) {
            getMore();
        }
      }

    useEffect(() => {
        setContent(
            new Array(...data).map((event) => {
                
                return (
                    <div key={event[0]} style={mainStyle}>
                        <div style={dateStyle}>{getDisplayDateWithYear(new Date(event[1][0].date), language)}</div>
                        <PanelMessageEntry data={event[1]} from={from} closeService={closeService} handlePointTimestamp={handlePointTimestamp}/>
                    </div>
                );
            }
        ));
    }, [data]);

    return (
        <div >
            <div style={{zIndex: '0'}}>
                {from === 'services' && <div style={serviceHeaderStyle}>{recentServicesString(language)}</div>}
                <DateSortButtonGroup handleSortByChange={handleSortByChange} sortBy={sortBy} />
            </div>

            {sortBy === BY_DATE && <DateDisplay date={sortDate} handleDateChange={handleDateChange} />}
            <div style={listStyle} onScroll={handleScroll}>
                <InfiniteScroll 
                    style={{paddingBottom: '300px' }}
                    dataLength={content ? content.length - 1 : 0}
                    next={getMore}
                    hasMore={hasMore}
                    onScroll={handleScroll}
                    loader={<div style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center'}} > <CircularProgress /> </div>}
                    endMessage={
                        <p style={{ textAlign: 'center', fontWeight: '300', color: LightDarkGray, fontSize: '12px' }}>
                            {finishedLoadingMessage(language)}
                        </p>
                    }
                    >
                    <div>
                        {content}
                    </div>

                </InfiniteScroll>
            </div>
        </div>
    );
};

export default PanelMessageDate;
import React, { useState } from 'react';
import { choirType, general_description_en_attribute, song_entity_attribute, general_description_ro_attribute, general_title_en_attribute, general_title_ro_attribute, groupType, group_title_en_attribute, group_title_ro_attribute, language_attribute, orchestraType, otherChoirType, otherType, personnel_attribute, soloType, song_sub_entity_attribute, song_title_en_attribute, song_title_ro_attribute, with_praise_band_attribute, worshipType, instrumentalType } from '../../../../../constants';
import SongTypeSelector from './songPointComponents/songTypeSelector';
import Worship from './songPointComponents/songTypes/worship';
import Choir from './songPointComponents/songTypes/choir';
import Orchestra from './songPointComponents/songTypes/orchestra';
import Group from './songPointComponents/songTypes/group';
import Solo from './songPointComponents/songTypes/solo';
import Instrumental from './songPointComponents/songTypes/instrumental';
import Other from './songPointComponents/songTypes/other';

const mainStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
    flexDirection: 'column'
};

const SongPoint = (props) => {
    const { serviceData, handleItemAttributeChange, index, people, addPersonToPeople } = props;
    const [addPersonDialogOpen, setAddPersonDialogOpen] = useState(false);
    const [addPeopleOpen, setAddPeopleOpen] = useState(false);
    const [newPerson, setNewPerson] = useState();
    const [newPosition, setNewPosition] = useState();
    const [personPanelIsOpen, setPersonPanelIsOpen] = useState(false);

    const handleSongEntityChange = (event) => {
        handleItemAttributeChange(index, song_entity_attribute, event.target.value);

        handleItemAttributeChange(index, song_sub_entity_attribute, '');
        handleItemAttributeChange(index, general_description_en_attribute, '');
        handleItemAttributeChange(index, general_description_ro_attribute, '');
        handleItemAttributeChange(index, general_title_en_attribute, '');
        handleItemAttributeChange(index, general_title_ro_attribute, '');
        handleItemAttributeChange(index, group_title_en_attribute, '');
        handleItemAttributeChange(index, group_title_ro_attribute, '');
        handleItemAttributeChange(index, language_attribute, '');
        handleItemAttributeChange(index, song_title_en_attribute, '');
        handleItemAttributeChange(index, song_title_ro_attribute, '');
        handleItemAttributeChange(index, with_praise_band_attribute, false);
        handleItemAttributeChange(index, personnel_attribute, []); 
    };

    const handleLanguageChange = (event) => {
        handleItemAttributeChange(index, language_attribute, event.target.value);
    };

    const handleSongTitleEnChange = (event) => {
        handleItemAttributeChange(index, song_title_en_attribute, event.target.value);
    };

    const handleSongTitleRoChange = (event) => {
        handleItemAttributeChange(index, song_title_ro_attribute, event.target.value);
    };

    const handleSongSubEntityTypeChange = (event) => {
        handleItemAttributeChange(index, song_sub_entity_attribute, event.target.value);

        if (event.target.value !== otherChoirType) {
            handleItemAttributeChange(index, group_title_en_attribute, '');
            handleItemAttributeChange(index, group_title_ro_attribute, '')
        }
        handleItemAttributeChange(index, personnel_attribute, []);
        handleItemAttributeChange(index, language_attribute, '');
        handleItemAttributeChange(index, song_title_en_attribute, '');
        handleItemAttributeChange(index, song_title_ro_attribute, '');
        handleItemAttributeChange(index, general_title_en_attribute, '');
        handleItemAttributeChange(index, general_title_ro_attribute, '');
        handleItemAttributeChange(index, general_description_en_attribute, '');
        handleItemAttributeChange(index, general_description_ro_attribute, '');
    };

    const handleGroupTitleEnChange = (event) => {
        handleItemAttributeChange(index, group_title_en_attribute, event.target.value);
    };

    const handleGroupTitleRoChange = (event) => {
        handleItemAttributeChange(index, group_title_ro_attribute, event.target.value);
    };

    const handleAddPersonnel = () => {
        setAddPeopleOpen(true);
    };

    const handleAddPeopleClose = () => {
        setAddPeopleOpen(false);
    };

    const handlePersonChange = (person) => {
        if (person.label.startsWith("Add ")) {
            console.log(`Adding ${person.label}`);
            setPersonPanelIsOpen(true);
            return;
        }
        setNewPerson(person);
    };

    const handlePositionChange = (event) => {
        setNewPosition(event.target.value);
    };



    const saveNewPerson = () => {
       const tmpPersonnel = [...serviceData.personnel];
       
       tmpPersonnel.push({
        person: newPerson,
        position: newPosition,
        service_item_key: serviceData.service_item_key,
        personnel_key: '-1'
    });
        

       handleItemAttributeChange(index, personnel_attribute, tmpPersonnel);
       setAddPeopleOpen(false);
       setAddPersonDialogOpen(false);
       setNewPerson('');
       setNewPosition('');
    };

    const removePerson = (i) => {
        const tmpPersonnel = [...serviceData.personnel];
        tmpPersonnel.splice(i, 1);
        handleItemAttributeChange(index, personnel_attribute, tmpPersonnel);
    };

    const handleGeneralTitleEnChange = (event) => {
        handleItemAttributeChange(index, general_title_en_attribute, event.target.value);
    };

    const handleGeneralTitleRoChange = (event) => {
        handleItemAttributeChange(index, general_title_ro_attribute, event.target.value);
    };

    const handleGeneralDescriptionEnChange = (event) => {
        handleItemAttributeChange(index, general_description_en_attribute, event.target.value);
    };

    const handleGeneralDescriptionRoChange = (event) => {
        handleItemAttributeChange(index, general_description_ro_attribute, event.target.value);
    };

    const handleAddPersonClose = () => {
        setPersonPanelIsOpen(false);
    };

    return (
        <div style={mainStyle}>
            <SongTypeSelector serviceData={serviceData} handleSongEntityChange={handleSongEntityChange} />
            {serviceData.song_entity === worshipType && <Worship serviceData={serviceData} handleLanguageChange={handleLanguageChange} handleSongTitleEnChange={handleSongTitleEnChange} handleSongTitleRoChange={handleSongTitleRoChange} />}
            {serviceData.song_entity === choirType && <Choir serviceData={serviceData} addPersonToPeople={addPersonToPeople} personPanelIsOpen={personPanelIsOpen} handleAddPersonClose={handleAddPersonClose} handleSongSubEntityTypeChange={handleSongSubEntityTypeChange} handleLanguageChange={handleLanguageChange} handleGroupTitleEnChange={handleGroupTitleEnChange} handleGroupTitleRoChange={handleGroupTitleRoChange} handleSongTitleEnChange={handleSongTitleEnChange} handleSongTitleRoChange={handleSongTitleRoChange} handleAddPersonnel={handleAddPersonnel} addPersonDialogOpen={addPersonDialogOpen} addPeopleOpen={addPeopleOpen} handlePersonChange={handlePersonChange} people={people} handlePositionChange={handlePositionChange} saveNewPerson={saveNewPerson} newPerson={newPerson} newPosition={newPosition} handleAddPerson={saveNewPerson} handleAddPeopleClose={handleAddPeopleClose} removePerson={removePerson}/>}
            {serviceData.song_entity === orchestraType && <Orchestra serviceData={serviceData} addPersonToPeople={addPersonToPeople} personPanelIsOpen={personPanelIsOpen} handleAddPersonClose={handleAddPersonClose} handleSongSubEntityTypeChange={handleSongSubEntityTypeChange} handleLanguageChange={handleLanguageChange} handleGroupTitleEnChange={handleGroupTitleEnChange} handleGroupTitleRoChange={handleGroupTitleRoChange} handleSongTitleEnChange={handleSongTitleEnChange} handleSongTitleRoChange={handleSongTitleRoChange} handleAddPersonnel={handleAddPersonnel} addPersonDialogOpen={addPersonDialogOpen} addPeopleOpen={addPeopleOpen} handlePersonChange={handlePersonChange} people={people} handlePositionChange={handlePositionChange} saveNewPerson={saveNewPerson} newPerson={newPerson} newPosition={newPosition} handleAddPerson={saveNewPerson} handleAddPeopleClose={handleAddPeopleClose} removePerson={removePerson}/>}
            {serviceData.song_entity === groupType && <Group serviceData={serviceData} addPersonToPeople={addPersonToPeople} personPanelIsOpen={personPanelIsOpen} handleAddPersonClose={handleAddPersonClose} handleSongSubEntityTypeChange={handleSongSubEntityTypeChange} handleLanguageChange={handleLanguageChange} handleGroupTitleRoChange={handleGroupTitleRoChange} handleSongTitleEnChange={handleSongTitleEnChange} handleSongTitleRoChange={handleSongTitleRoChange} handleAddPersonnel={handleAddPersonnel} addPersonDialogOpen={addPersonDialogOpen} addPeopleOpen={addPeopleOpen} handlePersonChange={handlePersonChange} people={people} handlePositionChange={handlePositionChange} saveNewPerson={saveNewPerson} newPerson={newPerson} newPosition={newPosition} handleAddPerson={saveNewPerson} handleAddPeopleClose={handleAddPeopleClose} removePerson={removePerson}/>}
            {serviceData.song_entity === soloType && <Solo serviceData={serviceData} addPersonToPeople={addPersonToPeople} personPanelIsOpen={personPanelIsOpen} handleAddPersonClose={handleAddPersonClose} handleSongSubEntityTypeChange={handleSongSubEntityTypeChange} handleLanguageChange={handleLanguageChange} handleGroupTitleEnChange={handleGroupTitleEnChange} handleGroupTitleRoChange={handleGroupTitleRoChange} handleSongTitleEnChange={handleSongTitleEnChange} handleSongTitleRoChange={handleSongTitleRoChange} handleAddPersonnel={handleAddPersonnel} addPersonDialogOpen={addPersonDialogOpen} addPeopleOpen={addPeopleOpen} handlePersonChange={handlePersonChange} people={people} handlePositionChange={handlePositionChange} saveNewPerson={saveNewPerson} newPerson={newPerson} newPosition={newPosition} handleAddPerson={saveNewPerson} handleAddPeopleClose={handleAddPeopleClose} removePerson={removePerson}/>}
            {serviceData.song_entity === instrumentalType && <Instrumental serviceData={serviceData} addPersonToPeople={addPersonToPeople} personPanelIsOpen={personPanelIsOpen} handleAddPersonClose={handleAddPersonClose} handleSongSubEntityTypeChange={handleSongSubEntityTypeChange} handleLanguageChange={handleLanguageChange} handleGroupTitleEnChange={handleGroupTitleEnChange} handleGroupTitleRoChange={handleGroupTitleRoChange} handleSongTitleEnChange={handleSongTitleEnChange} handleSongTitleRoChange={handleSongTitleRoChange} handleAddPersonnel={handleAddPersonnel} addPersonDialogOpen={addPersonDialogOpen} addPeopleOpen={addPeopleOpen} handlePersonChange={handlePersonChange} people={people} handlePositionChange={handlePositionChange} saveNewPerson={saveNewPerson} newPerson={newPerson} newPosition={newPosition} handleAddPerson={saveNewPerson} handleAddPeopleClose={handleAddPeopleClose} removePerson={removePerson}/>}
            {serviceData.song_entity === otherType && <Other serviceData={serviceData} addPersonToPeople={addPersonToPeople} personPanelIsOpen={personPanelIsOpen} handleAddPersonClose={handleAddPersonClose} handleSongSubEntityTypeChange={handleSongSubEntityTypeChange} handleLanguageChange={handleLanguageChange} handleGroupTitleEnChange={handleGroupTitleEnChange} handleGroupTitleRoChange={handleGroupTitleRoChange} handleSongTitleEnChange={handleSongTitleEnChange} handleSongTitleRoChange={handleSongTitleRoChange} handleAddPersonnel={handleAddPersonnel} addPersonDialogOpen={addPersonDialogOpen} addPeopleOpen={addPeopleOpen} handlePersonChange={handlePersonChange} people={people} handlePositionChange={handlePositionChange} saveNewPerson={saveNewPerson} newPerson={newPerson} newPosition={newPosition} handleAddPerson={saveNewPerson} handleAddPeopleClose={handleAddPeopleClose} removePerson={removePerson} handleGeneralTitleEnChange={handleGeneralTitleEnChange} handleGeneralTitleRoChange={handleGeneralTitleRoChange} handleGeneralDescriptionEnChange={handleGeneralDescriptionEnChange} handleGeneralDescriptionRoChange={handleGeneralDescriptionRoChange}/>}
        </div>
    );
};

export default SongPoint;
import React from 'react';
import Userfront from "@userfront/react";
import { useTheme } from '@mui/material';
import RequireAuth from './requireAuth';
import { admin_permission, announcements_permission, DarkGray, newUserPath, pointsDashboardPath, points_permission, propertiesPath, properties_permission, announcementsDashboardPath } from '../../constants';
import Card from '../../components/dashboard/card';
import { faArrowRightFromBracket, faBullhorn, faChurch, faGear, faUserPlus } from '@fortawesome/free-solid-svg-icons';

const getPermissions = () => {
    if (Userfront.tokens.accessToken) {
        if (!Userfront.user.data.permissions) {
            return ['NoRights'];
        }
        return Userfront.user.data.permissions;
    } else {
        return [];
    }
};

const getUsername = () => {
    if (Userfront.tokens.accessToken) {
        return Userfront.user.username;
    } else {
        return [null];
    }
};

const cardsContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '16px',
    maxWidth: 'calc(282px + 282px + 282px + 200px)'
};

const cardContainerStyle = {
    flex: '0 0 calc(33.33% - 10.66px)',  // We subtract 1/3 of the total gap to ensure 3 fit in a row.
};

const titleStyle = {
    padding: '10px',
    fontSize: '50px',
    fontWeight: '600',
    color: DarkGray
};


const Dashboard = () => {
    const theme = useTheme();

    const mainStyle = {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Montserrat',
        color: 'white',
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.secondary.main
    };

    return (
        <div style={mainStyle}>
            <RequireAuth />
            {!getPermissions().includes('NoRights') && <div style={titleStyle}>Welcome, {getUsername()}</div>}
            {getPermissions().includes('NoRights') && <div style={titleStyle}>Welcome, new user. You have no permissions yet. Contact your system administrator to set you up with your permissions.</div>}
            <div style={cardsContainerStyle}>
                {getPermissions().includes(announcements_permission) &&
                    <div style={cardContainerStyle} onClick={() => window.location.href = announcementsDashboardPath}>
                        <Card
                            icon={faBullhorn}
                            title="Announcements"
                            description="Add, edit, and remove church announcements"
                        />
                    </div>
                }
                {getPermissions().includes(points_permission) &&
                    <div style={cardContainerStyle} onClick={() => window.location.href = pointsDashboardPath}>
                        <Card
                            icon={faChurch}
                            title="Service Points"
                            description="Manage church services and video items"
                        />
                    </div>
                }
                {getPermissions().includes(properties_permission) &&
                    <div style={cardContainerStyle} onClick={() => window.location.href = propertiesPath}>
                        <Card
                            icon={faGear}
                            title="Properties"
                            description="Handle live settings and web messages"
                        />
                    </div>
                }
                {getPermissions().includes(admin_permission) &&
                    <div style={cardContainerStyle} onClick={() => window.location.href = newUserPath}>
                        <Card
                            icon={faUserPlus}
                            title="New User"
                            description="Add a new user to our backend"
                        />
                    </div>
                }
                <div style={cardContainerStyle} onClick={Userfront.logout}>
                    <Card
                        icon={faArrowRightFromBracket}
                        title="Sign Out"
                        description="Sign out from Bethel Bible Church "
                    />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;

import React from 'react';
import { Paper, Typography, useTheme } from '@mui/material';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha } from '@mui/system';
import { darken } from '@mui/system';
import '../../theme/font.css';


const StyledPaper = styled(Paper)`
  display: flex;
  align-items: center;
  padding: 14px 18px;
  border-radius: 8px;
  width: 282px;  // Setting the width of the card
  height: 100px;
  cursor: pointer;
  &:hover {
    background-color: ${props => darken(props.theme.palette.background.paper, 0.05)};
  };
`;

const ContentColumn = styled.div`
  flex: 9;
  padding-left: 16px; // add some space between the image and the content
`;

const Card = ({ icon, title, description }) => {
    const theme = useTheme();

    const IconColumn = styled.div`
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => alpha(props.theme.palette.primary.main, 0.9)};    // 50% opacity
  border-radius: 50%;        // Makes it a circle
  width: 66px;               // width of the circle
  height: 66px;              // height of the circle
`;

    return (
        <StyledPaper>
            <IconColumn>
                <FontAwesomeIcon icon={icon} size="2x" color="white" />
            </IconColumn>
            <ContentColumn>
                <Typography variant="h6" style={{ fontFamily: 'Amazon Ember', color: theme.palette.primary.text, fontSize: '20px' }}>
                    {title}
                </Typography>
                <Typography variant="body2" style={{ color: theme.palette.primary.softText, fontSize: '14px' }}>
                    {description}
                </Typography>
            </ContentColumn>
        </StyledPaper>
    );
};

export default Card;

import React from 'react';
import RequireAuth from './requireAuth';
import Userfront from "@userfront/react";
import { Button, Grid, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { dashboardPath, newAnnouncementPath, pastServicesPath, serviceEditorPath } from '../../constants';
import BackButton from './backButton';
import Card from '../../components/dashboard/card';
import { faArrowRightFromBracket, faFileCirclePlus, faRectangleList, faRotateLeft, faSquarePlus } from '@fortawesome/free-solid-svg-icons';

const cardContainerStyle = {
    flex: '0 0 calc(33.33% - 10.66px)',  // We subtract 1/3 of the total gap to ensure 3 fit in a row.
};

const PointsDashboard = () => {
    const theme = useTheme();

    const mainStyle = {
        fontFamily: "Montserrat",
        display: 'flex',
        flexDirection: 'column',
        margin: '0',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'space-between', // Make the items spread out
        backgroundColor: theme.palette.secondary.main,
    };

    const cardsContainerStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '16px',
        maxWidth: 'calc(282px + 282px + 282px + 200px)'
    };

    const contentWrapperStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,  // This will make it take the available vertical space
        width: '100%',
    };

    const navigate = useNavigate();

    const handleNewService = () => {
        navigate(serviceEditorPath, { state: { service_key: '-1' } });
    };

    return (
        <div style={mainStyle}>
            <RequireAuth />

            <BackButton backToPath={dashboardPath} />

            <div style={contentWrapperStyle}>
                <div style={cardsContainerStyle}>

                    <div style={cardContainerStyle} onClick={handleNewService}>
                        <Card
                            icon={faSquarePlus}
                            title="New Service"
                            description="Create a new service and add video data" />
                    </div>

                    <div style={cardContainerStyle} onClick={() => window.location.href = pastServicesPath}>
                        <Card
                            icon={faRectangleList}
                            title="Past Service"
                            description="View past services, edit and delete services" />
                    </div>

                    <div style={cardContainerStyle} onClick={() => window.location.href = dashboardPath}>
                        <Card
                            icon={faRotateLeft}
                            title="Main Menu"
                            description="Go back to the main admin dashboard" />
                    </div>

                    <div style={cardContainerStyle} onClick={Userfront.logout}>
                        <Card
                            icon={faArrowRightFromBracket}
                            title="Sign Out"
                            description="Sign out from Bethel Bible Church "
                        />
                    </div>

                </div>
            </div>
        </div>
    );
};

export default PointsDashboard;
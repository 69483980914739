import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@material-ui/core';
import { Button } from '@mui/material';
import DatePicker from '../../../../components/DatePicker';
import dayjs from 'dayjs';
import { addNewMessage, deleteMessage, getAllMessages, updateMessage } from '../../../../helpers/api/api';
import { formatDateString } from '../../../../helpers/dataBuilders';
import { LIGHT_BLUE } from '../../../../theme/colors';
import AddMessage from './addMessage';

const headerTitleStyle = {
  color: 'white',
  fontWeight: '500'
};

const MessagesTable = () => {
  const [addMessageDialog, setOpenMessageDialog] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const [data, setData] = useState([]);
  const [editCell, setEditCell] = useState(null); 

  const openAddMessage = () => {
    setOpenMessageDialog(true);
  };

  const addMessage = async (messageEn, messageRo, expirationDate, severity, pages) => {
    setLoadingSave(true);
    const newMessageId = await addNewMessage(messageEn, messageRo, severity, expirationDate, pages);

    const newData = [...data, {
      id: newMessageId,
      datePosted: formatDateString(),
      messageEn: messageEn,
      messageRo: messageRo,
      expirationDate: formatDateString(expirationDate),
      severity: severity,
      pages: pages
    }];

    setData(newData);
    setOpenMessageDialog(false);
    setLoadingSave(false);
  };

  const deleteMessageRow = (id) => {
    deleteMessage(id);
    const newData = data.filter((d) => d.id !== id);
    setData(newData);
  };

  const handleCloseMessage = () => {
    setOpenMessageDialog(false);
    setEditCell(null);
  };

  const handleCellClick = (id, field) => {
    if (editCell?.id !== id || editCell?.field !== field) {
      setEditCell({ id, field });
    }
  };

  const handleCellChange = (event, id, field) => {
    const newData = data.map(row => {
      if (row.id === id) {
        if (field === "messageEn" || field === "messageRo") {
          // Only update the database and state when the "Enter" key is pressed
          if (event.key === 'Enter') {
            updateDatabaseRow(row);
            return row;
          }
          else {
            return { ...row, [field]: event.target.value }
          }
        }
        else {
          updateDatabaseRow({ ...row, [field]: event.target.value });
          return { ...row, [field]: event.target.value };
        }
        
      }
      return row;
    });
    setData(newData);
  };

  const handleDateChange = (date, id) => {
    const newData = data.map(row => {
      if (row.id === id) {
        const updatedRow = {
          ...row,
          expirationDate: formatDateString(date),
        };
        updateDatabaseRow(updatedRow);
        return updatedRow;
      }
      return row;
    });
    setData(newData);
  };

  const handleDateClose = () => {
    setEditCell(null);
  };

  const handlePagesChange = (selectedOptions, id, field) => {
    const newData = data.map(row => {
      if (row.id === id) {
        updateDatabaseRow({
          ...row,
          [field]: selectedOptions.join(', '),
        });
        return {
          ...row,
          [field]: selectedOptions.join(', '),
        };
      }
      return row;
    });
    setData(newData);
  };

  const updateDatabaseRow = (row) => {
    const { id, messageEn, messageRo, severity, pages, expirationDate } = row;
    updateMessage(id, messageEn, messageRo, severity, expirationDate, pages);
  };

  /**
   * This use effect will get the initial data from the database
   */
  useEffect(() => {
    async function getAllUrgentMessages() {
      const allMessages = await getAllMessages();
      setData(allMessages);
    }

    getAllUrgentMessages();
  }, []); 

  return (
    <div style={{ maxWidth: '100%' }}>
      <TableContainer component={Paper} style={{ width: '100%' }}>
        <Table aria-label="sample table" style={{ width: '100%' }}>
          <TableHead style={{ backgroundColor: LIGHT_BLUE }}>
            <TableRow>
              <TableCell style={headerTitleStyle}>Date Posted</TableCell>
              <TableCell style={headerTitleStyle}>Message (English)</TableCell>
              <TableCell style={headerTitleStyle}>Message (Romanian)</TableCell>
              <TableCell style={headerTitleStyle}>Expiration Date</TableCell>
              <TableCell style={headerTitleStyle}>Severity</TableCell>
              <TableCell style={headerTitleStyle}>Affected Pages</TableCell>
              <TableCell style={headerTitleStyle}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    {row.datePosted}
                  </TableCell>
                  <TableCell
                    onClick={() => handleCellClick(row.id, 'messageEn')}
                  >
                    {editCell?.id === row.id && editCell?.field === 'messageEn' ? (
                      <TextField
                        value={row.messageEn}
                        onChange={(event) =>
                          handleCellChange(event, row.id, 'messageEn')
                        }
                        onBlur={() => setEditCell(null)}
                        onKeyDown={(event) => {
                          if (event.key === 'Escape' ) {
                            setEditCell(null);
                          } else if (event.key === 'Enter') {
                            setEditCell(null);
                            handleCellChange(event, row.id, 'messageEn');
                          }
                        }}
                      />
                    ) : (
                      row.messageEn
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() => handleCellClick(row.id, 'messageRo')}
                  >
                    {editCell?.id === row.id && editCell?.field === 'messageRo' ? (
                      <TextField
                        value={row.messageRo}
                        onChange={(event) =>
                          handleCellChange(event, row.id, 'messageRo')
                        }
                        onBlur={() => setEditCell(null)}
                        onKeyDown={(event) => {
                          if (event.key === 'Escape') {
                            setEditCell(null);
                          } else if (event.key === 'Enter') {
                            handleCellChange(event, row.id, 'messageRo');
                            setEditCell(null);
                          }
                        }}
                      />
                    ) : (
                      row.messageRo
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() => handleCellClick(row.id, 'expirationDate')}
                  >
                    {editCell?.id === row.id &&
                      editCell?.field === 'expirationDate' ? (
                      <DatePicker label='Expiration Date' onClose={handleDateClose} date={dayjs(row.expirationDate)} handleDateChange={(date) => handleDateChange(date, row.id)} minDate={dayjs()} />
                    ) : (
                      row.expirationDate
                    )}
                  </TableCell>
                  <TableCell onClick={() => handleCellClick(row.id, 'severity')}>
                    {editCell?.id === row.id && editCell?.field === 'severity' ? (
                      <select id="select" value={row.severity}
                        onChange={(event) => {
                          handleCellChange(event, row.id, 'severity');
                          setEditCell(null);
                        }
                        }>
                        <option value="Low">Low</option>
                        <option value="Medium">Medium</option>
                        <option value="High">High</option>
                      </select>
                    ) : (
                      row.severity
                    )}
                  </TableCell>
                  <TableCell onClick={() => handleCellClick(row.id, 'pages')}>
                    {editCell?.id === row.id && editCell?.field === 'pages' ? (
                      <select
                        id="select"
                        value={row.pages.split(', ')}
                        multiple
                        onChange={(event) => {
                          const selectedOptions = Array.from(
                            event.target.selectedOptions,
                            (option) => option.value
                          );
                          handlePagesChange(selectedOptions, row.id, 'pages');
                        }}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter' || event.key === 'Escape') {
                            setEditCell(null);
                          }
                        }}
                      >
                        <option value="Home">Home</option>
                        <option value="Services">Services</option>
                        <option value="About">About</option>
                        <option value="Donations">Donations</option>
                        <option value="Contact">Contact</option>
                        <option value="Upcoming Events">Upcoming Events</option>
                        <option value="Announcements">Announcements</option>
                      </select>
                    ) : (
                      row.pages
                    )}
                  </TableCell>

                  <TableCell>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() => {
                        deleteMessageRow(row.id);
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            <TableRow>
              <TableCell colSpan={6}>
                <div style={{ display: 'flex', justifyContent: 'center', padding: '0', margin: '0', marginBottom: '44px' }}>
                  <Button variant="outlined" onClick={openAddMessage} >Add Message</Button>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <AddMessage handleClose={handleCloseMessage} isOpen={addMessageDialog} addMessage={addMessage} loadingSave={loadingSave} />
    </div>
  );
};

export default MessagesTable;


import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@mui/material';

const ServiceLoading = () => {
    return (
        <Grid container direction="row" spacing={1}>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'bottom', justifyContent: 'center' }}>
                <Skeleton height={60} width={300} />
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Skeleton height={60} width={100} />
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '0' }}>
                <Skeleton height={60} width={100} />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: '0', paddingBottom: '0', marginLeft: '15px' }}>
                <Skeleton width={200} style={{ padding: '0', margin: '0' }} />
                <Skeleton width={350} height={100} style={{ padding: '0', margin: '0', paddingTop: 0 }} />
                <Skeleton width={350} height={100} style={{ padding: '0', margin: '0', paddingTop: 0 }} />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: '0', paddingBottom: '0', marginLeft: '15px' }}>
                <Skeleton width={200} style={{ padding: '0', margin: '0' }} />
                <Skeleton width={350} height={100} style={{ padding: '0', margin: '0', paddingTop: 0 }} />
                <Skeleton width={350} height={100} style={{ padding: '0', margin: '0', paddingTop: 0 }} />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: '0', paddingBottom: '0', marginLeft: '15px' }}>
                <Skeleton width={200} style={{ padding: '0', margin: '0' }} />
                <Skeleton width={350} height={100} style={{ padding: '0', margin: '0', paddingTop: 0 }} />
                <Skeleton width={350} height={100} style={{ padding: '0', margin: '0', paddingTop: 0 }} />
            </Grid>


        </Grid>
    );
};

export default ServiceLoading;

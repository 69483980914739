import "./serviceTimes.css";
import {
  fridayServiceTimeString,
  LightBlue,
  serviceTimesString,
  sundayEveningServiceTimeString,
  sundayMorningServiceTimeString,
  thursdayServiceTimeString,
  tuesdayServiceTimeString,
} from "../../../../constants";
import { useAppSelector } from "../../../../app/hooks";
import { selectLanguage } from "../../../../app/slices/languageSlice";

const ServiceTimes = () => {
  const language = useAppSelector(selectLanguage);
  return (
    <div className="serviceTimes">
      <h1 style={{ color: LightBlue, fontWeight: "600" }}>
        {serviceTimesString(language)}
      </h1>
      <ul>
        <li className="one">{sundayMorningServiceTimeString(language)}</li>
        <li className="two">{sundayEveningServiceTimeString(language)}</li>
        <li className="three">{tuesdayServiceTimeString(language)}</li>
        <li className="four">{thursdayServiceTimeString(language)}</li>
        <li className="five">{fridayServiceTimeString(language)}</li>
        <li className="serviceTime one">10:00 AM</li>
        <li className="serviceTime two">6:00 PM</li>
        <li className="serviceTime three">7:00 PM</li>
        <li className="serviceTime four">7:00 PM</li>
        <li className="serviceTime five">7:30 PM</li>
      </ul>
    </div>
  );
};

export default ServiceTimes;

import { Box, Button, Grid, TextField } from '@mui/material';
import React from 'react';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LightBlue } from '../../../constants';

const AnnouncementForm = (props) => {
    const {announcement, handleTitleEnChange, handleTitleRoChange, handleAnnouncementEnChange, handleAnnouncementRoChange, handleSubmit, handleDateChange} = props;

    return (
        <form style={{height: '100%'}} onSubmit={handleSubmit}>
                    
            <Grid container spacing={2} alignContent={'center'} justifyContent={'center'} sx={{flex: '1', paddingLeft: '10px', paddingRight: '10px'}} height="100%">

                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', color: LightBlue}}>
                    <h1>New Announcement</h1>
                </Grid>
                <Grid item xs={6}>
                    <TextField label="Title (English)" fullWidth value={announcement.titleEn} onChange={handleTitleEnChange} />
                </Grid>
                <Grid item xs={6}>
                    <TextField label="Title (Romanian)" fullWidth value={announcement.titleRo} onChange={handleTitleRoChange} />
                </Grid> 
                <Grid item xs={6}>
                    <TextField label="Announcement (English)" fullWidth multiline rows={6} value={announcement.announcementEn} onChange={handleAnnouncementEnChange} />
                </Grid>
                <Grid item xs={6}>
                    <TextField label="Announcement (Romanian)" fullWidth multiline rows={6} value={announcement.announcementRo} onChange={handleAnnouncementRoChange} />
                </Grid>

                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                            label="Announcement Expiration Date"
                            inputFormat="MM/DD/YYYY"
                            value={announcement.expiration_date}
                            onChange={handleDateChange}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                    <Box textAlign='center' style={{paddingTop: '.5rem'}}>
                        <Button type="submit" variant='contained'>Submit</Button>
                    </Box>
                </Grid>

            </Grid>
        </form>
    );
};

export default AnnouncementForm;
import React from 'react';
import LanguageSelector from '../languageSelector';
import PersonnelSelector from '../personnelSelector';
import SongTitleSelector from '../songTitleSelector';

const Solo = (props) => {
    const { serviceData, handleLanguageChange, handleSongTitleEnChange, handleSongTitleRoChange, handleAddPersonnel, addPersonDialogOpen, addPeopleOpen, handlePersonChange, people, handlePositionChange, saveNewPerson, newPerson, newPosition, handleAddPerson, handleAddPeopleClose, removePerson, addPersonToPeople, personPanelIsOpen, handleAddPersonClose } = props;

    return (
        <>
            <LanguageSelector serviceData={serviceData} handleLanguageChange={handleLanguageChange}/>
            {serviceData.language && <SongTitleSelector serviceData={serviceData} handleSongTitleEnChange={handleSongTitleEnChange} handleSongTitleRoChange={handleSongTitleRoChange}/>}
            {(serviceData.song_title_en || serviceData.song_title_ro)&& <PersonnelSelector serviceData={serviceData} addPersonToPeople={addPersonToPeople} personPanelIsOpen={personPanelIsOpen} handleAddPersonClose={handleAddPersonClose} handleAddPersonnel={handleAddPersonnel} addPersonDialogOpen={addPersonDialogOpen} addPeopleOpen={addPeopleOpen} handlePersonChange={handlePersonChange} people={people} handlePositionChange={handlePositionChange} saveNewPerson={saveNewPerson} parentProps={props} newPerson={newPerson} newPosition={newPosition} handleAddPerson={handleAddPerson} handleAddPeopleClose={handleAddPeopleClose} removePerson={removePerson}/>}
        </>
    );
};

export default Solo;
import {
  DarkGray,
  EN,
  generalEnglishToRomanianMap,
  LightBlue,
  LightDarkGray,
  RO,
  sermonType,
} from "../../../constants";
import unidecode from "unidecode";
import { useAppSelector } from "../../../app/hooks";
import { selectLanguage } from "../../../app/slices/languageSlice";

/**
 * This functions takes the searched for text and highlights it in the display when found in results
 * @param {*} text
 * @returns
 */
export const getHighlightedText = (text, searchFor) => {
  const unaccentedText = unidecode(text.toLowerCase());
  const unaccentedSearchFor = unidecode(searchFor.toLowerCase());
  const splitText = unaccentedText.split(unaccentedSearchFor);

  let startIndex = 0;
  return splitText.reduce((acc, part, index) => {
    const substring = text.substring(startIndex, startIndex + part.length);
    startIndex += part.length + searchFor.length;
    return index !== splitText.length - 1
      ? [
          ...acc,
          substring,
          <span style={{ fontWeight: "600", color: LightBlue }} key={index}>
            {text.substring(startIndex - searchFor.length, startIndex)}
          </span>,
        ]
      : [...acc, substring];
  }, []);
};

/**
 * COMPONENENTS
 */
export const MainSearchTitle = ({ result, searchFor, isHovered }) => {
  const language = useAppSelector(selectLanguage);
  const title = language === EN ? result.entityTitleEn : result.entityTitleRo;
  const alternateTitle =
    language === EN ? result.entityTitleRo : result.entityTitleEn;

  return (
    <div>
      {title && (
        <div
          style={{
            fontWeight: "500",
            fontSize: "20px",
            color: `${isHovered ? "white" : DarkGray}`,
          }}
        >
          {getHighlightedText(title, searchFor)}
        </div>
      )}
      {alternateTitle &&
        alternateTitle.toLowerCase().includes(searchFor.toLowerCase()) && (
          <div
            style={{
              fontWeight: "400",
              fontSize: "12px",
              color: `${isHovered ? "white" : LightDarkGray}`,
            }}
          >
            {getHighlightedText(alternateTitle, searchFor)}
          </div>
        )}
    </div>
  );
};

export const SecondarySearchTitle = (props) => {
  const { result, searchFor, isHovered } = props;
  const language = useAppSelector(selectLanguage);

  return (
    <div>
      {language === EN && (
        <div>
          {result.songTitleEn && (
            <div
              style={{
                fontWeight: "500",
                fontSize: "17px",
                color: `${isHovered ? "white" : DarkGray}`,
              }}
            >
              "{getHighlightedText(result.songTitleEn, searchFor)}"
            </div>
          )}
          {result.songTitleRo &&
            result.songTitleEn !== result.songTitleRo &&
            result.songTitleRo
              .toLowerCase()
              .includes(searchFor.toLowerCase()) && (
              <div
                style={{
                  fontWeight: "400",
                  fontSize: "12px",
                  color: `${isHovered ? "white" : LightDarkGray}`,
                }}
              >
                "{getHighlightedText(result.songTitleRo, searchFor)}"
              </div>
            )}
          {result.type === sermonType && (
            <div
              style={{
                fontWeight: "500",
                fontSize: "17px",
                color: `${isHovered ? "white" : DarkGray}`,
              }}
            >
              {result.positionEn}:{" "}
              {getHighlightedText(result.preacher, searchFor)}
            </div>
          )}
          {result.type === sermonType && result.passageEn && (
            <div
              style={{
                fontWeight: "400",
                fontSize: "12px",
                color: `${isHovered ? "white" : LightDarkGray}`,
              }}
            >
              {getHighlightedText(result.passageEn, searchFor)}
            </div>
          )}
          {result.type === sermonType &&
            result.passageRo &&
            result.passageRo
              .toLowerCase()
              .includes(searchFor.toLowerCase()) && (
              <div
                style={{
                  fontWeight: "400",
                  fontSize: "12px",
                  color: `${isHovered ? "white" : LightDarkGray}`,
                }}
              >
                {getHighlightedText(result.passageRo, searchFor)}
              </div>
            )}
          {result.otherPerson && (
            <div
              style={{
                fontWeight: "500",
                fontSize: "15px",
                color: `${isHovered ? "white" : DarkGray}`,
              }}
            >
              {result.positionEn && <span>{result.positionEn}: </span>}
              {getHighlightedText(result.otherPerson, searchFor)}
            </div>
          )}
          <div
            style={{
              fontWeight: "500",
              fontSize: "15px",
              color: `${isHovered ? "white" : DarkGray}`,
            }}
          >
            {result.otherTypeEn && (
              <span>
                {result.otherTypeEn}
                {result.otherTypeEn && result.otherTitleEn && <span>: </span>}
              </span>
            )}
            {result.otherTitleEn && (
              <span>{getHighlightedText(result.otherTitleEn, searchFor)}</span>
            )}
          </div>
        </div>
      )}

      {language === RO && (
        <div>
          {result.songTitleRo && (
            <div
              style={{
                fontWeight: "500",
                fontSize: "17px",
                color: `${isHovered ? "white" : DarkGray}`,
              }}
            >
              "{getHighlightedText(result.songTitleRo, searchFor)}"
            </div>
          )}
          {result.songTitleEn &&
            result.songTitleRo !== result.songTitleEn &&
            result.songTitleEn
              .toLowerCase()
              .includes(searchFor.toLowerCase()) && (
              <div
                style={{
                  fontWeight: "400",
                  fontSize: "12px",
                  color: `${isHovered ? "white" : LightDarkGray}`,
                }}
              >
                "{getHighlightedText(result.songTitleEn, searchFor)}"
              </div>
            )}
          {result.type === sermonType && (
            <div
              style={{
                fontWeight: "500",
                fontSize: "17px",
                color: `${isHovered ? "white" : DarkGray}`,
              }}
            >
              {result.positionRo}:{" "}
              {getHighlightedText(result.preacher, searchFor)}
            </div>
          )}
          {result.type === sermonType && result.passageRo && (
            <div
              style={{
                fontWeight: "400",
                fontSize: "12px",
                color: `${isHovered ? "white" : LightDarkGray}`,
              }}
            >
              {getHighlightedText(result.passageRo, searchFor)}
            </div>
          )}
          {result.type === sermonType &&
            result.passageEn &&
            result.passageEn
              .toLowerCase()
              .includes(searchFor.toLowerCase()) && (
              <div
                style={{
                  fontWeight: "400",
                  fontSize: "12px",
                  color: `${isHovered ? "white" : LightDarkGray}`,
                }}
              >
                {getHighlightedText(result.passageEn, searchFor)}
              </div>
            )}
          {result.otherPerson && (
            <div
              style={{
                fontWeight: "500",
                fontSize: "15px",
                color: `${isHovered ? "white" : DarkGray}`,
              }}
            >
              {result.positionRo && <span>{result.positionRo}: </span>}
              {getHighlightedText(result.otherPerson, searchFor)}
            </div>
          )}
          <div
            style={{
              fontWeight: "500",
              fontSize: "15px",
              color: `${isHovered ? "white" : DarkGray}`,
            }}
          >
            {result.otherTypeRo && (
              <span>
                {result.otherTypeRo}
                {result.otherTypeRo && result.otherTitleRo && <span>: </span>}
              </span>
            )}
            {result.otherTitleRo && (
              <span>{getHighlightedText(result.otherTitleRo, searchFor)}</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const NameSearchTitle = (props) => {
  const { result, searchFor, isHovered } = props;
  const language = useAppSelector(selectLanguage);

  return (
    <div>
      {language === EN && (
        <div>
          {result.searchedPerson &&
            result.searchedPerson.toLowerCase().includes(searchFor) && (
              <div
                style={{
                  fontWeight: "400",
                  fontSize: "13px",
                  color: `${isHovered ? "white" : DarkGray}`,
                }}
              >
                {getHighlightedText(result.searchedPerson, searchFor)}
                {result.positionEn && <span> - {result.positionEn}</span>}
              </div>
            )}
        </div>
      )}

      {language === RO && (
        <div>
          {result.searchedPerson &&
            result.searchedPerson.toLowerCase().includes(searchFor) && (
              <div
                style={{
                  fontWeight: "400",
                  fontSize: "13px",
                  color: `${isHovered ? "white" : DarkGray}`,
                }}
              >
                {getHighlightedText(result.searchedPerson, searchFor)}
                {result.positionRo && <span> - {result.positionRo}</span>}
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export const LanguageTitle = (props) => {
  const { resultLanguage, isHovered } = props;
  const language = useAppSelector(selectLanguage);

  return (
    <div>
      {language === EN && (
        <div
          style={{
            fontWeight: "300",
            fontSize: "10px",
            color: `${isHovered ? "white" : LightDarkGray}`,
          }}
        >
          {resultLanguage && resultLanguage}
        </div>
      )}
      {language === RO && (
        <div
          style={{
            fontWeight: "300",
            fontSize: "10px",
            color: `${isHovered ? "white" : LightDarkGray}`,
          }}
        >
          {resultLanguage && generalEnglishToRomanianMap[resultLanguage]}
        </div>
      )}
    </div>
  );
};

import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  useTheme,
} from "@mui/material";
import { useEffect, useState, useRef } from "react";
import {
  AlertExcessResults,
  AlertInvalidSearchCharacter,
  AlertInvalidSearchLength,
} from "../../components/snackbarWarnings";
import {
  goString,
  LightBlue,
  searchMaxValue,
  searchString,
} from "../../constants";
import { search_endpoint } from "../../helpers/api/apiConstants";
import SearchResults from "../../pages/services/search/searchResults";
import { getUrl } from "../../helpers/api/api";
import SearchIcon from "@mui/icons-material/Search";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../app/slices/languageSlice";

const mainStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
};

function isValid(str) {
  return !/[~`@!#$%^&*+=[]';,{}|":<>\?]/g.test(str);
}

const SearchPanel = ({ handlePointTimestamp, handleAddResultToResults }) => {
  const language = useAppSelector(selectLanguage);
  const inputRef = useRef(null);
  const [searchFor, setSearchFor] = useState();
  const [searchedValue, setSearchedValue] = useState("");
  const [searchTextError, setSearchTextError] = useState(false);
  const [searchCharacterError, setSearchCharacterError] = useState(false);
  const [results, setResults] = useState();
  const [excessResults, setExcessResults] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const theme = useTheme();

  const serviceHeaderStyle = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    fontWeight: "400",
    color: LightBlue,
    padding: "0",
    fontSize: "min(6vw, 35px)",
    fontFamily: theme.typography.secondaryFontFamily,
  };

  // Handles the search box value change
  const handleSearchTextChange = (event) => {
    if (event.target.value.length > searchMaxValue) {
      setSearchTextError(true);
      return;
    }
    if (!isValid(event.target.value)) {
      setSearchCharacterError(true);
      return;
    }
    setSearchTextError(false);
    setSearchCharacterError(false);
    setSearchFor(event.target.value);
  };

  const handleSnackbarClose = () => {
    setSearchTextError(false);
    setSearchCharacterError(false);
    setExcessResults(false);
  };

  const handleSearch = () => {
    inputRef.current.blur();
    setSearchedValue(searchFor);
  };

  /**
   * This use effect updates when the results are gotten, and checks if the results count is more than 1000
   */
  useEffect(() => {
    if (results && results.length > 1000) {
      setExcessResults(true);
    }
  }, [results]);

  // This use effect will run whenever the user presses "go"
  useEffect(() => {
    if (searchedValue) {
      async function getResults() {
        setIsSearching(true);
        const searchResults = await fetch(
          getUrl(`${search_endpoint}?searchValue=${searchedValue}`)
        );
        const finalResponse = await searchResults.json();
        setResults(finalResponse);
        setIsSearching(false);
      }

      getResults();
    }
  }, [searchedValue]);

  return (
    <div style={mainStyle}>
      <div style={serviceHeaderStyle}>{searchString(language)}</div>
      <TextField
        value={searchFor}
        onChange={handleSearchTextChange}
        label={searchString(language)}
        inputRef={inputRef}
        sx={{ width: "90%" }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleSearch();
          }
        }}
        inputMode="search"
        type="search"
        InputProps={{
          type: "search",
          inputProps: {
            inputMode: "search",
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="Search" onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="outlined"
        sx={{ marginTop: "5px" }}
        onClick={handleSearch}
      >
        {goString(language)}
      </Button>

      {results && !isSearching && (
        <SearchResults
          results={results}
          searchFor={searchedValue}
          handlePointTimestamp={handlePointTimestamp}
          handleAddResultToResults={handleAddResultToResults}
        />
      )}
      {isSearching && <CircularProgress />}

      <AlertInvalidSearchLength
        open={searchTextError}
        handleSnackbarClose={handleSnackbarClose}
      />
      <AlertInvalidSearchCharacter
        open={searchCharacterError}
        handleSnackbarClose={handleSnackbarClose}
      />
      <AlertExcessResults
        open={excessResults}
        handleSnackbarClose={handleSnackbarClose}
      />
    </div>
  );
};

export default SearchPanel;

import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';

const DatePicker = (props) => {
    const { minDate, label, date, handleDateChange, onClose } = props;

    return (
        <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                    label={label ? label : 'Date'}
                    inputFormat="MM/DD/YYYY"
                    value={date ? date : dayjs()}
                    onChange={handleDateChange ? handleDateChange : () => {}}
                    renderInput={(params) => <TextField size="small" {...params} />}
                    minDate={minDate ? minDate : new Date('2000-01-01')}
                    onClose={onClose ? onClose : () => {}}
                />
            </LocalizationProvider>
        </div>
    );
};

export default DatePicker;
import React, { useEffect, useState } from 'react';
import RequireAuth from '../requireAuth';
import BackButton from '../backButton';
import { announcementsDashboardPath, LightBlue, LightDarkGray } from '../../../constants';
import { Divider, Grid } from '@mui/material';
import { fetchAnnouncements } from '../../../helpers/api/api';
import Announcement from './announcement';
import { deleteSingleAnnouncement, updateSingleAnnouncement } from '../../../helpers/backend';

const mainStyle = {
    backgroundColor: 'white',
    fontFamily: "Montserrat",
    display: 'flex',
    flexDirection: 'column',
    margin: '0',
    width: '100%',
    height: '100%',
  };

  const gridItemStyle = {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginLeft: '15px',
    marginRight: '15px'
  };

const PastAnnouncements = () => {
    const [announcements, setAnnouncements] = useState();

    /**
     * This use effect is to handle getting new announcements
     */
    useEffect(() => {
        if (!announcements) {
            async function getAnnouncements () {
                const queriedAnnouncements = await fetchAnnouncements();
                setAnnouncements(queriedAnnouncements);
            };
            getAnnouncements();
        }
    }, [announcements]);

    const deleteAnnouncement = async (index) => {
        await deleteSingleAnnouncement(index);
        async function getAnnouncements () {
            const queriedAnnouncements = await fetchAnnouncements();
            setAnnouncements(queriedAnnouncements);
        };
        getAnnouncements();
    };

    const updateAnnouncement = async (newAnnouncement) => {
        await updateSingleAnnouncement(newAnnouncement);
        async function getAnnouncements () {
            const queriedAnnouncements = await fetchAnnouncements();
            setAnnouncements(queriedAnnouncements);
        };
        getAnnouncements();
    };

    return (
        <div style={mainStyle}>
            <RequireAuth />
            
            <BackButton backToPath={announcementsDashboardPath}/>

            <Grid container spacing={2} justifyContent='center' sx={{ marginTop: '10px', backgroundColor: 'white', overflow: 'scroll', paddingBottom: '10vh' }}>
                <Grid item xs={12} sx={gridItemStyle}>
                    <h1 style={{color: LightBlue}}>Past Announcements</h1>
                </Grid>
                {announcements && announcements.map(announcement => 
                <Grid item xs={12} sx={gridItemStyle} key={announcement.ANNOUNCEMENT_KEY}>
                    <Divider style={{ flex: '1' }}/>
                    <Announcement announcement={announcement} index={announcement.ANNOUNCEMENT_KEY} deleteAnnouncement={deleteAnnouncement} updateAnnouncement={updateAnnouncement}/>
                </Grid>)}
                {(announcements && announcements.length < 1) && <h3 style={{color: LightDarkGray}}>No announcements found.</h3>}
            </Grid>

        </div>
    );
};

export default PastAnnouncements;
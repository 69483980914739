import React from 'react';
import { otherChoirType } from '../../../../../../../constants';
import ChoirTypeSelector from '../choirTypeSelector';
import GroupTitleSelector from '../groupTitleSelector';
import LanguageSelector from '../languageSelector';
import PersonnelSelector from '../personnelSelector';
import SongTitleSelector from '../songTitleSelector';

//personPanelIsOpen, handleAddPersonClose

const Choir = (props) => {
    const { serviceData, handleSongSubEntityTypeChange, handleLanguageChange, personPanelIsOpen, handleAddPersonClose, handleGroupTitleEnChange, handleGroupTitleRoChange, handleSongTitleEnChange, handleSongTitleRoChange, handleAddPersonnel, addPersonDialogOpen, addPeopleOpen, handlePersonChange, people, handlePositionChange, saveNewPerson, newPerson, newPosition, handleAddPerson, handleAddPeopleClose, removePerson, addPersonToPeople } = props;

    return (
        <>
            <ChoirTypeSelector serviceData={serviceData} handleSongSubEntityTypeChange={handleSongSubEntityTypeChange} />
            {serviceData.song_sub_entity === otherChoirType && <GroupTitleSelector serviceData={serviceData} handleGroupTitleEnChange={handleGroupTitleEnChange} handleGroupTitleRoChange={handleGroupTitleRoChange}/>}
            {serviceData.song_sub_entity && <LanguageSelector serviceData={serviceData} handleLanguageChange={handleLanguageChange}/>}
            {serviceData.language && <SongTitleSelector serviceData={serviceData} handleSongTitleEnChange={handleSongTitleEnChange} handleSongTitleRoChange={handleSongTitleRoChange}/>}
            {(serviceData.song_title_en || serviceData.song_title_ro)&& <PersonnelSelector serviceData={serviceData} personPanelIsOpen={personPanelIsOpen} handleAddPersonClose={handleAddPersonClose} handleAddPersonnel={handleAddPersonnel} addPersonDialogOpen={addPersonDialogOpen} addPeopleOpen={addPeopleOpen} handlePersonChange={handlePersonChange} people={people} handlePositionChange={handlePositionChange} saveNewPerson={saveNewPerson} parentProps={props} newPerson={newPerson} newPosition={newPosition} handleAddPerson={handleAddPerson} handleAddPeopleClose={handleAddPeopleClose} addPersonToPeople={addPersonToPeople} removePerson={removePerson}/>}
        </>
    );
};

export default Choir;
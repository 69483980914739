import { useState } from "react";
import {
  babyDedicationTitleString,
  LightBlue,
  LightDarkGray,
  parentString,
} from "../../../constants";
import { getBabyName, getParentsDisplay } from "../../../helpers/points";
import { useAppSelector } from "../../../app/hooks";
import { selectLanguage } from "../../../app/slices/languageSlice";

const babyDedicationEntityStyle = {
  fontWeight: "500",
};

const nameStyle = {
  fontWeight: "400",
};

const hoverStyle = {
  color: LightBlue,
  cursor: "pointer",
  paddingBottom: "10px",
  fontSize: "14px",
};

const nameCaptionStyle = {
  fontSize: "13px",
  fontWeight: "400",
};

const nameTitleStyle = {
  fontSize: "13px",
  fontWeight: "300",
};

const mainStyle = {
  color: LightDarkGray,
  fontSize: "14px",
  paddingBottom: "10px",
};

const BabyDedicationPoint = ({ servicePoint, handlePointTimestamp }) => {
  const {
    BABY_FIRST_NAME,
    BABY_MIDDLE_NAME,
    FAMILY_NAME,
    FATHER_FIRST_NAME,
    MOTHER_FIRST_NAME,
  } = servicePoint;
  const language = useAppSelector(selectLanguage);
  const [babyDedicationStyle, setBabyDedicationStyle] = useState(mainStyle);

  const handleMouseEnter = () => {
    setBabyDedicationStyle(hoverStyle);
  };

  const handleMouseLeave = () => {
    setBabyDedicationStyle(mainStyle);
  };

  const handleClick = () => {
    handlePointTimestamp(servicePoint.START_TIMESTAMP_UNIX);
  };

  return (
    <div
      style={babyDedicationStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <div>
        <span style={babyDedicationEntityStyle}>
          {babyDedicationTitleString(language)}
        </span>
        <span style={nameStyle}>
          {getBabyName(BABY_FIRST_NAME, BABY_MIDDLE_NAME, FAMILY_NAME)}
        </span>
      </div>
      {FAMILY_NAME && (
        <div>
          <span style={nameCaptionStyle}>{parentString(language)}: </span>
          <span style={nameTitleStyle}>
            {getParentsDisplay(
              FAMILY_NAME,
              FATHER_FIRST_NAME,
              MOTHER_FIRST_NAME
            )}
          </span>
        </div>
      )}
    </div>
  );
};

export default BabyDedicationPoint;

import React, { useEffect, useState } from 'react';
import { Alert, Button, TextField, Grid, CircularProgress, MenuItem } from '@mui/material';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { DarkGray, LightGray, propertiesPath, youtubePath } from '../../../constants';
import { useNavigate } from 'react-router';
import { getUrl } from '../../../helpers/api/api';
import { liveStatus_endpoint, setLiveLink_endpoint, setLiveOn_endpoint, setliveStatus_endpoint } from '../../../helpers/api/apiConstants';
import VisuallyHiddenText from '../../../components/VisuallyHiddenText';
import RestreamStatus from './restreamStatus';
import RequireAuth from '../requireAuth';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NumberIcon from '@mui/icons-material/FormatListNumbered';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GoLive = () => {
    const mainStyle = {
        backgroundColor: 'white',
        fontFamily: "Montserrat",
        display: 'flex',
        flexDirection: 'column',
        margin: '0',
        width: '100%',
        height: '100%',
        alignItems: 'center'
    };

    const menuStyle = {
        alignItems: 'center',
        backgroundColor: LightGray,
        width: '100%',
        paddingTop: '10px',
        paddingBottom: '10px',
        color: DarkGray,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '56px'
    };

    const [liveStatus, setLiveStatus] = useState();
    const [live, setLive] = useState();
    const [liveOn, setLiveOn] = useState();
    const [liveLink, setLiveLink] = useState();
    const [iframeInput, setIframeInput] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const handleBackButton = () => {
        navigate(propertiesPath);
    };

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleLiveChange = () => {
        setLive(live ? false : true);
    };

    const handleLiveOnChange = (event) => {
        setLiveOn(event.target.value);
    };

    const handleLiveLinkChange = (event) => {
        setLiveLink(event.target.value);
    };

    const handleIframeInputChange = (event) => {
        setIframeInput(event.target.value);
    };

    const handleIframeToLink = () => {
        const srcMatch = iframeInput.match(/src="([^"]*)"/i);
        if (srcMatch && srcMatch[1]) {
            setLiveLink(srcMatch[1]);
        }
    };

    useEffect(() => {
        if (!liveStatus) {
            fetch(getUrl(liveStatus_endpoint))
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw response;
                })
                .then(data => {
                    setLiveStatus(true);
                    setLive(data.IS_LIVE);
                    setLiveOn(data.LIVE_ON);
                    setLiveLink(data.LINK);
                })
                .catch(error => {
                    console.error("Error setting live status. Error: ", error);
                });
        }
    }, [liveStatus]);

    useEffect(() => {
        async function setLiveStatusDb() {
            const liveJson = {
                live_status: live
            };
    
            await fetch(getUrl(setliveStatus_endpoint), {
                method: 'POST',
                body: JSON.stringify(liveJson),
                headers: { 'Content-Type': 'application/json' }
            }).then(res => res.json())
                .then(json => console.log('Live Status Update: ', json));
        }
        
        if (live !== undefined) {  // Only call setLiveStatusDb if live is defined
            setLiveStatusDb();
        }
    }, [live]);

    useEffect(() => {
        async function setLiveOnDb() {
            const liveOnJson = {
                live_on: liveOn
            };

            await fetch(getUrl(setLiveOn_endpoint), {
                method: 'POST',
                body: JSON.stringify(liveOnJson),
                headers: { 'Content-Type': 'application/json' }
            }).then(res => res.json())
                .then(json => console.log('Live On Update: ', json));
        }
        setLiveOnDb();
    }, [liveOn]);

    const handleLiveLinkSave = async () => {
        const liveLinkJson = {
            live_link: liveLink
        };

        await fetch(getUrl(setLiveLink_endpoint), {
            method: 'POST',
            body: JSON.stringify(liveLinkJson),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => res.json())
            .then(json => console.log('Live Link Update: ', json));
    };

    return (
        <div style={mainStyle}>
            <RequireAuth />

            <div style={menuStyle}>
                <Button variant="outlined" sx={{ marginLeft: '10px' }} startIcon={<ArrowBackIosIcon />} onClick={handleBackButton}>
                    Back
                </Button>
            </div>

            <Grid container spacing={2} alignContent={'center'} alignItems={'center'} justifyContent={'center'} sx={{ flex: '1', alignItems: 'center', justifyContent: 'center' }}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    {!liveStatus && <Button startIcon={<CircularProgress />}>Getting Live Status...</Button>}
                    {liveStatus && <Button startIcon={<LiveTvIcon />} variant="contained" size="large" onClick={handleLiveChange} sx={{ marginTop: '10px' }}>{live ? 'STOP GOING LIVE' : 'GO LIVE NOW'}</Button>}
                </Grid>

                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <a href={youtubePath} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faYoutube} aria-hidden="true" size="xs"/>
                <VisuallyHiddenText>YouTube</VisuallyHiddenText>
            </a>
                </Grid>

                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', paddingTop: '0', marginTop: '0' }}>
                    <Alert severity='info'>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6" component="h6">
                                Follow these steps to get the embedded YouTube link:
                            </Typography>
                            <IconButton onClick={handleToggle}>
                                <ExpandMoreIcon />
                            </IconButton>
                        </div>
                        <Collapse in={isOpen}>
                            <List>
                                <ListItem>
                                    <ListItemIcon><NumberIcon>1</NumberIcon></ListItemIcon>
                                    <ListItemText primary="Click the YouTube icon above." />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><NumberIcon>2</NumberIcon></ListItemIcon>
                                    <ListItemText primary="From the YouTube page, click the current live video." />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><NumberIcon>3</NumberIcon></ListItemIcon>
                                    <ListItemText primary="Click the 'Share' button under the video." />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><NumberIcon>4</NumberIcon></ListItemIcon>
                                    <ListItemText primary="Click the 'Embed' icon in the sharing options." />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><NumberIcon>5</NumberIcon></ListItemIcon>
                                    <ListItemText primary="Copy the provided iframe code." />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><NumberIcon>6</NumberIcon></ListItemIcon>
                                    <ListItemText primary="Paste the copied iframe code into the text box below." />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><NumberIcon>7</NumberIcon></ListItemIcon>
                                    <ListItemText primary="Press 'Transform to Link' to extract the video URL from the iframe code." />
                                </ListItem>
                            </List>
                        </Collapse>
                    </Alert>
                </Grid>


                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <TextField
                        multiline
                        rows={4}
                        placeholder='<iframe width="560" height="315" src="" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
                        label="YouTube embedded frame to video link"
                        value={iframeInput}
                        onChange={handleIframeInputChange}
                    />
                </Grid>

                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button onClick={handleIframeToLink} variant="outlined">Transform to Link</Button>
                </Grid>

                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <TextField
                        label="Live On:"
                        select
                        sx={{ marginTop: '15px', width: '50%' }}
                        value={liveOn ? liveOn : ''}
                        onChange={handleLiveOnChange}
                    >
                        <MenuItem value="YouTube">YouTube</MenuItem>
                    </TextField>
                </Grid>

                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <TextField
                        label="Live Link"
                        value={liveLink ? liveLink : ''}
                        onChange={handleLiveLinkChange}
                        sx={{ marginTop: '15px', width: '75%' }}
                    />
                </Grid>

                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button onClick={handleLiveLinkSave} variant="contained" color="primary" sx={{ marginTop: '15px' }}>Save Live Link</Button>
                </Grid>

                <RestreamStatus />
            </Grid>
        </div>
    );
};

export default GoLive;

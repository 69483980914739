import React, { useEffect, useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { DarkGray, LightGray, pointsDashboardPath, serviceEditorPath } from '../../../constants';
import { Button, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import RequireAuth from '../requireAuth';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getUrl } from '../../../helpers/api/api';
import { getServices_endpoint } from '../../../helpers/api/apiConstants';
import moment from 'moment-timezone';


const mainStyle = {
    fontFamily: "Montserrat",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    top: '56px',
    flexDirection: 'column',
    paddingBottom: '40px',
  };

  const menuStyle = {
    alignItems: 'center',
    backgroundColor: LightGray,
    width: '100%',
    paddingTop: '10px',
    paddingBottom: '10px',
    color: DarkGray,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  };

  const servicesContainerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '70%',
      backgroundColor: 'white',
      marginTop: '50px'
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'date', headerName: 'Date', width: 250 },
    { field: 'day_night', headerName: 'Day/Night', width: 170 },
    { field: 'title', headerName: 'Service Title', width: 250 },
  ];

const PastServices = () => {
    const [viewBy, setViewBy] = useState('recent');
    const [serviceList, setServiceList] = useState([]);
    const [date, setDate] = useState();
    const [isoDate, setIsoDate] = useState();
    // STEPS ON RECENT
    // 1. Get the count, decide how many pages we're going to need
    // 2. Get the full number of results needed for the first page
    // 3. From there, do a re-query when a new page is selected
    // 4. Keep the data. Have each page's services be an element in an array, where each array element is a full page of data. Keep it for each query so we don't have to re-query later
    
    // STEPS ON DATES
    // 1. Query for count from before/on that date to know how many pages there will be
    // 2. Query for every service in that date

    const handleViewChange = (event) => {
        setViewBy(event.target.value);
        if (event.target.value === 'recent') {
            setDate();
            setIsoDate();
        }
    };

    const navigate = useNavigate();
    const handleBackButton = () => {
        navigate(pointsDashboardPath);
    };

    // Firstly, get the service count
    useEffect(() => {
        fetch(getUrl(`${getServices_endpoint}${viewBy === "before-date" ? "?date=" + isoDate : ''}`))
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw response;
            })
            .then(data => {
                setServiceList(data);
            })
            .catch(error => {
                console.error("Error setting data. Error: ", error);
            });
    }, [isoDate, viewBy]);

    const handleCellClick = (data) => {
        navigate(serviceEditorPath, { state: { service_key: data.id } });
    };

    const handleDateChange = (newDate) => {
        setIsoDate(new Date(newDate).toISOString());
        setDate(newDate);
    };

    return (
        <div style={mainStyle}>
            <RequireAuth />
            <div style={menuStyle}>
                <Button variant="outlined" sx={{marginLeft: '10px'}} startIcon={<ArrowBackIosIcon />} onClick={handleBackButton}>
                    Back
                </Button>
                <div>
                    {viewBy === 'before-date' && <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                            label="Before/On Date"
                            inputFormat="MM/DD/YYYY"
                            value={date}
                            onChange={handleDateChange}
                            renderInput={(params) => <TextField {...params} size="small" />}
                            />
                    </LocalizationProvider>}

                    <ToggleButtonGroup
                        value={viewBy}
                        exclusive
                        sx={{marginRight: '10px', marginLeft: '10px'}}
                        color="primary"
                        aria-label="text alignment"
                        onChange={handleViewChange}
                        >
                        <ToggleButton value="recent" aria-label="recent">
                            Most Recent
                        </ToggleButton>
                        <ToggleButton value="before-date" aria-label="before-date">
                            Before/On Date
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>

            <div style={servicesContainerStyle}>
            <DataGrid
                rows={serviceList.map(service => {
                    let utcDate = moment(service.displayDate).utc();
                    let pacificDate = utcDate.clone().tz('America/Los_Angeles');
                    return {
                        id: service.service_key,
                        date: service.displayDate,
                        day_night: service.day_night,
                        title: service.title
                    }
                })}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                sx={{
                    height: 500
                }}
                onCellClick={handleCellClick}
            />
            </div>

        </div>
    );
};

export default PastServices;
import { Alert, TextField } from '@mui/material';
import React from 'react';
import { duetType, otherInstrumentalType, quartetType, soloType, trioType } from '../../../../../../constants';

const InstrumentalTypeSelector = (props) => {
    const { serviceData, handleSongSubEntityTypeChange } = props;

    return (
        <>
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center', marginTop: '5px'}}>
                <TextField
                    select
                    label="Instrumental Type"
                    SelectProps={{
                        native: true
                    }}
                    sx={{marginTop: '10px'}}
                    fullWidth
                    size="small"
                    value={serviceData.song_sub_entity}
                    onChange={handleSongSubEntityTypeChange}
                    >
                    <option value=''></option>
                    <option value={soloType}>Solo</option>
                    <option value={duetType}>Duet</option>
                    <option value={trioType}>Trio</option>
                    <option value={quartetType}>Quartet</option>
                    <option value={otherInstrumentalType}>Other Instrumental</option>
                </TextField>

                {serviceData.song_sub_entity === otherInstrumentalType && <Alert severity="info">If there is a large "other" instrumentation, consider selecting "Orchestra", with a sub-type of "Instrument Ensemble"</Alert>}
            </div>
        </>
    );
};

export default InstrumentalTypeSelector;
import { useContext, useEffect, useState } from 'react';
import { DarkGray, LighterBlue, LightGray, PastelRed, LightBlue, serviceDisplayDate, closeString, EN } from '../constants';
import { formatAMPM } from '../helpers/dataBuilders';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Button, CircularProgress, Divider } from '@mui/material';
import ServicePoints from './messagePanel/servicePoints';
import ServiceContext from '../contexts/serviceContext';
import CloseIcon from '@mui/icons-material/Close';
import { getUrl } from '../helpers/api/api';
import { servicePoints_endpoint } from '../helpers/api/apiConstants';
import { useAppSelector } from '../app/hooks';
import { selectLanguage } from '../app/slices/languageSlice';


const mainStyle = {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: LightGray,
    padding: '10px',
    margin: '5px',
    cursor: 'pointer'
};

const hoverStyle = {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: LighterBlue,
    padding: '10px',
    margin: '5px',
    cursor: 'pointer',
    color: 'white'
};

const timeStyle = {
    fontSize: '16px',
    color: DarkGray,
    fontWeight: '600'
};

const descStyle = {
    fontSize: '13.3px',
    color: DarkGray,
};

const detailsStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
};

const cancelButtonStyle = {
    display: 'flex',
    alignSelf: 'end',
    paddingTop: '2px'
};

const cancelButtonHoverStyle = {
    display: 'flex',
    alignSelf: 'end',
    paddingTop: '2px',
    color: PastelRed
};

const closeButtonStyle = {
    display: 'flex', 
    float: 'right', 
    '&:hover': {
        color: LighterBlue
    }
};

const PanelEntryBox = (props) => {
    const language = useAppSelector(selectLanguage);
    const { eventData, from, handlePointTimestamp } = props;
    const {serviceId, setServiceId, setServiceTimestamp} = useContext(ServiceContext);
    const [divStyle, setDivStyle] = useState(mainStyle);
    const [cancelStyle, setCancelStyle] = useState(cancelButtonStyle);
    const [showDetails, setShownDetails] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [servicePoints, setServicePoints] = useState();

    const alignCenterStyle = {
        alignSelf: 'center', 
        display: 'flex'
    };

    useEffect(() => {
        if (eventData.id !== serviceId) {
            setIsClicked(false);
        }
        
      }, [serviceId, eventData]);

    const mouseEnter = () => {
        setDivStyle(hoverStyle);
    };

    const mouseEnterService = () => {
        if (!isClicked) {
            setDivStyle(hoverStyle);
        }
    };
    
    const mouseEnterCancel = () => {
        setCancelStyle(cancelButtonHoverStyle);
    };

    const mouseLeave = () => {
        setDivStyle(mainStyle);
    };

    const mouseLeaveCancel = () => {
        setCancelStyle(cancelButtonStyle);
    };

    const mouseLeaveService = () => {
        setDivStyle(mainStyle);
    };

    const toggleDetails = () => {
        setShownDetails(showDetails ? false : true);
    };

    const cancelClick = () => {
        setShownDetails(false);
        setCancelStyle(cancelButtonStyle);
        setDivStyle(mainStyle);
    };
    
    const getServicePoints  = async () => {
        const servicePointsResponse = await fetch(getUrl(servicePoints_endpoint + `?service=${eventData.id}`));
        const finalResponse = await servicePointsResponse.json();
        setServicePoints(finalResponse);
      };

    const openServiceDetails = () => {
        const isOpen = isClicked;

        // We only want to change the service id of the playing video when we open a new service
        // We only want to open a new box if it's yet to be clicked
        if (!isOpen) {
            setServiceId(eventData.id);
            setServiceTimestamp(eventData.start_timestamp);
            setIsClicked(true);
        }
        setDivStyle(mainStyle);

        // If the service points for this box aren't available yet, query for them.
        if (!servicePoints) {
            getServicePoints();
        }
    };

    const closeServiceDetails = () => {
        setIsClicked(false);
    };

    const detailsComponent = 
        <div style={detailsStyle}>
            <Divider />
            <CancelIcon sx={cancelStyle} onMouseEnter={mouseEnterCancel} onMouseLeave={mouseLeaveCancel} onClick={cancelClick}/>
            <div>{language === EN ? eventData.descriptionEn : eventData.descriptionRo}</div>
        </div>;

    const serviceDetailsComponent =
    <div style={alignCenterStyle}>
        <div style={alignCenterStyle}>{serviceDisplayDate(language, eventData.weekday, eventData.day_night)}</div> 
    </div>;
    
    const waitingPanelComponent = 
    <Box sx={{ display: 'flex', color: LightBlue}}>
        <CircularProgress color='inherit'/>
    </Box>;

    if (from === 'announcements') {
        return (
            <div key={eventData.id.toString()} style={divStyle} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onClick={toggleDetails}>
                <div style={timeStyle}>{formatAMPM(eventData.date)}</div>
                <div style={descStyle}>{language === EN ? eventData.subjectEn : eventData.subjectRo} | {formatAMPM(eventData.date)}</div>
                {showDetails && detailsComponent}
            </div>
        );
    }
    else {
        return (
            <div style={divStyle} onMouseEnter={mouseEnterService} onMouseLeave={mouseLeaveService} onClick={openServiceDetails}>
                {serviceDetailsComponent}
                <div style={{width: '100%'}}>
                    {isClicked && <Divider style={{margin: '5px'}}/>}
                    {isClicked && 
                        <div>
                            <div style={closeButtonStyle}>
                                <Button onClick={closeServiceDetails} size="small" endIcon={<CloseIcon />} sx={{color: LightBlue, '&:hover': {color: LighterBlue} }} variant="text">
                                    {closeString(language)}
                                </Button>
                            </div>
                            {servicePoints ? <ServicePoints servicePoints={servicePoints} handlePointTimestamp={handlePointTimestamp}/> : waitingPanelComponent}
                        </div>}
                </div>
            </div>
        );
    }
};

export default PanelEntryBox;
import { DialogTitle } from '@material-ui/core';
import { Alert, Button, Dialog, DialogActions, MenuItem, TextField } from '@mui/material';
import React, { useState } from 'react';
import { getUrl } from '../../helpers/api/api';
import { addPerson_endpoint } from '../../helpers/api/apiConstants';

const AddPersonComponent = (props) => {
    const [firstName, setFirstName] = useState();
    const [middleName, setMiddleName] = useState();
    const [lastName, setLastName] = useState();
    const [nickName, setNickName] = useState();
    const [maidenName, setMaidenName] = useState();
    const [suffix, setSuffix] = useState();
    const [alertFirstName, setAlertFirstName] = useState(false);
    const [alertLastName, setAlertLastName] = useState(false);
    // TODO: We need to add in checkers for white space or bad characters and don't let them add it
    

    const handleAddPerson = async () => {
        if (!firstName) {
            setAlertFirstName(true);
            return;
        }
        if (!lastName) {
            setAlertLastName(true);
            return;
        }

        let personJson = {
            firstName: firstName,
            middleName: middleName,
            lastName: lastName,
            nickName: nickName,
            maidenName: maidenName,
            suffix: suffix
        }; 

        fetch(getUrl(addPerson_endpoint), {
            method: 'POST',
            body: JSON.stringify(personJson),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => res.json())
          .then(json => {
              props.addPersonToPeople(json);
              props.changePerson(json);
        });

        setFirstName('');
        setMiddleName('');
        setLastName('');
        setNickName('');
        setMaidenName('');
        setSuffix('');
        setAlertFirstName(false);
        setAlertLastName(false);
        props.onClose();
    };

    return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
        <DialogTitle id="alert-dialog-title">
            <Alert severity="warning">Before adding a new person, are you sure their name is spelled correctly?</Alert>
        </DialogTitle>
        <TextField
            autoFocus
            id="firstname"
            label="First Name"
            sx={{margin: '15px'}}
            value={firstName}
            onChange={event => {
                setFirstName(event.target.value);
                setAlertFirstName(false);
            }}
          />
          {alertFirstName && <Alert severity="error">First name is required.</Alert>}
          <TextField
            autoFocus
            id="middlename"
            label="Middle Name"
            sx={{margin: '15px'}}
            value={middleName}
            onChange={event => setMiddleName(event.target.value)}
          />
          <TextField
            autoFocus
            id="lastname"
            label="Last Name"
            sx={{margin: '15px'}}
            value={lastName}
            onChange={event => {
                setLastName(event.target.value);
                setAlertLastName(false);
            }}
          />
          {alertLastName && <Alert severity="error">Last name is required.</Alert>}
          <TextField
            autoFocus
            id="nickname"
            label="Common Nickname"
            sx={{margin: '15px'}}
            value={nickName}
            onChange={event => setNickName(event.target.value)}
          />
          {nickName && <Alert severity="warning">Only add a nickname if the person goes by their nickname regularly. Example: Octavian Tira is known by "Tavi".</Alert>}
          <TextField
            autoFocus
            id="suffix"
            label="Suffix"
            sx={{margin: '15px'}}
            value={suffix}
            onChange={event => setSuffix(event.target.value)}
            select
          >
              <MenuItem value={''}>None</MenuItem>
              <MenuItem value={'Sr'}>Sr</MenuItem>
              <MenuItem value={'Jr'}>Jr</MenuItem>
              <MenuItem value={'III'}>III</MenuItem>
              <MenuItem value={'IV'}>IV</MenuItem>
          </TextField>
          <TextField
            autoFocus
            id="maidenname"
            label="Maiden Name"
            sx={{margin: '15px'}}
            value={maidenName}
            onChange={event => setMaidenName(event.target.value)}
          />
          {maidenName && <Alert severity="warning">If someone has recently changed their last name, please do not add a new person. Contact database admin to change their last name and maiden name. Only add maiden name if this is a new person.</Alert>}
        <DialogActions>
            <Button onClick={props.onClose}>Cancel</Button>
            <Button onClick={handleAddPerson} autoFocus>
                Confirm
            </Button>
        </DialogActions>
    </Dialog>);
};

export default AddPersonComponent;
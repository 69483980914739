import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { LightBlue } from '../../constants';
  
const Error = () => {
  const [timeRemaining, setTimeRemaining] = useState(10);

  setTimeout(() => {
    setTimeRemaining(timeRemaining - 1);
    if (timeRemaining === 0) {
      document.getElementById("homeNav").click();
    }
  }, 1000); 

  return (
    <div
      style={{
        display: 'block',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '100vh',
        paddingTop: '60px',
        'font-family': "Montserrat",
        backgroundColor: 'white'
      }}
    >
      <h1 style={{ color: LightBlue }}>Error</h1>
      <p style={{ fontSize: 20 }}>The page you are looking for cannot be found</p>
      <p>Redirecting to the home page in {timeRemaining} seconds...</p>
      <NavLink to='/' id='homeNav'></NavLink>
    </div>
  );
};
  
export default Error;
import { InputLabel, TextField } from '@mui/material';
import React from 'react';

const GeneralDescriptionSelector = (props) => {
    const { serviceData, handleGeneralDescriptionRoChange, handleGeneralDescriptionEnChange } = props;

    return (
        <>
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', marginTop: '5px'}}>
                <InputLabel>General Description</InputLabel>
                <div style={{marginTop: '10px'}}>
                    <TextField label="Description (English)" size="small" value={serviceData.general_description_en} onChange={handleGeneralDescriptionEnChange} />
                    <TextField label="Description (Romanian)" size="small" value={serviceData.general_description_ro} onChange={handleGeneralDescriptionRoChange} />
                </div>
            </div>
        </>
    );
};

export default GeneralDescriptionSelector;
/**
 * This file is for housing functions that relate to the front-end requests to the backend.
 */
import config from '../../configuration.json';
import { EMPTY_ARRAY, ERROR } from '../../constants';
import { formatDateString } from '../dataBuilders';
import { announcements_endpoint, deleteAnnouncement_endpoint, updateAnnouncement_endpoint, urgentMessage_endpoint } from './apiConstants';

/**
 * Gets the url to fetch for serving to the node js backend. This is an environment variable.
 * @param {*} endpoint - The endpoint of a url. The endpoint will look something like "/example", while the full url will look something like "https://test.com/example"
 */
export const getUrl = (endpoint) => {
    try {
        return process.env.REACT_APP_DEV_MODE === "true" ? `/api${endpoint}` : `${config.SERVER_URL}/api${endpoint}`;
    } catch (error) {
        console.error("There was an error getting the url. Please ensure that your host has the url set in their server's path: ", error);
        return endpoint;
    }
};

/**
 * On a Fetch, this is the ".then" that will take the response and return the json data
 * @param {*} response 
 * @returns 
 */
export const getJsonResponse = async (response) => {
    if (response.ok) {
        return response.json();
    }
    throw response;
};

/**
 * ANNOUNCEMENTS
 */

/**
 * Fetch API for announcements
 */
export const fetchAnnouncements = async () => {
    try {
        const fetchData = await fetch(getUrl(announcements_endpoint));
        const finalResponse = await fetchData.json();
        return finalResponse;
    }
    catch (error) {
        console.error('There was an error getting announcements: ', error);
        return EMPTY_ARRAY;
    }
};

/**
 * Fetch API for updating announcement
 * @param {*} updatedAnnouncement - An object containing Subjects (EN/RO), Descriptions (EN/RO), and a Expiration Date
 */
export const fetchUpdateAnnouncement = async (updatedAnnouncement) => {
    try {
        let returnStatus = '';
        await fetch(getUrl(updateAnnouncement_endpoint), {
            method: 'POST',
            body: JSON.stringify(updatedAnnouncement),
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response => {
                if (response.ok) {
                    return response.text();
                }
                throw response;
            })
            .then(text => {
                returnStatus = text;
            });

        return returnStatus;
    }
    catch (error) {
        console.error('There was an error trying to update announcement: ', error);
        return ERROR;
    }
};

/**
 * Fetch API for deleting announcement
 * @param {*} announcementJson - Object containing only the announcement_key to send to the backend
 */
export const fetchDeleteAnnouncement = async (announcementJson) => {
    try {
        let returnStatus = '';
        await fetch(getUrl(deleteAnnouncement_endpoint), {
            method: 'POST',
            body: JSON.stringify(announcementJson),
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response => {
                if (response.ok) {
                    return response.text();
                }
                throw response;
            })
            .then(text => {
                returnStatus = text;
            });

        return returnStatus;
    }
    catch (error) {
        console.error('There was an error trying to delete announcement: ', error);
        return ERROR;
    }
};

/**
 * API call to return all urgent messages
 * @returns 
 */
export const getAllMessages = () => {
    return fetch(`${getUrl(urgentMessage_endpoint)}`)
        .then(response => response.json())
        .catch(error => console.error(error));
};

/**
 * API call to return all urgent messages from a given page
 * @returns 
 */
export const getAllMessagesByPage = (page) => {
    return fetch(`${getUrl(urgentMessage_endpoint)}?page=${page}`)
        .then(response => response.json())
        .catch(error => console.error(error));
};

/**
 * API to add a new message to the database
 * @param {*} message 
 * @returns 
 */
export const addNewMessage = (messageEn, messageRo, severity, expirationDate, pages) => {
    const message = {
        messageEn: messageEn,
        messageRo: messageRo,
        severity: severity,
        expirationDate: formatDateString(expirationDate),
        pages: pages
    };

    return fetch(`${getUrl(urgentMessage_endpoint)}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(message)
    })
        .then(response => response.json())
        .catch(error => console.error(error));
};

/**
 * API call to update any message in the database
 * @param {*} id - The id of the given message
 * @param {*} updatedMessage - The full message to update
 * @returns 
 */
export const updateMessage = (id, messageEn, messageRo, severity, expirationDate, pages) => {
    const updatedMessage = {
        messageEn: messageEn,
        messageRo: messageRo,
        severity: severity,
        expirationDate: expirationDate,
        pages: pages
    };

    return fetch(`${getUrl(urgentMessage_endpoint)}/${id}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedMessage)
    })
        .then(response => response.json())
        .catch(error => console.error(error));
};

/**
 * Delete urgent message from a database given the id
 * @param {*} id 
 * @returns 
 */
export const deleteMessage = (id) => {
    return fetch(`${getUrl(urgentMessage_endpoint)}/${id}`, {
        method: 'DELETE'
    })
        .catch(error => console.error(error));
};

/**
 * Fetches data from a specified endpoint and uses the callback function to set the data in the state.
 * @param {string} endpoint - The endpoint to fetch data from.
 * @param {Function} callback - The callback function that will be used to set the state with the fetched data. 
 *                              This function should accept one argument: the data fetched from the endpoint.
 * @returns {Promise<void>} Returns a Promise that resolves to undefined. 
 *                          If an error occurs during the fetch operation, it will be logged to the console.
 * @throws {Object} Will throw an error if the response from the fetch is not ok.
 * @example 
 *     fetchAndSetData('my_endpoint', setData);
 */
export const fetchAndSetData = async (endpoint, callback) => {
    try {
        const response = await fetch(getUrl(endpoint));
        if (!response.ok) throw response;
        const data = await response.json();
        callback(data);
    } catch (error) {
        console.error("Error setting data. Error: ", error);
    }
};
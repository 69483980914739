/**
 * This file contains all constants used in the Bethel web-app
 */

/**
 * DATE STRINGS
 */
const weekdayMapEn = {
  0: 'SUNDAY',
  1: 'MONDAY',
  2: 'TUESDAY',
  3: 'WEDNESDAY',
  4: 'THURSDAY',
  5: 'FRIDAY',
  6: 'SATURDAY'
};

const weekdayMapRo = {
  0: 'DUMINICĂ',
  1: 'LUNI',
  2: 'MARŢI',
  3: 'MIERCURI',
  4: 'JOI',
  5: 'VINERI',
  6: 'SÂMBĂTĂ'
};

export const weekdayMap = (dayIndex, language) =>  { return language === EN ? weekdayMapEn[dayIndex] : weekdayMapRo[dayIndex]; };

export const camelCaseWeekdayMapEn = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday'
};

const monthMapEn = {
  0: 'JANUARY',
  1: 'FEBRUARY',
  2: 'MARCH',
  3: 'APRIL',
  4: 'MAY',
  5: 'JUNE',
  6: 'JULY',
  7: 'AUGUST',
  8: 'SEPTEMBER',
  9: 'OCTOBER',
  10: 'NOVEMBER',
  11: 'DECEMBER'
};

const monthMapRo = {
  0: 'IANUARIE',
  1: 'FEBRUARIE',
  2: 'MARTIE',
  3: 'APRILIE',
  4: 'MAI',
  5: 'IUNIE',
  6: 'IULIE',
  7: 'AUGUST',
  8: 'SEPTEMBRIE',
  9: 'OCTOMBRIE',
  10: 'NOIEMBRIE',
  11: 'DECEMBRIE'
};

export const monthMap = (monthIndex, language) => { return language === EN ? monthMapEn[monthIndex] : monthMapRo[monthIndex]; };

export const camelCaseMonthMapEn = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December'
};

const englishToRomanianWeekdayMap = {
  'Sunday': 'Duminică',
  'Monday': 'Luni',
  'Tuesday': 'Marţi',
  'Wednesday': 'Miercuri',
  'Thursday': 'Joi',
  'Friday': 'Vineri',
  'Saturday': 'Sâmbătă'
};

const englishToRomanianDayNightMap = {
  'Morning': 'dimineata',
  'Evening': 'seara',
  'Night': 'seara',
  '': ''
};

export const serviceDisplayDate = (language, weekday, dayNight) => {
  if (language === EN) {
    return `${weekday} ${dayNight} Service`;
  }
  else {
    return `Serviciu de ${englishToRomanianWeekdayMap[weekday]} ${englishToRomanianDayNightMap[dayNight]}`;
  }
};

const englishShortMonthToRoMap = {
  'Jan': 'Ian',
  'Feb': 'Feb',
  'Mar': 'Mar',
  'Apr': 'Apr',
  'May': 'Mai',
  'Jun': 'Iunie',
  'Jul': 'Iulie',
  'Aug': 'Aug',
  'Sep': 'Sept',
  'Oct': 'Oct',
  'Nov': 'Noem',
  'Dec': 'Dec'
};

export const englishToRomanianShortDateMap = (language, month) => {
  return language === EN ? month : englishShortMonthToRoMap[month];
};

export const generalEnglishToRomanianMap  = {
  English: 'Engleză',
  Romanian: 'Română',
  Both: 'engleza si romana',
  Neither: ''
};

 /**
  * COLORS
  */
 export const Gray = '#32373c';
 export const LightBlue = '#007cbb';
 export const LighterBlue = '#38a2c9'
 export const AlmostBlack = '#1F1F1F'
 export const LightGray = '#f6f6f6';
 export const DarkGray = '#404140';
 export const LightDarkGray = '#666667';
 export const PastelRed = '#ff5148';
 export const WarningOrange = '#f7d1ad';
 export const MoneyGreen = '#27a86c';
 export const DateGray = '#343434';
 export const BorderGray = '#e3d7d1';
 export const textBlack = '#32373c';
 export const textWhite = '#F7F7F7';
 export const LIGHT_BLUE_RGB = `${parseInt(LightBlue.slice(1, 3), 16)}, ${parseInt(LightBlue.slice(3, 5), 16)}, ${parseInt(LightBlue.slice(5), 16)}`;

 export const BBC_SLOGAN = 'A Romanian-American Pentecostal Church Community to Call Home';

 /**
  * WIDTHS
  */
 export const minWidth = '430px';
 export const twoColumnWidth = '650px';
export const bannerWidth = '1307px';
export const bannerWidthRo = '1307px'
export const socialWidth = '1165px';
export const socialWidthRo = '1165px';
 export const flagWidth = '755px';
 export const flagWidthRo = '755px';
 export const policyWidth = '971px';
 export const policyWidthRo = '971px';
 export const topBannerWidth = '970px';
 export const topBannerWidthRo = '1020px';

 /**
  * WEB CONSTANTS
  */
 export const default_image = 'default_image.png';


 /**
  * ADDRESS
  */
 export const street = '10801 Dale Ave';
 export const city = 'Stanton';
 export const state = 'CA';
 export const zip = '90680';

 /**
  * BROWSER PATHS
  */
 export const homePath = '/';
 export const aboutPath = '/about';
 export const servicePath = '/services';
 export const contactPath = '/contact';
 export const donationsPath = '/donations';
 export const givePath = '/give';
 export const announcementsPath = '/announcements';
 export const babyDedicationPath = '/babyDedication';
 export const privacyPolicyPath = '/privacyPolicy';
 export const refundPolicyPath = '/refundPolicy';
 export const romanianPath = '/ro';
 export const englishPath = '/en';
 export const dashboardPath = '/dashboard';
 export const loginPath = '/login';
 export const pointsDashboardPath = '/pointsDashboard';
 export const newServicePath = '/newService';
 export const pastServicesPath = '/pastServices';
 export const announcementsDashboardPath = '/announcementsDashboard';
 export const newAnnouncementPath = '/newAnnouncement';
 export const pastAnnouncementsPath = '/pastAnnouncements';
 export const serviceEditorPath = '/serviceEditor';
 export const propertiesPath = '/properties';
 export const newUserPath = '/newUser';
 export const goLivePath = '/goLive';
 export const socialPath = '/social';
 export const urgentMessagesDashboardPath = '/urgentMessagesDashboard';
 export const mediaPath = '/media';

 // The following paths are for the 2024 Bible Reading Plan. They should all be removed in 2025.
 export const bibleReadingPlanPath = '/BiblePlan';
 export const bibleInAYearPath = '/BibleInAYear';
 export const biblePath = '/Bible';
 export const readingPlanPath = 'ReadingPlan';

 /**
  * SOCIAL LINKS
  */
 export const facebookPath = 'https://www.facebook.com/BethelRomanianPentecostalChurch';
 export const youtubePath = 'https://www.youtube.com/user/BRPCMediaTeam/feed';
 export const vimeoPath = 'https://vimeo.com/channels/bethelchurch';
 export const instagramPath = 'https://www.instagram.com/bethelbibleoc/';
 export const twitterPath = 'https://twitter.com/BethelBibleOC';

 /**
  * SNAZZY CONSTANTS
  * This is for the Google Maps Display on the contact page
  */
 export const snazzyMobile = "421352";
 export const snazzyDesktop = "421350";

 /**
  * DISPLAY STRINGS
  */
const contactBannerStringEn = 'Contact Us'; 
const contactBannerStringRo = 'Contact';
export const contactBannerString = (language) => { return language === EN ? contactBannerStringEn : contactBannerStringRo };

const fullNameStringEn = "Full name";
const fullNameStringRo = "Numele complet";
export const fullNameString = (language) => { return language === EN ? fullNameStringEn : fullNameStringRo };

const emailStringEn = "Email";
const emailStringRo = "e-mail";
export const emailString = (language) => { return language === EN ? emailStringEn: emailStringRo };

const contactMessageStringEn = "Message";
const contactMessageStringRo = "Mesaj";
export const contactMessageString = (language) => { return language === EN ? contactMessageStringEn : contactMessageStringRo };

const submitStringEn = "Submit";
const submitStringRo = "Trimite";
export const submitString = (language) => { return language === EN ? submitStringEn : submitStringRo };

const homeStringEn = 'Home';
const homeStringRo = 'Acasă';
export const homeString = (language) => { return language === EN ? homeStringEn : homeStringRo };

const servicesStringEn = 'Services';
const servicesStringRo = 'Servicii';
export const servicesString = (language) => { return language === EN ? servicesStringEn : servicesStringRo };

const aboutStringEn = 'About';
const aboutStringRo = 'Despre Noi';
export const aboutString = (language) => { return language === EN ? aboutStringEn : aboutStringRo };

const donationsStringEn = 'Donations';
const donationsStringRo = 'Donații';
export const donationsString = (language) => { return language === EN ? donationsStringEn : donationsStringRo };

const contactStringEn = 'Contact';
const contactStringRo = 'Contactaţi-ne';
export const contactString = (language) => { return language === EN ? contactStringEn : contactStringRo };

const mediaStringEn = 'Gallery';
const mediaStringRo = 'Galerie';
export const mediaString = (language) => { return language === EN ? mediaStringEn : mediaStringRo; };

const refundPolicyStringEn = 'Refund Policy';
const refundPolicyStringRo = 'Politica de rambursare';
export const refundPolicyString = (language) => { return language === EN ? refundPolicyStringEn : refundPolicyStringRo };

const privacyPolicyStringEn = 'Privacy Policy';
const privacyPolicyStringRo = 'Politica de Confidențialitate';
export const privacyPolicyString = (language) => { return language === EN ? privacyPolicyStringEn : privacyPolicyStringRo };

const locationStringEn = 'Location';
const locationStringRo = 'Locație';
export const locationString = (language) => { return language === EN ? locationStringEn : locationStringRo };

const serviceTimesStringEn = 'Service Times';
const serviceTimesStringRo = 'Program';
export const serviceTimesString = (language) => { return language === EN ? serviceTimesStringEn : serviceTimesStringRo };

const sundayMorningServiceTimeStringEn = 'Sunday Morning Service';
const sundayMorningServiceTimeStringRo = 'Serviciu de duminica dimineata';
export const sundayMorningServiceTimeString = (language) => { return language === EN ? sundayMorningServiceTimeStringEn : sundayMorningServiceTimeStringRo };

const sundayEveningServiceTimeStringEn = 'Sunday Evening Service';
const sundayEveningServiceTimeStringRo = 'Serviciu de duminică seara';
export const sundayEveningServiceTimeString = (language) => { return language === EN ? sundayEveningServiceTimeStringEn : sundayEveningServiceTimeStringRo };

const tuesdayServiceTimeStringEn = 'Tuesday Prayer Night';
const tuesdayServiceTimeStringRo = 'Seara de rugăciune de marți';
export const tuesdayServiceTimeString = (language) => { return language === EN ? tuesdayServiceTimeStringEn : tuesdayServiceTimeStringRo };

const thursdayServiceTimeStringEn = 'Thursday Bible Study';
const thursdayServiceTimeStringRo = 'Joi studiu biblic';
export const thursdayServiceTimeString = (language) => { return language === EN ? thursdayServiceTimeStringEn : thursdayServiceTimeStringRo };

const fridayServiceTimeStringEn = 'Friday Youth Night';
const fridayServiceTimeStringRo = 'Vineri seara tineretului';
export const fridayServiceTimeString = (language) => { return language === EN ? fridayServiceTimeStringEn : fridayServiceTimeStringRo };

const babyDedicationStringEn = 'Baby Dedications';
const babyDedicationStringRo = 'Binecuvântări de copii';
export const babyDedicationString = (language) => { return language === EN ? babyDedicationStringEn : babyDedicationStringRo };

const moreStringEn = 'More';
const moreStringRo = 'Mai mult';
export const moreString = (language) => { return language === EN ? moreStringEn : moreStringRo };

const announcementsStringEn = 'Announcements';
const announcementsStringRo = 'Anunturi';
export const announcementsString = (language) => { return language === EN ? announcementsStringEn : announcementsStringRo };

const welcomeStringEn = 'Welcome';
const welcomeStringRo = 'Bine aţi venit';
export const welcomeString = (language) => { return language === EN ? welcomeStringEn : welcomeStringRo };

const latestServiceStringEn = 'Watch Our Latest Service';
const latestServiceStringRo = 'Urmărește cel mai recent serviciu al nostru';
export const latestServiceString = (language) => { return language === EN ? latestServiceStringEn : latestServiceStringRo };

const liveNowStringEn = 'Watch Live Now';
const liveNowStringRo = 'Urmăriți serviciul live acum';
export const liveNowString = (language) => { return language === EN ? liveNowStringEn : liveNowStringRo; };

const upcomingEventsStringEn = 'Upcoming Events';
const upcomingEventsStringRo = 'Evenimente Viitoare';
export const upcomingEventsString = (language) => { return language === EN ? upcomingEventsStringEn : upcomingEventsStringRo };

const announcementTitleStringEn = 'Announcements';
const announcementTitleStringRo = 'Anunturi';
export const announcementTitleString = (language) => { return language === EN ? announcementTitleStringEn : announcementTitleStringRo; };

const SERMON_EN = 'SERMONS';
const SERMON_RO = 'PREDICI';
export const SERMONS = (language) => { return language === EN ? SERMON_EN : SERMON_RO };

const SERVICES_EN = 'SERVICES';
const SERVICES_RO = 'SERVICII';
export const SERVICES = (language) => { return language === EN ? SERVICES_EN : SERVICES_RO };

const SEARCH_EN = 'SEARCH';
const SEARCH_RO = 'CĂUTARE';
export const SEARCH = (language) => { return language === EN ? SEARCH_EN : SEARCH_RO };

const recentServicesStringEn = 'Recent Services';
const recentServicesStringRo = 'Servicii recente';
export const recentServicesString = (language) => { return language === EN ? recentServicesStringEn : recentServicesStringRo };

const seniorChoirStringEn = 'Senior Choir';
const seniorChoirStringRo = 'Corul Bisericii';
export const seniorChoirString = (language) => { return language === EN ? seniorChoirStringEn : seniorChoirStringRo };

const youthChoirStringEn = 'Youth Choir';
const youthChoirStringRo = 'Corul de Tineret';
export const youthChoirString = (language) => { return language === EN ? youthChoirStringEn : youthChoirStringRo };

const mensChoirStringEn = 'Mens Choir';
const mensChoirStringRo = 'Corul Bărbătesc';
export const mensChoirString = (language) => { return language === EN ? mensChoirStringEn : mensChoirStringRo };

const girlsChoirStringEn = 'Girls Choir';
const girlsChoirStringRo = 'Cor de Fete';
export const girlsChoirString = (language) => { return language === EN ? girlsChoirStringEn : girlsChoirStringRo };

const kidsChoirStringEn = 'Kids Choir';
const kidsChoirStringRo = 'Corul de copii';
export const kidsChoirString = (language) => { return language === EN ? kidsChoirStringEn : kidsChoirStringRo };

const unitedChoirStringEn = 'United Choir';  
const unitedChoirStringRo = 'Corul Unit';
export const unitedChoirString = (language) => { return language === EN ? unitedChoirStringEn : unitedChoirStringRo };

const bethelChoirStringEn = 'Bethel Choir';
const bethelChoirStringRo = 'Corul Betel';
export const bethelChoirString = (language) => { return language === EN ? bethelChoirStringEn : bethelChoirStringRo };

const seniorChoirWithPraiseBandStringEn = `${seniorChoirStringEn} and Praise Band`;
const seniorChoirWithPraiseBandStringRo = `${seniorChoirStringRo} cu grup de laudă`;
export const seniorChoirWithPraiseBandString = (language) => { return language === EN ? seniorChoirWithPraiseBandStringEn : seniorChoirWithPraiseBandStringRo };

const bethelChoirWithPraiseBandStringEn = `${bethelChoirStringEn} and Praise Band`;
const bethelChoirWithPraiseBandStringRo = `${bethelChoirStringRo} cu grup de laudă`;
export const bethelChoirWithPraiseBandString = (language) => { return language === EN ? bethelChoirWithPraiseBandStringEn : bethelChoirWithPraiseBandStringRo };

const unitedChoirWithPraiseBandStringEn = `${unitedChoirStringEn} and Praise Band`;
const unitedChoirWithPraiseBandStringRo = `${unitedChoirStringRo} cu grup de laudă`;
export const unitedChoirWithPraiseBandString = (language) => { return language === EN ? unitedChoirWithPraiseBandStringEn : unitedChoirWithPraiseBandStringRo };

const youthChoirWithPraiseBandStringEn = `${youthChoirStringEn} and Praise Band`;
const youthChoirWithPraiseBandStringRo = `${youthChoirStringRo} cu grup de laudă`;
export const youthChoirWithPraiseBandString = (language) => { return language === EN ? youthChoirWithPraiseBandStringEn : youthChoirWithPraiseBandStringRo };

const kidsChoirWithPraiseBandStringEn = `${kidsChoirStringEn} and Praise Band`;
const kidsChoirWithPraiseBandStringRo = `${kidsChoirStringRo} cu grup de laudă`;
export const kidsChoirWithPraiseBandString = (language) => { return language === EN ? kidsChoirWithPraiseBandStringEn : kidsChoirWithPraiseBandStringRo };

const choirDefaultStringEn = 'Choir';
const choirDefaultStringRo = 'Courl';
export const choirDefaultString = (language) => { return language === EN ? choirDefaultStringEn : choirDefaultStringRo };

const orchestraDefaultStringEn = 'Orchestra';
const orchestraDefaultStringRo = 'Formație instrumentală';
export const orchestraDefaultString = (language) => { return language === EN ? orchestraDefaultStringEn : orchestraDefaultStringRo };

const groupDefaultStringEn = 'Group';
const groupDefaultStringRo = 'Grup';
export const groupDefaultString = (language) => { return language === EN ? groupDefaultStringEn : groupDefaultStringRo };

const instrumentalDefaultStringEn = 'Instrumental';
const instrumentalDefaultStringRo = 'Instrumental';
export const instrumentalDefaultString = (language) => { return language === EN ? instrumentalDefaultStringEn : instrumentalDefaultStringRo };

const orchestraStringEn = 'Orchestra';
const orchestraStringRo = 'Formație instrumentală';
export const orchestraString = (language) => { return language === EN ? orchestraStringEn : orchestraStringRo };

const symphonicStringEn = 'Symphonic Orchestra';
const symphonicStringRo = 'Orchestra Simfonică';
export const symphonicString = (language) => { return language === EN ? symphonicStringEn : symphonicStringRo };

const instrumentEnsembleEn = 'Instrument Ensemble';
const instrumentEnsembleRo = 'Ansamblul de instrumente';
export const instrumentEnsemble = (language) => { return language === EN ? instrumentEnsembleEn : instrumentEnsembleRo };

const worshipStringEn = 'Worship';
const worshipStringRo = 'Laudă și închinare';
export const worshipString = (language) => { return language === EN ? worshipStringEn : worshipStringRo };

const soloDefaultStringEn = 'Solo';
const soloDefaultStringRo = 'Solo';
const soloDefaultString = (language) => { return language === EN ? soloDefaultStringEn : soloDefaultStringRo };

const duetDefaultStringEn = 'Duet';
const duetDefaultStringRo = 'Duet';
const duetDefaultString = (language) => { return language === EN ? duetDefaultStringEn : duetDefaultStringRo };

const trioDefaultStringEn = 'Trio';
const trioDefaultStringRo = 'Trio';
const trioDefaultString = (language) => { return language === EN ? trioDefaultStringEn : trioDefaultStringRo };

const quartetDefaultStringEn = 'Quartet';
const quartetDefaultStringRo = 'Cvartet';
const quartetDefaultString = (language) => { return language === EN ? quartetDefaultStringEn : quartetDefaultStringRo };

const mensGroupStringEn = 'Mens Group';
const mensGroupStringRo = 'Grup de bărbați'; 
export const mensGroupString = (language) => { return language === EN ? mensGroupStringEn : mensGroupStringRo };

const childrensGroupStringEn = 'Childrens Group';
const childrensGroupStringRo = 'Grup de copii';
export const childrensGroupString = (language) => { return language === EN ? childrensGroupStringEn : childrensGroupStringRo };

const youthGroupStringEn = 'Youth Group';
const youthGroupStringRo = 'Grup de tineri';
export const youthGroupString = (language) => { return language === EN ? youthGroupStringEn : youthGroupStringRo };

const worshipGroupStringEn = 'Worship Group';
const worshipGroupStringRo = 'Grup de Închinare';
export const worshipGroupString = (language) => { return language === EN ? worshipGroupStringEn : worshipGroupStringRo };

const ladiesGroupStringEn = 'Ladies Group';
const ladiesGroupStringRo = 'Grupul Femeilor';
export const ladiesGroupString = (language) => { return language === EN ? ladiesGroupStringEn : ladiesGroupStringRo };

const mixedGroupStringEn = 'Mixed Group';
const mixedGroupStringRo = 'Grup mixt';
export const mixedGroupString = (language) => { return language === EN ? mixedGroupStringEn : mixedGroupStringRo };

const sermonStringEn = 'Sermon';
const sermonStringRo = 'Predică';
export const sermonString = (language) => { return language === EN ? sermonStringEn : sermonStringRo };

const encouragementStringEn = 'Prayer Encouragement';
const encouragementStringRo = 'Îndemn la rugăciune';
export const encouragementString = (language) => { return language === EN ? encouragementStringEn : encouragementStringRo };

const seriesStringEn = 'Series';
const seriesStringRo = 'Serie';
export const seriesString = (language) => { return language === EN ? seriesStringEn : seriesStringRo };

const titleStringEn = 'Title';
const titleStringRo = 'Titlu';
export const titleString = (language) => { return language === EN ? titleStringEn : titleStringRo };

const passageStringEn = 'Scripture';
const passageStringRo = 'Scriptura';
export const passageString = (language) => { return language === EN ? passageStringEn : passageStringRo };

const generalAnnouncementsStringEn = 'General Announcements';
const generalAnnouncementsStringRo = 'Anunturi Generale';
export const generalAnnouncementsString = (language) => { return language === EN ? generalAnnouncementsStringEn : generalAnnouncementsStringRo };

const babyDedicationTitleStringEn = 'Baby Dedication';
const babyDedicationTitleStringRo = 'Binecuvântare de Copil'
export const babyDedicationTitleString = (language) => { return language === EN ? babyDedicationTitleStringEn : babyDedicationTitleStringRo };

const parentStringEn = 'Parents';
const parentStringRo = 'Părinţi';
export const parentString = (language) => { return language === EN ? parentStringEn : parentStringRo };

const poemDefaultStringEn = 'Poem';
const poemDefaultStringRo = 'Poezie';
const poemDefaultString = (language) => { return language === EN ? poemDefaultStringEn : poemDefaultStringRo };

const readingStringEn = 'Scripture Reading';
const readingStringRo = 'Citirea Scripturii';
export const readingString = (language) => { return language === EN ? readingStringEn : readingStringRo };

const aboutUsStringEn = 'About Us';
const aboutUsStringRo = 'Despre Noi';
export const aboutUsString = (language) => { return language === EN ? aboutUsStringEn : aboutUsStringRo };

export const getDefaultString = (language, entity) => {
  switch(entity) {
    case 'Choir':
      return choirDefaultString(language);
    case 'Orchestra':
      return orchestraDefaultString(language);
    case 'Group':
      return groupDefaultString(language);
    case 'Instrumental':
      return instrumentalDefaultString(language);
    case 'Solo':
      return soloDefaultString(language);
    case 'Duet':
      return duetDefaultString(language);
    case 'Trio':
      return trioDefaultString(language);
    case 'Quartet':
      return quartetDefaultString(language);
    case 'Poem':
      return poemDefaultString(language);
    default:
      return '';
  }
};

export const neitherHint = 'Only select "Neither" if the song is neither in Romanian or English. If the song is an instrumental, go back and select "Instrumental".';
export const bothHint = 'Verify that the song is sung partly in English, partly in Romanian.';
export const englishHint = 'Song should be sung fully in English.';
export const romanianHint = 'Song should be sung fully in Romanian.';
export const getLanguageHint = (language) => {
  switch (language) {
    case englishType:
      return englishHint;
    case romanianType:
      return romanianHint;
    case bothType:
      return bothHint;
    default:
      return neitherHint;
  }
};

export const serviceItemType = 'serviceItemType';
export const secondaryItemType = 'secondaryItemType';
export const songType = 'Song';
export const sermonType = 'Sermon';
export const encouragementType = 'Encouragement';
export const otherType = 'Other';
export const announcementsType = 'Announcements';
export const readingType = 'Reading';
export const babyDedicationType = 'Baby Dedication';
export const poemType = 'Poem';
export const presentationType = 'Presentation';
export const greetingType = 'Greeting';
export const testimonyType = 'Testimony';
export const otherSecondaryType = 'otherSecondaryType';
export const choirType = 'Choir';
export const worshipType = 'Worship';
export const orchestraType = 'Orchestra';
export const groupType = 'Group';
export const soloType = 'Solo';
export const instrumentalType = 'Instrumental';
export const typeType = 'type';
export const babyFirstNameType = 'babyFirstName';
export const babyMiddleNameType = 'babyMiddleName';
export const encouragementForType = 'encouragementFor';
export const familyNameType = 'familyName';
export const fatherFirstNameType = 'fatherFirstName';
export const generalDescriptionEnType = 'generalDescriptionEn';
export const generalDescriptionRoType = 'generalDescriptionRo';
export const generalTitleEnType = 'generalTitleEn';
export const generalTitleRoType = 'generalTitleRo';
export const groupTitleEnType = 'groupTitleEn';
export const groupTitleRoType = 'groupTitleRo';
export const languageType = 'language';
export const locationFromType = 'locationFrom';
export const motherFirstNameType = 'motherFirstName';
export const passageType = 'passage';
export const seriesTitleEnType = 'seriesTitleEn';
export const seriesTitleRoType = 'seriesTitleRo';
export const sermonTitleEnType = 'sermonTitleEn';
export const sermonTitleRoType = 'sermonTitleRo';
export const songEntityType = 'songEntity';
export const songSubEntityType = 'songSubEntity';
export const songTitleEnType = 'songTitleEn';
export const songTitleRoType = 'songTitleRo';
export const speakerType = 'speaker';
export const speakerKeyType = 'speakerKey';
export const startTimestampType = 'startTimestamp';
export const englishType = 'English';
export const romanianType = 'Romanian';
export const bothType = 'Both';
export const neitherType = 'Neither';
export const seniorChoirType = 'Senior Choir';
export const youthChoirType = 'Youth Choir';
export const mensChoirType = 'Mens Choir';
export const girlsChoirType = 'Girls Choir';
export const kidsChoirType = 'Kids Choir';
export const unitedChoirType = 'United Choir';
export const bethelChoirType = 'Bethel Choir';
export const withPraiseBandType = 'with Praise Band';
export const otherChoirType = 'Other Choir';
export const passageRecitalType = 'Passage Recital';
export const youngChoirType = 'Young Choir';
export const peopleType = 'people';
export const symphonicType = 'Symphonic';
export const ensembleType = 'Instrument Ensemble';
export const otherOrchestraType = 'Other Orchestra';
export const duetType = 'Duet';
export const trioType = 'Trio';
export const quartetType = 'Quartet';
export const mensGroupType = 'Mens Group';
export const childrensGroupType = 'Childrens Group';
export const youthGroupType = 'Youth Group';
export const worshipGroupType = 'Worship Group';
export const ladiesGroupType = 'Ladies Group';
export const mixedGroupType = 'Mixed Group';
export const otherGroupType = 'Other Group';
export const soloistType = 'Soloist';
export const otherInstrumentalType = 'Other Instrumental';


export const service_item_key_attribute = 'service_item_key';
export const baby_first_name_attribute = 'baby_first_name';
export const baby_middle_name_attribute = 'baby_middle_name';
export const encouragement_for_attribute = 'encouragement_for';
export const family_name_attribute = 'family_name';
export const father_first_name_attribute = 'father_first_name';
export const general_description_en_attribute = 'general_description_en';
export const general_description_ro_attribute = 'general_description_ro';
export const general_title_en_attribute = 'general_title_en';
export const general_title_ro_attribute = 'general_title_ro';
export const group_title_en_attribute = 'group_title_en';
export const group_title_ro_attribute = 'group_title_ro';
export const language_attribute = 'language';
export const location_from_attribute = 'location_from';
export const mother_first_name_attribute = 'mother_first_name';
export const passage_attribute = 'passage';
export const person_title_attribute = 'person_title';
export const series_title_en_attribute = 'series_title_en';
export const series_title_ro_attribute = 'series_title_ro';
export const sermon_title_en_attribute = 'sermon_title_en';
export const sermon_title_ro_attribute = 'sermon_title_ro';
export const service_key_attribute = 'service_key';
export const song_entity_attribute = 'song_entity';
export const song_sub_entity_attribute = 'song_sub_entity';
export const song_title_en_attribute = 'song_title_en';
export const song_title_ro_attribute = 'song_title_ro';
export const speaker_attribute = 'speaker';
export const speaker_key_attribute = 'speaker_key';
export const start_timestamp_attribute = 'start_timestamp';
export const type_attribute = 'type';
export const item_type_attribute = 'item_type';
export const item_secondary_type_attribute = 'item_secondary_type';
export const personnel_attribute = 'personnel';
export const with_praise_band_attribute = 'with_praise_band';

const prayer_request_string_en = 'Prayer Requests';
const prayer_request_string_ro = 'Cereri de rugăciune';
export const prayer_request_string = (language) => { return language === EN ? prayer_request_string_en : prayer_request_string_ro; };

const general_questions_string_en = 'General Questions';
const general_questions_string_ro = 'Intrebari generale';
export const general_questions_string = (language) => { return language === EN ? general_questions_string_en : general_questions_string_ro; };

const concerns_string_en = 'Concerns';
const concerns_string_ro = 'Părere';
export const concerns_string = (language) => { return language === EN ? concerns_string_en : concerns_string_ro; };

const other_string_en = 'Other';
const other_string_ro = 'Alte';
export const other_string = (language) => { return language === EN ? other_string_en : other_string_ro; };

const firstNameStringEn = 'First Name';
const firstNameStringRo = 'Prenume';
export const firstNameString = (language) => { return language === EN ? firstNameStringEn : firstNameStringRo; };

const lastNameStringEn = 'Last Name';
const lastNameStringRo = 'Numele de familie';
export const lastNameString = (language) => { return language === EN ? lastNameStringEn : lastNameStringRo; };

const phoneNumberStringEn = 'Phone Number';
const phoneNumberStringRo = 'Număr de telefon';
export const phoneNumberString = (language) => { return language === EN ? phoneNumberStringEn : phoneNumberStringRo; };

const emailAddressStringEn = 'Email Address';
const emailAddressStringRo = 'Adresa de e-mail';
export const emailAddressString = (language) => { return language === EN ? emailAddressStringEn : emailAddressStringRo; };

const billingAddressStringEn = 'Billing Street Address';
const billingAddressStringRo = 'Adresa de facturare';
export const billingAddressString = (language) => { return language === EN ? billingAddressStringEn : billingAddressStringRo; };

const billingZipStringEn = 'Zip Code';
const billingZipStringRo = 'Cod poștal';
export const billingZipString = (language) => { return language === EN ? billingZipStringEn : billingZipStringRo; };

const donationsForStringEn = 'Giving For';
const donationsForStringRo = 'Donații pentru';
export const donationsForString = (language) => { return language === EN ? donationsForStringEn : donationsForStringRo; };

const generalStringEn = 'General';
const generalStringRo = 'General';
export const generalString = (language) => { return language === EN ? generalStringEn : generalStringRo; };

const tithingStringEn = 'Tithing';
const tithingStringRo = 'Zeciuiala';
export const tithingString = (language) => { return language === EN ? tithingStringEn : tithingStringRo; };

const childrensMinistryStringEn = "Children's Ministry";
const childrensMinistryStringRo = "Departamentul pentru copii";
export const childrensMinistryString = (language) => { return language === EN ? childrensMinistryStringEn : childrensMinistryStringRo; };

const mediaMinistryStringEn = 'Media Ministry';
const mediaMinistryStringRo = 'Departamentul Media';
export const mediaMinistryString = (language) => { return language === EN ? mediaMinistryStringEn : mediaMinistryStringRo; };

const musicMinistryStringEn = 'Music Ministry';
const musicMinistryStringRo = 'Departamentul de Muzică';
export const musicMinistryString = (language) => { return language === EN ? musicMinistryStringEn : musicMinistryStringRo; };

const onlineMinistryStringEn = 'Online Ministry';
const onlineMinistryStringRo = 'Departamentul online';
export const onlineMinistryString = (language) => { return language === EN ? onlineMinistryStringEn : onlineMinistryStringRo; };

const youthMinistryStringEn = 'Youth Ministry';
const youthMinistryStringRo = 'Tineret';
export const youthMinistryString = (language) => { return language === EN ? youthMinistryStringEn : youthMinistryStringRo; };

const youthCampPaymentStringEn = 'Youth Camp Payment';
const youthCampPaymentStringRo = 'Plata Tabăra Tineretului';
export const youthCampPaymentString = (language) => { return language === EN ? youthCampPaymentStringEn : youthCampPaymentStringRo; };

const eselnitaProjectStringEn = 'Eselnita Project';
const eselnitaProjectStringRo = 'Proiect din Eselnita';
export const eselnitaProjectString = (language) => { return language === EN ? eselnitaProjectStringEn : eselnitaProjectStringRo; };

const kidsCampStringEn = "Kid's Camp";
const kidsCampStringRo = 'Tabăra pentru copii';
export const kidsCampString = (language) => { return language === EN ? kidsCampStringEn : kidsCampStringRo; };

const donateStringEn = 'Donate';
const donateStringRo = 'Donează';
export const donateString = (language) => { return language === EN ? donateStringEn : donateStringRo; };

export const announcements_permission = 'announcements';
export const points_permission = 'points';
export const live_permisson = 'live';
export const properties_permission = 'properties';
export const admin_permission = 'admin';

export const BY_DATE = 'sort_by_date';
export const BY_RECENT = 'sort_by_most_recent';

const mostRecentStringEn = 'Most Recent';
const mostRecentStringRo = 'Recent';
export const mostRecentString = (language) => { return language === EN ? mostRecentStringEn : mostRecentStringRo; };

const selectDateStringEn = 'Select Date';
const selectDateStringRo = 'Selectați data';
export const selectDateString = (language) => { return language === EN ? selectDateStringEn : selectDateStringRo; };

const serviceDateStringEn = 'Service Date';
const serviceDateStringRo = 'Data serviciului';
export const serviceDateString = (language) => { return language === EN ? serviceDateStringEn : serviceDateStringRo; };

const finishedLoadingMessageEn = 'End of results';
const finishedLoadingMessageRo = 'Sfârșitul rezultatelor';
export const finishedLoadingMessage = (language) => { return language === EN ? finishedLoadingMessageEn : finishedLoadingMessageRo; };

const noResultsStringEn = 'No Results';
const noResultsStringRo = '0 Rezultate';
export const noResultsString = (language) => { return language === EN ? noResultsStringEn : noResultsStringRo; };

const closeStringEn = 'Close';
const closeStringRo = 'Închide';
export const closeString = (language) => { return language === EN ? closeStringEn : closeStringRo; };

export const EN = 'en';
export const RO = 'ro';

const limit_100_lengthStringEn = 'The length cannot be more than 100';
const limit_100_lengthStringRo = 'Lungimea nu poate fi mai mare de 100';
export const limit_100_lengthString = (language) => { return language === EN ? limit_100_lengthStringEn : limit_100_lengthStringRo; };

const messageErrorStringEn = 'There was an error sending your message. Try again later.';
const messageErrorStringRo = 'A apărut o eroare la trimiterea mesajului. Încercați mai târziu.';
export const messageErrorString = (language) => { return language === EN ? messageErrorStringEn : messageErrorStringRo; };

const messageThankYouStringEn = 'Thank you for your message';
const messageThankYouStringRo = 'Vă mulțumim pentru mesaj';
export const messageThankYouString = (language) => { return language === EN ? messageThankYouStringEn : messageThankYouStringRo; };

const getBackStringEn = 'Someone will get back to you shortly';
const getBackStringRo = 'Cineva te va contacta in scurt timp';
export const getBackString = (language) => { return language === EN ? getBackStringEn : getBackStringRo; };

const followUsStringEn = 'Follow Us';
const followUsStringRo = 'Urmați-ne';
export const followUsString = (language) => { return language === EN ? followUsStringEn : followUsStringRo; };

const redirectingStringEn = 'Redirecting...';
const redirectingStringRo = 'Redirecționăm...';
export const redirectingString = (language) => { return language === EN ? redirectingStringEn : redirectingStringRo; };

const ifNotRedirectedStringEn = 'If you are not redirected quickly, please click';
const ifNotRedirectedStringRo = 'Dacă nu sunteți redirecționat rapid, vă rugăm să faceți clic';
export const ifNotRedirectedString = (language) => { return language === EN ? ifNotRedirectedStringEn : ifNotRedirectedStringRo; };

const hereStringEn = ' here ';
const hereStringRo = ' aici ';
export const hereString = (language) => { return language === EN ? hereStringEn : hereStringRo; };

const toBeRedirectedStringEn = 'to be redirected to the donations page.';
const toBeRedirectedStringRo = 'pentru a fi redirecționat către pagina de donații.';
export const toBeRedirectedString = (language) => { return language === EN ? toBeRedirectedStringEn : toBeRedirectedStringRo; };

const unavailableVideoStringEn = 'This video is currently unavailable. Try again later.';
const unavailableVideoStringRo = 'Acest serviciu nu este accesibil acum. Încercați mai târziu.';
export const unavailableVideoString = (language) => { return language === EN ? unavailableVideoStringEn : unavailableVideoStringRo; };

export const NULL = '';
export const NEW_KEY = '-1';
export const EMPTY_ARRAY = [];
export const ERROR = 'error'; 
export const SUCCESS = 'success';
export const DEFAULT = 'DEFAULT';
export const searchMaxValue = 160;

export const defaultVimeoIdEn = '796189112';
export const defaultVimeoIdRo = '796188712';
export const defaultVimeoId = (language) => { return language === EN ? defaultVimeoIdEn : defaultVimeoIdRo; };

const searchStringEn = 'Search';
const searchStringRo = 'Căutare';
export const searchString = (language) => { return language === EN ? searchStringEn : searchStringRo; }; 

const searchLimitErrorStringEn = `You cannot type more than ${searchMaxValue} characters.`;
const searchLimitErrorStringRo = `Nu poți scrie mai mult de ${searchMaxValue} caractere.`;
export const searchLimitErrorString = (language) => { return language === EN ? searchLimitErrorStringEn : searchLimitErrorStringRo; };

const noSpecialCharacterList = "~ ` @ ! # $ % ^ & * + = [ ] \\ ' ; , / { } | \" : < > ?";
const searchCharacterErrorStringEn = `You cannot enter any of the following characters: ${noSpecialCharacterList}`;
const searchCharacterErrorStringRo = `Nu puteți scrie niciunul dintre următoarele caractere: ${noSpecialCharacterList}`;
export const searchCharacterErrorString = (language) => { return language === EN ? searchCharacterErrorStringEn : searchCharacterErrorStringRo; };

const excessResultsWarningStringEn = 'Your search returned over 1000 results. Showing the first 1000. Try using more specific keywords to refine your search and find the most relevant results.';
const excessResultsWarningStringRo = 'Căutarea dvs. a returnat peste 1000 de rezultate. Se afișează primele 1000. Încercați să utilizați cuvinte cheie mai specifice pentru a rafina căutarea și a găsi cele mai relevante rezultate.';
export const excessResultsWarningString = (language) => { return language === EN ? excessResultsWarningStringEn : excessResultsWarningStringRo; };

const goStringEn = 'Go';
const goStringRo = 'Cauta';
export const goString = (language) => { return language === EN ? goStringEn : goStringRo; };

const resultsStringEn = 'Results';
const resultsStringRo = '';
export const resultsString = (language) => { return language === EN ? resultsStringEn : resultsStringRo; };

const noAnnouncementsEn = 'There are no announcements at the moment. Check back later.';
const noAnnouncementsRo = 'Nu există anunțuri momentan';
export const noAnnouncementsString = (language) => { return language === EN ? noAnnouncementsEn : noAnnouncementsRo; };

const presentationStringEn = 'Presentation';
const presentationStringRo = 'Prezentare';
export const presentationString = (language) => { return language === EN ? presentationStringEn : presentationStringRo; };

const greetingStringEn = 'Greeting';
const greetingStringRo = 'Salut';
export const greetingString = (language) => { return language === EN ? greetingStringEn : greetingStringRo; };

const testimonyStringEn = 'Testimony';
const testimonyStringRo = 'Mărturie';
export const testimonyString = (language) => { return language === EN ? testimonyStringEn : testimonyStringRo; };

const followForMoreStringEn = 'Follow us on Instagram for more content';
const followForMoreStringRo = 'Urmărește-ne pe Instagram pentru mai mult conținut';
export const followForMoreString = (language) => { return language === EN ? followForMoreStringEn : followForMoreStringRo; };

const aboutSectionStringEn = 'About';
const aboutSectionStringRo = 'Despre';
export const aboutSectionString = (language) => { return language === EN ? aboutSectionStringEn : aboutSectionStringRo; };

const historySectionStringEn = 'History';
const historySectionStringRo = 'Istorie';
export const historySectionString = (language) => { return language === EN ? historySectionStringEn : historySectionStringRo; };

const missionSectionStringEn = 'Mission';
const missionSectionStringRo = 'Misiune';
export const missionSectionString = (language) => { return language === EN ? missionSectionStringEn : missionSectionStringRo; };

const ourMissionStringEn = 'Our Mission';
const ourMissionStringRo = 'Misiunea noastră';
export const ourMissionString = (language) => { return language === EN ? ourMissionStringEn : ourMissionStringRo; };

const ourHistoryStringEn = 'Our History';
const ourHistoryStringRo = 'Istoria noastra';
export const ourHistoryString = (language) => { return language === EN ? ourHistoryStringEn : ourHistoryStringRo; };

const contactDisclaimerEn = 'Please note that your message might be read by a representative from our church, so we kindly ask you not to share any highly sensitive information. Thank you.';
const contactDisclaimerRo = 'Vă rugăm să rețineți că mesajul dumneavoastră ar putea fi citit de un reprezentant al bisericii noastre, așa că vă rugăm să nu distribuiți nicio informație foarte sensibilă. Vă mulțumim.';
export const contactDisclaimerString = (language) => { return language === EN ? contactDisclaimerEn : contactDisclaimerRo; };

const searchingNotReadyStringEn = "Be aware results before March 2023 may not be ready yet. We're working diligently on transferring our historical data over. Thank you for your patience!";
const searchingNotReadyStringRo = 'Fiți conștienți că rezultatele de dinainte de martie 2023 s-ar putea să nu fie pregătite încă. Lucrăm cu diligență la transferarea datelor noastre istorice. Vă mulțumim pentru răbdare!';
export const searchingNotReadyString = (language) => { return language === EN ? searchingNotReadyStringEn : searchingNotReadyStringRo; };

const morePhotosStringEn = "More Photos";
const morePhotosStringRo = "Mai multe poze";
export const morePhotosString = (language) => { return language === EN ? morePhotosStringEn : morePhotosStringRo; };

// The following are exports for the 2024 Bible Reading Plan. They should be removed after 2025.
const bibleReadingPlanStringEn = "Bible Reading Plan";
const bibleReadingPlanStringRo = "Plan de Citire a Bibliei";
export const bibleReadingPlanString = (language) => { return language === EN ? bibleReadingPlanStringEn : bibleReadingPlanStringRo };
import { IconButton, InputLabel, TextField, Tooltip } from '@mui/material';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';

const LocationFromSelector = (props) => {
    const { serviceData, handleLocationFromChange } = props;

    return (
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', marginTop: '5px'}}>
            <InputLabel>Guest Location</InputLabel>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <TextField label="Church or City" size="small" value={serviceData.location_from} onChange={(event) => {handleLocationFromChange(event.target.value)}} fullWidth/>
                <Tooltip title={
                    <div>
                        <div>Example:</div>
                        <div>Philadelphia Romanian Church, Portland, OR</div>
                        <div>or...</div>
                        <div>Chicago, IL</div>
                        <div>or...</div>
                        <div>Kindred Community Church</div>
                    </div>
                }>
                    <IconButton>
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    );
};

export default LocationFromSelector;
import { TextField } from '@mui/material';
import React from 'react';
import { choirType, groupType, instrumentalType, orchestraType, otherType, soloType, worshipType } from '../../../../../../constants';

const SongTypeSelector = (props) => {
    const { serviceData, handleSongEntityChange } = props;

    return (
        <TextField
            label="Song Type"
            select
            SelectProps={{
                native: true
            }}
            size="small"
            fullWidth
            value={serviceData.song_entity}
            onChange={handleSongEntityChange}
            >
                <option value=''></option>
                <option value={worshipType}>Worship</option>
                <option value={choirType}>Choir</option>
                <option value={orchestraType}>Orchestra</option>
                <option value={groupType}>Group</option>
                <option value={soloType}>Solo</option>
                <option value={instrumentalType}>Instrumental</option>
                <option value={otherType}>Other</option>
        </TextField>
    );
};

export default SongTypeSelector;
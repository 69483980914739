import './banner.css';
import { NavLink} from 'react-router-dom';
import { homePath, servicePath, aboutPath, contactPath, homeString, servicesString, aboutString, donationsString, contactString, givePath } from '../../constants';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/slices/languageSlice';

const Banner = (props) => {
    const language = useAppSelector(selectLanguage);

    return (
        <div>
            <NavLink activeclassname="active" className="NavLink" onClick={props.handleClick} to={homePath}>{homeString(language)}</NavLink>
            <NavLink activeclassname="active" className="NavLink" onClick={props.handleClick} to={servicePath}>{servicesString(language)}</NavLink>
            <NavLink activeclassname="active" className="NavLink" onClick={props.handleClick} to={aboutPath}>{aboutString(language)}</NavLink>
            <NavLink activeclassname="active" className="NavLink" onClick={props.handleClick} to={givePath} target='_blank'>{donationsString(language)}</NavLink>
            <NavLink activeclassname="active" className="NavLink" onClick={props.handleClick} to={contactPath}>{contactString(language)}</NavLink>
        </div>
    );
};

export default Banner;
import { useEffect, useState } from "react";
import { LightDarkGray, noAnnouncementsString } from "../../constants";
import AnnouncementPanel from "./announcementPanel/announcementPanel";
import { getUrl } from "../../helpers/api/api";
import { announcements_endpoint } from "../../helpers/api/apiConstants";
import AnnouncementLoading from "./announcementLoading";
import UrgentMessageDisplay from "../../components/UrgentMessageDisplay";
import { Typography, useTheme } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../app/slices/languageSlice";

const Announcements = () => {
  const [announcements, setAnnouncements] = useState();
  const language = useAppSelector(selectLanguage);
  const theme = useTheme();

  const mainStyle = {
    backgroundColor: "white",
    fontFamily: "Montserrat",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    top: "56px",
    width: "100%",
    height: "calc(100% - 56px)",
  };

  const announcementPanelStyle = {
    width: "100%",
    backgroundColor:
      announcements && announcements.length % 2 === 0
        ? theme.palette.secondary.main
        : "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  // ANNOUNCEMENT FETCHING ------------------------------
  useEffect(() => {
    if (!announcements) {
      async function getAnnouncements() {
        const announcementResponse = await fetch(
          getUrl(announcements_endpoint)
        );
        const finalResponse = await announcementResponse.json();
        setAnnouncements(finalResponse);
      }

      getAnnouncements();
    }
  }, [announcements]);

  return (
    <div style={mainStyle}>
      <UrgentMessageDisplay page="Announcements" />
      <div style={announcementPanelStyle}>
        {announcements ? (
          <AnnouncementPanel data={announcements} />
        ) : (
          <AnnouncementLoading />
        )}
        {announcements && announcements.length === 0 && (
          <Typography
            variant="p"
            style={{
              color: LightDarkGray,
              fontFamily: theme.typography.fontFamily,
            }}
          >
            {noAnnouncementsString(language)}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default Announcements;

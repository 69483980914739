import { TextField, Tooltip } from '@mui/material';
import React from 'react';
import { childrensGroupType, duetType, ladiesGroupType, LightBlue, LightDarkGray, mensGroupType, mixedGroupType, otherGroupType, quartetType, trioType, worshipGroupType } from '../../../../../../constants';
import InfoIcon from '@mui/icons-material/Info';

const GroupTypeSelector = (props) => {
    const { serviceData, handleSongSubEntityTypeChange } = props;

    return (
        <>
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center', marginTop: '5px'}}>
                <TextField
                    select
                    label="Group Type"
                    SelectProps={{
                        native: true
                    }}
                    sx={{marginTop: '10px'}}
                    fullWidth
                    size="small"
                    value={serviceData.song_sub_entity}
                    onChange={handleSongSubEntityTypeChange}
                    >
                    <option value=''></option>
                    <option value={duetType}>Duet</option>
                    <option value={trioType}>Trio</option>
                    <option value={quartetType}>Quartet</option>
                    <option value={mensGroupType}>Mens Group</option>
                    <option value={childrensGroupType}>Childrens Group</option>
                    <option value={worshipGroupType}>Worship Group</option>
                    <option value={ladiesGroupType}>Ladies Group</option>
                    <option value={mixedGroupType}>Mixed Group</option>
                    <option value={otherGroupType}>Other Group</option>
                </TextField>

                <Tooltip title={
                    <div>
                        <div>Duet: 2 individuals</div>
                        <div>Trio: 3 individuals</div>
                        <div>Quartet: 4 individuals</div>
                        <div>Mens Group: 5+ Men</div>
                        <div>Childrens Group: 5+ Children</div>
                        <div>Worship Group: Guest worship group (or alternative worship team)</div>
                        <div>Ladies Group: 5+ Women</div>
                        <div>Mixed Group: 5+ Men and Women</div>
                        <div>Other Group: Guest group, usually with a special name</div>
                    </div>
                            }
                    sx={{
                        color: LightDarkGray,
                        marginLeft: '10px',
                        '&:hover': {
                            color: LightBlue
                        },
                    }}>
                    <InfoIcon />
                </Tooltip>
            </div>
        </>
    );
};

export default GroupTypeSelector;
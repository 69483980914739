import { useState } from "react";
import { EN, LightBlue, LightDarkGray, RO } from "../../../constants";
import { useAppSelector } from "../../../app/hooks";
import { selectLanguage } from "../../../app/slices/languageSlice";

const titleStyle = {
  fontWeight: "500",
};

const descriptionStyle = {
  fontWeight: "200",
};

const hoverStyle = {
  color: LightBlue,
  cursor: "pointer",
  paddingBottom: "10px",
  fontSize: "14px",
};

const mainStyle = {
  color: LightDarkGray,
  fontSize: "14px",
  paddingBottom: "10px",
};

const OtherPoint = ({ servicePoint, handlePointTimestamp }) => {
  const language = useAppSelector(selectLanguage);
  const [otherStyle, setOtherStyle] = useState(mainStyle);

  const handleMouseEnter = () => {
    setOtherStyle(hoverStyle);
  };

  const handleMouseLeave = () => {
    setOtherStyle(mainStyle);
  };

  const handleClick = () => {
    handlePointTimestamp(servicePoint.START_TIMESTAMP_UNIX);
  };

  return (
    <div
      style={otherStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      {language === EN && (
        <span style={titleStyle}>{servicePoint.GENERAL_TITLE_EN}: </span>
      )}
      {language === RO && (
        <span style={titleStyle}>{servicePoint.GENERAL_TITLE_RO}: </span>
      )}
      {language === EN && (
        <span style={descriptionStyle}>
          {servicePoint.GENERAL_DESCRIPTION_EN}
        </span>
      )}
      {language === RO && (
        <span style={descriptionStyle}>
          {servicePoint.GENERAL_DESCRIPTION_RO}
        </span>
      )}
    </div>
  );
};

export default OtherPoint;

import { useEffect, useState } from "react";
import { getDisplayDateWithYear } from "../../../helpers/dataBuilders";
import { DarkGray, EN, LightBlue } from "../../../constants";
import { useTheme } from "@mui/material";
import { useAppSelector } from "../../../app/hooks";
import { selectLanguage } from "../../../app/slices/languageSlice";

const Announcement = (props) => {
  const { data } = props;
  const [announcementWidth, setAnnouncementWidth] = useState(
    window.innerWidth - 500
  ); // TODO: This is only the case when the browser is a certain width, because the mobile menu will be different
  const language = useAppSelector(selectLanguage);
  const theme = useTheme();

  const mainStyle = {
    display: "flex",
    flexDirection: "column",
    margin: "20px",
    width: "100%", // TODO: Change this for mobile settings
  };

  const announcementStyle = {
    fontSize: "16px",
    color: theme.palette.primary.text,
    fontWeight: "400",
    padding: "5px",
    width: "90%",
  };

  const dateStyle = {
    fontSize: "20px",
    color: DarkGray,
    fontWeight: "600",
    padding: "5px",
  };

  const subjectStyle = {
    fontSize: "20px",
    color: LightBlue,
    fontWeight: "500",
    padding: "5px",
    fontFamily: theme.typography.secondaryFontFamily,
  };

  const handleResize = () => {
    setAnnouncementWidth(window.innerWidth - 500);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("size", handleResize);
    };
  }, [announcementWidth]);

  return (
    <div style={mainStyle}>
      <div style={dateStyle}>
        {getDisplayDateWithYear(new Date(data.DATE), language)}
      </div>
      <div style={subjectStyle}>
        {language === EN ? data.SUBJECT_EN : data.SUBJECT_RO}
      </div>
      <div style={announcementStyle}>
        {language === EN ? data.DESCRIPTION_EN : data.DESCRIPTION_RO}
      </div>
    </div>
  );
};

export default Announcement;

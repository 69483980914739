import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

function RestreamStatus() {
  const [isLive, setIsLive] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState('');
  const [accessToken, setAccessToken] = useState(null);
  const [code, setCode] = useState(null);
  // const clientId = 'ac927bfe-6f55-4b3d-afc8-c74348f4369b';
  // const clientSecret = '53766cf4-4e14-4f78-aad9-6839b1e2a5d3';
  // const redirectUri = 'http://localhost:3000/goLive'; // TODO: Change this later
  const youtubeChannelId = 'UChZPWeLt1ihnBJg3Ju8sWeg';
  //const youtubeChannelId = 'UCuz54AWPX1Yo4z_F7PjTCdA';
  //const API_KEY = 'ac927bfe-6f55-4b3d-afc8-c74348f4369b';
  const iframeRef = useRef(null);

  const clientId = 'ac927bfe-6f55-4b3d-afc8-c74348f4369b';
    const clientSecret = '53766cf4-4e14-4f78-aad9-6839b1e2a5d3';
    const redirectUri = 'http://localhost:3000/goLive';
    const navigate = useNavigate();

  // Generate a random state token
  const state = Math.random().toString(36).substring(2);

  // Define your useEffect hook
  useEffect(() => {
    // Define the function that will make the API call
    const fetchAccessToken = async () => {
      try {
        // Call your exchange_code endpoint to get the access token
        const res = await axios.get('http://localhost:8080/exchange_code?code=your_authorization_code');
        
        // If the response has data, set the access token in your state
        if (res.data && res.data.access_token) {
          setAccessToken(res.data.access_token);
        }
      } catch (error) {
        console.error('Error during fetching access token:', error);
      }
    };

    // Call the function defined above
    fetchAccessToken();
  }, []);


  return (
    <div>
      <div style={{width: '100%', alignContent: 'center', display: 'flex', justifyContent: 'center', paddingBottom: '60px'}}>
        <iframe
      title="YouTube live stream player"
      src={`https://www.youtube.com/embed/live_stream?channel=${youtubeChannelId}`}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      ref={iframeRef}
    />
      </div>
    </div>
  );
}

export default RestreamStatus;
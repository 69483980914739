import { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
  useTheme,
  Button,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@mui/icons-material/Close";
import InstagramEmbed from "./instagramPreview";
import { getUrl } from "../../helpers/api/api";
import { gallery_endpoint } from "../../helpers/api/apiConstants";
import { morePhotosString } from "../../constants";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../app/slices/languageSlice";

const externalGalleryUrl = "https://bethelbibleoc.pixieset.com";

function Gallery() {
  const language = useAppSelector(selectLanguage);
  const [albums, setAlbums] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentAlbum, setCurrentAlbum] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    const fetchAlbums = async () => {
      const response = await fetch(getUrl(gallery_endpoint));

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        const data = await response.json();
        setAlbums(data);
      }
    };

    fetchAlbums().catch((error) => console.log(error));
  }, []);

  const openImageDialog = (album, index) => {
    setCurrentAlbum(album);
    setSelectedImageIndex(index);
    setOpenDialog(true);
  };

  const closeDialog = () => {
    setOpenDialog(false);
    setCurrentAlbum(null);
    setSelectedImageIndex(0);
  };

  return (
    <Box
      sx={{
        bgcolor: "white",
        pt: 7,
        pb: 5,
        fontFamily: theme.typography.fontFamily,
      }}
    >
      <Box sx={{ pb: 2, display: "flex", justifyContent: "center" }}>
        <Button
          variant="outlined"
          href={externalGalleryUrl}
          target="_blank"
          rel="noopener noreferrer"
          size="large"
          sx={{
            marginTop: "10px",
            color: "black",
            borderColor: "black",
            borderRadius: 2,
            fontFamily: theme.typography.secondaryFontFamily,
            fontSize: "1.5rem",
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              color: "white",
            },
          }}
        >
          {morePhotosString(language)}
        </Button>
      </Box>
      {albums.map((album, index) => (
        <Box
          sx={{
            bgcolor: index % 2 === 0 ? theme.palette.secondary.main : "white",
            px: 1.5,
          }}
        >
          <Typography
            align="left"
            variant="h4"
            gutterBottom
            sx={{
              fontFamily: theme.typography.secondaryFontFamily,
              mt: 1,
              mb: 0,
              ml: 1,
            }}
          >
            {album.title || ""}
          </Typography>
          {album.description && (
            <Typography
              align="left"
              variant="body2"
              sx={{ mb: 0, pb: 0, ml: 1 }}
            >
              {album.description}
            </Typography>
          )}
          {album.images ? (
            <Box
              sx={{
                display: "flex",
                overflowX: "auto",
                gap: 2,
                p: 2,
                alignItems: "center",
                mt: 0,
                pt: 1,
              }}
            >
              {album.images.map((image, index) => (
                <Box
                  onClick={() => openImageDialog(album, index)}
                  key={image.id}
                  sx={{
                    cursor: "pointer",
                    position: "relative",
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      backgroundColor: `${theme.palette.primary.main}`,
                      opacity: 0,
                      transition: "opacity .3s ease-in-out",
                    },
                    "&:hover::after": {
                      opacity: 0.6,
                    },
                    "&:hover img": {
                      filter: "brightness(90%)",
                    },
                  }}
                >
                  <img
                    src={image.link}
                    style={{
                      height: "100px",
                      width: "auto",
                      marginBottom: "0",
                      paddingBottom: "0",
                      display: "block",
                    }}
                    alt={image.description || ""}
                  />
                </Box>
              ))}
            </Box>
          ) : (
            <Typography>No images available for this album.</Typography>
          )}
        </Box>
      ))}
      <Dialog open={openDialog} onClose={closeDialog} maxWidth="md" fullWidth>
        <DialogContent style={{ padding: 0, position: "relative" }}>
          <IconButton
            style={{ position: "absolute", right: 15, top: 15, zIndex: 2 }}
            onClick={closeDialog}
          >
            <CloseIcon />
          </IconButton>
          {currentAlbum && (
            <Carousel
              key={selectedImageIndex}
              selectedItem={selectedImageIndex}
              showThumbs={false}
              showStatus={false}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <IconButton
                    style={{
                      position: "absolute",
                      left: 15,
                      top: "calc(50% - 15px)",
                      zIndex: 1,
                    }}
                    onClick={onClickHandler}
                  >
                    <ArrowBackIosIcon color="primary" />
                  </IconButton>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <IconButton
                    style={{
                      position: "absolute",
                      right: 15,
                      top: "calc(50% - 15px)",
                      zIndex: 1,
                    }}
                    onClick={onClickHandler}
                  >
                    <ArrowForwardIosIcon color="primary" />
                  </IconButton>
                )
              }
            >
              {currentAlbum.images.map((image, index) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh",
                  }}
                >
                  <img
                    src={image.link}
                    alt={image.description || ""}
                    style={{
                      maxHeight: "100%",
                      maxWidth: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              ))}
            </Carousel>
          )}
        </DialogContent>
      </Dialog>

      <InstagramEmbed />
    </Box>
  );
}

export default Gallery;

import { InputLabel, TextField } from '@mui/material';
import React from 'react';

const GeneralTitleSelector = (props) => {
    const { serviceData, handleGeneralTitleRoChange, handleGeneralTitleEnChange } = props;

    return (
        <>
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', marginTop: '5px'}}>
                <InputLabel>General Title</InputLabel>
                <div style={{marginTop: '10px'}}>
                    <TextField label="Title (English)" size="small" value={serviceData.general_title_en} onChange={handleGeneralTitleEnChange} />
                    <TextField label="Title (Romanian)" size="small" value={serviceData.general_title_ro} onChange={handleGeneralTitleRoChange} />
                </div>
            </div>
        </>
    );
};

export default GeneralTitleSelector;
import React, { useEffect, useRef, useState } from 'react';

const mainStyle = {
    position: 'fixed',
    zIndex: '1',
    width: '100vw',
    height: '100vh',
    top: '0',
    left: '0',
    objectFit: 'cover'
};

const REEL_URL = 'https://firebasestorage.googleapis.com/v0/b/bethel-bible-church.appspot.com/o/HomeReel.mp4?alt=media&token=9d95c344-e511-48c9-88d7-68226d8c8a64';
const PHOTO_URL = 'https://firebasestorage.googleapis.com/v0/b/bethel-bible-church.appspot.com/o/MainReelGif.gif?alt=media&token=8dc7c3b4-8487-4027-bb8f-c28435c904d5';

const BackgroundReel = () => {
    const [autoPlayError, setAutoPlayError] = useState(false);
    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;
        video.muted = true;
        video.play().catch(() => {
          setAutoPlayError(true);
        });
    }, []);

    return (
        <div>
            {!autoPlayError && 
            <video 
                autoPlay 
                muted 
                playsInline 
                loop 
                preload="metadata"
                id='video' 
                style={mainStyle} 
                ref={videoRef}
                poster={PHOTO_URL}
                alt=""
            >
                <source src={REEL_URL} type='video/mp4'/>
            </video>}
            {autoPlayError && 
            <img
                src={PHOTO_URL}
                alt="WELCOME TO BETHEL BIBLE CHURCH"
                style={mainStyle}
            />}
        </div>
    );
};

export default BackgroundReel;

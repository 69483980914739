import { useEffect, useState, lazy, Suspense } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { isBrowser } from "react-device-detect";
import { snazzyDesktop, snazzyMobile } from "../../constants";
import { Skeleton } from "@mui/material";
import UrgentMessageDisplay from "../../components/UrgentMessageDisplay";

// Lazy load the components
const ContactForm = lazy(() => import("./contactForm/contactForm"));
const SentSuccess = lazy(() => import("./sentSuccess"));

const snazzy = () => {
  return isBrowser ? snazzyDesktop : snazzyMobile;
};

const mainContainerStyle = {
  backgroundColor: "white",
  fontFamily: "Montserrat",
  display: "flex",
  flexDirection: "column",
  margin: "0",
  width: "100%",
  justifyContent: "center",
  height: "calc(100%)", // adjust for footer
  paddingBottom: "46px",
};

const mapBannerStyle = {
  position: "relative",
  top: "57px",
  width: "100%",
  height: "25vh",
};

const contactContainerMainStyle = {
  display: "grid",
  gridTemplateColumns: "1fr",
  alignItems: "center",
  width: "100%",
  height: "calc(74vh)", // adjust for footer
};

const contactContainerMobileStyle = {
  display: "grid",
  gridTemplateColumns: "1fr",
  alignItems: "center",
  width: "100%",
  height: "calc(100%)", // adjust for footer
};

const minWidth = "720px";

const Contact = () => {
  const minWidthMatch = window.matchMedia(`(min-width: ${minWidth})`);
  const [isMinWidth, setMinWidth] = useState(minWidthMatch.matches);
  const [isSent, setIsSent] = useState(false);

  const handleResize = () => {
    const minWidthMatch = window.matchMedia(`(min-width: ${minWidth})`);
    setMinWidth(minWidthMatch.matches);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAvYJvDgp9DV10jTrQBr1qfk7Jf521Xy0o",
  });

  const handleSent = () => {
    setIsSent(true);
  };

  return (
    <div style={mainContainerStyle}>
      <UrgentMessageDisplay page="Contact" />
      <div style={mapBannerStyle}>
        {isLoaded ? (
          <iframe
            src={`https://snazzymaps.com/embed/${snazzy()}`}
            width="100%"
            height="100%"
            style={{ border: "none" }}
            title="Google-Map-Snazzy"
          ></iframe>
        ) : (
          <Skeleton style={{ width: "100%", height: "100%" }} />
        )}
      </div>
      <div
        style={
          isMinWidth ? contactContainerMainStyle : contactContainerMobileStyle
        }
      >
        <Suspense fallback={<div>Loading...</div>}>
          {!isSent && <ContactForm isSent={isSent} handleSent={handleSent} />}
          {isSent && <SentSuccess />}
        </Suspense>
      </div>
    </div>
  );
};

export default Contact;

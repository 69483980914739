import { useEffect, useState } from "react";
import { getSongEntityTitle, getSongTitle } from "../../../helpers/points";
import { LIGHT_BLUE, LIGHT_DARK_GRAY } from "../../../theme/colors";
import { useAppSelector } from "../../../app/hooks";
import { selectLanguage } from "../../../app/slices/languageSlice";

const songEntityStyle = {
  fontWeight: "500",
};

const songTitleStyle = {
  fontWeight: "200",
};

const hoverStyle = {
  color: LIGHT_BLUE,
  cursor: "pointer",
  paddingBottom: "10px",
  fontSize: "14px",
};

const mainStyle = {
  color: LIGHT_DARK_GRAY,
  fontSize: "14px",
  paddingBottom: "10px",
};

const SongPoint = (props) => {
  const language = useAppSelector(selectLanguage);
  const { servicePoint, handlePointTimestamp } = props;
  const [songPointStyle, setSongPointStyle] = useState(mainStyle);
  const {
    SONG_ENTITY,
    SONG_SUB_ENTITY,
    GROUP_TITLE_EN,
    GROUP_TITLE_RO,
    LANGUAGE,
    SONG_TITLE_EN,
    SONG_TITLE_RO,
  } = servicePoint;
  const [songEntityTitle, setSongEntityTitle] = useState(
    getSongEntityTitle(
      language,
      SONG_ENTITY,
      SONG_SUB_ENTITY,
      GROUP_TITLE_EN,
      GROUP_TITLE_RO
    )
  );
  const [songTitle, setSongTitle] = useState(
    getSongTitle(LANGUAGE, language, SONG_TITLE_EN, SONG_TITLE_RO)
  );

  useEffect(() => {
    setSongEntityTitle(
      getSongEntityTitle(
        language,
        SONG_ENTITY,
        SONG_SUB_ENTITY,
        GROUP_TITLE_EN,
        GROUP_TITLE_RO
      )
    );
    setSongTitle(
      getSongTitle(LANGUAGE, language, SONG_TITLE_EN, SONG_TITLE_RO)
    );
  }, [
    language,
    SONG_ENTITY,
    SONG_SUB_ENTITY,
    GROUP_TITLE_EN,
    GROUP_TITLE_RO,
    SONG_TITLE_EN,
    SONG_TITLE_RO,
    LANGUAGE,
  ]);

  const handleMouseEnter = () => {
    setSongPointStyle(hoverStyle);
  };

  const handleClick = () => {
    handlePointTimestamp(servicePoint.START_TIMESTAMP_UNIX);
  };

  const handleMouseLeave = () => {
    setSongPointStyle(mainStyle);
  };

  return (
    <div
      style={songPointStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <span style={songEntityStyle}>
        {songEntityTitle}
        {songTitle && ": "}
      </span>
      <span style={songTitleStyle}>{songTitle}</span>
    </div>
  );
};

export default SongPoint;

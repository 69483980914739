import React, { useState, useEffect } from 'react';
import { language_attribute, location_from_attribute, passage_attribute, series_title_en_attribute, series_title_ro_attribute, sermon_title_en_attribute, sermon_title_ro_attribute, speaker_attribute, speaker_key_attribute } from '../../../../../constants';
import LocationFromSelector from './sermonPointComponents/locationFromSelector';
import PassageSelector from './sermonPointComponents/passageSelector';
import SeriesTitleSelector from './sermonPointComponents/seriesTitleSelector';
import SermonTitleSelector from './sermonPointComponents/sermonTitleSelector';
import SpeakerSelector from './sermonPointComponents/speakerSelector';
import LanguageSelector from './songPointComponents/languageSelector';

const SermonPoint = (props) => {
    const { serviceData, handleItemAttributeChange, index, people, addPersonToPeople } = props; 

    const [newPerson, setNewPerson] = useState(serviceData.speaker ? serviceData.speaker : '');
    const [personPanelIsOpen, setPersonPanelIsOpen] = useState(false);
    const [guestSpeaker, setGuestSpeaker] = useState(false);

    const handleAddPersonClose = () => {
        setPersonPanelIsOpen(false);
    };

    const handlePersonChange = (person) => {
        if (person.label.startsWith("Add ")) {
            console.log(`Adding ${person.label}`);
            setPersonPanelIsOpen(true);
            return;
        }
        setNewPerson(person);
        handleItemAttributeChange(index, speaker_attribute, person.label);
        handleItemAttributeChange(index, speaker_key_attribute, person.id);
    };

    const handleLanguageChange = (event) => {
        handleItemAttributeChange(index, language_attribute, event.target.value);
    };

    const handlePassageChange = (passages) => {
        handleItemAttributeChange(index, passage_attribute, passages); 
    };

    const handleGuestSpeakerChange = (event) => {
        setGuestSpeaker(event.target.checked);
    };

    const handleLocationFromChange = (location) => {
        handleItemAttributeChange(index, location_from_attribute, location);
    };

    const handleSermonTitleEnChange = (event) => {
        handleItemAttributeChange(index, sermon_title_en_attribute, event.target.value);
    };

    const handleSermonTitleRoChange = (event) => {
        handleItemAttributeChange(index, sermon_title_ro_attribute, event.target.value);
    };

    const handleSeriesTitleEnChange = (event) => {
        handleItemAttributeChange(index, series_title_en_attribute, event.target.value);
    };

    const handleSeriesTitleRoChange = (event) => {
        handleItemAttributeChange(index, series_title_ro_attribute, event.target.value);
    };

    useEffect(() => {
        if (!guestSpeaker) {
            handleLocationFromChange('');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [guestSpeaker]);

    return (
        <div>
            {<SpeakerSelector newPerson={newPerson} people={people} handlePersonChange={handlePersonChange} addPersonToPeople={addPersonToPeople} personPanelIsOpen={personPanelIsOpen} handleAddPersonClose={handleAddPersonClose} handleGuestSpeakerChange={handleGuestSpeakerChange}/>}
            {/* TODO: IN THE FUTURE, WE SHOULD ADD THE PERSON'S TITLE HERE*/}
            {guestSpeaker && <LocationFromSelector serviceData={serviceData} handleLocationFromChange={handleLocationFromChange}/>}
            {serviceData.speaker && <LanguageSelector serviceData={serviceData} handleLanguageChange={handleLanguageChange}/>}
            {serviceData.language && <PassageSelector serviceData={serviceData} handlePassageChange={handlePassageChange} /> }
            {serviceData.language && <SermonTitleSelector serviceData={serviceData} handleSermonTitleEnChange={handleSermonTitleEnChange} handleSermonTitleRoChange={handleSermonTitleRoChange}/>}
            {serviceData.language && <SeriesTitleSelector serviceData={serviceData} handleSeriesTitleEnChange={handleSeriesTitleEnChange} handleSeriesTitleRoChange={handleSeriesTitleRoChange}/>}
        </div>
    );
};

export default SermonPoint;
import React from 'react';
import VisuallyHiddenText from '../../components/VisuallyHiddenText';
import { facebookPath, vimeoPath, instagramPath, youtubePath, twitterPath } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faFacebook, 
    faInstagram, 
    faXTwitter, 
    faYoutube, 
    faVimeoV
} from '@fortawesome/free-brands-svg-icons';

export const Social = () => {
    return (
        <ul className="social">
            <li>
                <a href={facebookPath} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faFacebook} size="xs" aria-hidden="true" />
                    <VisuallyHiddenText>Facebook</VisuallyHiddenText>
                </a>
            </li>
            <li>
                <a href={instagramPath} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faInstagram} size="xs" aria-hidden="true" />
                    <VisuallyHiddenText>Instagram</VisuallyHiddenText>
                </a>
            </li>
            <li>
                <a href={twitterPath} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faXTwitter} size="xs" aria-hidden="true" />
                    <VisuallyHiddenText>Twitter</VisuallyHiddenText>
                </a>
            </li>
            <li>
                <a href={youtubePath} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faYoutube} size="xs" aria-hidden="true" />
                    <VisuallyHiddenText>YouTube</VisuallyHiddenText>
                </a>
            </li>
            <li>
                <a href={vimeoPath} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faVimeoV} size="xs" aria-hidden="true" />
                    <VisuallyHiddenText>Vimeo</VisuallyHiddenText>
                </a>
            </li>
        </ul>
    );
};

export default Social;

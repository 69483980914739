import './menu.css';
import { useEffect, useState, useRef } from 'react';
import { isBrowser } from 'react-device-detect';
import { Hamburger } from './hamburger/hamburger';
import { MobileMenu } from './mobile/mobileMenu';
import { SecondaryMenu } from './secondaryMenu/secondaryMenu';
import { NavLink} from 'react-router-dom';
import logo_wide from '../assets/logos/bbc_main_test_2.png';
import logo_icon from '../assets/logos/bbc_blue.png';
import Banner from './banner/banner';
import { RO, topBannerWidth, topBannerWidthRo } from '../constants';
import { useAppSelector } from '../app/hooks';
import { selectLanguage } from '../app/slices/languageSlice';

/*
FUNCTIONS
*/
const getBannerWidth = (lang) => { return lang === RO ? topBannerWidthRo : topBannerWidth };

/**
 * The main top menu is rendered here
 * @param {} props 
 * @returns 
 */
function Menu(props) {
    const menuRef = useRef();

    const language = useAppSelector(selectLanguage);
    const { closeMenu, handleMenuHeight, handleHamburger, isHamburgerClicked, menuIsActive } = props;

    // BROWSER SIZE USE STATES -------------------------------------------
    const mql = window.matchMedia(`(max-width: ${getBannerWidth(language)})`);
    const [smallWindow, setSmallWindow] = useState(mql.matches);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [menuHeight, setWindowHeight] = useState(56);
    // -------------------------------------------------------------------

    /**
     * UseEffect functionality for re-rendering componenent when needed
     */
    useEffect(() => {
        /**
         * Handle resizing of a window to keep track of what components apply
         */
        const handleResize = () => {
            const mql = window.matchMedia(`(max-width: ${getBannerWidth(language)})`);
            setSmallWindow(mql.matches);
            
            if (window.innerWidth !== windowWidth) {
                closeMenu();
            }
            setWindowWidth(window.innerWidth);
            setWindowHeight(menuRef.current?.clientHeight);
            handleMenuHeight(menuHeight);
        };

        window.addEventListener('resize', handleResize);
        setWindowHeight(menuRef.current?.clientHeight);
        handleMenuHeight(menuHeight);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

      return (
        <div>
            <div className="menu" >
                <nav>
                    <div className="navbar">
                        <div className="container nav-container" ref={menuRef}>
                            {!smallWindow && <NavLink activeclassname="active" onClick={closeMenu} to='/' id='logoNav'><img src={logo_wide} alt='Bethel Bible Church' id='logo' style={{marginLeft: '10px'}}/></NavLink>}
                            {smallWindow && <NavLink activeclassname="active" onClick={closeMenu} to='/' id='logoNav'><img src={logo_icon} alt='Bethel Bible Church' id='logo' style={{marginLeft: '10px'}}/></NavLink>}
                            {/* {smallWindow && <NavLink activeclassname="active" onClick={closeMenu} to='/' id='logoNav'><img src={word_logo} alt='Bethel Bible Church' id='logo' style={{marginLeft: '10px'}}/></NavLink>} */}
                            {(isBrowser && !smallWindow) && <div className='bannerItems'>
                                <Banner handleClick={closeMenu}/>
                            </div>}
                            <Hamburger onClick={handleHamburger} clicked={isHamburgerClicked}/>
                        </div>
                    </div>
                </nav>
            </div>
            {(isBrowser && !smallWindow) ? <SecondaryMenu isActive={menuIsActive} handleClick={closeMenu}/> : 
            <MobileMenu isActive={menuIsActive} handleClick={closeMenu}/>}
        </div>
      );
  }

  export default Menu;
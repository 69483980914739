const STRIPE = () => {
  return (
    <div style={{display: "flex", height: "100vh", justifyContent: "center", alignItems: "center"}}>
      <a href="mailto:rosca.marius@gmail.com" style={{ marginTop: "100px" }}>
        rosca.marius@gmail.com
      </a>
    </div>
  );
};

export default STRIPE;

// TODO: REMOVE THIS PAGE WHEN WE NEED TO

import React, { useState } from 'react';
import { general_description_en_attribute, general_description_ro_attribute, general_title_en_attribute, general_title_ro_attribute, personnel_attribute } from '../../../../../constants';
import GeneralDescriptionSelector from './songPointComponents/generalDescriptionSelector';
import GeneralTitleSelector from './songPointComponents/generalTitleSelector';
import PersonnelSelector from './songPointComponents/personnelSelector';

const OtherPoint = (props) => {
    const { serviceData, handleItemAttributeChange, index, people, addPersonToPeople } = props; 
    const [personPanelIsOpen, setPersonPanelIsOpen] = useState(false);
    const [addPersonDialogOpen, setAddPersonDialogOpen] = useState(false);
    const [addPeopleOpen, setAddPeopleOpen] = useState(false);
    const [newPerson, setNewPerson] = useState();
    const [newPosition, setNewPosition] = useState();

    const handleAddPersonnel = () => {
        setAddPeopleOpen(true);
    };

    const handleAddPeopleClose = () => {
        setAddPeopleOpen(false);
    };

    const handlePersonChange = (person) => {
        if (person.label.startsWith("Add ")) {
            console.log(`Adding ${person.label}`);
            setPersonPanelIsOpen(true);
            return;
        }
        setNewPerson(person);
    };

    const handlePositionChange = (event) => {
        setNewPosition(event.target.value);
    };



    const saveNewPerson = () => {
       const tmpPersonnel = [...serviceData.personnel];
       
       tmpPersonnel.push({
           person: newPerson,
           position: newPosition
       });

       handleItemAttributeChange(index, personnel_attribute, tmpPersonnel);
       setAddPeopleOpen(false);
       setAddPersonDialogOpen(false);
       setNewPerson('');
       setNewPosition('');
    };

    const removePerson = (i) => {
        const tmpPersonnel = [...serviceData.personnel];
        tmpPersonnel.splice(i, 1);
        handleItemAttributeChange(index, personnel_attribute, tmpPersonnel);
    };

    const handleGeneralTitleEnChange = (event) => {
        handleItemAttributeChange(index, general_title_en_attribute, event.target.value);
    };

    const handleGeneralTitleRoChange = (event) => {
        handleItemAttributeChange(index, general_title_ro_attribute, event.target.value);
    };

    const handleGeneralDescriptionEnChange = (event) => {
        handleItemAttributeChange(index, general_description_en_attribute, event.target.value);
    };

    const handleGeneralDescriptionRoChange = (event) => {
        handleItemAttributeChange(index, general_description_ro_attribute, event.target.value);
    };

    const handleAddPersonClose = () => {
        setPersonPanelIsOpen(false);
    };

    const handleAddPerson = () => {
        const tmpPersonnel = [...serviceData.personnel];
       
       tmpPersonnel.push({
           person: newPerson,
           position: newPosition
       });

       handleItemAttributeChange(index, personnel_attribute, tmpPersonnel);
       setAddPeopleOpen(false);
       setAddPersonDialogOpen(false);
       setNewPerson('');
       setNewPosition('');
    };

    return (
        <div>
            {/* TODO:
                In a future version, we're going to add some more type to the other. This will include:
                - Announcements
                - Reading
                - Baby Dedication
                - Poem
                - Presentation
                - Greeting
                - Testimony
                - Passage Recital
                - Other

                Until then, we'll add these under a general title and description, and still have the ability to add personnel to them. 
            */}
            <GeneralTitleSelector serviceData={serviceData} handleGeneralTitleRoChange={handleGeneralTitleRoChange} handleGeneralTitleEnChange={handleGeneralTitleEnChange}/>
            {(serviceData.general_title_en || serviceData.general_title_ro) && <GeneralDescriptionSelector serviceData={serviceData} handleGeneralDescriptionEnChange={handleGeneralDescriptionEnChange} handleGeneralDescriptionRoChange={handleGeneralDescriptionRoChange}/>}
            {((serviceData.general_title_en || serviceData.general_title_ro) && (serviceData.general_description_en || serviceData.general_description_ro)) && <PersonnelSelector serviceData={serviceData} handleAddPersonnel={handleAddPersonnel} addPersonToPeople={addPersonToPeople} personPanelIsOpen={personPanelIsOpen} handleAddPersonClose={handleAddPersonClose} addPersonDialogOpen={addPersonDialogOpen} addPeopleOpen={addPeopleOpen} handlePersonChange={handlePersonChange} people={people} handlePositionChange={handlePositionChange} saveNewPerson={saveNewPerson} parentProps={props} newPerson={newPerson} newPosition={newPosition} handleAddPerson={handleAddPerson} handleAddPeopleClose={handleAddPeopleClose} removePerson={removePerson}/>}
        </div>
    );
};

export default OtherPoint;
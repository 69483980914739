import moment from 'moment-timezone';
import { monthMap, weekdayMap } from "../constants";
import dayjs from 'dayjs';


/**
 * Take in DB announcement data and parse it into something more usable for the client-side
 * @param {*} jsonDataEntry - Announcement object from the server
 */
export const createNewEventAnnouncementObject = (jsonDataEntry) => {
  return {
    date: new Date(jsonDataEntry.date),
    id: jsonDataEntry.announcement_key,
    subjectEn: jsonDataEntry.subjectEn,
    subjectRo: jsonDataEntry.subjectRo,
    descriptionEn: jsonDataEntry.descriptionEn,
    descriptionRo: jsonDataEntry.descriptionRo,
  };
};

/**
 * Creates a map of dates to lists of upcoming events on that given day
 * @param {*} data 
 */
export const getDateToEventMap = (data) => {
  const eventMap = new Map();
  for (let i = 0; i < data.length; i++) {
    const upcomingEvent = data[i];
    const newEntry = [{
      date: new Date(upcomingEvent.DATE),
      id: upcomingEvent.UPCOMING_EVENT_KEY,
      subjectEn: upcomingEvent.SUBJECT_EN,
      subjectRo: upcomingEvent.SUBJECT_RO,
      descriptionEn: upcomingEvent.DESCRIPTION_EN,
      descriptionRo: upcomingEvent.DESCRIPTION_RO,
    }];

    const newDate = new Date(upcomingEvent.date).toLocaleDateString();
    if (!eventMap.has(newDate)) {
      eventMap.set(newDate, newEntry);
    }
    else {
      const resetArray = [...eventMap.get(newDate), ...newEntry];
      eventMap.set(newDate, resetArray);
    }
  }

  return eventMap;
};

/**
 * Converts queried for services into an object that is split by date
 * @param {*} data 
 */
export const getDateToServiceMap = (data) => {
  const serviceMap = new Map();

  data.forEach((service) => {
    const newEntry = {
      id: service.SERVICE_KEY,
      date: service.DATE,
      day_night: service.DAY_NIGHT,
      start_timestamp: service.START_TIMESTAMP,
      title: service.TITLE,
      vimeo_id: service.VIMEO_ID,
      weekday: service.WEEKDAY
    };

    const newDate = new Date(service.DATE).toLocaleDateString();
    if (!serviceMap.has(newDate)) {
      serviceMap.set(newDate, [newEntry]);
    } else {
      serviceMap.set(newDate, [...serviceMap.get(newDate), newEntry]);
    }
  });

  return serviceMap;
};


/**
 * Given a date and a language, converts that into a readable display date for that language
 * @param {*} date 
 * @param {*} language 
 */
export const getDisplayDate = (date, language) => {
  const unixDate = moment(date).utc();
  const weekday = weekdayMap(unixDate.weekday(), language);
  const month = monthMap(unixDate.month(), language);
  const day = unixDate.date();
  return `${weekday}, ${month} ${day}`;
};

/**
 * Given a data and language, converts that into a readable display date with the year for that language
 * @param {*} date 
 * @param {*} language 
 */
export const getDisplayDateWithYear = (date, language) => {
  const unixDate = moment(date).utc();
  const weekday = weekdayMap(unixDate.weekday(), language);
  const month = monthMap(unixDate.month(), language);
  const year = unixDate.year();
  const day = unixDate.date();
  return `${weekday}, ${month} ${day}, ${year}`;
};

/**
 * Formats a AM/PM 12 hour clock date
 * @param {*} date 
 */
export const formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

/**
 * Puts any date into a format meant for MySql insertion
 * @param {*} date 
 * @returns A date in the format meant for MySQL: YYYY-MM-DD HH:MM:SS
 */
export const formatSqlDate = (date) => {
  if (date) {
    return new Date(date).toISOString().slice(0, 19).replace('T', ' ');
  }
  return new Date().toISOString().slice(0, 19).replace('T', ' ')
};

/**
 * Returns a date in a dayjs format
 * @param {*} date 
 * @returns a date in the format YYYY-MM-DD
 */
export const formatDateString = (date) => {
  return date ? dayjs(date).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');
};

/**
 * Returns a date in a dayjs format
 * @param {*} date 
 * @returns a date and time in the format YYYY-MM-DD 
 */
export const formatDatetimeString = (date) => {
  return date ? dayjs(date).format('YYYY-MM-DD HH:mm:ss') : dayjs().format('YYYY-MM-DD HH:mm:ss');
};
import { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import { EN } from "../constants";
import { getAllMessagesByPage } from "../helpers/api/api";
import { useAppSelector } from "../app/hooks";
import { selectLanguage } from "../app/slices/languageSlice";

const severityMap = {
  Low: "info",
  Medium: "warning",
  High: "error",
};

const UrgentMessageDisplay = ({ page, handleNoData }) => {
  const language = useAppSelector(selectLanguage);
  const [messages, setMessages] = useState([]);

  /**
   * This use effect is used to query
   */
  useEffect(() => {
    async function getMessages() {
      const messageResults = await getAllMessagesByPage(page);
      if ((messageResults && messageResults.length === 0) || messageResults === "error") {
        handleNoData(true);
        setMessages([]);
      } // This will return back to the parent div that there is no data found
      else {
        setMessages(messageResults);
      }
      
    }

    getMessages();
  }, [handleNoData, page]);

  const onSnackbarClose = (id) => {
    setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== id));
  };

  const handleClose = (event, reason, id) => {
    if (reason === "clickaway") {
      return;
    }
    onSnackbarClose(id);
  };

  const getSnackbarStyle = (index) => ({
    position: "fixed",
    top: `${56 + index * 70}px`,
    left: "50%",
    transform: "translate(-50%, 0)",
    zIndex: 1400,
  });

  return (
    <div>
      {messages.map((message, index) => {
        const alertContent =
          language === EN ? message.messageEn : message.messageRo;
        return (
          <Snackbar
            key={message.id}
            style={getSnackbarStyle(index)}
            open={true}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={(event, reason) => handleClose(event, reason, message.id)}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={(event) => handleClose(event, null, message.id)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            <Alert
              onClose={(event) => handleClose(event, null, message.id)}
              severity={severityMap[message.severity]}
              sx={{
                width: "100%",
                maxWidth: "100%",
                boxSizing: "border-box",
                wordWrap: "break-word",
              }}
            >
              {alertContent}
            </Alert>
          </Snackbar>
        );
      })}
    </div>
  );
};

export default UrgentMessageDisplay;

import React from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const LiveVideo = ({ videoLink, open, onClose }) => {
    // console.log(videoLink);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          overflowY: 'visible',
        },
      }}
    >
      <DialogContent
        sx={{
          position: 'relative',
          padding: 0,
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            zIndex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          <CloseIcon />
        </IconButton>
        <iframe
          src={`${videoLink}?autoplay=1`}
          title="Video Player"
          width="100%"
          height="500px"
          allow="autoplay; fullscreen"
          style={{
            border: 'none',
          }}
        ></iframe>
      </DialogContent>
    </Dialog>
  );
};

export default LiveVideo;

import { Button } from '@mui/material';
import { Grid } from '@mui/material';
import React from 'react';
import { announcementsDashboardPath, dashboardPath, LightBlue, pastAnnouncementsPath } from '../../../constants';
import { Link } from "react-router-dom";
import Userfront from "@userfront/react";

const mainStyle = {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '2'
  };

const AnnouncementSuccess = () => {

    return (
        <div style={mainStyle}>
            <h1 style={{color: LightBlue, textAlign: 'center'}}>The announcement has been successfully posted!</h1>

            <Grid container spacing={2} alignItems='center' alignContent='center' justifyContent='center'>
                <Grid item xs={8}>
                    <Button variant='contained' component={Link} to={pastAnnouncementsPath} fullWidth>VIEW PAST ANNOUNCEMENTS</Button>
                </Grid>

                <Grid item xs={8}>
                    <Button variant='contained' component={Link} to={announcementsDashboardPath} fullWidth>ANNOUNCEMENTS DASHBOARD</Button>
                </Grid>

                <Grid item xs={8}>
                    <Button variant='contained' component={Link} to={dashboardPath} fullWidth>MAIN DASHBOARD</Button>
                </Grid>

                <Grid item xs={8}>
                    <Button variant='contained' onClick={Userfront.logout} fullWidth>LOGOUT</Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default AnnouncementSuccess;
import { useState } from "react";
import "./MobileMore.css";
import {
  DarkGray,
  fridayServiceTimeString,
  LightBlue,
  serviceTimesString,
  sundayEveningServiceTimeString,
  sundayMorningServiceTimeString,
  thursdayServiceTimeString,
  tuesdayServiceTimeString,
} from "../../../constants";
import { useAppSelector } from "../../../app/hooks";
import { selectLanguage } from "../../../app/slices/languageSlice";

const titleStyle = {
  color: LightBlue,
  display: "flex",
  fontWeight: "600",
};

const timeStyle = {
  color: DarkGray,
  fontSize: "14px",
  fontWeight: "500",
};

const MobileServiceTimes = (props) => {
  const [displayMore, setDisplayMore] = useState(false);
  const language = useAppSelector(selectLanguage);

  const toggleMore = () => {
    setDisplayMore(displayMore === true ? false : true);
  };

  /* 
    Sunday Morning Service
    Sunday Evening Service
    Tuesday Prayer Night
    Thursday Bible Study
    Friday Youth Night
    10:00 AM
    6:00 PM
    7:00 PM
    7:00 PM
    7:30 PM
    */
  return (
    <div className="moreContainer">
      <div className="more" onClick={toggleMore}>
        {serviceTimesString(language)}
      </div>
      {displayMore && (
        <ul>
          <li>
            <span style={titleStyle}>
              {sundayMorningServiceTimeString(language)}
            </span>
            <span style={timeStyle}>10:00am</span>
          </li>
          <li>
            <span style={titleStyle}>
              {sundayEveningServiceTimeString(language)}
            </span>
            <span style={timeStyle}>6:00pm</span>
          </li>
          <li>
            <span style={titleStyle}>{tuesdayServiceTimeString(language)}</span>
            <span style={timeStyle}>7:00pm</span>
          </li>
          <li>
            <span style={titleStyle}>
              {thursdayServiceTimeString(language)}
            </span>
            <span style={timeStyle}>7:00pm</span>
          </li>
          <li>
            <span style={titleStyle}>{fridayServiceTimeString(language)}</span>
            <span style={timeStyle}>7:30am</span>
          </li>
        </ul>
      )}
    </div>
  );
};

export default MobileServiceTimes;

import { Accordion, AccordionSummary, Typography, AccordionDetails, InputLabel, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useState } from 'react';
import PassageInput from '../../../../../../components/serviceEditor/passageInput';

const mainStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '5px',
    width: '100%'
};

const PassageSelector = (props) => {
    const { serviceData, handlePassageChange } = props;
    const [passages, setPassages] = useState(serviceData.passage.split(', '));

    const handleAddPassage = () => {
        const newPassages = [...passages];
        newPassages.push('');
        setPassages(newPassages);
    };

    const handleRemovePassage = (index) => {
        const newPassages = [...passages];
        newPassages.splice(index, 1);
        setPassages(newPassages);
    };

    const handleLocalPassageChange = (index, newPassage) => {
        const tmpPassages = [...passages];
        tmpPassages[index] = newPassage;
        setPassages(tmpPassages);
    };

    useEffect(() => {
        handlePassageChange(passages.join(', '));
        
       // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [passages]);

    return (
        <div style={mainStyle}>  
            <InputLabel>Passage</InputLabel>
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <Typography>{serviceData.passage ? serviceData.passage : 'No Passage'}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {passages.map((passage, index) =>
                        <div key={index} >
                            <PassageInput handleRemovePassage={handleRemovePassage} index={index} handleLocalPassageChange={handleLocalPassageChange} passage={passage} />
                        </div>
                    )}
                    <Button onClick={handleAddPassage}><AddIcon /></Button>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default PassageSelector;
import React from 'react';
import { LanguageTitle, MainSearchTitle, NameSearchTitle, SecondarySearchTitle } from './searchDetailComponents';

const mainStyle = {
    display: 'flex',
    marginLeft: '10px',
    flexDirection: 'column',
    justifyContent: 'center'
};

const SearchDetails = (props) => {
    const { result, searchFor, isHovered } = props;

    return (
        <div style={mainStyle}>
            <MainSearchTitle result={result} searchFor={searchFor} isHovered={isHovered} />
            <SecondarySearchTitle result={result} searchFor={searchFor} isHovered={isHovered} />
            <NameSearchTitle result={result} searchFor={searchFor} isHovered={isHovered} />
            <LanguageTitle resultLanguage={result.language} isHovered={isHovered} />
        </div>
    );
};

export default SearchDetails;
import { useCallback, useEffect, useRef, useState } from "react";
import { Divider, Typography, Button } from "@mui/material";
import { EN, RO } from "../../constants";
import { useTheme } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"; // Import MUI Arrow Icon
import TodayIcon from "@mui/icons-material/Today";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../app/slices/languageSlice";

const BiblePlan = () => {
  const language = useAppSelector(selectLanguage);
  const theme = useTheme();
  const currentDateRef = useRef(null);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [showScrollToToday, setShowScrollToToday] = useState(false);

  const checkScrollTop = useCallback(() => {
    if (!showScrollTop && window.pageYOffset > 400) {
      setShowScrollTop(true);
    } else if (showScrollTop && window.pageYOffset <= 400) {
      setShowScrollTop(false);
    }
  }, [showScrollTop]); // Dependencies for useCallback

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [showScrollTop, checkScrollTop]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const checkScrollToToday = () => {
    if (currentDateRef.current) {
      const rect = currentDateRef.current.getBoundingClientRect();
      const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

      setShowScrollToToday(!isVisible);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollToToday);
    return () => {
      window.removeEventListener("scroll", checkScrollToToday);
    };
  }, []);

  const scrollToTodayVerse = () => {
    if (currentDateRef.current) {
      const position = currentDateRef.current.offsetTop - 70; // Adjust as needed
      window.scrollTo({ top: position, behavior: "smooth" });
    }
  };

  const scrollTopButtonStyle = {
    position: "fixed",
    right: "20px",
    top: "56px",
    zIndex: 100,
    opacity: "80%",
  };

  const scrollCurrentButtonStyle = {
    position: "fixed",
    left: "20px",
    top: "56px",
    zIndex: 100,
    opacity: "80%",
  };

  const mainStyle = {
    backgroundColor: "white",
    fontFamily: "Montserrat",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    position: "relative",
    top: "56px",
    maxWidth: "100%",
    padding: "20px",
  };

  const monthHeaderStyle = {
    fontSize: "40px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    margin: "20px 0",
    textAlign: "center",
  };

  const dayPassageStyle = {
    textAlign: "left",
    width: "100%",
  };

  const introParagraphStyle = {
    margin: "0 20px", // Adding margin on the sides for paragraphs
    textAlign: "left",
    textIndent: "2em", // Adding indent for paragraphs
    marginBottom: "1em", // Increasing gap between paragraphs
  };

  const introNonParagraphStyle = {
    margin: "0 20px", // Adding margin on the sides for non-paragraphs
    textAlign: "left",
    marginBottom: "1em", // Space after the non-paragraph elements
  };

  const dayStyle = {
    fontWeight: "bold",
  };

  const pastDateStyle = {
    color: "gray",
  };

  const todayDateStyle = {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  };

  useEffect(() => {
    // Scroll to a position slightly above the current date element
    if (currentDateRef.current) {
      const position = currentDateRef.current.offsetTop - 70; // Adjust 100px or as needed for the header
      window.scrollTo({ top: position, behavior: "smooth" });
    }
  }, []);

  const getDateStyle = (dateStr) => {
    const today = new Date();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const [month, day] = dateStr.split(" ");

    // Convert month name to month number
    const monthNumber = monthNames.indexOf(month);
    const dateToCheck = new Date(
      today.getFullYear(),
      monthNumber,
      parseInt(day, 10)
    );

    // Compare dates without time
    if (dateToCheck.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0))
      return pastDateStyle;
    if (dateToCheck.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0))
      return todayDateStyle;
    return {};
  };

  // Determine the book based on the month and language
  const getBookForMonth = (month, lang) => {
    const bookMapping = {
      en: {
        January: "January",
        February: "February",
        March: "March",
        April: "April",
        May: "May",
        June: "June",
        July: "July",
        August: "August",
        September: "September",
        October: "October",
        November: "November",
        December: "December",
      },
      ro: {
        January: "January",
        February: "February",
        March: "March",
        April: "April",
        May: "May",
        June: "June",
        July: "July",
        August: "August",
        September: "September",
        October: "October",
        November: "November",
        December: "December",
      },
    };

    return bookMapping[lang][month];
  };

  // Map for the book of Genesis and Exodus in English and Romanian for January
  const biblePassages = {
    en: {
      January: [
        "Genesis 1-3",
        "Genesis 4-7",
        "Genesis 8 to 11:9",
        "Genesis 11:10 to 14:13",
        "Genesis 14:14 to 18:8",
        "Genesis 18:9 to 21:21",
        "Genesis 21:22 to 24:27",
        "Genesis 24:28 to 26:35",
        "Genesis 27-29",
        "Genesis 30:1 to 31:42",
        "Genesis 31:43 to 34:31",
        "Genesis 35:1 to 37:24",
        "Genesis 37:25 to 40:8",
        "Genesis 40:9 to 42:28",
        "Genesis 42:29 to 45:15",
        "Genesis 45:16 to 48:7",
        "Genesis 48:8 to 50:26 and Exodus 1",
        "Exodus 2:1 to 5:9",
        "Exodus 5:10 to 8:15",
        "Exodus 8:16 to 11:10",
        "Exodus 12:1 to 14:20",
        "Exodus 14:21 to 17:16",
        "Exodus 18:1 to 21:21",
        "Exodus 21:22 to 25:9",
        "Exodus 25:10 to 27:21",
        "Exodus 28 to 29",
        "Exodus 30-32",
        "Exodus 33:1 to 35:29",
        "Exodus 35:30 to 37:29",
        "Exodus 38:1 to 40:16",
        "Exodus 40:17-38 and Leviticus 1-4",
      ],
      February: [
        "Leviticus 5-7",
        "Leviticus 8:1 to 11:8",
        "Leviticus 11:9 to 13:39",
        "Leviticus 13:40 to 14:57",
        "Leviticus 15:1 to 18:18",
        "Leviticus 18:19 to 21:24",
        "Leviticus 22-23",
        "Leviticus 24:1 to 26:13",
        "Leviticus 26:14 to 27:34 and Numbers 1:1-41",
        "Numbers 1:42 to 3:32",
        "Numbers 3:33 to 5:22",
        "Numbers 5:23 to 7:59",
        "Numbers 7:60 to 10:10",
        "Numbers 10:11 to 13:16",
        "Numbers 13:17 to 15:21",
        "Numbers 15:22 to 16:50",
        "Numbers 17-20",
        "Numbers 21-23",
        "Numbers 24:1 to 29:34",
        "Numbers 26:35 to 28:31",
        "Numbers 29:1 to 31:47",
        "Numbers 31:48 to 33:56",
        "Numbers 34-36 and Deuteronomy 1:1-15",
        "Deuteronomy 1:16 to 3:29",
        "Deuteronomy 4:1 to 6:15",
        "Deuteronomy 6:16 to 9:21",
        "Deuteronomy 9:22 to 12:32",
        "Deuteronomy 13:1 to 16:8",
        "Catch Up Day!",
      ],
      March: [
        "Deuteronomy 16:9 to 19:21",
        "Deuteronomy 20:1 to 23:14",
        "Deuteronomy 23:15 to 27:10",
        "Deuteronomy 27:11 to 28:68",
        "Deuteronomy 29:1 to 32:14",
        "Deuteronomy 32:15 to 34:12 and Joshua 1:1-9",
        "Joshua 1:10 to 4:24",
        "Joshua 5:1 to 8:23",
        "Joshua 8:24 to 11:9",
        "Joshua 11:10 to 14:15",
        "Joshua 15-17",
        "Joshua 18:1 to 21:12",
        "Joshua 21:13 to 23:16",
        "Joshua 24 and Judges 1-2",
        "Judges 3-5",
        "Judges 6-7",
        "Judges 8-9",
        "Judges 10-13",
        "Judges 14-16",
        "Judges 17:1 to 20:11",
        "Judges 20:12 to 21:25 and Ruth 1:1 to 2:13",
        "Ruth 2:14 to 4:22 and 1 Samuel 1",
        "1 Samuel 2-4",
        "1 Samuel 5:1 to 9:10",
        "1 Samuel 9:11 to 12:18",
        "1 Samuel 12:19 to 14:42",
        "1 Samuel 14:43 to 17:25",
        "1 Samuel 17:26 to 19:24",
        "1 Samuel 20-22",
        "1 Samuel 23:1 to 25:31",
        "1 Samuel 25:32 to 30:10",
      ],
      April: [
        "1 Samuel 30:11 to 1 Samuel 31:13 and 2 Samuel 1-2",
        "2 Samuel 3:1 to 6:11",
        "2 Samuel 6:12 to 10:19",
        "2 Samuel 11-13",
        "2 Samuel 14-16",
        "2 Samuel 17-19",
        "2 Samuel 20:1 to 22:34",
        "2 Samuel 22:35 to 24:17",
        "2 Samuel 24:18 to 25 and 1 Kings 1:1 to 2:18",
        "1 Kings 2:19 to 4:19",
        "1 Kings 4:20 to 7:39",
        "1 Kings 7:40 to 9:9",
        "1 Kings 9:10 to 11:25",
        "1 Kings 11:26 to 13:34",
        "1 Kings 14-17",
        "1 Kings 18:1 to 20:25",
        "1 Kings 20:26 to 22:36",
        "1 Kings 22:37-53 and 2 Kings 1:1 to 4:28",
        "2 Kings 4:29 to 8:15",
        "2 Kings 8:16 to 10:24",
        "2 Kings 10:25 to 14:10",
        "2 Kings 14:11 to 17:18",
        "2 Kings 17:19 to 19:24",
        "2 Kings 19:25 to 23:9",
        "2 Kings 23:10 to 25:30 and 1 Chronicles 1:1-16",
        "1 Chronicles 1:17 to 3:9",
        "1 Chronicles 3:10 to 6:30",
        "1 Chronicles 6:31 to 8:28",
        "1 Chronicles 8:29 to 11:21",
        "1 Chronicles 11:22 to 15:29",
      ],
      May: [
        "1 Chronicles 16:1 to 19:9",
        "1 Chronicles 19:10 to 23:11",
        "1 Chronicles 23:12 to 26:19",
        "1 Chronicles 26:20 to 29:19",
        "1 Chronicles 29:20-30 and 2 Chronicles 1:1 to 4:10",
        "2 Chronicles 4:11 to 7:22",
        "2 Chronicles 8:1 to 11:12",
        "2 Chronicles 11 to 13:15",
        "2 Chronicles 16:1 to 20:13",
        "2 Chronicles 20:14 to 24:14",
        "2 Chronicles 24:15 to 28:27",
        "2 Chronicles 29-31",
        "2 Chronicles 32:1 to 35:19",
        "2 Chronicles 35:20 to 36:23 and Ezra 1-3",
        "Ezra 4-7",
        "Ezra 8-10 and Nehemiah 1",
        "Nehemiah 2-5",
        "Nehemiah 6:1 to 8:8",
        "Nehemiah 8:9 to 11:21",
        "Nehemiah 11:22 to 13:22",
        "Nehemiah 13:23-31 and Esther 1-4",
        "Esther 5-10",
        "Job 1:1 to 5:16",
        "Job 5:17 to 8:22",
        "Job 9:1 to 12:12",
        "Job 12:13 to 16:10",
        "Job 16:11 to 20:11",
        "Job 20:12 to 24:12",
        "Job 24:13 to 29:13",
        "Job 29:14 to 32:10",
        "Job 32:11 to 35:16",
      ],
      June: [
        "Job 36:1 to 39:15",
        "Job 39:13 to 42:9",
        "Job 42:10-17 and Psalms 1:1-57",
        "Psalms 58-89",
        "Psalms 9-10",
        "Psalms 11:1 to 17:5",
        "Psalms 17:6 to 18:36",
        "Psalms 18:37 to 21:13",
        "Psalms 22:1 to 24:6",
        "Psalms 24:7 to 27:6",
        "Psalms 27:7 to 31:5",
        "Psalms 31:6 to 33:5",
        "Psalms 33:6 to 35:21",
        "Psalms 35:22 to 37:26",
        "Psalms 37:27 to 39:13",
        "Psalms 40-42",
        "Psalms 43:1 to 45:12",
        "Psalms 45:13 to 48:14",
        "Psalms 49:1 to 51:9",
        "Psalms 51:10 to 54:7",
        "Psalms 55:1 to 57:3",
        "Psalms 57:4 to 60:12",
        "Psalms 61-64",
        "Psalms 65:1 to 68:4",
        "Psalms 68:5 to 69:4",
        "Psalms 69:5 to 71:16",
        "Psalms 71:17 to 73:20",
        "Psalms 73:21 to 76:7",
        "Psalms 76:8 to 78:24",
        "Psalms 78:25-72",
      ],
      July: [
        "Psalms 79-82",
        "Psalms 83-86",
        "Psalms 87:1 to 89:37",
        "Psalms 89:38 to 91:13",
        "Psalms 91:14 to 94:16",
        "Psalms 94:17 to 98:3",
        "Psalms 98:4 to 102:7",
        "Psalms 102:8 to 104:4",
        "Psalms 104:5 to 105:24",
        "Psalms 105:25 to 106:33",
        "Psalms 106:34 to 107:38",
        "Psalms 107:39 to 109:31",
        "Psalms 110-113",
        "Psalms 114:1 to 118:9",
        "Psalms 118:10 to 119:40",
        "Psalms 119:41-96",
        "Psalms 119:97-160",
        "Psalms 119:161 to 124:8",
        "Psalms 125-131",
        "Psalms 132:1 to 135:14",
        "Psalms 135:15 to 138:3",
        "Psalms 138:4 to 140:13",
        "Psalms 141:1 to 145:7",
        "Psalms 145:8 to 148:6",
        "Psalms 148:7 to 150:6 and Proverbs 1:1 to 2:9",
        "Proverbs 2:10 to 5:14",
        "Proverbs 5:15 to 8:11",
        "Proverbs 8:12 to 11:11",
        "Proverbs 11:12 to 13:25",
        "Proverbs 14-16",
        "Proverbs 17-20",
      ],
      August: [
        "Proverbs 21-23",
        "Proverbs 24:1 to 27:10",
        "Proverbs 27:11 to 30:33",
        "Proverbs 31 and Ecclesiastes 1:1 to 3:8",
        "Ecclesiastes 3:9 to 8:17",
        "Ecclesiastes 9-12 and Song of Solomon 1-2",
        "Song of Solomon 3-8 and Isaiah 1:1-9",
        "Isaiah 1:10 to 5:17",
        "Isaiah 5:18 to 9:12",
        "Isaiah 9:13 to 13:16",
        "Isaiah 13:17 to 19:10",
        "Isaiah 19:11 to 24:6",
        "Isaiah 24:7 to 28:22",
        "Isaiah 28:23 to 32:20",
        "Isaiah 33:1 to 37:29",
        "Isaiah 37:30 to 40:31",
        "Isaiah 41-44",
        "Isaiah 45-49",
        "Isaiah 50-54",
        "Isaiah 55:1 to 60:9",
        "Isaiah 60:10 to 65:25",
        "Isaiah 66 and Jeremiah 1:1 to 2:25",
        "Jeremiah 2:26 to 5:19",
        "Jeremiah 5:20 to 8:22",
        "Jeremiah 9-12",
        "Jeremiah 13:1 to 16:9",
        "Jeremiah 16:10 to 20:18",
        "Jeremiah 21-24",
        "Jeremiah 25-27",
        "Jeremiah 28:1 to 31:20",
        "Jeremiah 31:21 to 33:26",
      ],
      September: [
        "Jeremiah 34-36",
        "Jeremiah 37:1 to 41:10",
        "Jeremiah 41:11 to 45:5",
        "Jeremiah 46-48",
        "Jeremiah 49:1 to 51:10",
        "Jeremiah 51:11 to 52:34",
        "Lamentations 1:1 to 3:51",
        "Lamentations 3:52 to 5:22 and Ezekiel 1-2",
        "Ezekiel 3-8",
        "Ezekiel 9-12",
        "Ezekiel 13:1 to 16:43",
        "Ezekiel 16:44 to 20:17",
        "Ezekiel 20:18 to 22:12",
        "Ezekiel 22:13 to 24:27",
        "Ezekiel 25:1 to 28:10",
        "Ezekiel 28:11 to 31:18",
        "Ezekiel 32:1 to 34:24",
        "Ezekiel 34:25 to 38:9",
        "Ezekiel 38:10 to 40:49",
        "Ezekiel 41-43",
        "Ezekiel 44-47",
        "Ezekiel 48 and Daniel 1:1 to 2:30",
        "Daniel 2:31 to 4:27",
        "Daniel 4:28 to 7:12",
        "Daniel 7:13 to 11:13",
        "Daniel 11:14 to 12:13 and Hosea 1-3",
        "Hosea 4-9",
        "Hosea 10-14 and Joel 1:1 to 2:17",
        "Joel 2:18 to 3:21 and Amos 1-4",
        "Amos 5-9 and Obadiah 1:1-9",
      ],
      October: [
        "Obadiah 1:10 to 21, Jonah, and Micah 1-3",
        "Micah 4-7 and Nahum 1-2",
        "Nahum 3, Habakkuk, and Zephaniah 1:1-13",
        "Zephaniah 1:14 to 3:20, Haggai, and Zechariah 1:1-11",
        "Zechariah 1:12 to 7:14",
        "Zechariah 8-13",
        "Zechariah 14 and Malachi 1-4",
        "Matthew 1-4",
        "Matthew 5-7",
        "Matthew 8:1 to 10:15",
        "Matthew 10:16 to 12:21",
        "Matthew 12:22 to 14:12",
        "Matthew 14:13 to 17:13",
        "Matthew 17:14 to 20:34",
        "Matthew 21-22",
        "Matthew 23:1 to 25:13",
        "Matthew 25:14 to 26:68",
        "Matthew 26:69 to 28:20",
        "Mark 1-3",
        "Mark 4-5",
        "Mark 6-7",
        "Mark 8:1 to 10:12",
        "Mark 10:13 to 12:12",
        "Mark 12:13 to 14:11",
        "Mark 14:12 to 15:47",
        "Mark 16, Luke 1",
        "Luke 2-3",
        "Luke 4:1 to 6:26",
        "Luke 6:27 to 8:25",
        "Luke 8:26 to 10:16",
        "Luke 10:17 to 12:12",
      ],
      November: [
        "Luke 12:13 to 14:11",
        "Luke 14:12 to 16:31",
        "Luke 17:1 to 19:27",
        "Luke 19:28 to 21:9",
        "Luke 21:10 to 22:46",
        "Luke 22:47 to 24:23",
        "Luke 24:24 to 53 and John 1:1 to 2:11",
        "John 2:12 to 4:38",
        "John 4:39 to 6:51",
        "John 6:52 to 8:20",
        "John 8:21 to 10:18",
        "John 10:19 to 12:11",
        "John 12:12 to 14:11",
        "John 14:12 to 17:13",
        "John 17:14 to 19:42",
        "John 20-21 and Acts 1:1 to 2:13",
        "Acts 2:14 to 4:37",
        "Acts 5:1 to 7:29",
        "Acts 7:30 to 9:22",
        "Acts 9:23 to 11:30",
        "Acts 12-13",
        "Acts 14:1 to 16:10",
        "Acts 16:11 to 18:28",
        "Acts 19-20",
        "Acts 21:1 to 23:25",
        "Acts 23:26 to 27:8",
        "Acts 27:9 to 28:31 and Romans 1:1 to 15",
        "Romans 1:16 to 5:11",
        "Romans 5:12 to 8:25",
        "Romans 8:26 to 11:24",
      ],
      December: [
        "Romans 11:25 to 15:33",
        "Romans 16 and 1 Corinthians 1:1 to 4:13",
        "1 Corinthians 4:14 to 7:40",
        "1 Corinthians 8:1 to 12:11",
        "1 Corinthians 12:12 to 15:28",
        "1 Corinthians 15:29 to 16:24 and 2 Corinthians 1-2",
        "2 Corinthians 3-7",
        "2 Corinthians 8-12",
        "2 Corinthians 13 and Galatians 1:1 to 4:11",
        "Galatians 4:12 to 6:18 and Ephesians 1:1-14",
        "Ephesians 1:15 to 4:32",
        "Ephesians 5-6 and Philippians 1-2",
        "Philippians 3-4 and Colossians 1",
        "Colossians 2-4 and 1 Thessalonians 1:1 to 2:12",
        "1 Thessalonians 2:13 to 5:28 and 2 Thessalonians 1-3",
        "1 Timothy 1-5",
        "1 Timothy 6 and 2 Timothy 1-4",
        "Titus 1-3, Philemon, and Hebrews 1:1 to 2:10",
        "Hebrews 2:11 to 6:20",
        "Hebrews 7-10",
        "Hebrews 11-13",
        "James 1:1 to 4:10",
        "James 4:11 to 5:20 and 1 Peter 1:1 to 3:12",
        "1 Peter 3:13 to 5:14 and 2 Peter 1-2",
        "2 Peter 3 and 1 John 1:1 to 3:12",
        "1 John 3:13 to 5:21, 2 John, and 3 John",
        "Jude and Revelation 1-4",
        "Revelation 5:1 to 9:11",
        "Revelation 9:12 to 14:8",
        "Revelation 14:9 to 18:24",
        "Revelation 19-22",
      ],
    },
    ro: {
      January: [
        "Geneza 1-3",
        "Geneza 4-7",
        "Geneza 8 până la 11:9",
        "Geneza 11:10 până la 14:13",
        "Geneza 14:14 până la 18:8",
        "Geneza 18:9 până la 21:21",
        "Geneza 21:22 până la 24:27",
        "Geneza 24:28 până la 26:35",
        "Geneza 27-29",
        "Geneza 30:1 până la 31:42",
        "Geneza 31:43 până la 34:31",
        "Geneza 35:1 până la 37:24",
        "Geneza 37:25 până la 40:8",
        "Geneza 40:9 până la 42:28",
        "Geneza 42:29 până la 45:15",
        "Geneza 45:16 până la 48:7",
        "Geneza 48:8 până la 50:26 și Exodul 1",
        "Exodul 2:1 până la 5:9",
        "Exodul 5:10 până la 8:15",
        "Exodul 8:16 până la 11:10",
        "Exodul 12:1 până la 14:20",
        "Exodul 14:21 până la 17:16",
        "Exodul 18:1 până la 21:21",
        "Exodul 21:22 până la 25:9",
        "Exodul 25:10 până la 27:21",
        "Exodul 28 până la 29",
        "Exodul 30-32",
        "Exodul 33:1 până la 35:29",
        "Exodul 35:30 până la 37:29",
        "Exodul 38:1 până la 40:16",
        "Exodul 40:17-38 și Leviticul 1-4",
      ],
      February: [
        "Leviticul 5-7",
        "Leviticul 8:1 până la 11:8",
        "Leviticul 11:9 până la 13:39",
        "Leviticul 13:40 până la 14:57",
        "Leviticul 15:1 până la 18:18",
        "Leviticul 18:19 până la 21:24",
        "Leviticul 22-23",
        "Leviticul 24:1 până la 26:13",
        "Leviticul 26:14 până la 27:34 și Numerii 1:1-41",
        "Numerii 1:42 până la 3:32",
        "Numerii 3:33 până la 5:22",
        "Numerii 5:23 până la 7:59",
        "Numerii 7:60 până la 10:10",
        "Numerii 10:11 până la 13:16",
        "Numerii 13:17 până la 15:21",
        "Numerii 15:22 până la 16:50",
        "Numerii 17-20",
        "Numerii 21-23",
        "Numerii 24:1 până la 29:34",
        "Numerii 26:35 până la 28:31",
        "Numerii 29:1 până la 31:47",
        "Numerii 31:48 până la 33:56",
        "Numerii 34-36 și Deuteronomul 1:1-15",
        "Deuteronomul 1:16 până la 3:29",
        "Deuteronomul 4:1 până la 6:15",
        "Deuteronomul 6:16 până la 9:21",
        "Deuteronomul 9:22 până la 12:32",
        "Deuteronomul 13:1 până la 16:8",
        "Zi de recuperare a citirii",
      ],
      March: [
        "Deuteronomul 16:9 până la 19:21",
        "Deuteronomul 20:1 până la 23:14",
        "Deuteronomul 23:15 până la 27:10",
        "Deuteronomul 27:11 până la 28:68",
        "Deuteronomul 29:1 până la 32:14",
        "Deuteronomul 32:15 până la 34:12 și Iosua 1:1-9",
        "Iosua 1:10 până la 4:24",
        "Iosua 5:1 până la 8:23",
        "Iosua 8:24 până la 11:9",
        "Iosua 11:10 până la 14:15",
        "Iosua 15-17",
        "Iosua 18:1 până la 21:12",
        "Iosua 21:13 până la 23:16",
        "Iosua 24 și Judecătorii 1-2",
        "Judecătorii 3-5",
        "Judecătorii 6-7",
        "Judecătorii 8-9",
        "Judecătorii 10-13",
        "Judecătorii 14-16",
        "Judecătorii 17:1 până la 20:11",
        "Judecătorii 20:12 până la 21:25 și Rut 1:1 până la 2:13",
        "Rut 2:14 până la 4:22 și 1 Samuel 1",
        "1 Samuel 2-4",
        "1 Samuel 5:1 până la 9:10",
        "1 Samuel 9:11 până la 12:18",
        "1 Samuel 12:19 până la 14:42",
        "1 Samuel 14:43 până la 17:25",
        "1 Samuel 17:26 până la 19:24",
        "1 Samuel 20-22",
        "1 Samuel 23:1 până la 25:31",
        "1 Samuel 25:32 până la 30:10",
      ],
      April: [
        "1 Samuel 30:11 până la 1 Samuel 31:13 și 2 Samuel 1-2",
        "2 Samuel 3:1 până la 6:11",
        "2 Samuel 6:12 până la 10:19",
        "2 Samuel 11-13",
        "2 Samuel 14-16",
        "2 Samuel 17-19",
        "2 Samuel 20:1 până la 22:34",
        "2 Samuel 22:35 până la 24:17",
        "2 Samuel 24:18 până la 25 și 1 Împărați 1:1 până la 2:18",
        "1 Împărați 2:19 până la 4:19",
        "1 Împărați 4:20 până la 7:39",
        "1 Împărați 7:40 până la 9:9",
        "1 Împărați 9:10 până la 11:25",
        "1 Împărați 11:26 până la 13:34",
        "1 Împărați 14-17",
        "1 Împărați 18:1 până la 20:25",
        "1 Împărați 20:26 până la 22:36",
        "1 Împărați 22:37-53 și 2 Împărați 1:1 până la 4:28",
        "2 Împărați 4:29 până la 8:15",
        "2 Împărați 8:16 până la 10:24",
        "2 Împărați 10:25 până la 14:10",
        "2 Împărați 14:11 până la 17:18",
        "2 Împărați 17:19 până la 19:24",
        "2 Împărați 19:25 până la 23:9",
        "2 Împărați 23:10 până la 25:30 și 1 Cronici 1:1-16",
        "1 Cronici 1:17 până la 3:9",
        "1 Cronici 3:10 până la 6:30",
        "1 Cronici 6:31 până la 8:28",
        "1 Cronici 8:29 până la 11:21",
        "1 Cronici 11:22 până la 15:29",
      ],
      May: [
        "1 Cronici 16:1 până la 19:9",
        "1 Cronici 19:10 până la 23:11",
        "1 Cronici 23:12 până la 26:19",
        "1 Cronici 26:20 până la 29:19",
        "1 Cronici 29:20-30 și 2 Cronici 1:1 până la 4:10",
        "2 Cronici 4:11 până la 7:22",
        "2 Cronici 8:1 până la 11:12",
        "2 Cronici 11 până la 13:15",
        "2 Cronici 16:1 până la 20:13",
        "2 Cronici 20:14 până la 24:14",
        "2 Cronici 24:15 până la 28:27",
        "2 Cronici 29-31",
        "2 Cronici 32:1 până la 35:19",
        "2 Cronici 35:20 până la 36:23 și Ezra 1-3",
        "Ezra 4-7",
        "Ezra 8-10 și Neemia 1",
        "Neemia 2-5",
        "Neemia 6:1 până la 8:8",
        "Neemia 8:9 până la 11:21",
        "Neemia 11:22 până la 13:22",
        "Neemia 13:23-31 și Estera 1-4",
        "Estera 5-10",
        "Iov 1:1 până la 5:16",
        "Iov 5:17 până la 8:22",
        "Iov 9:1 până la 12:12",
        "Iov 12:13 până la 16:10",
        "Iov 16:11 până la 20:11",
        "Iov 20:12 până la 24:12",
        "Iov 24:13 până la 29:13",
        "Iov 29:14 până la 32:10",
        "Iov 32:11 până la 35:16",
      ],
      June: [
        "Iov 36:1 până la 39:15",
        "Iov 39:13 până la 42:9",
        "Iov 42:10-17 și Psalmii 1:1-57",
        "Psalmii 58-89",
        "Psalmii 9-10",
        "Psalmii 11:1 până la 17:5",
        "Psalmii 17:6 to 18:36",
        "Psalmii 18:37 până la 21:13",
        "Psalmii 22:1 până la 24:6",
        "Psalmii 24:7 până la 27:6",
        "Psalmii 27:7 până la 31:5",
        "Psalmii 31:6 până la 33:5",
        "Psalmii 33:6 până la 35:21",
        "Psalmii 35:22 până la 37:26",
        "Psalmii 37:27 până la 39:13",
        "Psalmii 40-42",
        "Psalmii 43:1 până la 45:12",
        "Psalmii 45:13 până la 48:14",
        "Psalmii 49:1 până la 51:9",
        "Psalmii 51:10 până la 54:7",
        "Psalmii 55:1 până la 57:3",
        "Psalmii 57:4 până la 60:12",
        "Psalmii 61-64",
        "Psalmii 65:1 până la 68:4",
        "Psalmii 68:5 până la 69:4",
        "Psalmii 69:5 până la 71:16",
        "Psalmii 71:17 până la 73:20",
        "Psalmii 73:21 până la 76:7",
        "Psalmii 76:8 până la 78:24",
        "Psalmii 78:25-72",
      ],
      July: [
        "Psalmii 79-82",
        "Psalmii 83-86",
        "Psalmii 87:1 până la 89:37",
        "Psalmii 89:38 până la 91:13",
        "Psalmii 91:14 până la 94:16",
        "Psalmii 94:17 până la 98:3",
        "Psalmii 98:4 până la 102:7",
        "Psalmii 102:8 până la 104:4",
        "Psalmii 104:5 până la 105:24",
        "Psalmii 105:25 până la 106:33",
        "Psalmii 106:34 până la 107:38",
        "Psalmii 107:39 până la 109:31",
        "Psalmii 110-113",
        "Psalmii 114:1 până la 118:9",
        "Psalmii 118:10 până la 119:40",
        "Psalmii 119:41-96",
        "Psalmii 119:97-160",
        "Psalmii 119:161 până la 124:8",
        "Psalmii 125-131",
        "Psalmii 132:1 până la 135:14",
        "Psalmii 135:15 până la 138:3",
        "Psalmii 138:4 până la 140:13",
        "Psalmii 141:1 până la 145:7",
        "Psalmii 145:8 până la 148:6",
        "Psalmii 148:7 până la 150:6 și Proverbele 1:1 până la 2:9",
        "Proverbele 2:10 până la 5:14",
        "Proverbele 5:15 până la 8:11",
        "Proverbele 8:12 până la 11:11",
        "Proverbele 11:12 până la 13:25",
        "Proverbele 14-16",
        "Proverbele 17-20",
      ],
      August: [
        "Proverbele 21-23",
        "Proverbele 24:1 până la 27:10",
        "Proverbele 27:11 până la 30:33",
        "Proverbele 31 și Eclesiastul 1:1 până la 3:8",
        "Eclesiastul 3:9 până la 8:17",
        "Eclesiastul 9-12 și Cântarea Cântărilor 1-2",
        "Cântarea Cântărilor 3-8 și Isaia 1:1-9",
        "Isaia 1:10 până la 5:17",
        "Isaia 5:18 până la 9:12",
        "Isaia 9:13 până la 13:16",
        "Isaia 13:17 până la 19:10",
        "Isaia 19:11 până la 24:6",
        "Isaia 24:7 până la 28:22",
        "Isaia 28:23 până la 32:20",
        "Isaia 33:1 până la 37:29",
        "Isaia 37:30 până la 40:31",
        "Isaia 41-44",
        "Isaia 45-49",
        "Isaia 50-54",
        "Isaia 55:1 până la 60:9",
        "Isaia 60:10 până la 65:25",
        "Isaia 66, Ieremia 1:1 până la 2:25",
        "Ieremia 2:26 până la 5:19",
        "Ieremia 5:20 până la 8:22",
        "Ieremia 9-12",
        "Ieremia 13:1 până la 16:9",
        "Ieremia 16:10 până la 20:18",
        "Ieremia 21-24",
        "Ieremia 25-27",
        "Ieremia 28:1 până la 31:20",
        "Ieremia 31:21 până la 33:26",
      ],
      September: [
        "Ieremia 34-36",
        "Ieremia 37:1 până la 41:10",
        "Ieremia 41:11 până la 45:5",
        "Ieremia 46-48",
        "Ieremia 49:1 până la 51:10",
        "Ieremia 51:11 până la 52:34",
        "Plângerile lui Ieremia 1:1 până la 3:51",
        "Plângerile lui Ieremia 3:52 până la 5:22 și Ezechiel 1-2",
        "Ezechiel 3-8",
        "Ezechiel 9-12",
        "Ezechiel 13:1 până la 16:43",
        "Ezechiel 16:44 până la 20:17",
        "Ezechiel 20:18 până la 22:12",
        "Ezechiel 22:13 până la 24:27",
        "Ezechiel 25:1 până la 28:10",
        "Ezechiel 28:11 până la 31:18",
        "Ezechiel 32:1 până la 34:24",
        "Ezechiel 34:25 până la 38:9",
        "Ezechiel 38:10 până la 40:49",
        "Ezechiel 41-43",
        "Ezechiel 44-47",
        "Ezechiel 48 și Daniel 1:1 până la 2:30",
        "Daniel 2:31 până la 4:27",
        "Daniel 4:28 până la 7:12",
        "Daniel 7:13 până la 11:13",
        "Daniel 11:14 până la 12:13 și Osea 1-3",
        "Osea 4-9",
        "Osea 10-14 și Ioel 1:1 până la 2:17",
        "Ioel 2:18 până la 3:21, Amos 1-4",
        "Amos 5-9 și Obadia 1:1-9",
      ],
      October: [
        "Obadia 1:10 până la 21, Iona, și Mica 1-3",
        "Mica 4-7 și Naum 1-2",
        "Naum 3, Habacuc, și Sofonia 1:1-13",
        "Sofonia 1:14 până la 3:20, Hagai, și Zaharia 1:1-11",
        "Zaharia 1:12 până la 7:14",
        "Zaharia 8-13",
        "Zaharia 14 și Maleahi 1-4",
        "Matei 1-4",
        "Matei 5-7",
        "Matei 8:1 până la 10:15",
        "Matei 10:16 până la 12:21",
        "Matei 12:22 până la 14:12",
        "Matei 14:13 până la 17:13",
        "Matei 17:14 până la 20:34",
        "Matei 21-22",
        "Matei 23:1 până la 25:13",
        "Matei 25:14 până la 26:68",
        "Matei 26:69 până la 28:20",
        "Marcu 1-3",
        "Marcu 4-5",
        "Marcu 6-7",
        "Marcu 8:1 până la 10:12",
        "Marcu 10:13 până la 12:12",
        "Marcu 12:13 până la 14:11",
        "Marcu 14:12 până la 15:47",
        "Marcu 16 și Luca 1",
        "Luca 2-3",
        "Luca 4:1 până la 6:26",
        "Luca 6:27 până la 8:25",
        "Luca 8:26 până la 10:16",
        "Luca 10:17 până la 12:12",
      ],
      November: [
        "Luca 12:13 până la 14:11",
        "Luca 14:12 până la 16:31",
        "Luca 17:1 până la 19:27",
        "Luca 19:28 până la 21:9",
        "Luca 21:10 până la 22:46",
        "Luca 22:47 până la 24:23",
        "Luca 24:24 până la 53 și Ioan 1:1 până la 2:11",
        "Ioan 2:12 până la 4:38",
        "Ioan 4:39 până la 6:51",
        "Ioan 6:52 până la 8:20",
        "Ioan 8:21 până la 10:18",
        "Ioan 10:19 până la 12:11",
        "Ioan 12:12 până la 14:11",
        "Ioan 14:12 până la 17:13",
        "Ioan 17:14 până la 19:42",
        "Ioan 20-21 și Faptele Apostolilor 1:1 până la 2:13",
        "Faptele Apostolilor 2:14 până la 4:37",
        "Faptele Apostolilor 5:1 până la 7:29",
        "Faptele Apostolilor 7:30 până la 9:22",
        "Faptele Apostolilor 9:23 până la 11:30",
        "Faptele Apostolilor 12-13",
        "Faptele Apostolilor 14:1 până la 16:10",
        "Faptele Apostolilor 16:11 până la 18:28",
        "Faptele Apostolilor 19-20",
        "Faptele Apostolilor 21:1 până la 23:25",
        "Faptele Apostolilor 23:26 până la 27:8",
        "Faptele Apostolilor 27:9 până la 28:31 și Romani 1:1-15",
        "Romani 1:16 până la 5:11",
        "Romani 5:12 până la 8:25",
        "Romani 8:26 până la 11:24",
      ],
      December: [
        "Romani 11:25 până la 15:33",
        "Romani 16 și 1 Corinteni 1:1 până la 4:13",
        "1 Corinteni 4:14 până la 7:40",
        "1 Corinteni 8:1 până la 12:11",
        "1 Corinteni 12:12 până la 15:28",
        "1 Corinteni 15:29 până la 16:24 și 2 Corinteni 1-2",
        "2 Corinteni 3-7",
        "2 Corinteni 8-12",
        "2 Corinteni 13 și Galateni 1:1 până la 4:11",
        "Galateni 4:12 până la 6:18 și Efeseni 1:1-14",
        "Efeseni 1:15 până la 4:32",
        "Efeseni 5-6 și Filipeni 1-2",
        "Filipeni 3-4 și Coloseni 1",
        "Coloseni 2-4 și 1 Tesaloniceni 1:1 până la 2:12",
        "1 Tesaloniceni 2:13 până la 5:28 și 2 Tesaloniceni 1-3",
        "1 Timotei 1-5",
        "1 Timotei 6 și 2 Timotei 1-4",
        "Tit 1-3, Filimon, și Evrei 1:1 până la 2:10",
        "Evrei 2:11 până la 6:20",
        "Evrei 7-10",
        "Evrei 11-13",
        "Iacov 1:1 până la 4:10",
        "Iacov 4:11 până la 5:20 și 1 Petru 1:1 până la 3:12",
        "1 Petru 3:13 până la 5:14 și 2 Petru 1-2",
        "2 Petru 3 și 1 Ioan 1:1 până la 3:12",
        "1 Ioan 3:13 până la 5:21, 2 Ioan, și 3 Ioan",
        "Iuda și Apocalipsa 1-4",
        "Apocalipsa 5:1 până la 9:11",
        "Apocalipsa 9:12 până la 14:8",
        "Apocalipsa 14:9 până la 18:24",
        "Apocalipsa 19-22",
      ],
    },
  };

  const renderMonth = (month, days) => {
    const book = getBookForMonth(month, language === RO ? RO : EN);

    return (
      <div>
        <Typography style={monthHeaderStyle}>{month}</Typography>
        {days.map((day, index) => {
          const isToday = getDateStyle(day) === todayDateStyle;
          return (
            <Typography
              key={index}
              variant="h6"
              style={{ ...dayPassageStyle, ...getDateStyle(day) }}
              ref={isToday ? currentDateRef : null}
            >
              <span style={dayStyle}>{day} - </span>
              {biblePassages[language === RO ? RO : EN][book][index]}
            </Typography>
          );
        })}
      </div>
    );
  };

  return (
    <div style={mainStyle}>
      <Typography
        variant="h1"
        sx={{
          fontSize: {
            xs: "3rem", // small devices
            sm: "3rem", // medium devices
            md: "4rem", // large devices
            lg: "4rem", // extra-large devices
            xl: "5rem", // extra-extra-large devices
          },
          marginBottom: "10px",
        }}
      >
        {language === EN ? "Bible Reading Plan" : "Plan de Citire a Bibliei"}
      </Typography>

      <Divider light width="100%" sx={{ marginBottom: "15px" }} />
      <Typography style={introNonParagraphStyle}>
        {language === EN
          ? "Dear Brothers and Sisters,"
          : "Dragi frați și surori,"}
      </Typography>

      <div style={introParagraphStyle}>
        <Typography>
          {language === EN
            ? "As we begin to read Holy Scripture, that is God's own inerrant Word, let us member the authority of Scripture is a key issue for all Christians in this and every age. Those who profess faith in Jesus Christ as Lord and Savior are called to show the reality of their discipleship by humbly and faithfully obeying God's written Word. To stray from Scripture in faith and practice is disloyalty to our Master Jesus Christ."
            : "Pe măsură ce începem să citim Sfânta Scriptură, care este Cuvântul infailibil al lui Dumnezeu, să ne amintim că autoritatea Scripturii este o problemă cheie pentru toți creștinii în această epocă și în toate celelalte. Cei care mărturisesc credința în Isus Hristos ca Domn și Mântuitor sunt chemați să arate realitatea discipolatului lor prin ascultarea umilă și credincioasă a Cuvântului scris al lui Dumnezeu. A ne abate de la Scriptură în credință și practică este o neloialitate față de Stăpânul nostru Isus Hristos."}
        </Typography>
      </div>

      <div style={introParagraphStyle}>
        <Typography>
          {language === EN
            ? "Let us remember that God, who is Himself Truth and speaks truth only, has inspired Holy Scripture in order thereby to reveal Himself to lost mankind through Jesus Christ as Creator and Lord, Redeemer, and ludge. Holy Scripture is God's witness to Himself."
            : "Să ne amintim că Dumnezeu, care este Însuși Adevărul și vorbește doar adevărul, a inspirat Sfânta Scriptură pentru a Se revela astfel omenirii pierdute prin Isus Hristos ca Creator și Domn, Răscumpărător și Judecător. Sfânta Scriptură este mărturia lui Dumnezeu despre Sine Însuși."}
        </Typography>
      </div>

      <div style={introParagraphStyle}>
        <Typography>
          {language === EN
            ? "Scripture is without error or fault in all its teaching, no less in what it states about God's acts in creation, about the events of world history, and about its own literary origins under God, than in its witness to God's saving grace in individual lives."
            : "Scriptura este fără eroare sau cusur în toate învățăturile sale, nu mai puțin în ceea ce afirmă despre actele lui Dumnezeu în creație, despre evenimentele istoriei lumii și despre propriile sale origini literare sub Dumnezeu, decât în mărturia sa despre harul salvator al lui Dumnezeu în viețile individuale."}
        </Typography>
      </div>

      <div style={introParagraphStyle}>
        <Typography>
          {language === EN
            ? "I look forward to reading God's Holy Word together; word by word, verse by verse, chapter by chapter, and book by book. As we read God's Word together my prayer is that the Holy Spirit authenticate Scripture to us by His inward witness, open our minds to understand its meaning, and move our wills to obey its commands, thus sanctifying our lives for God's glory and our good."
            : "Aștept cu nerăbdare să citim împreună Cuvântul Sfânt al lui Dumnezeu; cuvânt cu cuvânt, verset cu verset, capitol cu capitol și carte cu carte. Pe măsură ce citim Cuvântul lui Dumnezeu împreună, rugăciunea mea este ca Duhul Sfânt să autentifice Scriptura pentru noi prin mărturia Sa interioară, să ne deschidă mințile pentru a înțelege semnificația ei și să miște voințele noastre pentru a asculta poruncile Sale, sfințind astfel viețile noastre pentru gloria lui Dumnezeu și binele nostru."}
        </Typography>
      </div>

      <Typography style={{ ...introNonParagraphStyle, marginBottom: "0px" }}>
        Leonard Semenea
      </Typography>

      <Typography style={{ ...introNonParagraphStyle, marginBottom: "0px" }}>
        {language === EN ? "Senior Pastor" : "Pastor Senior"}
      </Typography>

      <Typography style={{ ...introNonParagraphStyle, fontSize: "12px" }}>
        {language === EN
          ? "[Adapted from the Chicago Statement on Biblical Inerrancy]"
          : "[Adaptat de la Declarația de la Chicago despre Inerabilitatea Biblică]"}
      </Typography>
      <Divider light width="100%" />

      {/* January with all 31 days */}
      {renderMonth("January", [
        "January 1",
        "January 2",
        "January 3",
        "January 4",
        "January 5",
        "January 6",
        "January 7",
        "January 8",
        "January 9",
        "January 10",
        "January 11",
        "January 12",
        "January 13",
        "January 14",
        "January 15",
        "January 16",
        "January 17",
        "January 18",
        "January 19",
        "January 20",
        "January 21",
        "January 22",
        "January 23",
        "January 24",
        "January 25",
        "January 26",
        "January 27",
        "January 28",
        "January 29",
        "January 30",
        "January 31",
      ])}

      {renderMonth("February", [
        "February 1",
        "February 2",
        "February 3",
        "February 4",
        "February 5",
        "February 6",
        "February 7",
        "February 8",
        "February 9",
        "February 10",
        "February 11",
        "February 12",
        "February 13",
        "February 14",
        "February 15",
        "February 16",
        "February 17",
        "February 18",
        "February 19",
        "February 20",
        "February 21",
        "February 22",
        "February 23",
        "February 24",
        "February 25",
        "February 26",
        "February 27",
        "February 28",
        "February 29",
      ])}

      {renderMonth("March", [
        "March 1",
        "March 2",
        "March 3",
        "March 4",
        "March 5",
        "March 6",
        "March 7",
        "March 8",
        "March 9",
        "March 10",
        "March 11",
        "March 12",
        "March 13",
        "March 14",
        "March 15",
        "March 16",
        "March 17",
        "March 18",
        "March 19",
        "March 20",
        "March 21",
        "March 22",
        "March 23",
        "March 24",
        "March 25",
        "March 26",
        "March 27",
        "March 28",
        "March 29",
        "March 30",
        "March 31",
      ])}

      {renderMonth("April", [
        "April 1",
        "April 2",
        "April 3",
        "April 4",
        "April 5",
        "April 6",
        "April 7",
        "April 8",
        "April 9",
        "April 10",
        "April 11",
        "April 12",
        "April 13",
        "April 14",
        "April 15",
        "April 16",
        "April 17",
        "April 18",
        "April 19",
        "April 20",
        "April 21",
        "April 22",
        "April 23",
        "April 24",
        "April 25",
        "April 26",
        "April 27",
        "April 28",
        "April 29",
        "April 30",
      ])}

      {renderMonth("May", [
        "May 1",
        "May 2",
        "May 3",
        "May 4",
        "May 5",
        "May 6",
        "May 7",
        "May 8",
        "May 9",
        "May 10",
        "May 11",
        "May 12",
        "May 13",
        "May 14",
        "May 15",
        "May 16",
        "May 17",
        "May 18",
        "May 19",
        "May 20",
        "May 21",
        "May 22",
        "May 23",
        "May 24",
        "May 25",
        "May 26",
        "May 27",
        "May 28",
        "May 29",
        "May 30",
        "May 31",
      ])}

      {renderMonth("June", [
        "June 1",
        "June 2",
        "June 3",
        "June 4",
        "June 5",
        "June 6",
        "June 7",
        "June 8",
        "June 9",
        "June 10",
        "June 11",
        "June 12",
        "June 13",
        "June 14",
        "June 15",
        "June 16",
        "June 17",
        "June 18",
        "June 19",
        "June 20",
        "June 21",
        "June 22",
        "June 23",
        "June 24",
        "June 25",
        "June 26",
        "June 27",
        "June 28",
        "June 29",
        "June 30",
      ])}

      {renderMonth("July", [
        "July 1",
        "July 2",
        "July 3",
        "July 4",
        "July 5",
        "July 6",
        "July 7",
        "July 8",
        "July 9",
        "July 10",
        "July 11",
        "July 12",
        "July 13",
        "July 14",
        "July 15",
        "July 16",
        "July 17",
        "July 18",
        "July 19",
        "July 20",
        "July 21",
        "July 22",
        "July 23",
        "July 24",
        "July 25",
        "July 26",
        "July 27",
        "July 28",
        "July 29",
        "July 30",
        "July 31",
      ])}

      {renderMonth("August", [
        "August 1",
        "August 2",
        "August 3",
        "August 4",
        "August 5",
        "August 6",
        "August 7",
        "August 8",
        "August 9",
        "August 10",
        "August 11",
        "August 12",
        "August 13",
        "August 14",
        "August 15",
        "August 16",
        "August 17",
        "August 18",
        "August 19",
        "August 20",
        "August 21",
        "August 22",
        "August 23",
        "August 24",
        "August 25",
        "August 26",
        "August 27",
        "August 28",
        "August 29",
        "August 30",
        "August 31",
      ])}

      {renderMonth("September", [
        "September 1",
        "September 2",
        "September 3",
        "September 4",
        "September 5",
        "September 6",
        "September 7",
        "September 8",
        "September 9",
        "September 10",
        "September 11",
        "September 12",
        "September 13",
        "September 14",
        "September 15",
        "September 16",
        "September 17",
        "September 18",
        "September 19",
        "September 20",
        "September 21",
        "September 22",
        "September 23",
        "September 24",
        "September 25",
        "September 26",
        "September 27",
        "September 28",
        "September 29",
        "September 30",
      ])}

      {renderMonth("October", [
        "October 1",
        "October 2",
        "October 3",
        "October 4",
        "October 5",
        "October 6",
        "October 7",
        "October 8",
        "October 9",
        "October 10",
        "October 11",
        "October 12",
        "October 13",
        "October 14",
        "October 15",
        "October 16",
        "October 17",
        "October 18",
        "October 19",
        "October 20",
        "October 21",
        "October 22",
        "October 23",
        "October 24",
        "October 25",
        "October 26",
        "October 27",
        "October 28",
        "October 29",
        "October 30",
        "October 31",
      ])}

      {renderMonth("November", [
        "November 1",
        "November 2",
        "November 3",
        "November 4",
        "November 5",
        "November 6",
        "November 7",
        "November 8",
        "November 9",
        "November 10",
        "November 11",
        "November 12",
        "November 13",
        "November 14",
        "November 15",
        "November 16",
        "November 17",
        "November 18",
        "November 19",
        "November 20",
        "November 21",
        "November 22",
        "November 23",
        "November 24",
        "November 25",
        "November 26",
        "November 27",
        "November 28",
        "November 29",
        "November 30",
      ])}

      {renderMonth("December", [
        "December 1",
        "December 2",
        "December 3",
        "December 4",
        "December 5",
        "December 6",
        "December 7",
        "December 8",
        "December 9",
        "December 10",
        "December 11",
        "December 12",
        "December 13",
        "December 14",
        "December 15",
        "December 16",
        "December 17",
        "December 18",
        "December 19",
        "December 20",
        "December 21",
        "December 22",
        "December 23",
        "December 24",
        "December 25",
        "December 26",
        "December 27",
        "December 28",
        "December 29",
        "December 30",
        "December 31",
      ])}

      {showScrollTop && (
        <Button
          onClick={scrollTop}
          style={scrollTopButtonStyle}
          variant="contained"
          color="primary"
          size="medium"
          startIcon={<ArrowUpwardIcon />}
        >
          {language === EN ? "Scroll to top" : "Derulați în Sus"}
        </Button>
      )}

      {showScrollToToday && (
        <Button
          onClick={scrollToTodayVerse}
          style={scrollCurrentButtonStyle}
          variant="contained"
          color="primary"
          size="medium"
          startIcon={<TodayIcon />}
        >
          {language === EN
            ? "Scroll to today's passage"
            : "Derulați la pasajul de astăzi"}
        </Button>
      )}

      <div style={{ height: "50px" }}></div>
    </div>
  );
};

export default BiblePlan;

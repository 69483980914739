import { InputLabel, TextField } from '@mui/material';
import React from 'react';

const SeriesTitleSelector = (props) => {
    const { serviceData, handleSeriesTitleEnChange, handleSeriesTitleRoChange } = props;

    return (
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', marginTop: '5px'}}>
            <InputLabel>Series Title</InputLabel>
            <div>
                <TextField label="Series Title (English)" size="small" value={serviceData.series_title_en} onChange={handleSeriesTitleEnChange} />
                <TextField label="Series Title (Romanian)" size="small" value={serviceData.series_title_ro} onChange={handleSeriesTitleRoChange} />
            </div>
        </div>
    );
};

export default SeriesTitleSelector;
import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, InputLabel, ListItem, ListItemText, TextField, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { yellow } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { announcementsType, babyDedicationType, DarkGray, encouragementType, greetingType, otherType, passageRecitalType, poemType, presentationType, readingType, sermonType, songType, testimonyType } from '../../../../constants';
import { DialogTitle } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SongPoint from './typePoints/songPoint';
import SermonPoint from './typePoints/sermonPoint';
import EncouragementPoint from './typePoints/encouragementPoint';
import OtherPoint from './typePoints/otherPoint';
import { newMomentDay, timestamp } from '../../../../helpers/dateHelper';


const ServiceItem = (props) => {
    const { serviceData, index, removeServiceItem, handleChangeItemType, serviceStartTime, handlePointStartTime, handleChangeSecondaryItemType, handleItemAttributeChange, people, addPersonToPeople } = props;
    const [openDialog, setOpenDialog] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        const newTime = serviceData.start_timestamp_unix ? serviceData.start_timestamp_unix : timestamp(serviceStartTime).unix();

        // Get the time parts from newTime
        const newTimeObject = timestamp(newTime);
        const hours = newTimeObject.hours();
        const minutes = newTimeObject.minutes();
        const seconds = newTimeObject.seconds();

        // Get the date parts from serviceStartTime
        const newDateObject = timestamp(serviceStartTime);
        const year = newDateObject.year();
        const month = newDateObject.month();
        const day = newDateObject.date();

        // Combine the date and time parts
        const combinedDate = newMomentDay();
        combinedDate.year(year);
        combinedDate.month(month);
        combinedDate.date(day);
        combinedDate.hours(hours);
        combinedDate.minutes(minutes);
        combinedDate.seconds(seconds);

        handlePointStartTime(index, combinedDate.unix());
    }, [serviceData.start_timestamp_unix, serviceStartTime]);


    const handleTypeChange = (event, value) => {
        if (value !== otherType) {
            handleChangeSecondaryItemType(index, '');
        }

        handleChangeItemType(index, value);
    };

    const handleSecondaryTypeChange = (event) => {
        handleChangeSecondaryItemType(index, event.target.value);
    };

    const setToNow = () => {
        let oldStartTime = timestamp(serviceData.start_timestamp_unix).clone();
        const newDay = newMomentDay();
        oldStartTime.hour(newDay.hour());
        oldStartTime.minute(newDay.minute());
        oldStartTime.second(newDay.second());
        handlePointStartTime(index, oldStartTime.unix());
    };

    const addUnit = (unit) => {
        let oldStartTime = timestamp(serviceData.start_timestamp_unix).clone();
        oldStartTime.add(1, unit);
        handlePointStartTime(index, oldStartTime.unix());
    };

    const subtractUnit = (unit) => {
        let oldStartTime = timestamp(serviceData.start_timestamp_unix).clone();
        oldStartTime.subtract(1, unit);
        handlePointStartTime(index, oldStartTime.unix());
    };

    const addHour = () => addUnit('hours');
    const addMinute = () => addUnit('minutes');
    const addSecond = () => addUnit('seconds');

    const subtractHour = () => subtractUnit('hours');
    const subtractMinute = () => subtractUnit('minutes');
    const subtractSecond = () => subtractUnit('seconds');

    const serviceDataFields = [
        { key: 'family_name', label: (data) => `Baby Dedication for the ${data} Family` },
        { key: 'encouragement_for', label: (data) => `Prayer Encouragement for ${data}` },
        { key: 'language', label: (data) => `Language: ${data}` },
        { key: 'passage', label: (data) => `Passage: ${data}` },
        { key: 'series_title_en', label: (data) => `Series Title (English): ${data}` },
        { key: 'series_title_ro', label: (data) => `Series Title (Romanian): ${data}` },
        { key: 'sermon_title_en', label: (data) => `Sermon Title (English): ${data}` },
        { key: 'sermon_title_ro', label: (data) => `Sermon Title (Romanian): ${data}` },
        { key: 'song_entity', label: (data) => `${data}` },
        { key: 'song_sub_entity', label: (data) => `${data}` },
        { key: 'song_title_en', label: (data) => `Song Title (English): ${data}` },
        { key: 'song_title_ro', label: (data) => `Song Title (Romanian): ${data}` },
        { key: 'speaker', label: (data) => `Speaker: ${data}` },
        { key: 'start_timestamp_unix', label: (data) => `Start Time: ${timestamp(data).format('MMM DD YYYY hh:mm:ss A')}` },
    ];
    
    const boldFields = [
        { key: 'general_title_en', label: (data) => `${data}` },
        { key: 'general_title_ro', label: (data) => `${data}` },
        { key: 'group_title_en', label: (data) => `${data}` },
        { key: 'group_title_ro', label: (data) => `${data}` },
    ];
    
    const italicFields = [
        { key: 'general_description_en', label: (data) => `${data}` },
        { key: 'general_description_ro', label: (data) => `${data}` },
    ];    

    return (
        <ListItem style={{}}>
            {serviceData && <ListItemText primary={serviceData.item_type ? serviceData.item_type : <div>
                <WarningAmberIcon sx={{ marginRight: '10px', color: yellow[900] }} />
                <span> New Service Item</span>
            </div>} secondary={
                <div>
                {serviceDataFields.map((field, i) => 
                    serviceData[field.key] && 
                    <div key={i}>
                        {field.label(serviceData[field.key])}
                    </div>
                )}
                {boldFields.map((field, i) => 
                    serviceData[field.key] && 
                    <div key={`bold-${i}`}>
                        <b>{field.label(serviceData[field.key])}</b>
                    </div>
                )}
                {italicFields.map((field, i) => 
                    serviceData[field.key] && 
                    <div key={`italic-${i}`}>
                        <i>{field.label(serviceData[field.key])}</i>
                    </div>
                )}
            </div>

            } />}
            <EditIcon
                sx={{
                    '&:hover': {
                        color: theme.palette.primary.main
                    },
                    fontSize: '23px',
                    color: DarkGray,
                    cursor: 'pointer',
                    marginRight: '7px'
                }}
                onClick={() => setOpenDialog(true)}
            />


            <DeleteIcon
                sx={{
                    '&:hover': {
                        color: theme.palette.warning.main
                    },
                    fontSize: '20px',
                    color: DarkGray,
                    cursor: 'pointer'
                }}
                onClick={() => removeServiceItem(index)}
            />

            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="edit-dialog-title"
                aria-describedby="edit-dialog-description"

            >
                <DialogTitle id="edit-dialog">
                    {"Edit Service Item"}
                </DialogTitle>
                <DialogContent>

                    <div>
                        <div
                            style={{
                                fontFamily: theme.typography.paragraphFontFamily,
                                padding: '5px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                            <div>Start Time: {timestamp(serviceData.start_timestamp_unix).format('hh:mm:ss A')}</div>
                            <Button variant="contained" size="small" onClick={setToNow} sx={{ marginLeft: '10px' }}>Set to now</Button>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            alignItems: 'center'
                        }}>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <ButtonGroup>
                                    <Button
                                        aria-label="reduce"
                                        onClick={subtractHour}
                                        size="small"
                                    >
                                        <RemoveIcon fontSize="small" />
                                    </Button>
                                    <Button
                                        aria-label="increase"
                                        onClick={addHour}
                                        size="small"
                                    >
                                        <AddIcon fontSize="small" />
                                    </Button>
                                </ButtonGroup>
                                <InputLabel>Hour</InputLabel>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <ButtonGroup>
                                    <Button
                                        aria-label="reduce"
                                        onClick={subtractMinute}
                                        size="small"
                                    >
                                        <RemoveIcon fontSize="small" />
                                    </Button>
                                    <Button
                                        aria-label="increase"
                                        onClick={addMinute}
                                        size="small"
                                    >
                                        <AddIcon fontSize="small" />
                                    </Button>
                                </ButtonGroup>
                                <InputLabel>Minute</InputLabel>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <ButtonGroup>
                                    <Button
                                        aria-label="reduce"
                                        onClick={subtractSecond}
                                        size="small"
                                    >
                                        <RemoveIcon fontSize="small" />
                                    </Button>
                                    <Button
                                        aria-label="increase"
                                        onClick={addSecond}
                                        size="small"
                                    >
                                        <AddIcon fontSize="small" />
                                    </Button>
                                </ButtonGroup>
                                <InputLabel>Second</InputLabel>
                            </div>
                        </div>
                    </div>

                    <ToggleButtonGroup
                        value={serviceData.item_type}
                        exclusive
                        onChange={handleTypeChange}
                        aria-label="text alignment"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                        color="primary"
                    >
                        <ToggleButton value={songType} aria-label="song" size="small">
                            Song
                        </ToggleButton>
                        <ToggleButton value={sermonType} aria-label="sermon" size="small">
                            Sermon
                        </ToggleButton>
                        <ToggleButton value={encouragementType} aria-label="encouragement" size="small">
                            Encouragement
                        </ToggleButton>
                        <ToggleButton value={otherType} aria-label="other" size="small">
                            Other
                        </ToggleButton>
                    </ToggleButtonGroup>

                    {serviceData.item_type === otherType && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <TextField
                            sx={{ marginTop: '15px' }}
                            label="Other Service Item"
                            select
                            fullWidth
                            value={serviceData.item_secondary_type}
                            onChange={handleSecondaryTypeChange}
                            SelectProps={{
                                native: true,
                            }}
                            size="small"
                        >
                            <option value=""></option>
                            <option value={announcementsType}>Announcements</option>
                            <option value={readingType}>Reading</option>
                            <option value={babyDedicationType}>Baby Dedication</option>
                            <option value={poemType}>Poem</option>
                            <option value={presentationType}>Presentation</option>
                            <option value={greetingType}>Greeting</option>
                            <option value={testimonyType}>Testimony</option>
                            <option value={passageRecitalType}>Passage Recital</option>
                            <option value={otherType}>Other</option>
                        </TextField>
                    </div>}

                    {serviceData.item_type === songType && <SongPoint serviceData={serviceData} handleItemAttributeChange={handleItemAttributeChange} index={index} people={people} addPersonToPeople={addPersonToPeople} />}
                    {serviceData.item_type === sermonType && <SermonPoint serviceData={serviceData} handleItemAttributeChange={handleItemAttributeChange} index={index} people={people} addPersonToPeople={addPersonToPeople} />}
                    {serviceData.item_type === encouragementType && <EncouragementPoint serviceData={serviceData} handleItemAttributeChange={handleItemAttributeChange} index={index} people={people} addPersonToPeople={addPersonToPeople} />}
                    {serviceData.item_type === otherType && <OtherPoint serviceData={serviceData} handleItemAttributeChange={handleItemAttributeChange} index={index} people={people} addPersonToPeople={addPersonToPeople} />}
                </DialogContent>

                <DialogActions>
                    <Button autoFocus variant="contained" onClick={() => setOpenDialog(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </ListItem>
    );
};

export default ServiceItem;
/**
 * This file is to house any api constants, like endpoints, that we need
 */

/**
 * ENDPOINTS
 */
export const addPerson_endpoint         = '/addPerson';
export const announcements_endpoint     = '/announcements';
export const getFullService_endpoint    = '/getFullService';
export const getServices_endpoint       = '/getServices';
export const liveStatus_endpoint        = '/liveStatus'; 
export const peopleBasic_endpoint       = '/peopleBasic';
export const saveService_endpoint       = '/saveService';
export const servicePoints_endpoint     = '/servicePoints';
export const services_endpoint          = '/services';
export const setLiveLink_endpoint       = '/setLiveLink';
export const setLiveOn_endpoint         = '/setLiveOn';
export const setliveStatus_endpoint     = '/setLiveStatus';
export const upcomingEvents_endpoint    = '/upcomingEvents';
export const saveAnnouncement_endpoint  = '/saveAnnouncement';
export const updateAnnouncement_endpoint = '/updateAnnouncement';
export const deleteAnnouncement_endpoint = '/deleteAnnouncement';
export const search_endpoint             = '/search';
export const urgentMessage_endpoint      = '/urgentMessage';
export const gallery_endpoint            = '/getGalleries';
import { Button, ButtonGroup, FormControlLabel, FormGroup, InputLabel, Switch, TextField, ToggleButton, ToggleButtonGroup, Checkbox, Dialog, Divider, List, ListItem, Box, CircularProgress, Tooltip, IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DarkGray, encouragementType, LightBlue, LightDarkGray, LightGray, otherType, pointsDashboardPath, sermonType, serviceEditorPath } from '../../../constants';
import RequireAuth from '../requireAuth';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import PianoIcon from '@mui/icons-material/Piano';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { yellow } from '@mui/material/colors';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as dayjs from 'dayjs';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { DialogTitle } from '@material-ui/core';
import SaveIcon from '@mui/icons-material/Save';
import { createNewServiceItem, getSortedServiceData } from '../../../helpers/points';
import ServiceItem from './serviceItems/serviceItem';
import AddPersonDialog from './addPersonDialog';
import { saveService } from '../../../helpers/backend';
import InfoIcon from '@mui/icons-material/Info';
import { ServiceSaveError, SuccessfulSave } from '../../../components/snackbarWarnings';
import { fetchAndSetData } from '../../../helpers/api/api';
import { getFullService_endpoint, peopleBasic_endpoint } from '../../../helpers/api/apiConstants';
import { newMomentDay, timestamp } from '../../../helpers/dateHelper';
import { useServiceEditorContext } from '../../../contexts/serviceEditorContext';

const mainStyle = {
    fontFamily: "Montserrat",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    top: '56px',
    flexDirection: 'column',
    paddingBottom: '40px',
};

const menuStyle = {
    alignItems: 'center',
    backgroundColor: LightGray,
    width: '100%',
    paddingTop: '10px',
    paddingBottom: '10px',
    color: DarkGray,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
};

const frameStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    minHeight: '100%'
};

const sideMenuStyle = {
    width: '300px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column'
};

const menuItemStyle = {
    marginLeft: '10px',
    marginRight: '10px',
    backgroundColor: 'black',
    width: '100%'
};

const ServiceEditor = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const service_key = state?.service_key;

    const { 
        serviceData, setServiceData,
        date, setDate,
        serviceStartTime, setServiceStartTime,
        morningOrNight, setMorningOrNight,
        vimeoId, setVimeoId,
        addPeopleOpen, setAddPeopleOpen,
        personPanelIsOpen, setPersonPanelIsOpen,
        newPersonPosition, setNewPersonPosition,
        newPerson, setNewPerson,
        people, setPeople,
        saveServiceDialog, setSaveServiceDialog,
        disableService, setDisableService,
        serviceItems, setServiceItems,
        successSnackbar, setSuccessSnackbar,
        errorSnackbar, setErrorSnackbar,
        loadingSave, setLoadingSave,
        handleServiceData
     } = useServiceEditorContext();

    /**
     * This useEffect hook is used to fetch and set the necessary data for the Service Editor component.
     * 
     * It checks if the `service_key` is defined and not equal to "-1". If it is, it fetches the full
     * service data from the server, sorts the service items, and sets the relevant state variables.
     * 
     * The hook runs whenever the `service_key` state variables change.
     */
    useEffect(() => {
        if (service_key && service_key !== "-1") {
            fetchAndSetData(`${getFullService_endpoint}?serviceKey=${service_key}`, (data) => {
                setServiceData(data);
                setServiceItems(getSortedServiceData(data.service_items));
                setDisableService(Boolean(data.disable_service));
            });
        }

        fetchAndSetData(peopleBasic_endpoint, setPeople);
    }, [service_key]);

    /**
     * This useEffect hook runs whenever the `serviceData` state variable changes.
     * It calls the `handleServiceData` function, which updates multiple state variables 
     * based on the properties of the `serviceData` object. 
     */
    useEffect(() => {
        if (serviceData) {
            handleServiceData(serviceData);
        }
    }, [serviceData])


    const handleBackButton = () => {
        navigate(pointsDashboardPath);
    };

    const handleDateChange = (newDate) => {
        let oldServiceStartTime = timestamp(serviceStartTime).clone();
        oldServiceStartTime.set({
            'year': newDate.year(),
            'month': newDate.month(),
            'date': newDate.date()
        });

        setDate(newDate.format('YYYY-MM-DD'));
        setServiceStartTime(oldServiceStartTime.unix());
    };

    const changeToNow = () => {
        let oldServiceStartTime = timestamp(serviceStartTime).clone();
        const newDay = newMomentDay();
        oldServiceStartTime.hour(newDay.hour());
        oldServiceStartTime.minute(newDay.minute());
        oldServiceStartTime.second(newDay.second());
        setServiceStartTime(oldServiceStartTime.unix());
    };

    const addUnit = (unit) => {
        let oldServiceStartTime = timestamp(serviceStartTime).clone();
        oldServiceStartTime.add(1, unit);
        setServiceStartTime(oldServiceStartTime.unix());
    };

    const subtractUnit = (unit) => {
        let oldServiceStartTime = timestamp(serviceStartTime).clone();
        oldServiceStartTime.subtract(1, unit);
        setServiceStartTime(oldServiceStartTime.unix());
    };

    const addHour = () => addUnit('hours');
    const addMinute = () => addUnit('minutes');
    const addSecond = () => addUnit('seconds');

    const subtractHour = () => subtractUnit('hours');
    const subtractMinute = () => subtractUnit('minutes');
    const subtractSecond = () => subtractUnit('seconds');

    const handleMorningOrNightChange = (event, value) => {
        setMorningOrNight(value);
    };

    const handleAddPeopleClose = () => {
        setAddPeopleOpen(false);
    };

    const openAddPeople = () => {
        setAddPeopleOpen(true);
    };

    const handleAddPersonClose = () => {
        setPersonPanelIsOpen(false);
    };

    const addPersonToPeople = (person) => {
        people.push(person);
    };

    const handlePersonChange = (person) => {
        if (person.label.startsWith("Add ")) {
            console.log(`Adding ${person.label}`);
            setPersonPanelIsOpen(true);
            return;
        }
        setNewPerson(person);
    };

    const handlePositionChange = (event) => {
        setNewPersonPosition(event.target.value);
    };

    const handleSaveService = () => {
        setSaveServiceDialog(true);
    };

    const handleServiceDialogClose = () => {
        setSaveServiceDialog(false);
    };

    const handleSaveServiceFinal = async () => {
        setLoadingSave(true);

        const saveResponse = await saveService(date, serviceStartTime, morningOrNight, vimeoId, disableService, serviceItems, service_key);

        if (saveResponse === 'success') {
            setSuccessSnackbar(true);
        }
        else if (saveResponse === 'error') {
            setErrorSnackbar(true);
        }

        else {
            navigate(serviceEditorPath, { state: { service_key: saveResponse } });
        }

        setSaveServiceDialog(false);
        setLoadingSave(false);
    };

    const handleDisableService = (event, value) => {
        setDisableService(value);
    };

    const handleAddServiceItem = () => {
        const tmpServiceItems = [...serviceItems];
        tmpServiceItems.push(createNewServiceItem(service_key));
        setServiceItems(tmpServiceItems);
    };

    const removeServiceItem = (index) => {
        const tmpServiceItems = [...serviceItems];
        tmpServiceItems.splice(index, 1);
        setServiceItems(tmpServiceItems);
    };

    const handleChangeItemType = (index, value) => {
        const tmpServiceItems = [...serviceItems];

        if (value === sermonType || value === encouragementType) {
            if (value !== encouragementType) {
                tmpServiceItems[index].encouragement_for = '';
            }
            tmpServiceItems[index].item_secondary_type = '';
            tmpServiceItems[index].location_from = '';
            tmpServiceItems[index].passage = '';
            tmpServiceItems[index].person_title = '';
            tmpServiceItems[index].song_entity = '';
            tmpServiceItems[index].song_title_en = '';
            tmpServiceItems[index].song_title_ro = '';
            tmpServiceItems[index].language = '';
        }

        if (value !== sermonType && value !== encouragementType) {
            tmpServiceItems[index].speaker = '';
            tmpServiceItems[index].speaker_key = undefined;
        }

        if (value !== otherType) {
            tmpServiceItems[index].item_secondary_type = '';
        }

        tmpServiceItems[index].item_type = value;
        setServiceItems(tmpServiceItems);
    };

    const handleChangeSecondaryItemType = (index, value) => {
        const tmpServiceItems = [...serviceItems];
        tmpServiceItems[index].item_secondary_type = value;
        setServiceItems(tmpServiceItems);
    };

    const handlePointStartTime = (index, value) => {
        const tmpServiceItems = [...serviceItems];
        tmpServiceItems[index].start_timestamp_unix = value;
        setServiceItems(tmpServiceItems);
    };
    
    const handleItemAttributeChange = (index, attribute, newValue) => {
        const tmpServiceItems = [...serviceItems];
        tmpServiceItems[index][attribute] = newValue;
        setServiceItems(tmpServiceItems);
    };

    const handleCloseError = () => {
        setErrorSnackbar(false);
    };

    const handleCloseSuccess = () => {
        setSuccessSnackbar(false);
    };

    const insertDefaultVimeoId = () => {
        setVimeoId('DEFAULT');
    };

    return (
        <div style={mainStyle}>
            <RequireAuth />
            <div style={menuStyle}>
                <Button variant="outlined" sx={{ marginLeft: '10px' }} startIcon={<ArrowBackIosIcon />} onClick={handleBackButton}>
                    Back
                </Button>
            </div>
            <div style={frameStyle}>
                <div style={sideMenuStyle}>

                    <div style={menuItemStyle}>

                    </div>
                    <div>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                {!morningOrNight && <WarningAmberIcon sx={{ marginRight: '10px', color: yellow[900] }} />}
                                <AccessTimeIcon style={{ marginRight: '10px' }} />
                                <Typography>Service Times</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        label="Service Date"
                                        inputFormat="MM/DD/YYYY"
                                        value={dayjs(date)}
                                        onChange={handleDateChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                                <ToggleButtonGroup
                                    value={morningOrNight}
                                    exclusive
                                    onChange={handleMorningOrNightChange}
                                    aria-label="text alignment"
                                    sx={{
                                        marginTop: '10px',
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                    color="primary"
                                >
                                    <ToggleButton value="Morning" aria-label="morning">
                                        <Typography>
                                            Morning
                                        </Typography>
                                    </ToggleButton>
                                    <ToggleButton value="Evening" aria-label="evening">
                                        <Typography>
                                            Evening
                                        </Typography>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                {!vimeoId && <WarningAmberIcon sx={{ marginRight: '10px', color: yellow[900] }} />}
                                <VideoSettingsIcon style={{ marginRight: '10px' }} />
                                <Typography>Video Settings</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Typography component={'span'} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <div style={{ fontWeight: '600', fontSize: '17px' }}>Service Video Start Time: </div>
                                    {/* <div >{serviceStartTime.format('MMM DD YYYY h:mm:ss A')}</div> */}
                                    <div>{timestamp(serviceStartTime).format('YYYY-MM-DD hh:mm:ss A')}</div>
                                </Typography>
                                <Button onClick={changeToNow} variant='contained' size="small">Set Time To Now</Button>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px' }}>
                                    <ButtonGroup sx={{ marginRight: '10px' }}>
                                        <Button
                                            aria-label="reduce"
                                            onClick={subtractHour}
                                            size="small"
                                        >
                                            <RemoveIcon fontSize="small" />
                                        </Button>
                                        <Button
                                            aria-label="increase"
                                            onClick={addHour}
                                            size="small"
                                        >
                                            <AddIcon fontSize="small" />
                                        </Button>
                                    </ButtonGroup>
                                    <InputLabel>Hour</InputLabel>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '2px' }}>
                                    <ButtonGroup sx={{ marginRight: '10px' }}>
                                        <Button
                                            aria-label="reduce"
                                            onClick={subtractMinute}
                                            size="small"
                                        >
                                            <RemoveIcon fontSize="small" />
                                        </Button>
                                        <Button
                                            aria-label="increase"
                                            onClick={addMinute}
                                            size="small"
                                        >
                                            <AddIcon fontSize="small" />
                                        </Button>
                                    </ButtonGroup>
                                    <InputLabel>Minute</InputLabel>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '2px' }}>
                                    <ButtonGroup sx={{ marginRight: '10px' }}>
                                        <Button
                                            aria-label="reduce"
                                            onClick={subtractSecond}
                                            size="small"
                                        >
                                            <RemoveIcon fontSize="small" />
                                        </Button>
                                        <Button
                                            aria-label="increase"
                                            onClick={addSecond}
                                            size="small"
                                        >
                                            <AddIcon fontSize="small" />
                                        </Button>
                                    </ButtonGroup>
                                    <InputLabel>Second</InputLabel>
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <TextField label="Vimeo ID" value={vimeoId ? vimeoId : ''} variant="outlined" fullWidth size="small" onChange={event => setVimeoId(event.target.value)} />
                                    <span style={{ display: 'inline-block' }}>
                                        <Typography variant="caption" display="block" gutterBottom sx={{ color: LightDarkGray, '&:hover': { color: LightBlue }, cursor: 'pointer' }} onClick={insertDefaultVimeoId}>
                                            Insert Default Vimeo ID
                                        </Typography>
                                    </span>
                                    <span>
                                        <Tooltip title="When there isn't a Vimeo ID ready yet, you can insert a temporary default Vimeo ID that displays a loading video until a real vimeo ID can be placed here later." >
                                            <IconButton>
                                                <InfoIcon sx={{ fontSize: '20px' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </span>

                                </div>
                                <FormGroup sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                    <FormControlLabel control={<Switch onChange={handleDisableService} checked={disableService} />} />
                                    <InputLabel>Disable Service</InputLabel>
                                </FormGroup>
                            </AccordionDetails>
                        </Accordion>
                        <div style={{ marginTop: '10px', marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button variant="contained" endIcon={<SaveIcon size="small" />} onClick={handleSaveService} disabled={!vimeoId || !morningOrNight}>Save Service</Button>
                        </div>
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                    <div style={{ fontSize: '35px', fontWeight: '500', color: DarkGray, marginTop: '10px' }}>
                        {timestamp(serviceStartTime).format('dddd')} {morningOrNight ? `${morningOrNight} ` : ''}service on {timestamp(serviceStartTime).format('MMMM DD, YYYY')}
                    </div>

                    <div style={{ marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <List sx={{ width: '75%', bgcolor: 'background.paper' }}>
                            {serviceItems && serviceItems.map((item, index) =>
                                <div>
                                    <ServiceItem
                                        serviceData={item}
                                        index={index}
                                        key={index}
                                        removeServiceItem={removeServiceItem}
                                        handleChangeItemType={handleChangeItemType}
                                        serviceStartTime={serviceStartTime}
                                        handlePointStartTime={handlePointStartTime}
                                        handleChangeSecondaryItemType={handleChangeSecondaryItemType}
                                        handleItemAttributeChange={handleItemAttributeChange}
                                        people={people}
                                        addPersonToPeople={addPersonToPeople}
                                    />
                                    <Divider />
                                </div>
                            )}
                            <Divider />
                            <ListItem style={{ display: 'flex', alignItems: 'center', flexDisplay: 'row' }}>
                                <Button variant="contained" endIcon={<AddIcon size="small" />} onClick={handleAddServiceItem}>Add Item</Button>
                            </ListItem>
                        </List>
                    </div>
                </div>
            </div>

            <AddPersonDialog handleAddPeopleClose={handleAddPeopleClose} addPeopleOpen={addPeopleOpen} personPanelIsOpen={personPanelIsOpen} handleAddPersonClose={handleAddPersonClose} addPersonToPeople={addPersonToPeople} handlePersonChange={handlePersonChange} newPosition={newPersonPosition} handlePositionChange={handlePositionChange} newPerson={newPerson} people={people} />

            <Dialog onClose={handleServiceDialogClose} open={saveServiceDialog} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <DialogTitle>Save Service? This action can't be undone</DialogTitle>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant="outlined" startIcon={<SaveIcon />} onClick={handleServiceDialogClose} color="primary" sx={{ width: '50%' }}>
                        Cancel
                    </Button>
                    <Button variant="contained" startIcon={loadingSave ? <Box sx={{ display: 'flex', color: LightGray }}><CircularProgress color="inherit" /></Box> : <SaveIcon />} onClick={handleSaveServiceFinal} sx={{ marginTop: '20px', marginBottom: '10px', width: '50%' }}>
                        {loadingSave ? 'Saving...' : 'Confirm'}
                    </Button>

                </div>
            </Dialog>

            <SuccessfulSave open={successSnackbar} handleSnackbarClose={handleCloseSuccess} />
            <ServiceSaveError open={errorSnackbar} handleSnackbarClose={handleCloseError} />
        </div>
    );
};

export default ServiceEditor;
import React from 'react';
import { otherOrchestraType } from '../../../../../../../constants';
import GroupTitleSelector from '../groupTitleSelector';
import OrchestraTypeSelector from '../orchestraTypeSelector';
import PersonnelSelector from '../personnelSelector';
import SongTitleSelector from '../songTitleSelector';

const Orchestra = (props) => {
    const { serviceData, handleSongSubEntityTypeChange, handleGroupTitleEnChange, handleGroupTitleRoChange, handleSongTitleEnChange, handleSongTitleRoChange, handleAddPersonnel, addPersonDialogOpen, addPeopleOpen, handlePersonChange, people, handlePositionChange, saveNewPerson, newPerson, newPosition, handleAddPerson, handleAddPeopleClose, removePerson, addPersonToPeople, personPanelIsOpen, handleAddPersonClose } = props;

    return (
        <>
            <OrchestraTypeSelector serviceData={serviceData} handleSongSubEntityTypeChange={handleSongSubEntityTypeChange}/>
            {serviceData.song_sub_entity === otherOrchestraType && <GroupTitleSelector serviceData={serviceData} handleGroupTitleEnChange={handleGroupTitleEnChange} handleGroupTitleRoChange={handleGroupTitleRoChange}/>}
            {serviceData.song_sub_entity && <SongTitleSelector serviceData={serviceData} handleSongTitleEnChange={handleSongTitleEnChange} handleSongTitleRoChange={handleSongTitleRoChange}/>}
            {(serviceData.song_title_en || serviceData.song_title_ro)&& <PersonnelSelector serviceData={serviceData} addPersonToPeople={addPersonToPeople} handleAddPersonnel={handleAddPersonnel} addPersonDialogOpen={addPersonDialogOpen} addPeopleOpen={addPeopleOpen} handlePersonChange={handlePersonChange} people={people} handlePositionChange={handlePositionChange} saveNewPerson={saveNewPerson} parentProps={props} newPerson={newPerson} newPosition={newPosition} handleAddPerson={handleAddPerson} handleAddPeopleClose={handleAddPeopleClose} removePerson={removePerson} personPanelIsOpen={personPanelIsOpen} handleAddPersonClose={handleAddPersonClose}/>}
        </>
    );
};

export default Orchestra;
import React from 'react';
import { announcementsType, babyDedicationType, encouragementType, greetingType, poemType, presentationType, readingType, sermonType, songType, testimonyType } from '../../constants';
import AnnouncementPoint from './pointTypes/announcementPoint';
import BabyDedicationPoint from './pointTypes/babyDedicationPoint';
import EncouragementPoint from './pointTypes/encouragementPoint';
import GreetingPoint from './pointTypes/greetingPoint';
import OtherPoint from './pointTypes/otherPoint';
import PoemPoint from './pointTypes/poemPoint';
import PresentationPoint from './pointTypes/presentationPoint';
import ReadingPoint from './pointTypes/readingPoint';
import SermonPoint from './pointTypes/sermonPoint';
import SongPoint from './pointTypes/songPoint';
import TestimonyPoint from './pointTypes/testimonyPoint';

const mainStyle = {
    
};


const ServicePoints = (props) => {
    const { servicePoints, handlePointTimestamp } = props;

    const servicePointTypeMap = (servicePoint) => {
        if (servicePoint.TYPE === songType) {
            return <SongPoint servicePoint={servicePoint} handlePointTimestamp={handlePointTimestamp}/>
        }
        else if (servicePoint.TYPE === sermonType) {
            return <SermonPoint servicePoint={servicePoint} handlePointTimestamp={handlePointTimestamp}/>
        }
        else if (servicePoint.TYPE === encouragementType) {
            return <EncouragementPoint servicePoint={servicePoint} handlePointTimestamp={handlePointTimestamp}/>
        }
        else if (servicePoint.TYPE === announcementsType) {
            return <AnnouncementPoint servicePoint={servicePoint} handlePointTimestamp={handlePointTimestamp}/>
        }
        else if (servicePoint.TYPE === readingType) {
            return <ReadingPoint servicePoint={servicePoint} handlePointTimestamp={handlePointTimestamp}/>
        }
        else if (servicePoint.TYPE === poemType) {
            return <PoemPoint servicePoint={servicePoint} handlePointTimestamp={handlePointTimestamp} />
        }
        else if (servicePoint.TYPE === babyDedicationType) {
            return <BabyDedicationPoint servicePoint={servicePoint} handlePointTimestamp={handlePointTimestamp} /> 
        }
        else if (servicePoint.TYPE === presentationType) {
            return <PresentationPoint servicePoint={servicePoint} handlePointTimestamp={handlePointTimestamp} /> 
        }
        else if (servicePoint.TYPE === greetingType) {
            return <GreetingPoint servicePoint={servicePoint} handlePointTimestamp={handlePointTimestamp} /> 
        }
        else if (servicePoint.TYPE === testimonyType) {
            return <TestimonyPoint servicePoint={servicePoint} handlePointTimestamp={handlePointTimestamp} /> 
        }
        else {
            return <OtherPoint servicePoint={servicePoint} handlePointTimestamp={handlePointTimestamp}/>
        }
    };
    
    const content = servicePoints.map((servicePoint) => 
        
        servicePointTypeMap(servicePoint)
    );
    return (
        <div style={mainStyle}>
            {content}
        </div> 
    );
};

export default ServicePoints;
import './hamburger.css';
import React from 'react';

export function Hamburger(props) {
    return (
        <div>
            <input className="checkbox" type="checkbox" name="" id="hamburgerCheckbox" onClick={props.onClick} checked={props.clicked} readOnly/>
            <div className="hamburger-lines">
                <span className="line line1"></span>
                <span className="line line2"></span>
                <span className="line line3"></span>
            </div>
        </div>
    );
}
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { BorderGray, DateGray, englishToRomanianShortDateMap } from '../constants';
import { LIGHT_DARK_GRAY } from '../theme/colors';
import { useAppSelector } from '../app/hooks';
import { selectLanguage } from '../app/slices/languageSlice';

const circleStyle = {
    display: 'flex',
    width: '70px',
    height: '70px',
    backgroundColor: 'white',
    borderRadius: '50%',
    marginLeft: '10px',
    boxShadow: '0 2px 6px rgb(0 0 0 / 8%)',
    border: `1px solid ${BorderGray}`,
    marginTop: '10px',
    marginBottom: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    flexShrink: 0
};

const mainTopStyle = {
    fontSize: '13px', 
    paddingBottom: '0',
    marginBottom: '0'
};

const mainBottomStyle = {
    fontSize: '25px'
};

const multiDayBottomStyle = {
    fontSize: '15px'
};

const multiStyle = {
    fontSize: '13px',
    padding: '0',
    margin: '0'
};


const dateStyle = {
    fontWeight: '600',
    color: DateGray,
    margin: 0,
    padding: 0
};

const yearStyle = {
    fontWeight: '400',
    fontSize: '12px',
    color: LIGHT_DARK_GRAY,
    paddingTop: '0',
    marginTop: '0'
};

const CircleDate = (props) => {
    const { date, endDate } = props;
    const language = useAppSelector(selectLanguage);
    const isMultiMonth = useMemo(() => endDate && dayjs(date).month() !== dayjs(endDate).month(), [date, endDate]);
    const [topStyle, setTopStyle] = useState({});
    const [bottomStyle, setBottomStyle] = useState({});


    /**
     * This use effect is used to set the fonts and styles of the top and bottom portion of a circle
     */
    useEffect(() => {
        function setStyles(newTopStyle, newBottomStyle) {
            setTopStyle(newTopStyle);
            setBottomStyle(newBottomStyle)
        }

        if (!endDate) {
            setStyles(mainTopStyle, mainBottomStyle);
        }
        else {
            if (!isMultiMonth) {
                setStyles(mainTopStyle, multiDayBottomStyle);
            }
            else {
                setStyles(multiStyle, multiStyle);
            }
        }
    }, [date, endDate, isMultiMonth]);

    /**
     * Get the top part of the date circle
     */
    const getTop = useMemo(() => {
        if (!endDate || !isMultiMonth) {
            return englishToRomanianShortDateMap(language, dayjs(date).format('MMM'));
        }
        return `${englishToRomanianShortDateMap(language, dayjs(date).format('MMM'))} ${dayjs(date).format('D')}`;
    }, [date, endDate, isMultiMonth, language]);

    /**
     * Get the bottom part of the date circle
     */
    const getBottom = useMemo(() => {
        if (!endDate) {
            return dayjs(date).format('D');
        }
        if (!isMultiMonth) {
            return `${dayjs(date).format('D')}-${dayjs(endDate).format('D')}`;
        }
        return `${englishToRomanianShortDateMap(language, dayjs(endDate).format('MMM'))} ${dayjs(endDate).format('D')}`;
    }, [date, endDate, isMultiMonth, language]);


    const getYear = useMemo(() => {
        if (!endDate) {
            return dayjs(date).format('YYYY');
        }
        if (!isMultiMonth) {
            return `${dayjs(date).format('YYYY')}-${dayjs(endDate).format('YYYY')}`;
        }

        return ''
    }, [date, endDate, isMultiMonth]);

    
    return (
        <div style={circleStyle}>
            <div style={{...dateStyle, ...topStyle}}>
                {getTop}
            </div>
            {isMultiMonth && <div style={{margin: '0', padding: '0'}}>-</div>}
            <div style={{...dateStyle, ...bottomStyle}}>
                {getBottom}
            </div>
            <div style={yearStyle}>
                {getYear}
            </div>
        </div>
    );
};

export default CircleDate;
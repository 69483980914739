import { InputLabel, TextField } from '@mui/material';
import React from 'react';
import { bothType, englishType, romanianType } from '../../../../../../constants';

const SongTitleSelector = (props) => {
    const { serviceData, handleSongTitleEnChange, handleSongTitleRoChange } = props;

    return (
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', marginTop: '5px'}}>
            <InputLabel>Song Title</InputLabel>
            <div>
                <TextField label="Song Title (English)" size="small" value={serviceData.song_title_en} onChange={handleSongTitleEnChange} required={serviceData.language === englishType || serviceData.language === bothType}/>
                <TextField label="Song Title (Romanian)" size="small" value={serviceData.song_title_ro} onChange={handleSongTitleRoChange} required={serviceData.language === romanianType || serviceData.language === bothType}/>
            </div>
        </div>
    );
};

export default SongTitleSelector;
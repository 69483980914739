import { InputLabel, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { bothType, englishType, neitherType, romanianType } from '../../../../../../constants';

const LanguageSelector = (props) => {
    const { serviceData, handleLanguageChange } = props;


    return (
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', marginTop: '5px'}}>
            <InputLabel>Language</InputLabel>
            <ToggleButtonGroup
                value={serviceData.language}
                exclusive
                onChange={handleLanguageChange}
                aria-label="text alignment"
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
                color="primary"
                >
                <ToggleButton value={englishType} aria-label="song" size="small">
                    English
                </ToggleButton>
                <ToggleButton value={romanianType} aria-label="sermon" size="small">
                    Romanian
                </ToggleButton>
                <ToggleButton value={bothType} aria-label="sermon" size="small">
                    Both
                </ToggleButton>
                <ToggleButton value={neitherType} aria-label="sermon" size="small">
                    Neither
                </ToggleButton>
            </ToggleButtonGroup>
        </div>
    );
};

export default LanguageSelector;
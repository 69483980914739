import { useEffect, useState } from "react";
import {
  LightBlue,
  LightDarkGray,
  passageString,
  seriesString,
  sermonString,
  titleString,
} from "../../../constants";
import { getSermonTitle, getSpeaker } from "../../../helpers/points";
import { getDisplayVerse } from "../../../helpers/bible";
import { useAppSelector } from "../../../app/hooks";
import { selectLanguage } from "../../../app/slices/languageSlice";

/**************************************************
 * STYLES
 **************************************************/
const sermonEntityStyle = {
  fontWeight: "500",
};

const speakerStyle = {
  fontWeight: "400",
};

const hoverStyle = {
  color: LightBlue,
  cursor: "pointer",
  paddingBottom: "10px",
  fontSize: "15px",
};

const mainStyle = {
  color: LightDarkGray,
  fontSize: "15px",
  paddingBottom: "10px",
};

const titleCaptionStyle = {
  fontSize: "15px",
  fontWeight: "400",
};

const titleStyle = {
  fontSize: "15px",
  fontWeight: "300",
  paddingTop: "0",
  alignSelf: "center",
  fontStyle: "italic",
};

const seriesCaptionStyle = {
  fontSize: "13px",
  fontWeight: "400",
};

const seriesTitleStyle = {
  fontSize: "13px",
  fontWeight: "300",
};

const SermonPoint = ({ servicePoint, handlePointTimestamp }) => {
  const {
    SERMON_TITLE_EN,
    SERMON_TITLE_RO,
    PASSAGE,
    SERIES_TITLE_EN,
    SERIES_TITLE_RO,
    LOCATION_FROM,
    PERSON_TITLE,
    SPEAKER,
  } = servicePoint;
  const language = useAppSelector(selectLanguage);
  const [sermonPointStyle, setSermonPointStyle] = useState(mainStyle);
  const [speaker, setSpeaker] = useState(
    getSpeaker(PERSON_TITLE, SPEAKER, LOCATION_FROM, language)
  );
  const [sermonTitle, setSermonTitle] = useState(
    getSermonTitle(language, SERMON_TITLE_EN, SERMON_TITLE_RO)
  );
  const [displayVerse, setDisplayVerse] = useState(
    getDisplayVerse(language, PASSAGE)
  );
  const [seriesTitle, setSeriesTitle] = useState(
    getSermonTitle(language, SERIES_TITLE_EN, SERIES_TITLE_RO)
  );

  useEffect(() => {
    setSpeaker(getSpeaker(PERSON_TITLE, SPEAKER, LOCATION_FROM, language));
    setSermonTitle(getSermonTitle(language, SERMON_TITLE_EN, SERMON_TITLE_RO));
    setDisplayVerse(getDisplayVerse(language, PASSAGE));
    setSeriesTitle(getSermonTitle(language, SERIES_TITLE_EN, SERIES_TITLE_RO));
  }, [
    language,
    PERSON_TITLE,
    SPEAKER,
    LOCATION_FROM,
    SERMON_TITLE_EN,
    SERMON_TITLE_RO,
    PASSAGE,
    SERIES_TITLE_EN,
    SERIES_TITLE_RO,
  ]);

  const handleMouseEnter = () => {
    setSermonPointStyle(hoverStyle);
  };

  const handleMouseLeave = () => {
    setSermonPointStyle(mainStyle);
  };

  const hasTitle = () => {
    return SERMON_TITLE_EN || SERMON_TITLE_RO;
  };

  const hasSeries = () => {
    return SERIES_TITLE_EN || SERIES_TITLE_RO;
  };

  const handleClick = () => {
    handlePointTimestamp(servicePoint.START_TIMESTAMP_UNIX);
  };

  return (
    <div
      style={sermonPointStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <div>
        <span style={sermonEntityStyle}>
          {sermonString(language)}
          {speaker && ": "}
        </span>
        <span style={speakerStyle}>{speaker}</span>
      </div>
      <div>
        {hasTitle() && (
          <span style={titleCaptionStyle}>{titleString(language)}: </span>
        )}
        {hasTitle() && <span style={titleStyle}>"{sermonTitle}"</span>}
      </div>
      <div>
        {PASSAGE && (
          <span style={seriesCaptionStyle}>{passageString(language)}: </span>
        )}
        {PASSAGE && <span style={seriesTitleStyle}>{displayVerse}</span>}
      </div>
      <div>
        {hasSeries() && (
          <span style={seriesCaptionStyle}>{seriesString(language)}: </span>
        )}
        {hasSeries() && <span style={seriesTitleStyle}>"{seriesTitle}"</span>}
      </div>
    </div>
  );
};

export default SermonPoint;

import React from 'react';
import Location from './location/location';
import ServiceTimes from './serviceTimes/serviceTimes';

const LeftColumn = () => {
    return(
        <div className='leftColumn' style={{paddingTop: '10px', paddingBottom: '10px'}}>
            <Location />
            <ServiceTimes />
        </div>
    );
};

export default LeftColumn;
import React from 'react';
import './secondaryMenu.css';
import LeftColumn from './leftColumn/leftColumn';
import RightColumn from './rightColumn/rightColumn';

export function SecondaryMenu (props) {

    if (props.isActive) {
        return (
            <div className='secondaryContainer'>
                <div className='column1'>
                    <LeftColumn />
                </div>
                <div className='column2'>
                    <RightColumn handleClick={props.handleClick}/>
                </div>
            </div>
        );
    }
    else {
        return <></>;
    }
}
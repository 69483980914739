import "./location.css";
import {
  street,
  city,
  state,
  zip,
  LightBlue,
  locationString,
} from "../../../../constants";
import { useAppSelector } from "../../../../app/hooks";
import { selectLanguage } from "../../../../app/slices/languageSlice";

const Location = () => {
  const language = useAppSelector(selectLanguage);
  return (
    <div className="location">
      <h1 style={{ color: LightBlue, fontWeight: "600" }}>
        {locationString(language)}
      </h1>
      <a
        href="https://goo.gl/maps/uz7WX7xbMQ9sioxb7"
        target="_blank"
        rel="noreferrer"
      >
        {street}, {city}, {state} {zip}
      </a>
    </div>
  );
};

export default Location;

import { useState, useEffect, useRef } from 'react';
import SocialLinks from './socialLinks/socialLinks';
import MenuItems from './menuItems/MenuItems';
import AddressComponent from './addressComponent/addressComponent';
import Copyright from './copyright/copyright';
import Flags from './flags/Flags';
import { minWidth, twoColumnWidth, bannerWidth, socialWidth, socialWidthRo, bannerWidthRo, policyWidthRo, policyWidth } from '../constants';
import { useAppSelector } from '../app/hooks';
import { selectLanguage } from '../app/slices/languageSlice';

/*
* STYLES
*/
const mainFooterStyle = {
    backgroundColor: 'white',
    fontFamily: "Montserrat",
    display: 'flex',
    opacity: '95%',
    padding: '0',
    margin: '0',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'fixed',
    bottom: '0',
    justifyContent: 'space-between',
    zIndex: '10000000',
    height: '42px'
};

const mobileFooterStyle = {
    backgroundColor: 'white',
    fontFamily: "Montserrat",
    display: 'flex',
    opacity: '95%',
    padding: '0',
    margin: '0',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'fixed',
    bottom: '0',
    justifyContent: 'center'
}

const leftFooterStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
};

const rightFooterStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    float: 'right'
};

/*
FUNCTIONS
*/
const getSocialWidth = (lang) => { return lang === 'ro' ? socialWidthRo : socialWidth; };
const getBannerWidth = (lang) => { return lang === 'ro' ? bannerWidthRo : bannerWidth; };
const getPolicyWidth = (lang) => { return lang === 'ro' ? policyWidthRo : policyWidth; };

/**
 * The component rendering the main Footer of the web-app
 * @param {*} props - for passing in click-handing info and more
 * @returns 
 */
const Footer = (props) => {
    const footerRef = useRef();

    const language = useAppSelector(selectLanguage);
    const { handleSizing, handleClick } = props;

    // USE STATES FOR FOOTER WIDTHS --------------------------------------
    const minWidthMatch = window.matchMedia(`(min-width: ${minWidth})`);
    const [isMinWidth, setMinWidth] = useState(minWidthMatch.matches);

    const twoColumnWidthMatch = window.matchMedia(`(min-width: ${twoColumnWidth})`);
    const [isTwoColumnWidth, setTwoColumnWidth] = useState(twoColumnWidthMatch.matches);

    const bannerWidthMatch = window.matchMedia(`(min-width: ${getBannerWidth(language)})`);
    const [isBannerWidth, setBannerWidth] = useState(bannerWidthMatch.matches);

    const socialWidthMatch = window.matchMedia(`(min-width: ${getSocialWidth(language)})`);
    const [isSocialWidth, setSocialWidth] = useState(socialWidthMatch.matches);

    const policyWidthMatch = window.matchMedia(`(min-width: ${getPolicyWidth(language)})`);
    const [isPolicyWidth, setPolicyWidth] = useState(policyWidthMatch.matches);

    const [footerHeight, setFooterHeight] = useState(60);
    // -------------------------------------------------------------------

    useEffect(() => {
        /**
         * Handles a resize event to always only display what fits on a screen
         */
        const handleResize = () => {
            const minWidthMatch = window.matchMedia(`(min-width: ${minWidth})`);
            setMinWidth(minWidthMatch.matches);

            const twoColumnWidthMatch = window.matchMedia(`(min-width: ${twoColumnWidth})`);
            setTwoColumnWidth(twoColumnWidthMatch.matches);

            const bannerWidthMatch = window.matchMedia(`(min-width: ${getBannerWidth(language)})`);
            setBannerWidth(bannerWidthMatch.matches);

            const socialWidthMatch = window.matchMedia(`(min-width: ${getSocialWidth(language)})`);
            setSocialWidth(socialWidthMatch.matches);

            const policyWidthMatch = window.matchMedia(`(min-width: ${getPolicyWidth(language)})`);
            setPolicyWidth(policyWidthMatch.matches);

            setFooterHeight(footerRef.current.clientHeight);
            handleSizing(footerHeight);
            };

        // Resize handler
        window.addEventListener('resize', handleResize);
        setFooterHeight(footerRef.current.clientHeight);
        handleSizing(footerHeight);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [language]);

    if (isTwoColumnWidth) {
        return (
            <div ref={footerRef} style={mainFooterStyle}>
                <div style={leftFooterStyle}>
                    <Flags />
                    {isSocialWidth && <SocialLinks />}
                    {isBannerWidth && <MenuItems handleClick={handleClick} />}
                    {/* {isPolicyWidth && <PolicyLinks handleClick={handleClick} />} */}
                </div>

                <div style={rightFooterStyle}>
                    {isMinWidth && <AddressComponent />}
                    <Copyright />
                </div>
            </div>
        );
    }
    else {
        return (
            <div ref={footerRef} style={mobileFooterStyle}>
                <div style={rightFooterStyle}>

                </div>
            </div>
        );
    }

};

export default Footer;
import { isMobile } from "react-device-detect";

// Font size styler
const copyrightStyle = {
  fontSize: ".55rem",
  paddingBottom: "5px",
};

const copyrightMobileStyle = {
  fontSize: ".01rem",
  paddingbottom: "0",
};

/**
 * Component for displaying copyright string in footer
 * @returns The copyright component for the footer
 */
const Copyright = () => {
  return (
    <div style={isMobile ? copyrightMobileStyle : copyrightStyle}>
      Copyright © 2024 | Bethel Bible Church
    </div>
  );
};

export default Copyright;

import { Alert, Snackbar } from '@mui/material';
import { excessResultsWarningString, searchCharacterErrorString, searchLimitErrorString } from '../constants';
import { useAppSelector } from '../app/hooks';
import { selectLanguage } from '../app/slices/languageSlice';

/**
 * Invalid search length warning for Services Searching
 * @param {*} props 
 * @returns 
 */
export const AlertInvalidSearchLength = (props) => {
    const { open, handleSnackbarClose } = props;
    const language = useAppSelector(selectLanguage);

    return (
        <Snackbar open={open} autoHideDuration={7000} onClose={handleSnackbarClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
            <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                {searchLimitErrorString(language)}
            </Alert>
        </Snackbar>
    );
};

/**
 * Invalid search character warning for Services Searching
 * @param {*} props 
 * @returns 
 */
export const AlertInvalidSearchCharacter = (props) => {
    const { open, handleSnackbarClose } = props;
    const language = useAppSelector(selectLanguage);

    return (
        <Snackbar open={open} autoHideDuration={7000} onClose={handleSnackbarClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
            <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                {searchCharacterErrorString(language)}
            </Alert>
        </Snackbar>
    );
};

export const AlertExcessResults = (props) => {
    const { open, handleSnackbarClose } = props;
    const language = useAppSelector(selectLanguage);

    return (
        <Snackbar open={open} autoHideDuration={7000} onClose={handleSnackbarClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
            <Alert onClose={handleSnackbarClose} severity="info" sx={{ width: '100%' }}>
                {excessResultsWarningString(language)}
            </Alert>
        </Snackbar>
    );
};

export const SuccessfulSave = (props) => {
    const { open, handleSnackbarClose } = props;

    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
            <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                Successfully saved the service!
            </Alert>
        </Snackbar>
    );
};

export const ServiceSaveError = (props) => {
    const { open, handleSnackbarClose } = props;

    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
            <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                Error in saving the service!
            </Alert>
        </Snackbar>
    );
};

import React from "react";
import { NavLink } from "react-router-dom";
import { EN } from "../constants";
import { useAppDispatch } from "../app/hooks";
import { updateLanguage } from "../app/slices/languageSlice";

const English = () => {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    // Only when the page is done 'mounting', change global language to Romanian
    dispatch(updateLanguage(EN));
    document.getElementById("homeNav").click(); // Clicks the hidden button to re-route to the home page instantly
  });

  return <NavLink to="/" id="homeNav"></NavLink>;
};

export default English;

import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { EN } from "../../constants";

export const languageSlice = createSlice({
  name: "language",
  initialState: {
    language: EN,
  },
  reducers: {
    updateLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const { updateLanguage } = languageSlice.actions;

export const selectLanguage = (state: RootState) => state.language.language;

export default languageSlice.reducer;

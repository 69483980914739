import InfoIcon from '@mui/icons-material/Info';
import { TextField, Tooltip } from '@mui/material';
import React from 'react';
import { ensembleType, LightBlue, LightDarkGray, orchestraType, otherOrchestraType, symphonicType } from '../../../../../../constants';

const OrchestraTypeSelector = (props) => {
    const { serviceData, handleSongSubEntityTypeChange } = props;

    return (
        <>
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center', marginTop: '5px'}}>
                <TextField
                    select
                    label="Orchestra Type"
                    SelectProps={{
                        native: true
                    }}
                    sx={{marginTop: '10px'}}
                    fullWidth
                    size="small"
                    value={serviceData.song_sub_entity}
                    onChange={handleSongSubEntityTypeChange}
                    >
                    <option value=''></option>
                    <option value={orchestraType}>Orchestra</option>
                    <option value={symphonicType}>Symphonic</option>
                    <option value={ensembleType}>Instrument Ensemble</option>
                    <option value={otherOrchestraType}>Other Orchestra</option>
                </TextField>

                <Tooltip title={
                    <div>
                        <div>Orchestra: Full orchestra with strings</div>
                        <div>Symphonic: Full orchestra without strings</div>
                        <div>Instrument Ensemble: Smaller group of orchestra instruments</div>
                        <div>Other Orchestra: Usually reversed for a guest orchestra</div>
                    </div>
                            }
                    sx={{
                        color: LightDarkGray,
                        marginLeft: '10px',
                        '&:hover': {
                            color: LightBlue
                        },
                    }}>
                    <InfoIcon />
                </Tooltip>
            </div>
        </>
    );
};

export default OrchestraTypeSelector;
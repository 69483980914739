import Button from '@mui/material/Button';
import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router';
import { DarkGray, LightGray, propertiesPath } from '../../../../constants';
import MessageTable from './messagesTable';
import RequireAuth from '../../requireAuth';

const mainStyle = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'Montserrat',
  color: 'white',
  height: '100%',
  width: '100%'
};

const menuStyle = {
  alignItems: 'center',
  backgroundColor: LightGray,
  width: '100%',
  paddingTop: '10px',
  paddingBottom: '10px',
  color: DarkGray,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: '56px'
};


const UrgentMessagesDashbaord = () => {

    const navigate = useNavigate(); 
    const handleBackButton = () => {
        navigate(propertiesPath);
    };

    return (
        <div style={mainStyle}>
            <RequireAuth />
            <div style={menuStyle}>
                <Button variant="outlined" sx={{marginLeft: '10px', zIndex: '1000'}} startIcon={<ArrowBackIosIcon />} onClick={handleBackButton}>
                    Back
                </Button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <MessageTable />
            </div>
        </div>
    );
};

export default UrgentMessagesDashbaord;
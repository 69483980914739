import dayjs from "dayjs";
import { camelCaseWeekdayMapEn, NEW_KEY, NULL, otherType } from "../constants";
import { fetchDeleteAnnouncement, fetchUpdateAnnouncement, getUrl } from './api/api';
import { addPerson_endpoint, announcements_endpoint, saveAnnouncement_endpoint, saveService_endpoint, upcomingEvents_endpoint } from "./api/apiConstants";
import { formatSqlDate } from "./dataBuilders";
import { timestamp } from "./dateHelper";
import moment from 'moment-timezone';

/**
 * FUNCTIONALITY TO CONNECT TO NODE.JS
 */

 /**
  * Fetches announcements from service
  */
export async function getAnnouncements() {
    const announcementResponse = await fetch(getUrl(announcements_endpoint))
    .then((response) => response.json())
    .then((data) => console.log(data) );
    return announcementResponse;
}

/**
 * Get upcoming events from server
 */
export async function getUpcomingEvents() {
    const upcomingEventsResponse = await fetch(getUrl(upcomingEvents_endpoint))
    .then((response) => response.json())
    .then((data) => console.log(data) );
    return upcomingEventsResponse;
}

/**
 * Format a new "person", and send that new person to the server to upload to Dynamo
 * @param {*} firstName 
 * @param {*} middleName 
 * @param {*} lastName 
 * @param {*} nickName 
 * @param {*} maidenName 
 * @param {*} suffix 
 * @returns 
 */
export async function addNewPerson(firstName, middleName, lastName, nickName, maidenName, suffix) {
    let personJson = {
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        nickName: nickName,
        maidenName: maidenName,
        suffix: suffix
    }; 

    const addPersonResponse = await fetch(getUrl(addPerson_endpoint), {
        method: 'POST',
        body: JSON.stringify(personJson),
        headers: { 'Content-Type': 'application/json' }
    }).then(res => res.json())
      .then(json => console.log('Added Person', json));


      return addPersonResponse;
};

/**
 * Formats personnel items for inputting into the backend database
 * @param {*} serviceItems 
 */
function formatPersonnel (serviceItems) {
    
    for (let i = 0; i < serviceItems.length; i++) {
        serviceItems[i].type = serviceItems[i].item_type === otherType ? serviceItems[i].item_secondary_type : serviceItems[i].item_type;
        serviceItems[i].start_timestamp = formatSqlDate(serviceItems[i].start_timestamp);

        if (serviceItems[i].passage && typeof serviceItems[i].passage !== 'string') {
            serviceItems[i].passage = serviceItems[i].passage && serviceItems[i].passage.length > 0 ? serviceItems[i].passage.join(', ') : '';
        }

        if (serviceItems[i].personnel.length > 0) {

            
            for (let j = 0; j < serviceItems[i].personnel.length; j++) {
                serviceItems[i].personnel[j] = {
                    person: typeof serviceItems[i].personnel[j].person === 'object' ? serviceItems[i].personnel[j].person.label : serviceItems[i].personnel[j].person,
                    person_key: typeof serviceItems[i].personnel[j].person === 'object' ? serviceItems[i].personnel[j].person.id : serviceItems[i].personnel[j].person_key,
                    position: serviceItems[i].personnel[j].position,
                    service_item_key: serviceItems[i].personnel[j].service_item_key,
                    personnel_key: serviceItems[i].personnel[j].personnel_key
                };
            }
        }
    }

    return serviceItems;
}

/**
 * Format a new service and send it to the server to uplaod to Dynamo
 * @param {*} date 
 * @param {*} serviceStartTime 
 * @param {*} morningOrNight 
 * @param {*} vimeoId 
 * @param {*} disableService 
 * @param {*} serviceItems 
 * @param {*} service_key 
 * @returns 
 */
export async function saveService (date, serviceStartTime, morningOrNight, vimeoId, disableService, serviceItems, service_key) {

    const newServiceItems = formatPersonnel(serviceItems);

    let serviceJson = {
        service_key: service_key,
        date: date,
        day_night: morningOrNight,
        disable_service: disableService,
        start_timestamp: formatSqlDate(timestamp(serviceStartTime).toDate()), // The human-readable datetime
        start_timestamp_unix: serviceStartTime,
        vimeo_id: vimeoId,
        weekday: camelCaseWeekdayMapEn[moment(date).weekday()],
        year: moment(date).year(),
        service_items: newServiceItems
    };

    let returnStatus = '';
    await fetch(getUrl(saveService_endpoint), { 
        method: 'POST',
        body: JSON.stringify(serviceJson),
        headers: { 'Content-Type': 'application/json' }
    })
    .then(response => {
        if (response.ok) {
            return response.text();
        }
        throw response;
    })
    .then(text => {
        returnStatus = text;
    });

    return returnStatus;
};

/**
 * Saves a new announcement to the database
 * @param {*} titleEn 
 * @param {*} titleRo 
 * @param {*} announcementEn 
 * @param {*} announcementRo 
 * @param {*} expirationDate 
 * @param {*} announcementKey 
 * @returns 
 */
export async function saveAnnouncement (titleEn, titleRo, announcementEn, announcementRo, expirationDate, announcementKey) {
    const announcementJson = {
        date:               formatSqlDate(), 
        title_en:           titleEn ? titleEn : NULL,
        title_ro:           titleRo ? titleRo : NULL,
        announcement_en:    announcementEn ? announcementEn : NULL,
        announcement_ro:    announcementRo ? announcementRo : NULL,
        expiration_date:    expirationDate ? formatSqlDate(expirationDate) : formatSqlDate(dayjs().add(30, 'day')),
        announcement_id:    announcementKey ? announcementKey : NEW_KEY
    };

    let returnStatus = '';
    await fetch(getUrl(saveAnnouncement_endpoint), {
        method: 'POST',
        body: JSON.stringify(announcementJson),
        headers: { 'Content-Type': 'application/json' }
    })
    .then(response => {
        if (response.ok) {
            return response.text();
        }
        throw response;
    })
    .then(text => {
        returnStatus = text;
    });

    return returnStatus;
};

/**
 * Updates an old announcement
 * @param {*} newAnnouncement - The new announcement object to be updated
 */
export async function updateSingleAnnouncement(newAnnouncement) {
    const announcementJson = {
        title_en:           newAnnouncement.SUBJECT_EN ? newAnnouncement.SUBJECT_EN : NULL,
        title_ro:           newAnnouncement.SUBJECT_RO ? newAnnouncement.SUBJECT_RO : NULL,
        announcement_en:    newAnnouncement.DESCRIPTION_EN ? newAnnouncement.DESCRIPTION_EN : NULL,
        announcement_ro:    newAnnouncement.DESCRIPTION_RO ? newAnnouncement.DESCRIPTION_RO : NULL,
        expiration_date:    newAnnouncement.EXPIRE_ON ? formatSqlDate(newAnnouncement.EXPIRE_ON) : formatSqlDate(dayjs().add(30, 'day')),
        announcement_id:    newAnnouncement.ANNOUNCEMENT_KEY ? newAnnouncement.ANNOUNCEMENT_KEY : NEW_KEY
    };

    const fetchResponse = await fetchUpdateAnnouncement(announcementJson);
    return fetchResponse; 
}

/**
 * Deletes an announcement from the database 
 * @param {*} announcement_key 
 */
export async function deleteSingleAnnouncement(announcement_key) {
    const announcementJson = {
        announcement_key: announcement_key
    };

    const fetchResponse = await fetchDeleteAnnouncement(announcementJson);
    return fetchResponse;
}
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Userfront from "@userfront/react";
import { loginPath } from '../../constants';


const RequireAuth = ({ children }) => {
    let location = useLocation();
    if (!Userfront.tokens.accessToken) {
      // Redirect to the /login page
      return <Navigate to={loginPath} state={{ from: location }} replace />;
    }
    else {
      return <div></div>
    }
  };

  export default RequireAuth;
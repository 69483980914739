import React, { useState } from 'react';
import PanelEntryBox from '../panelEntryBox';

const PanelMessageEntry = (props) => {
    const { data, from, handlePointTimestamp } = props;
    const [content, setContent] = useState();

    /**
     * This use state is used to update each service box
     */
    useState(() => {
        setContent(
            data.map((eventData) =>
        <PanelEntryBox key={eventData.id} eventData={eventData} from={from} handlePointTimestamp={handlePointTimestamp}/>));
    }, [data]);

    return (
        <div>
            {content}
        </div>
    );
};

export default PanelMessageEntry;
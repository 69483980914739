import { useState } from "react";
import { greetingString, LightBlue, LightDarkGray } from "../../../constants";
import { useAppSelector } from "../../../app/hooks";
import { selectLanguage } from "../../../app/slices/languageSlice";

const greetingEntityStyle = {
  fontWeight: "500",
};

const hoverStyle = {
  color: LightBlue,
  cursor: "pointer",
  paddingBottom: "10px",
  fontSize: "14px",
};

const mainStyle = {
  color: LightDarkGray,
  fontSize: "14px",
  paddingBottom: "10px",
};

const GreetingPoint = (props) => {
  const language = useAppSelector(selectLanguage);
  const { servicePoint, handlePointTimestamp } = props;
  const [greetingStyle, setGreetingStyle] = useState(mainStyle);

  const handleMouseEnter = () => {
    setGreetingStyle(hoverStyle);
  };

  const handleClick = () => {
    handlePointTimestamp(servicePoint.START_TIMESTAMP_UNIX);
  };

  const handleMouseLeave = () => {
    setGreetingStyle(mainStyle);
  };

  return (
    <div
      style={greetingStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <span style={greetingEntityStyle}>{greetingString(language)}</span>
      {/** TODO: In the future, we should add to these points to include gretting details */}
    </div>
  );
};

export default GreetingPoint;

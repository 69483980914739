import {
  finishedLoadingMessage,
  LightDarkGray,
  resultsString,
  searchingNotReadyString,
} from "../../../constants";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import SearchResult from "./searchResult";
import { useAppSelector } from "../../../app/hooks";
import { selectLanguage } from "../../../app/slices/languageSlice";

const mainStyle = {
  marginTop: "10px",
  width: "100%",
  dispaly: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const listStyle = {
  width: "100%",
  height: "100vh",
  overflow: "scroll",
};

const SearchResults = (props) => {
  const language = useAppSelector(selectLanguage);
  const {
    searchFor,
    results,
    hasMore,
    getMore,
    handlePointTimestamp,
    handleAddResultToResults,
  } = props;

  const handleScroll = (event) => {
    const element = event.target;
    if (
      Math.ceil(element.scrollHeight - element.scrollTop) <=
        element.clientHeight + 2 &&
      hasMore
    ) {
      getMore();
    }
  };

  return (
    <div style={mainStyle}>
      <Divider
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center",
          margin: "0 auto",
        }}
      />
      <Typography
        variant="p"
        sx={{
          color: LightDarkGray,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          fontSize: "12px",
          paddingX: "10px",
        }}
      >
        {searchingNotReadyString(language)}
      </Typography>
      <Typography
        variant="h5"
        sx={{
          color: LightDarkGray,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        {resultsString(language)}
      </Typography>

      <div style={listStyle} onScroll={handleScroll}>
        {results &&
          results.map((result, index) => (
            <SearchResult
              searchFor={searchFor}
              result={result}
              key={index}
              index={index}
              handlePointTimestamp={handlePointTimestamp}
              handleAddResultToResults={handleAddResultToResults}
            />
          ))}
        {results && results.length > 0 && (
          <Typography
            variant="p"
            sx={{
              color: LightDarkGray,
              paddingBottom: "400px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              fontSize: "12px",
            }}
          >
            {finishedLoadingMessage(language)}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default SearchResults;

/*
 * HELPER FUNCTIONS FOR SERVICE POINTS
*/
import { bethelChoirString, bethelChoirWithPraiseBandString, girlsChoirString, kidsChoirString, mensChoirString, seniorChoirString, seniorChoirWithPraiseBandString, unitedChoirString, youthChoirString, youthChoirWithPraiseBandString, kidsChoirWithPraiseBandString, unitedChoirWithPraiseBandString, getDefaultString, orchestraString, symphonicString, instrumentEnsemble, worshipString, mensGroupString, childrensGroupString, youthGroupString, worshipGroupString, ladiesGroupString, mixedGroupString, generalAnnouncementsString, worshipType, EN } from "../constants";
import * as dayjs from 'dayjs';
import { newMomentDay } from "./dateHelper";

/**
 * Formats a song entity title to reflect what the display name should be
 * @param {*} language - The language the title should be displayed in, based on current selected language
 * @param {*} songEntity - What the song is - ex: choir, orchestra, worship team, group, instrumental, other...
 * @param {*} songSubEntity - Sub-Entity of the song - ex: If choir, could be Senior Choir, Youth Choir, Kids Choir...
 * @param {*} groupTitleEn - If the group has a name, this is the name in English
 * @param {*} groupTitleRo - If the group has a name, this is the name in Romanian
 */
export const getSongEntityTitle = (language, songEntity, songSubEntity, groupTitleEn, groupTitleRo) => {
    const hasSubEntityList = ['Choir', 'Orchestra', 'Group', 'Other'];

    const hasGroupTitle = groupTitleRo || groupTitleEn;
    const groupTitle = getGroupTitleDisplayName(language, groupTitleEn, groupTitleRo, songEntity);
    
    if (hasSubEntityList.includes(songEntity)) {
        switch (songSubEntity) {
            /**************************************************************************************
            * CHOIR CASES
            **************************************************************************************/
            case 'Senior Choir':
                return hasGroupTitle ? groupTitle : seniorChoirString(language); 
            case 'Youth Choir':
                return hasGroupTitle ? groupTitle : youthChoirString(language);
            case 'Mens Choir':
                return hasGroupTitle ? groupTitle : mensChoirString(language);
            case 'Girls Choir':
                return hasGroupTitle ? groupTitle : girlsChoirString(language);
            case 'Kids Choir':
                return hasGroupTitle ? groupTitle : kidsChoirString(language);
            case 'United Choir':
                return hasGroupTitle ? groupTitle : unitedChoirString(language);
            case 'Bethel Choir': // This is youth choir and senior choir together
                return hasGroupTitle ? groupTitle : bethelChoirString(language);
            case 'Senior Choir Praise Band':
                return hasGroupTitle ? groupTitle : seniorChoirWithPraiseBandString(language);
            case 'Youth Choir Praise Band':
                return hasGroupTitle ? groupTitle : youthChoirWithPraiseBandString(language);
            case 'Kids Choir Praise Band':
                return hasGroupTitle ? groupTitle : kidsChoirWithPraiseBandString(language);
            case 'Bethel Choir Praise Band': // This is youth choir and senior choir with praise band
                return hasGroupTitle ? groupTitle : bethelChoirWithPraiseBandString(language);
            case 'United Choir Praise Band': // Kids Choir, Youth Choir, and Senior Choir
                return hasGroupTitle ? groupTitle : unitedChoirWithPraiseBandString(language);
            /**************************************************************************************
            * ORCHESTRA CASES
            **************************************************************************************/
            case 'Orchestra': // This is the full orchestra with strings
                return hasGroupTitle ? groupTitle : orchestraString(language);
            case 'Symphonic': // This is a full orchestra without strings
                return hasGroupTitle ? groupTitle : symphonicString(language);
            case 'Instrument Ensemble': // Classified as smaller groups of orchestra instruments
                return hasGroupTitle ? groupTitle : instrumentEnsemble(language);
            /**************************************************************************************
            * GROUP CASES
            **************************************************************************************/
            case 'Duet':
                return hasGroupTitle ? groupTitle : getDefaultString(language, 'Duet'); // Duet should always have a group title. If not, something is wrong
            case 'Trio':
                return hasGroupTitle ? groupTitle : getDefaultString(language, 'Trio'); // Trio should always have a group title.
            case 'Quartet':
                return hasGroupTitle ? groupTitle : getDefaultString(language, 'Quartet');
            case 'Mens Group':
                return hasGroupTitle ? groupTitle : mensGroupString(language);
            case 'Childrens Group':
                return hasGroupTitle ? groupTitle : childrensGroupString(language);
            case 'Youth Group':
                return hasGroupTitle ? groupTitle : youthGroupString(language);
            case 'Worship Group':
                return hasGroupTitle ? groupTitle : worshipGroupString(language);
            case 'Ladies Group':
                return hasGroupTitle ? groupTitle : ladiesGroupString(language);
            case 'Mixed Group':
                return hasGroupTitle ? groupTitle : mixedGroupString(language);
            case 'Other Group':
                return hasGroupTitle ? groupTitle : getDefaultString(language, 'Group');
            default:
                return groupTitle; // Here, this should be the default choir name
        }
    }
    else {
        // This is for, worship team, solo, instrumental

        // Worship should never be changed. If there is anything but a worship team, it is just a group
        // Worship should be categorized as 'worship' in English and something else in Romanian'
        if (songEntity === worshipType) { // Write in that it's rare for a worship team to have a name, and that it's usually reserved for guests
            return hasGroupTitle ? groupTitle : worshipString(language);
        }
        else if (songEntity === 'Solo') {
            return hasGroupTitle ? groupTitle : getDefaultString(language, songEntity); // There should always be a group title for a solo, which will just be the name
        }
        else if (songEntity === 'Poem') {
            return hasGroupTitle ? groupTitle : getDefaultString(language, songEntity);
        }
        else if (songEntity === 'Instrumental') {
            if (hasGroupTitle) { return groupTitle };

            if (songSubEntity === 'Solo') {
                return getDefaultString(language, 'Solo'); // A solo should always have a group title
            }
            else if (songSubEntity === 'Duet') {
                return getDefaultString(language, 'Duet');
            }
            else if (songSubEntity === 'Trio') {
                return getDefaultString(language, 'Trio');
            }
            else if (songSubEntity === 'Quartet') {
                return getDefaultString(language, 'Quartet');
            }
            else {
                return getDefaultString(language, songEntity);
            }
        }

        return '';
    }
};

/**
 * Gets display name of group title based on current language selected and languages available of the group title
 * @param {*} language - The language the title should be displayed in, based on current selected language
 * @param {*} groupTitleEn - If the group has a name, this is the name in English
 * @param {*} groupTitleRo - If the group has a name, this is the name in Romanian
 */
const getGroupTitleDisplayName = (language, groupTitleEn, groupTitleRo, songEntity) => {
    // If neither languagae is available, return default group string in case it is needed
    if (!groupTitleRo && !groupTitleEn) {
        return getDefaultString(language, songEntity);
    }
    // If both languages are available, return the language that's currently selected
    else if (groupTitleEn && groupTitleRo) {
        return language === EN ? groupTitleEn : groupTitleRo;
    }
    // If only English is available, return it
    else if (groupTitleEn) {
        return groupTitleEn;
    }
    // If only Romanian is available, return it
    else if (groupTitleRo) {
        return groupTitleRo;
    }
    
    // Return a default null string here
    return getDefaultString(language, songEntity);
};

/**
 * Gets the display song title based on song language and current language
 * @param {*} songLanguage - The language that the song is in. Will be English, Romanian, Both, or Neither
 * @param {*} language - The current language selected of the website
 * @param {*} songTitleEn - The English song title
 * @param {*} songTitleRo - The Romanian song title
 */
export const getSongTitle = (songLanguage, language, songTitleEn, songTitleRo) => {
    if (songLanguage === 'English') {
        if (language === EN) { return songTitleEn }
        return songTitleRo ? `${songTitleEn} (${songTitleRo})` : songTitleEn;
    }
    else if (songLanguage === 'Romanian') {
        if (language === 'ro') { return songTitleRo };
        return songTitleEn ? `${songTitleRo} (${songTitleEn})` : songTitleRo;
    }
    else if (songLanguage === 'Both') {
        return `${songTitleEn}/${songTitleRo}`; // When selecting 'both', it should force both titles
    }
    else {
        if (songTitleEn && songTitleRo) {
            return `${songTitleEn}/${songTitleRo}`; 
        }
        else if (songTitleEn) {
            return songTitleEn;
        }
        else if (songTitleRo) {
            return songTitleRo;
        }
        else {
            return '';
        }
    }
};

/**
 * Returns the display name of a speaker
 * @param {*} personTitle - Title - ex. Senior Pastor, Deacon, more...
 * @param {*} speaker - Name of speaker
 * @param {*} locationFrom - Where they are from, if not from our local church
 */
export const getSpeaker = (personTitle, speaker, locationFrom, language) => {
    return `${personTitle ? personTitle+' ' : ''}${speaker}${locationFrom ? ' ('+locationFrom+')' : ''}`;
};

/**
 * Returns the display name of a sermon title based on current language and available titles
 * @param {*} language - current language of the website
 * @param {*} sermonTitleEn - Sermon title in English
 * @param {*} sermonTitleRo - Sermon title in Romanian
 */
export const getSermonTitle = (language, sermonTitleEn, sermonTitleRo) => {
    if (language === EN) {
        if (sermonTitleEn) { return sermonTitleEn; }
        else if (sermonTitleRo) { return sermonTitleRo; }
        else { return ''; };
    }
    else {
        if (sermonTitleRo) { return sermonTitleRo; }
        else if (sermonTitleEn) { return sermonTitleEn; }
        else { return ''; }
    }
};

/**
 * Gets the announcement or announcer based on the language for service points
 * @param {*} language - the current language of the website
 * @param {*} generalTitleEn - holds the announcement/announcer in English
 * @param {*} generalTitleRo - holds the announcement/announcer in Romanian
 */
export const getAnnouncerTitle = (language, generalTitleEn, generalTitleRo) => {
    if (language === EN) {
        if (generalTitleEn) { return generalTitleEn; }
        else if (generalTitleRo) { return generalTitleRo; }
        else { return generalAnnouncementsString(language); }
    }
    else {
        if (generalTitleRo) { return generalTitleRo; }
        else if (generalTitleEn) { return generalTitleEn; }
        else { return generalAnnouncementsString(language); }
    }
};

/**
 * Returns the baby name of a child
 * @param {*} babyFirstName 
 * @param {*} babyMiddleName 
 * @param {*} babyLastName 
 */
export const getBabyName = (babyFirstName, babyMiddleName, babyLastName) => {
    if (babyFirstName || babyMiddleName || babyLastName) {
        let babyDisplayName = ':';
        if (babyFirstName) { babyDisplayName += ' ' + babyFirstName; }
        else if (babyMiddleName) { babyDisplayName +=  ' ' + babyMiddleName; }
        else if (babyLastName) { babyDisplayName += ' ' + babyLastName; }

        return babyDisplayName;
    }
    else {
        return '';
    }
};

/**
 * Returns the display name of parents for a baby dedication
 * @param {*} familyName - Family last name
 * @param {*} fatherName - Father's first name
 * @param {*} motherName - Mother's last name
 */
export const getParentsDisplay = (familyName, fatherName, motherName) => {
    if (fatherName && motherName) {
        return `${fatherName} & ${motherName} ${familyName}`;
    }
    else if (fatherName) {
        return `${fatherName} ${familyName}`;
    }
    else if (motherName) {
        return `${motherName} ${familyName}`;
    }
    else {
        return familyName;
    }
};

/**
 * On the client-side, when looking at the service editor, creates a new empty service item
 * @param {*} serviceKey 
 */
export const createNewServiceItem = (serviceKey) => {
    return {
        service_item_key: '',
        baby_first_name: '',
        baby_middle_name: '',
        encouragement_for: '',
        family_name: '',
        father_first_name: '',
        general_description_en: '',
        general_description_ro: '',
        general_title_en: '',
        general_title_ro: '',
        group_title_en: '',
        group_title_ro: '',
        language: '',
        location_from: '',
        mother_first_name: '',
        passage: '',
        person_title: '',
        series_title_en: '',
        series_title_ro: '',
        sermon_title_en: '',
        sermon_title_ro: '',
        service_key: serviceKey,
        song_entity: '',
        song_sub_entity: '',
        song_title_en: '',
        song_title_ro: '',
        speaker: '',
        speaker_key: undefined,
        start_timestamp: dayjs(),
        start_timestamp_unix: newMomentDay().unix(),
        type: '',
        item_type: '',
        item_secondary_type: '',
        personnel: [],
        with_praise_band: false,
    }; 
};

/**
 * Formats the title on the UI side of a how a service item title is displayed
 * @param {*} serviceData 
 */
export const getServiceItemTitle = (serviceData) => {
    // TODO: We probably shouldn't pass in an entire object here. We should only pass in the actual type so that we aren't moving a massive serviceObject
    return serviceData.type ? serviceData.type : 'New Service Item';
};

/**
 * This function returns the description of a service item
 * @param {*} serviceData 
 */
export const getServiceItemDescription = (serviceData) => {
    return ''; // TODO: Finish the logic heres
};

/**
 * Sorts service items in-order by their service keys for displaying in the right order
 * @param {*} serviceData 
 */
export const getSortedServiceData = (serviceData) => {
    const sortNumerically = (a, b) => {
        if (Number(a.service_item_key) < Number(b.service_item_key)) {
            return -1;
        }
        if (Number(a.service_item_key) > Number(b.service_item_key)) {
            return 1;
        }
        return 0;
    };

    return serviceData.sort(sortNumerically);
};
import { Button } from "@mui/material";
import {
  BY_DATE,
  BY_RECENT,
  mostRecentString,
  selectDateString,
} from "../../constants";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../app/slices/languageSlice";

const DateSortButtonGroup = (props) => {
  const language = useAppSelector(selectLanguage);
  const { handleSortByChange, sortBy } = props;

  const handleButtonClick = (event) => {
    handleSortByChange(event.target.value);
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "1",
      }}
    >
      <Button
        value={BY_RECENT}
        sx={{ zIndex: "0" }}
        size="small"
        variant={sortBy === BY_RECENT ? "contained" : "text"}
        onClick={handleButtonClick}
      >
        {mostRecentString(language)}
      </Button>
      <Button
        value={BY_DATE}
        sx={{ zIndex: "0" }}
        size="small"
        variant={sortBy === BY_DATE ? "contained" : "text"}
        onClick={handleButtonClick}
      >
        {selectDateString(language)}
      </Button>
    </div>
  );
};

export default DateSortButtonGroup;

import React, { useEffect, useState } from "react";
import BackgroundReel from "./backgroundReel/backgroundReel";
import Button from "@mui/material/Button";
import {
  BBC_SLOGAN,
  latestServiceString,
  LightBlue,
  liveNowString,
  servicePath,
  welcomeString,
} from "../../constants";
import { getUrl } from "../../helpers/api/api";
import { liveStatus_endpoint } from "../../helpers/api/apiConstants";
import UrgentMessageDisplay from "../../components/UrgentMessageDisplay";
import LiveVideo from "../../components/liveVideo";
import { useTheme } from "@mui/material";
import word_logo from "../../assets/logos/word_logo_white.png";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../app/slices/languageSlice";

/*
STYLES
*/
const welcomeDisplayStyle = {
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
  fontFamily: "Montserrat",
  color: "white",
  zIndex: "10",
  fontWeight: "600",
  fontSize: "min(13vw, 70px)",
};

const latestServiceStyle = {
  fontWeight: "500",
  fontSize: "min(3.1vw, 20px)",
};

/*
FUNCTIONS
*/

const Home = (props) => {
  const language = useAppSelector(selectLanguage);
  const [statusKnown, setStatusKnown] = useState(false);
  const [live, setLive] = useState(false);
  const [, setLiveOn] = useState("");
  const [liveLink, setLiveLink] = useState("");
  const [liveFeedOpen, setLiveFeedOpen] = useState(false);
  const theme = useTheme();

  // Adding window size state
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    if (!statusKnown) {
      fetch(getUrl(liveStatus_endpoint))
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          setStatusKnown(true);
          setLive(data.IS_LIVE);
          setLiveOn(data.LIVE_ON);
          setLiveLink(data.LINK);
        })
        .catch((error) => {
          console.error("Error setting live status. Error: ", error);
        });
    }

    // Event listener for window resize
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [statusKnown]);

  const handleLiveFeedVideoClose = () => {
    setLiveFeedOpen(false);
  };

  const handleButtonPress = () => {
    if (!live) {
      window.location.href = servicePath;
    } else {
      if (liveLink.includes("youtube") && !liveLink.includes("embed")) {
        window.open(liveLink, "_blank");
      } else {
        setLiveFeedOpen(true);
      }
    }
  };

  const conditionalDisplayStyle =
    windowSize < 970
      ? {
          display: "block",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100%",
          paddingTop: "0px",
          textAlign: "center",
        }
      : { display: "none" };

  const subtitleStyle =
    windowSize >= 970
      ? {
          fontFamily: theme.typography.fontFamily,
          fontSize: "20px",
          fontWeight: "300",
          paddingTop: "10px",
          marginTop: "0",
          paddingBottom: "15px",
        }
      : {
          fontFamily: theme.typography.fontFamily,
          fontSize: "10px",
          fontWeight: "300",
          paddingTop: "0",
          marginTop: "0",
          paddingBottom: "15px",
        };

  return (
    <div
      style={{
        minHeight: `calc(100vh - ${props.size.footerSize}px)`,
        paddingTop: "43px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        backgroundColor: theme.palette.secondary.main,
      }}
    >
      <UrgentMessageDisplay page="Home" />
      <BackgroundReel />
      <div
        style={{
          ...welcomeDisplayStyle,
          fontFamily: theme.typography.secondaryFontFamily,
        }}
      >
        {windowSize >= 970 && (
          <React.Fragment>
            {welcomeString(language)}
            <div style={subtitleStyle}>{BBC_SLOGAN}</div>
          </React.Fragment>
        )}
        <div style={conditionalDisplayStyle}>
          <img
            src={word_logo}
            alt="Bethel Bible Church"
            id="logo"
            style={{
              paddingBottom: "0",
              paddingTop: "15px",
              marginBottom: "0",
            }}
          />
          <div
            style={{
              fontFamily: theme.typography.fontFamily,
              fontSize: "10px",
              fontWeight: "300",
              paddingTop: "0",
              marginTop: "0",
              paddingBottom: "15px",
              textAlign: "center",
            }}
          >
            {BBC_SLOGAN}
          </div>
        </div>

        <Button
          variant="contained"
          onClick={handleButtonPress}
          target={live ? "_blank" : ""}
          size="large"
          sx={{
            color: "white",
            backgroundColor: theme.palette.primary.main,
            opacity: "90%",
            fontFamily: "Montserrat",
            textTransform: "none",
            fontWeight: "600",
            "&:hover": {
              color: LightBlue,
              backgroundColor: "white",
              opacity: "80%",
            },
          }}
          style={latestServiceStyle}
        >
          {live ? liveNowString(language) : latestServiceString(language)}
        </Button>
      </div>
      <LiveVideo
        videoLink={liveLink}
        open={liveFeedOpen}
        onClose={handleLiveFeedVideoClose}
      />
    </div>
  );
};

export default Home;
